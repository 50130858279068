import axios, { AxiosInstance } from "axios";
import { ISdkSearchRequest } from "./models/ISdkSearchRequest";

export class SdkClient<T>
{
    private axiosClient: AxiosInstance;    
    private resourcePrefix: string;
    private pathPrefix: string;    

    constructor(private baseUri: string, pathPrefix: string, resourcePrefix: string, private token: string, private optionalHeaders?: {[key: string]: string}) {            
        this.axiosClient = axios.create({    
            baseURL: baseUri
        });        

        this.pathPrefix = pathPrefix
        this.resourcePrefix = resourcePrefix       
        
        if (this.token && this.token.length > 0) { 
            this.axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }

        if ( optionalHeaders) {
            for(let key in optionalHeaders) {
                this.axiosClient.defaults.headers.common[key] = optionalHeaders[key];
            }
        }        
    }
        
    get(relativeUri: string) : Promise<T> {        
        return this.axiosClient.get(this.pathPrefix + this.resourcePrefix + relativeUri).then((response) => {
            return response.data;
        })    
    }

    getIndirectType<M>(relativeUri: string) : Promise<M> {        
        return this.axiosClient.get(this.pathPrefix + this.resourcePrefix + relativeUri).then((response) => {
            return response.data;
        })    
    }
    
    getFromAbsoluteUri(absoluteUri: string) : Promise<T> {        
        return this.axiosClient.get(absoluteUri).then((response) => {
            return response.data;
        })            
    }

    list(relativeUri: string) : Promise<T[]> {
        return this.axiosClient.get(this.pathPrefix + this.resourcePrefix + relativeUri).then((response) => {
            return response.data;
        })
    }

    delete(relativeUri: string) : Promise<Boolean> {
        return this.axiosClient.delete(this.pathPrefix + this.resourcePrefix + relativeUri).then((response) => {
            return response.status === 200;
        })
    }    

    post<T>(relativeUri: string, data: T) : Promise<string | undefined> {
        return this.axiosClient.post(this.pathPrefix + this.resourcePrefix + relativeUri, data).then((response) => {            
            if (response.headers["location"])
                return response.headers["location"];            
            else
                return undefined            
        })
    }

    postToGetBody<T>(relativeUri: string, data: T) : Promise<any> {
        return this.axiosClient.post(this.pathPrefix + this.resourcePrefix + relativeUri, data).then((response) => {            
            return response.data            
        })
    }

    postToGetStatus<T>(relativeUri: string, data: T) : Promise<Boolean> {
        return this.axiosClient.post(this.pathPrefix + this.resourcePrefix + relativeUri, data).then((response) => {            
            return response.status === 200 || response.status === 201;
        })
    }

    put<T>(relativeUri: string, data: T) : Promise<string | undefined> {
        return this.axiosClient.put(this.pathPrefix + this.resourcePrefix + relativeUri, data).then((response) => {            
            if (response.headers["location"])
                return response.headers["location"];            
            else
                return undefined            
        })
    }

    search<T>(relativeUri: string, searchRequest: ISdkSearchRequest<any>) : Promise<T[]> {
        return this.axiosClient.post(this.pathPrefix + this.resourcePrefix + relativeUri, searchRequest).then((response) => {            
            return response.data;
        })
    }    
}