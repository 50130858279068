import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';

export interface IStackBarChartEasyDataItem {
    categoryValues: number[]
    seriesName: string
}
export interface IStackBarChartEasyProps {    
    items: IStackBarChartEasyDataItem[]    
    categories: { id: string, name: string }[]    
    showStackLabels?: boolean
}

export const StackBarChartEasy = (props: IStackBarChartEasyProps) => {

    const [options, setOptions] = useState<Highcharts.Options>()
 
    const getYwithErrorLogging = (name: string, y: number | undefined) : { name: string, y: number} => { 

        if (y === undefined || y === null || isNaN(y)) 
        {            
            return { name: name, y: 0 }
        } else {
            return { name: name, y: y }
        }
    }

    const getStackLabels = (): Highcharts.YAxisStackLabelsOptions | undefined => {
        return props.showStackLabels ? 
        {
            enabled: true,
            align: 'center',

        } : undefined 
    }

    useEffect(() => {
        
        // get the minimal value
        let minValueFromData = getMinimumValueInData(props.items)
        if (!minValueFromData || minValueFromData > 0)
            minValueFromData = 0

        // build the options
        setOptions({            
            title: {            
                text: undefined
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: props.categories.map(p => p.name)
            },
            yAxis: {
                min: minValueFromData,
                title: {
                    text: null
                }, 
                stackLabels: getStackLabels()    
            },
            legend: {
                enabled: false
            },            
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: props.items.map(item => {
                return {
                    name: item.seriesName,
                    type: 'column',
                    data: item.categoryValues.map((value) => getYwithErrorLogging(item.seriesName, value))                                                              
                }
            })            
        })

    // eslint-disable-next-line
    }, [props.categories, props.items, props.showStackLabels])
    
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    chartComponentRef.current?.chart?.reflow();

    return (
        <HighchartsReact 
            highcharts={Highcharts}             
            containerProps={{ style: { height: "100%" } }}
            options={options} 
            ref={chartComponentRef} 
            {...props} />                                                        
    )    
}

function getMinimumValueInData(items: IStackBarChartEasyDataItem[]) {

    // get the minimal value in all items from from the category values
    let minValue = 0
    items.forEach(item => {
        const min = Math.min(...item.categoryValues)
        if (min < minValue)
            minValue = min
    })
    
    return minValue
}
