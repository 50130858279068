import './AppPersonalCallout.scss';

import { Callout, Icon, Persona, PersonaSize, Stack } from "@fluentui/react"
import { Link, useParams } from "react-router-dom"
import { useProfileContext } from "../../authentication/AuthenticationProfileContext"
import { truncate } from '../../common/StringHelpers';
import { LinkEx } from '../../components/Generic/LinkEx';

export interface AppPersonaCalloutProps {
    isVisible: boolean
    targetId: string   
    onDismiss?: () => void; 
    onShowAuditLog?: (visible:boolean) => void;
}

export const AppPersonaCallout = (props: AppPersonaCalloutProps) => {

    const profileContext = useProfileContext();
    const { tenantId } = useParams();    

    const currentTenant = profileContext.profile.tenants.find(t => t.id === tenantId)

    let tenantName = '';    
    if (tenantId) {
        const tenant = profileContext.profile.tenants.find(t => t.id === tenantId);
        if (tenant) {
            tenantName = tenant.name;
        }
    }

    const dissmissCallout = () => {
        if (props.onDismiss)
            props.onDismiss()
    }
    
    return (
        <Callout 
            className={'mv-app-personal-callout'}
            hidden={!props.isVisible} 
            role="dialog"
            gapSpace={20}
            target={'#' + props.targetId}            
            setInitialFocus>
                
            <Stack className={'mv-app-personal-callout-persona'}>
                <Stack horizontal disableShrink horizontalAlign={'space-between'} className={'headline'} style={{display:  tenantName === '' ? 'none' : 'flex'}}>
                    <Stack.Item align="start">
                        <span hidden={profileContext && profileContext.profile && profileContext.profile.tenants && profileContext.profile.tenants.length === 1}>
                            {truncate(tenantName, 20)} (<Link to='/welcome/select' className={'logout-link'}>switch</Link>)
                        </span>
                    </Stack.Item>

                    <Stack.Item align="end" >
                        <Link to='/logout' className={'logout-link logout-link-end'}>Logout</Link>
                    </Stack.Item>
                </Stack>
                <Stack.Item>
                    <Persona 
                        imageUrl={profileContext.profile.avatarUri} 
                        text={profileContext.profile.firstName + ' ' + profileContext.profile.lastName}
                        secondaryText={profileContext.profile.contact}
                        size={PersonaSize.size56}  
                        showSecondaryText={true}                                                                               
                        />
                </Stack.Item>
            </Stack>
            { currentTenant && !currentTenant.managed &&
                <Stack className={'mv-app-personal-callout-actions'}> 
                    { currentTenant && !currentTenant.managed &&
                    <Stack.Item>
                        <LinkEx to={'/welcome/signup'} className={'logout-link'} onNavigated={dissmissCallout}>
                            <Icon iconName='faPlus' />&nbsp;Register new tenant                    
                        </LinkEx>
                    </Stack.Item>}                    
                    <Stack.Item>
                        <LinkEx to={''} className={'logout-link'} onNavigated={dissmissCallout} onNavigate={() => { if (props.onShowAuditLog) { props.onShowAuditLog(true) } }}>
                            <Icon iconName='faListTimeline' />&nbsp;Personal Activity Log                     
                        </LinkEx>
                    </Stack.Item>
                    <Stack.Item>                                 
                        <LinkEx to="https://mevodo-org.myfreshworks.com/login/auth/1671963070430?client_id=78368727158800390&redirect_uri=https%3A%2F%2Fhelp.mevodo.com%2Ffreshid%2Fcustomer_authorize_callback" target="_new" className='logout-link' onNavigated={dissmissCallout}>
                            <Icon iconName='faTicket' />&nbsp;Online Help & Support                    
                        </LinkEx>
                    </Stack.Item>    
                    <Stack.Item>
                        <LinkEx to={'/tenants/' + currentTenant.id + '/settings/status' } className={'logout-link'} onNavigated={dissmissCallout}>
                            <Icon iconName='faRadar' />&nbsp;Service Status                    
                        </LinkEx>                        
                    </Stack.Item>                                     
                </Stack>}                
        </Callout>
    )
}