import { IButtonProps, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react"

export interface IAnimatedSubmitButtonProps {
    processing: boolean
    processingText?: string
}

export const AnimatedSubmitButton = (props: IAnimatedSubmitButtonProps & IButtonProps) => {

    
    const renderText = () => {
        if (props.processing) {
            return (
                <span className="ms-Button-label" style={{display: 'flex'}}>                    
                    <Spinner size={SpinnerSize.xSmall} />
                    { props.processingText ? (<span style={{marginLeft: '5px'}}>{props.processingText}</span>) : (<></>) }
                </span>
            )
        } else {
            return (<span className="ms-Button-label label-128">{props.text || props.title}</span>)
        }
    }

    return(
        <PrimaryButton {...props} type="submit" onRenderText={renderText} readOnly={props.processing}>                        
        </PrimaryButton>
    )
}