import { PageHeader } from "../lib/ds/components/PageHeader"

export interface IServiceProviderDevelopmentCenterPowershellProps {

}

export const ServiceProviderDevelopmentCenterPowershell = (props: IServiceProviderDevelopmentCenterPowershellProps) => {    
    
    return (
        <div className="mv-content-container">
            <PageHeader title={'Powershell SDK'}>            
                <span>
                    The system supports a comprehensive set on powershell command-lets which makes it easy to 
                    build data integrations. An in-depth documentation is available in the knowledge base as 
                    described below.
                </span>
                <ul>
                    <li><a href="https://help.mevodo.com/support/solutions/articles/103000259749-getting-started-with-powershell-core" target="_blank" rel="noreferrer">Getting started with Powershell</a></li>
                    <li><a href="https://help.mevodo.com/support/solutions/folders/103000615711" target="_blank" rel="noreferrer">Scenarios for the Powershell SDK</a></li>
                </ul>
            </PageHeader>                    
        </div>
    )
}