import { useParams } from "react-router-dom"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { ReportManagementComponent } from "../lib/components/Reports/ReportManagementComponent"

export interface IServiceProviderReportsManagementProps {

}

export const ServiceProviderReportsManagement = (props: IServiceProviderReportsManagementProps) => {

    const { tenantId } = useParams()    

    return ( 
        <div className="mv-content-container">
            <PageHeader title={'Reports'}>            
                <span>Allows to manage all reports avaialble in the system. Reports can be used as data sources in every dashboard to visualize the results!</span>
            </PageHeader>

            <ReportManagementComponent tenantId={tenantId as string} reportCollectionType={'serviceProviderCollection'} />        
        </div>
    )
}