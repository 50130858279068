import { useState } from "react";
import { ISdkEnvironmentBranding } from "../../sdk/models/ISdkEnvironmentBranding";
import { useComponentDidMount } from "@mevodo/mv-react-helpers";
import { SdkEnvironmentClient } from "../../sdk/SdkEnvironmentClient";
import { FullScreeLoadingIndicator } from "../../components/FullScreenLoadingIndicator";
import React from "react";

export interface BrandingContextProps {
    company: ISdkEnvironmentBranding | undefined   
}

export const emptyBrandingContextProps: BrandingContextProps = {company: undefined}

export const BrandingContext = React.createContext<BrandingContextProps>(emptyBrandingContextProps);

export const useBranding = () => {
    return React.useContext(BrandingContext);
};

export interface BrandingProviderProps {        
    children?: React.ReactNode
}

export default function BrandingProvider(props: BrandingProviderProps) {
    
    const [branding, setBranding] = useState<ISdkEnvironmentBranding>();

    useComponentDidMount(() => {
        var client = new SdkEnvironmentClient()
        client.getBranding().then((branding) => {                                      
            setBranding(branding)
        })
    })

    if (!branding) {
        return (<FullScreeLoadingIndicator />)
    } else {        
        return (
            <BrandingContext.Provider value={{company: branding}}>            
                {props.children}            
            </BrandingContext.Provider>
        );
    }
}