import './SelectorListView.scss';
import { DirectionalHint, TooltipHost } from "@fluentui/react";

export interface ISelectorListViewItemProps {
    title: string, 
    description: string,
    properties?: any
}
export interface ISelectorListViewProps {
    items: ISelectorListViewItemProps[]
    onClick: (item: ISelectorListViewItemProps) => void
}

export const SelectorListView = (props: ISelectorListViewProps) => {

    return (
        <div className="mv-selectorlistview">         
            { props.items.map((w) => {

                return (
                    <TooltipHost content={w.title} directionalHint={DirectionalHint.leftCenter} key={w.title}>
                        <div className='mv-selectorlistview-item' onClick={() => {props.onClick(w)}}>                        
                            <img src={'./assets/widget-selector-pictogram.svg'} alt={'Select a widget'}></img>
                            <div>
                                <h5>{w.title}</h5>
                                <p>{w.description}</p>
                            </div>                        
                        </div>
                    </TooltipHost>
                )
            })}            
        </div>
    )
}