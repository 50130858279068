import { PanelType, Spinner, SpinnerSize } from "@fluentui/react"
import { useAuth } from "@mevodo/mv-react-authentication"
import { useState } from "react"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { getRelativeMonthDefinition } from "../lib/components/Helpers/DateHelpers"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ISdkCustomerDatasourcePeriodConsumptionsItem } from "../lib/sdk/models/ISdkCustomerDatasource"
import { ISdkDataIntegration } from "../lib/sdk/models/ISdkDataIntegration"
import { ISdkDataintegrationDatasourceLink, ISdkDataintegrationStats } from "../lib/sdk/models/ISdkDataintegrationStats"
import { SdkCustomerDataIntegrationClient } from "../lib/sdk/SdkCustomerDataIntegrationClient"
import numbro from 'numbro';
import { ManagedLinkedCustomerRegisterDialog } from "./ManagedLinkedCustomerRegisterDialog"
import { SdkDataintegrationStatsStackBar } from "../lib/sdkdatacomponents/SdkDataintegrationStatsStackBar"
import { getCurrencySymbol } from "../lib/common/CurrencyHelpers"

export interface IManagedLinkedCustomerDataSourcesProps extends IAppWindowBaseProps {   
    tenantId: string    
    itemId: string
}

export const ManagedLinkedCustomerDataSourcesDialog = (props: IManagedLinkedCustomerDataSourcesProps) => { 

    const auth = useAuth();           
    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        
    const [dataIntegration, setDataIntegration] = useState<ISdkDataIntegration>()    
    const [dataIntegrationStats, setDataIntegrationStats] = useState<ISdkDataintegrationStats>()    
    const [hasLinkedCustomers, setHasLinkedCustomers] = useState<boolean>(false)        
    const [showRegisterDialog, setShowRegisterDialog] = useState<boolean>(false)

    // load the data integration and the linked datasources
    useEffectAsync(async () => {

        if (props.itemId === '')
            return

        setIsPreparing(true)

        const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId, auth.currentToken as string);                 
        var item = await sdkClient.getDataIntegration(props.itemId as string)
        setDataIntegration(item)

        setHasLinkedCustomers(true)

        setIsPreparing(false)
    }, [props.itemId])

    // build the columns 
    const onToNavigation = (item: ISdkDataintegrationDatasourceLink) => {        
        return 'new:/tenants/' + props.tenantId + '/svp/customers?parent=' + item.customerId + '&type=customer'
    }    

    const getConsumptionValue = (item: ISdkDataintegrationDatasourceLink, consumptionProperty: string, consumptionMonth: number) => { 
        let consumption: ISdkCustomerDatasourcePeriodConsumptionsItem = { reported: 0, payGo: 0 };
        const monthDefinition = getRelativeMonthDefinition(consumptionMonth)

        if (item.stats && item.stats[monthDefinition]) {
            consumption = item.stats[monthDefinition]

            if (consumptionProperty === 'reported')
                return numbro(consumption.reported).format('(0.00)')
            else
                return numbro(consumption.payGo).format('(0.00)')
        } else {
            return 'n/a'
        }

    }

    const getCurrency = () => getCurrencySymbol(dataIntegration ? dataIntegration.currency : '')        
    
    const buildColumns = (builder: IColumnsBuilder<ISdkDataintegrationDatasourceLink>): void  => 
    {                
        builder.IconColumn({name: "Status", headerIcon: 'faBuildings', iconName: (item) => 'facCustomer', to: onToNavigation})                        
        builder.TextColumn({name: "Customer", maxWidth: 250,  value: 'customerName', to: onToNavigation})        
        builder.TextColumn({name: "Id", maxWidth: 250,  value: 'customerId', to: onToNavigation})
        builder.CurrencyColumn({name: "Cons. (R)", currency: (item) => getCurrency(), maxWidth: 100, value: (item) => { return getConsumptionValue(item, 'reported', 0) }, to: onToNavigation})
        builder.CurrencyColumn({name: "LM Cons. (R)", currency: (item) => getCurrency(), maxWidth: 100, value: (item) => { return getConsumptionValue(item, 'reported', -1) }, to: onToNavigation})
        builder.CurrencyColumn({name: "Cons. (PG)", currency: (item) => getCurrency(), maxWidth: 100, value: (item) => { return getConsumptionValue(item, 'payGo', 0) }, to: onToNavigation})
        builder.CurrencyColumn({name: "LM Cons. (PG)", currency: (item) => getCurrency(), maxWidth: 100, value: (item) => { return getConsumptionValue(item, 'payGo', -1) }, to: onToNavigation})
    }
    
    const loadData = (): Promise<ISdkDataintegrationDatasourceLink[]> => {
        const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId, auth.currentToken as string);                 
        return sdkClient.getDataIntegrationStats(props.itemId as string).then((stats: ISdkDataintegrationStats) => {
            setHasLinkedCustomers(stats.linkedDataSources && stats.linkedDataSources.length > 0)
            setDataIntegrationStats(stats)
            return stats.linkedDataSources ? stats.linkedDataSources : []
        });
    }
    
    const onNewItem = () => {
        setShowRegisterDialog(true)
        return Promise.resolve('')
    }

    const onCreateCustomer = (customerId: string, customerName: string) => {
        const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId, auth.currentToken as string);
        return sdkClient.registerLinkedDatasource(props.itemId, customerId, customerName).then(() => {
            return Promise.resolve()
        })        
    }

    const manualCustomerManagement = dataIntegration && !dataIntegration.manageCustomers ? true : false

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Managed Linked Customer Datasources'}
            onDismiss={props.dismissDialog}  
            type={PanelType.large}                                              
            progessMessage={''}
            submitLabel={'Close'}
            dissmissLabel={'Cancel'}
            noDissmissButton={true}
            isValid={true}            
            onSubmit={() => { return Promise.resolve() }}>

                { isPreparing ? (
                    <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                ) : (
                    <div>
                        <p>The data integration <strong>{dataIntegration?.name} ({dataIntegration?.id})</strong> is managing the following linked customer datasources. 
                            { dataIntegration?.manageCustomers ? 
                                'The integration is managing all customer fully automatically, no adjustments are possible' : 
                                'The integration allows to adust the customer links manually in the list below'}:
                        </p>   

                        { dataIntegrationStats && dataIntegrationStats.linkedDataSources && dataIntegrationStats.linkedDataSources.length > 0 && (
                            <SdkDataintegrationStatsStackBar item={dataIntegrationStats} height={180} />
                        )}
                        
                        { hasLinkedCustomers || manualCustomerManagement ? (
                            <NavigationalDetailsListWithStandardOperations
                                uniqueIdentifier='datasourcelinks'   
                                embedded={true}
                                hideReferesh={true}       
                                disabledItemSelect={true}                  
                                onBuildColumns={buildColumns}
                                onLoadData={loadData}
                                onNewItem={manualCustomerManagement ? onNewItem : undefined}
                                newItemLabel="Register new customer"
                                allowSorting={true} />
                                ) : (
                            <p>
                                <strong>No linked customers are found, it looks like the data processing
                                is in progress. Please come back later.</strong>
                            </p>

                        )}                                                                                     
                    </div>
                )}               

                <ManagedLinkedCustomerRegisterDialog tenantId={props.tenantId} dataIntegrationId={props.itemId} isVisible={showRegisterDialog} dismissDialog={() => { setShowRegisterDialog(false)}} onCreateCustomer={onCreateCustomer} />          
        </PanelWithDefaultFooter>
    )
}