import { ISdkProfileApplicationDetails } from "./models/ISdkProfileApplicationDetails";
import { ISdkRoleAssignment } from "./models/ISdkRoleAssignment";
import { ISdkUserProfile } from "./models/ISdkUserProfile";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";


export class SdkTenantUserProfileClient {

    private _sdkUserProfileClient: SdkClient<ISdkUserProfile>        
    private _sdkRoleAssignmentClient: SdkClient<ISdkRoleAssignment>
    private _sdkApplicationProfileClient: SdkClient<any>

    constructor(
        private _tenantId: string,
        private _token: string)         
    {                    
        this._sdkUserProfileClient = SdkClientServiceDiscoveryService.Discover<ISdkUserProfile>('/api/v1/tenants/' + this._tenantId + '/users', this._token);
        this._sdkApplicationProfileClient = SdkClientServiceDiscoveryService.Discover<ISdkProfileApplicationDetails>('/api/v1/tenants/' + this._tenantId + '/applications', this._token);
        this._sdkRoleAssignmentClient = SdkClientServiceDiscoveryService.Discover<ISdkRoleAssignment>('/api/v1/tenants/' + this._tenantId + '/users', this._token);
    }

    listUserProfiles(): Promise<ISdkUserProfile[]> 
    {        
        return this._sdkUserProfileClient.list('');        
    }   
    
    registerUser(firstName: string, lastName: string, contactAddress: string, isTeamAdmin: boolean): Promise<ISdkUserProfile> {
        
        const payload = {
            firstName: firstName,
            lastName: lastName,
            contactAddress: contactAddress,
            asAdministrator: isTeamAdmin
        }

        return this._sdkUserProfileClient.post('', payload).then(async (location) => {

            // check if we need to set the admin permissions for this tenant
            // if (isTeamAdmin)
            // await this._sd

            return await this._sdkUserProfileClient.getFromAbsoluteUri(location as string);
        })
    }

    registerApplication(name: string): Promise<ISdkProfileApplicationDetails> {            
        return this._sdkApplicationProfileClient.postToGetBody('', { name: name })
    }

    deleteUserFromTenant(userId: string): Promise<Boolean> {
        return this._sdkUserProfileClient.delete('/' + userId);
    }

    loadRoleAssignments(userId: string): Promise<ISdkRoleAssignment[]> {
        return this._sdkRoleAssignmentClient.list('/' + userId + '/assignments');
    }

    addRoleAssignment(userId: string, roleIdentifier: string, resourceIdentifier: string): Promise<Boolean> {
        
        const payload = {
            RoleIdentifier: roleIdentifier,
            ResourceContext: resourceIdentifier
        }

        return this._sdkRoleAssignmentClient.post('/' + userId + '/assignments', payload).then(result => {
            return result ? true : false
        })
    }

    deleteRoleAssignment(userId: string, roleIdentifier: string, resourceIdentifier: string): Promise<Boolean> {

        return this._sdkRoleAssignmentClient.delete('/' + userId + '/assignments/'+ btoa(roleIdentifier) + '/' + btoa(resourceIdentifier)).then(result => {
            return result ? true : false
        })
    }

}