import './TenantUserManagementRoleAssignments.scss';

import { Panel, PanelType, Persona, PersonaSize } from "@fluentui/react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ISdkUserProfile } from "../lib/sdk/models/ISdkUserProfile"
import { useAuthInContext } from '@mevodo/mv-react-authentication';
import { SdkRoleAssignmentsControl } from '../lib/sdkdatacomponents/SdkRoleAssignmentsControl';

export interface TenantUserManagementRoleAssignmentsProps extends IAppWindowBaseProps {
    tenantId: string
    profile: ISdkUserProfile
}

export const TenantUserManagementRoleAssignments = (props: TenantUserManagementRoleAssignmentsProps) => { 

    // get the authenticaiton 
    const auth = useAuthInContext(props.tenantId as string)        

    if (!props.isVisible) {
        return (<></>)
    }

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    return (
        <Panel
            isOpen={props.isVisible}
            headerText={'User Details'}
            onDismiss={dissmissDialog}   
            type={PanelType.large}     
            className={'mv-user-management-role-assignments'}
            >
                
                <Persona 
                    // imageUrl={props.profile.avatarUri} 
                    text={props.profile.firstName + ' ' + props.profile.lastName}
                    secondaryText={props.profile.contactAddresses[0]}
                    size={PersonaSize.size56}  
                    showSecondaryText={true}                                                                               
                />
                
                <p>Every user can have an infinite number of role assignments. Add or remove role assignments for the end user. Please keep in mind that several assignments are 
                    controlled on a deeper level in the application. Every assignment can be removed here!
                </p>
                    
                <SdkRoleAssignmentsControl 
                    tenantId={props.tenantId} 
                    currentToken={auth.currentToken as string} 
                    contextValue={props.profile.userId} 
                    contextDisplayName={props.profile.firstName + ' ' + props.profile.lastName} 
                    contextDimension='user' />                
        </Panel>
    )
}