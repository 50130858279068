import { useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { Icon, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { SdkTenantDashboardClient } from "../lib/sdk/SdkTenantDashboardClient";
import { useNavigationService } from "../AppDynamicNavigate";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { ISdkDashboardDefinition } from "../lib/sdk/models/ISdkDashboardDefinition";
import { SdkAddOrEditDashboardControlPanel } from "../lib/sdkdatacomponents/SdkAddOrEditDashboardControlPanel";
import { useQuery } from "../lib/ds/components/RouterExtensions";

export const EndCustomerPortalMainShell = () => {
    
    // get all the context data needed to process the request
    const { tenantId } = useParams()    
    const { reload } = useQuery()  
    const location = useLocation();
    const auth = useAuthInContext(tenantId as string)
    const navigationService = useNavigationService();
    const navigate = useNavigate()    

    // reload if needed
    if (reload)    
        window.location.href = process.env.PUBLIC_URL + location.pathname    
    
    // This control needs to load all dashbaords and generates out of the existing dashboards
    // the navigation structure. If no dashboard is availabel the welcome page is behind the first 
    // item in the navigation. If we have dashboards the system needs to render a plus button 
    // as first navigation item.

    // inject the required state for this control
    const [isLoading, { setTrue: setLoading, setFalse: resetLoading}] = useBoolean(true)       
    const [error, setError] = useState('')
    const [availableDashboards, setAvailableDashboards] = useState<ISdkDashboardDefinition[]>()
    const [isCreateDashboardOpen, { setTrue: showCreateDashboard, setFalse: hideCreateDashboard}] = useBoolean(false)   
        
    navigationService.useEventListener((event) => {
        if (event !== 'createDashboard') { return }
        onCreateDashboard()
    })

    // This region handles the standard operation to create a new dashboard
    const onCreateDashboard = () => {
        showCreateDashboard()
    }

    const dashboardRegistered = (dashboard: ISdkDashboardDefinition) => {
        hideCreateDashboard()                
        navigate('/tenants/' + tenantId + '/portal/' + dashboard.id + '?reload=1')
    }
    
    const loadDashboards = async (): Promise<void> => {
        
        try {
            // set the loading 
            setLoading()
            
            // get all dashboards
            var sdkDashboardClient = new SdkTenantDashboardClient(tenantId as string, auth.currentToken as string);
            const availableDashboards = await sdkDashboardClient.getDashboardDefinitions();        

            // set the state 
            setAvailableDashboards(availableDashboards)
                                
        } catch(error: any) {
            setError(error && error.message ? error.message : 'Unknown error, please try again later!')            
        } finally {
            resetLoading()            
        }
        return Promise.resolve()
    }

    // handle the initial load 
    useEffectAsync(async () => {        
        await loadDashboards()
    }, []);
    
    // Control the rendering as it is very dependent on the state of the control 
    if (isLoading ) {
        return (<div className="mv-content-container standalone">    
            <Spinner size={SpinnerSize.large} label="Loading dashboards..." style={{height:'100%'}}/>                                            
        </div>)
    } else if (error) {
        return (<MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>)    
    } else if (location.pathname.endsWith('portal') || location.pathname.endsWith('portal/')) {
        if (availableDashboards && availableDashboards.length > 0) {
            return (<Navigate to={'/tenants/' + tenantId + '/portal/' + availableDashboards[0].id } />)
        } else {
            return (
                <div className="mv-content-container standalone">                                    
                    <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '300px', paddingTop: '20px'}}>
                        <p>It's empty here!</p>
                        <p>Please create your first dashboard to visualize and analyze your data.</p>
                        <PrimaryButton onClick={onCreateDashboard}><Icon iconName={'faPlus'} />&nbsp;Create Dashboard</PrimaryButton>
                    </div>
                    <SdkAddOrEditDashboardControlPanel isVisible={isCreateDashboardOpen} dismissDialog={hideCreateDashboard} onDashboardRegistered={dashboardRegistered} tenantId={tenantId as string} />
                </div>
            )
        }
    } else if (location.pathname.endsWith('reports') || location.pathname.endsWith('reports/') || location.pathname.endsWith('triggers') || location.pathname.endsWith('triggers/')) {
        return (
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack horizontal disableShrink>
                    <div style={{ width: '100%', height: '100%', backgroundColor: 'white', borderLeft: '1px solid rgb(238, 238, 238)'}}>                        
                        <Outlet />                    
                    </div>
                </Stack>
            </Stack>)
    } else {        
        return (
            <div>
                <Outlet /> 
                <SdkAddOrEditDashboardControlPanel isVisible={isCreateDashboardOpen} dismissDialog={hideCreateDashboard} onDashboardRegistered={dashboardRegistered} tenantId={tenantId as string} />
            </div>
        )
    }    
}