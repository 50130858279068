import { ISdkCustomerDiscountProfile, ISdkCustomerDiscountProfileCreateRequest } from "./models/ISdkCustomerDiscountProfile";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerDiscountProfileClient {

    private sdkClient: SdkClient<ISdkCustomerDiscountProfile>    
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkCustomerDiscountProfile>('/api/v1/tenants/' + tenantId + '/svp/discountprofiles', token);
    }

    listDiscountProfiles(parent?: string, parentType?: string): Promise<ISdkCustomerDiscountProfile[]> 
    {        
        const parentId = parent ? parent : 'root'
        const parentTypeValue = parentType ? parentType : 'CustomerGroup'

        return this.sdkClient.list('?parentId=' + parentId + '&parentType=' + parentTypeValue);        
    }

    listEffectiveDiscountProfiles(parent?: string, parentType?: string): Promise<ISdkCustomerDiscountProfile[]> 
    {        
        const parentId = parent ? parent : 'root'
        const parentTypeValue = parentType ? parentType : 'CustomerGroup'

        return this.sdkClient.list('?parentId=' + parentId + '&parentType=' + parentTypeValue + '&effective=true');        
    }

    deleteDiscountProfile(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }

    createDiscountProfile(createRequest: ISdkCustomerDiscountProfileCreateRequest): Promise<ISdkCustomerDiscountProfile> 
    {                    
        return this.sdkClient.post('', createRequest).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })               
    }

    updateDiscountProfile(id: string, createRequest: ISdkCustomerDiscountProfileCreateRequest): Promise<ISdkCustomerDiscountProfile> 
    {                    
        return this.sdkClient.put('/' + id, createRequest).then(async () => {
            return this.getDiscountProfile(id)
        })               
    }

    getDiscountProfile(id: string): Promise<ISdkCustomerDiscountProfile>
    {
        return this.sdkClient.get('/' + id + '/details')
    }
}

