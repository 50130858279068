import { useParams } from "react-router-dom"
import { INavigationMenuSidebarRouteEntry, NavigationMenuSidebar } from "../lib/components/DetailsListHelper/NavigationMenuSideBar";
import { useEffect, useState } from "react";
import { useSubscriptionContext } from "../lib/authentication/AuthenticationSubscriptionContext";

export const ServiceProviderReports = () => {

    const { tenantId } = useParams();    
    const subContext = useSubscriptionContext()
    const [menuItems, setMenuItems] = useState<INavigationMenuSidebarRouteEntry[] | undefined>(undefined)

    useEffect(() => {

        setMenuItems(undefined)
        
        // the menuitems 
        const buildedMenuItems: INavigationMenuSidebarRouteEntry[] = []

        buildedMenuItems.push({name: 'Reports', route: '/tenants/' + tenantId + '/svpreports/reports' })

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.startsWith('subscription.cloudbilling') || s.id.startsWith('hidden.subscription.cloubbilling'))) {
            buildedMenuItems.push({name: 'Discount/Uplift Profiles', route: '/tenants/' + tenantId + '/svpreports/discountprofiles' })
        }
        
        setMenuItems(buildedMenuItems)
    
    }, [tenantId, subContext])
    
    return (<NavigationMenuSidebar routes={menuItems} />)         
}