import { useParams } from "react-router-dom"
import { INavigationMenuSidebarRouteEntry, NavigationMenuSidebar } from "../lib/components/DetailsListHelper/NavigationMenuSideBar";
import { useEffect, useState } from "react";
import { useSubscriptionContext } from "../lib/authentication/AuthenticationSubscriptionContext";

export const ServiceProviderManagement = () => {
    
    const { tenantId } = useParams();
    const subContext = useSubscriptionContext()
    const [menuItems, setMenuItems] = useState<INavigationMenuSidebarRouteEntry[] | undefined>(undefined)

    useEffect(() => {

        setMenuItems(undefined)

        // the menuitems 
        const buildedMenuItems: INavigationMenuSidebarRouteEntry[] = []

        buildedMenuItems.push({name: 'Customers', route: '/tenants/' + tenantId + '/svp/customers'})
        buildedMenuItems.push({name: 'Notifications', route: '/tenants/' + tenantId + '/svp/notifications'})

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id === 'subscription.cloudbilling.serviceprovider')) {
            buildedMenuItems.push({name: 'Data Integrations', route: '/tenants/' + tenantId + '/svp/integrations'})
            buildedMenuItems.push({name: 'Invoices', route: '/tenants/' + tenantId + '/svp/invoices'})
        }

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.serviceprovider'))) {
            buildedMenuItems.push({name: 'Service Credentials', route: '/tenants/' + tenantId + '/svp/servicecredentials'})
        }
        
        setMenuItems(buildedMenuItems)
    
    }, [tenantId, subContext])
    
    return (<NavigationMenuSidebar routes={menuItems} />)        
}