import './PageContent.scss'

export interface IPageContentProps {
    children?: React.ReactNode
}

export const PageContent = (props: IPageContentProps) => {
    return (
        <div className="mv-page-content">
            {props.children}
        </div>
    )
}
