import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { IObjectFromTemplateViewProps } from "../svp/IObjectFromTemplateViewProps";
import { ISdkObjectFromTemplate } from "../lib/sdk/models/ISdkObjectTemplate";
import { SdkAddOrEditTemplateBasedObjectPanel } from "../lib/sdkdatacomponents/SdkAddOrEditTemplateBasedObjectPanel";
import { SdkTenantIdentityProviderClient } from "../lib/sdk/SdkTenantIdentityProviderClient";

export const EditIdentityProviderPanel = (props: IObjectFromTemplateViewProps) => {
        
    const auth = useAuthInContext(props.tenantId as string)
            
    const onLoadTemplates = () => {     
        const sdkClient = new SdkTenantIdentityProviderClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.listTemplates()        
    }
    
    const onUpdate = (item: ISdkObjectFromTemplate) => {
        const sdkClient = new SdkTenantIdentityProviderClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.update_metadata_from_idp(item)        
    }    

    const onLoad = async (itemId: string) => {
        const sdkClient = new SdkTenantIdentityProviderClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.load(itemId)              
    }
    
    return (
        <SdkAddOrEditTemplateBasedObjectPanel 
            isVisible={props.isVisible} dismissDialog={props.dismissDialog}
            tenantId={props.tenantId as string} itemId={props.itemId} 
            objectTitle={'Identity Provider'}
            objectDescription={'Update the detailed fields below to adjust the behaviour of the identity provider. The payloads are often provided by the identity and access management system from the other side, e.g. Microsoft Entra Identity'}
            objectDescriptionHidden={true}
            templateSelectorDisabled={true}
            templateSelectorLabel={'The following identity provider template was used to create this identity provider:'}
            templateSelectorPlaceholder={''}
            onLoadTemplates={onLoadTemplates}            
            onUpdate={onUpdate}
            onLoad={onLoad}
            onCreate={() => Promise.resolve()}
            layerProps={{ eventBubblingEnabled: true }}
        />  
    )
}