import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProfileContext } from "../lib/authentication/AuthenticationProfileContext";
import { useBranding } from "../lib/ds/components/BrandingProvider";
import { Stage } from "../lib/ds/ds";
import { thin } from "@fortawesome/fontawesome-svg-core/import.macro";

export  const NoTenant = () => {
    
    const branding = useBranding()
    const profileContext = useProfileContext()
    
    return ( 
        <Stage brandText={branding.company?.name ? branding.company?.name : 'mevodo'} brandLogo={branding.company?.logos['fav32']} headerTitle={'Oh no, we cannot welcome you for now!'} height="450px" isVisible={true}>
            
            <p style={{textAlign: 'center', padding: '50px'}}>
                <FontAwesomeIcon icon={thin('face-pensive')} size='8x' />
            </p>
            <p>
                <strong>We are sorry</strong> but it looks like your user account (<strong>{profileContext.profile.contact}</strong>) is not associated to an existing 
                tenant in this service. Your service provider or your IT department should able to help you signing up to this service.</p>
                    
            <p style={{fontStyle: 'italic', position: 'absolute', bottom: 0}}>Your are logged in as user <strong>{profileContext.profile.contact}</strong> (<a href={'logout'}>logout</a>)</p>        
        </Stage>                 
    );
}