import { IDropdownOption, PanelType } from "@fluentui/react";
import { IAppWindowBaseProps } from "../../ds/components/AppWindow";
import { PanelWithDefaultFooter } from "../DetailsListHelper/PanelWithDefaultFooter";
import { ISdkCustomerDiscountProfile } from "../../sdk/models/ISdkCustomerDiscountProfile";
import { useEffectAsync } from "../Helpers/EffectAsyncHook";
import { SdkCustomerDiscountProfileClient } from "../../sdk/SdkCustomerDiscountProfileClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useState } from "react";
import { UnControlledFieldItemList } from "../../forms/UnControlledFieldItemList";
import { IFieldItem } from "../../forms/UnControlledFieldItem";

export interface IReportDiscountProfileSelectorDialogProps extends IAppWindowBaseProps {
    tenantId: string
    referenceReportId: string    
    
    fieldId: string
    fieldName: string

    activeDiscountProfiles: string[]
    onChange?: (discountProfiles: string[]) => void    
}

export const ReportDiscountProfileSelectorDialog = (props: IReportDiscountProfileSelectorDialogProps) => {

    const auth = useAuthInContext(props.tenantId)

    const [isPreparing, setIsPreparing] = useState(true)
    const [availableDiscountProfiles, setAvailableDiscountProfiles] = useState<ISdkCustomerDiscountProfile[]>([])    
    const [selectedDiscountProfiles, setSelectedDiscountProfiles] = useState<ISdkCustomerDiscountProfile[]>([])

    useEffectAsync(async () => {

        if (!props.isVisible) { return }

        setIsPreparing(true)

        // load all discount profile for the reference report
        const sdk = new SdkCustomerDiscountProfileClient(props.tenantId, auth.currentToken as string)
        const discountProfiles = await sdk.listDiscountProfiles("reports", "Reports")
        const validDiscountProfiles = discountProfiles.filter(dp => dp.relatedReportDefinitionId && dp.relatedReportDefinitionId === props.referenceReportId)
        setAvailableDiscountProfiles(validDiscountProfiles)                

    }, [props.referenceReportId, props.isVisible])

    useEffectAsync(async () => {

        // set the currently used profiles
        const localSelectedProfiles = getDiscountProfileyByIds(props.activeDiscountProfiles || [])
        setSelectedDiscountProfiles(localSelectedProfiles)

        // loading is done
        setIsPreparing(false)

    }, [availableDiscountProfiles])

    const onDismiss = () => {
        if (props.dismissDialog) { props.dismissDialog()}
    }
        
    const onSubmit = async () => {
        props.onChange && props.onChange(selectedDiscountProfiles.map(dp => dp.id))
        onDismiss()
    }

    const convertDiscountProfilesToItems = (discountProfiles: ISdkCustomerDiscountProfile[]): IFieldItem[] => {
        return discountProfiles.map(dp => {
            return {
                key: dp.id,
                field: dp.id
            }
        })
    }

    const getAvailableFields = (): IDropdownOption[] => {
        return availableDiscountProfiles.map(p => { return {key: p.id, text: p.name}})
    }

    const getDiscountProfileById = (id: string): ISdkCustomerDiscountProfile | undefined => {
        return availableDiscountProfiles.find(dp => dp.id === id)
    }

    const getDiscountProfileyByIds = (ids: string[]): ISdkCustomerDiscountProfile[] => {
        return ids.map(id => {
            return getDiscountProfileById(id)
        }).filter(dp => dp !== undefined) as ISdkCustomerDiscountProfile[]
    }

    const onItemsChanged = (items: IFieldItem[]) => {        
        const discountProfiles = getDiscountProfileyByIds(items.map(item => item.field))
        setSelectedDiscountProfiles(discountProfiles)
    }

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Assign active discount/uplift profiles'}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
            type={PanelType.medium} 
            progessMessage={'Assigning discount/uplift profiles...'} 
            submitLabel={'Assign'} 
            dissmissLabel={'Cancel'} 
            isValid={availableDiscountProfiles.length !== 0}>

            {isPreparing ? (
                <div>Preparing...</div>
            ) : (
                <>
                { availableDiscountProfiles.length === 0 ? (
                    <div>
                        <p>
                            There are no discount/uplift profiles defined which are compatible with the selected datasource. The 
                            used datasorce has the id {props.referenceReportId}, please go back and check if a discount 
                            profile was defined for this datasource.
                        </p>
                    </div>
                ) : (
                    <div>
                        <p>
                            Please add the discount/uplift profiles which will be applied to the property <strong>{props.fieldName} ({props.fieldId})</strong>. If you apply a 
                            discount/uplift profile it will be relevant for properties containing numbers e.g. costs or quantity properties. All other properties are not 
                            affected at all! The set of discount/uplift profiles is processed from top to down and the first matching profile is taken.</p>
                        <UnControlledFieldItemList availableFields={getAvailableFields()} items={convertDiscountProfilesToItems(selectedDiscountProfiles)} onItemsChanged={onItemsChanged} placeholder={'Select a discount/uplift profile'}/>
                    </div>
                )}
                </> 
            )}           
        </PanelWithDefaultFooter>            
    )    
}