import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { DatePicker, DateRangeType, IDatePickerProps } from "@fluentui/react";
import dayjs from "dayjs"

export const ControlledMonthField = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IDatePickerProps) => {
    
    return (
        <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue || undefined}
        render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
        }) => {
            console.log(value)
            return (
                <DatePicker   
                    {...props}
                    value={value ? new Date(value + '-01') : new Date()}                     
                    onSelectDate={(date: Date | undefined | null) => {
                        if (date) {
                            const monthFormat = dayjs(date).format('YYYY-MM')
                            onChange(monthFormat)
                        } else {
                            onChange(undefined)
                        }                        
                    }}
                    onBlur={onBlur}
                    key={fieldName}                    
                    formatDate={(date?: Date): string => {
                        return date ? dayjs(date).format('YYYY-MM') : ''                        
                    }}
                    placeholder="Select a month..."    
                    calendarProps={
                        { dateRangeType: DateRangeType.Month, }
                    }
                />      
        )}}
        />
    );
};