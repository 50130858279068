import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProfileContext } from "../lib/authentication/AuthenticationProfileContext";
import { useBranding } from "../lib/ds/components/BrandingProvider";
import { Stage } from "../lib/ds/ds";
import { thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ISdkCustomer } from "../lib/sdk/models/ISdkCustomer";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient";
import { ServiceProviderCustomerManagementUIService } from "../svp/ServiceProviderCustomerManagementUIService";

export interface ICustomerLauncherProps {

}

export function CustomerLauncher(props: ICustomerLauncherProps) {

    const branding = useBranding()
    const profileContext = useProfileContext()
    
    const { tenantId, customerId } = useParams();
    const [ activecustomer, setActiveCustomer ] = useState<ISdkCustomer | undefined>(undefined)
    const [ error, setError ] = useState<string | undefined>(undefined)

    const auth = useAuthInContext(tenantId as string)

    // establish the uiService
    const uiService = new ServiceProviderCustomerManagementUIService(tenantId ? tenantId : '', auth.currentToken ? auth.currentToken : '')
    
    useEffectAsync(async () => {
        try {
            const sdkClient = new SdkCustomerClient(tenantId as string, auth.currentToken as string)
            const customer = await sdkClient.getCustomer(customerId as string)
            if (customer) {
                setActiveCustomer(customer)                
            } else {
                setError('Requested customer not found or permisson denied')    
            }
        } catch (e) {
            setError('Requested customer not found or permisson denied')
        }
    }, [tenantId, customerId])

    useEffectAsync(async () => {
        if (!activecustomer) { return }
        await uiService.openInCustomerContextExt2(activecustomer.tenantId, activecustomer.id, '/portal', false)
    }, [activecustomer])

    return ( 
        <Stage brandText={branding.company?.name ? branding.company?.name : 'mevodo'} brandLogo={branding.company?.logos['fav32']} headerTitle={'Opening Customer Portal...'} height="450px" isVisible={true}>
            
            <p style={{textAlign: 'center', padding: '50px'}}>
                <FontAwesomeIcon icon={thin('rocket-launch')} size='8x' />
            </p>
            <p>
                { error ? (
                    <><strong>Sorry,</strong> something went wrong: {error}</>   
                ) : (
                    <>
                    <strong>We are preparing</strong> the customer portal {activecustomer ? (<>for customer <strong>{activecustomer.name} ({activecustomer.id})</strong></>) : ''}. 
                    Please stay tuned this process could take a couple seconds! </>   
                )}

                <br/><br/>
                You are logged in as user <strong>{profileContext.profile.contact}</strong>, in case of errors 
                please verify that this user has admin on behalf of permission for the requested customer!
            </p>                            
        </Stage>                 
    );
}