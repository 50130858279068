import { useParams } from "react-router-dom"
import { INavigationMenuSidebarRouteEntry, NavigationMenuSidebar } from "../lib/components/DetailsListHelper/NavigationMenuSideBar";
import { SdkTenantApiDefinitionClient } from "../lib/sdk/SdkTenantApiDefinitionClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { useState } from "react";

export const ServiceProviderDevelopmentCenter = () => {

    const { tenantId } = useParams();        
    const [menuItems, setMenuItems] = useState<INavigationMenuSidebarRouteEntry[] | undefined>(undefined)
    
    const authInContext = useAuthInContext(tenantId as string)    

    useEffectAsync( async() => {

        setMenuItems(undefined)
        
        // load the endpoints
        const sdkClient = new SdkTenantApiDefinitionClient(tenantId as string, authInContext.currentToken as string)
        const apis = await sdkClient.loadApis()
        
        // the menuitems 
        const buildedMenuItems: INavigationMenuSidebarRouteEntry[] = []

        buildedMenuItems.push({name: 'Powershell', route: '/tenants/' + tenantId + '/developmentcenter/powershell' })        

        apis.forEach((api) => {
            buildedMenuItems.push({name: api.swaggerTitle, route: '/tenants/' + tenantId + '/developmentcenter/' + api.swaggerId })        
        })
        setMenuItems(buildedMenuItems)                    
    
    }, [tenantId])
    
    
    return (<NavigationMenuSidebar routes={menuItems} />)             
}