import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"

export const ReadOnlyCustomerDatasourceDialog = (props: IAppWindowBaseProps) => { 
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Managed Datasource'}
            onDismiss={props.dismissDialog}                                                
            progessMessage={''}
            submitLabel={'Close'}
            dissmissLabel={'Cancel'}                        
            noDissmissButton={true}
            isValid={true}
            onSubmit={() => { return Promise.resolve() }}>

                <div>
                    <p>The selected datasource is managed by a data integration. Managed datasource can't 
                        be updated as all parameters are under control of the corresponding data integration!</p> 
                </div>
                         
        </PanelWithDefaultFooter>
    )
}