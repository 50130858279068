import { PanelType} from "@fluentui/react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { useState } from "react"
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { ISdkCustomerDetails } from "../lib/sdk/models/ISdkCustomer"
import { SdkCustomerDiscountProfileClient } from "../lib/sdk/SdkCustomerDiscountProfileClient"
import { SdkCustomerDataFilterPolicyClient } from "../lib/sdk/SdkCustomerDataFilterPolicyClient"

export interface IProfilePolicyViewModel {
    id: string,
    name: string,
    profileType: string,
    details: string,
    icon: string
}

export interface IShowEffectiveDiscountProfilesDialog extends IAppWindowBaseProps {   
    tenantId: string
    itemId?: string    
}

export const ShowEffectiveDiscountProfilesDialog = (props: IShowEffectiveDiscountProfilesDialog) => {

    const auth = useAuthInContext(props.tenantId);           
    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        
    const [loadedCustomer, setLoadedCustomer] = useState<ISdkCustomerDetails>()
        
    useEffectAsync(async () => {
        
        // do nothing when no item set
        if (!props.itemId) { return }

        // set the loading mode
        setIsPreparing(true)

        // load the customer model including details
        const sdkClient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        const customer = await sdkClient.getCustomer(props.itemId)
        setLoadedCustomer(customer)
        
        // disable the loading mode
        setIsPreparing(false)

    }, [props.itemId])
    
    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const buildColumns = (builder: IColumnsBuilder<IProfilePolicyViewModel>): void  => {        
        builder.IconColumn({name: "", headerIcon: 'faTags', iconName: (item) => item.icon})
        builder.TextColumn({name: "Type", value: 'profileType', maxWidth: 100})       
        builder.TextColumn({name: "Name", value: 'name', maxWidth: 250 })            
        builder.TextColumn({name: "Details", value: 'details', maxWidth: 250})                                
        builder.TextColumn({name: "Id", value: 'id', maxWidth: 250 })                     
    }

    const reloadData = async (): Promise<IProfilePolicyViewModel[]> => {

        const sdkClientProfiles = new SdkCustomerDiscountProfileClient(props.tenantId, auth.currentToken as string)
        const sdkProfiles = await sdkClientProfiles.listEffectiveDiscountProfiles(props.itemId as string, 'Customer')        
        const upliftProfiles = sdkProfiles.map(p => { 
            return {
                id: p.id,
                name: p.name,
                profileType: p.profileType === 'Uplift' ? 'Uplift Profile' : 'Discount Profile',
                details: '#' + p.selectorRules.length.toFixed(0) + ' filter rule' + (p.selectorRules.length === 1 ? '' : 's') +', #' + p.items.length.toFixed(0) + ' discount/uplift item' + (p.items.length === 1 ? '' : 's'),
                icon: p.profileType === 'Uplift' ? 'faArrowUp' : 'faArrowDown'
            }
        })

        const sdkClientPolicies = new SdkCustomerDataFilterPolicyClient(props.tenantId, auth.currentToken as string)
        const sdkPolicies = await sdkClientPolicies.listEffectiveDataFilterPolicies(props.itemId as string, 'Customer')
        const policies = sdkPolicies.map(p => {
            return {
                id: p.id,
                name: p.name,
                profileType: 'Data Filter Policy',
                details: '#' + p.excludeFilterRules.length.toFixed(0) + ' exclude filter rule' + (p.excludeFilterRules.length === 1 ? '' : 's'),
                icon: 'faFilterCircleDollar'
            }
        })

        return [...upliftProfiles, ...policies]
    }

    return (
        <PanelWithDefaultFooter        
            isOpen={props.isVisible}
            headerText={'Effective Profiles & Policies'}
            onDismiss={dissmissDialog}                                    
            type={PanelType.large}
            progessMessage={''}
            submitLabel={'Close'}
            noDissmissButton={true}
            dissmissLabel={'Cancel'}                        
            isValid={true}
            onSubmit={() => { dissmissDialog(); return Promise.resolve() }}
            isPreparing={isPreparing}>

                <p>
                    The following profiles and policies are effective for the customer <strong>{loadedCustomer?.name} ({loadedCustomer?.id})</strong>. 
                    Please keep in mind that customer in a partner channel reflect the costs you as a serviceprovider charge the partner. The partner 
                    can define separate profiles and policies for his customers.</p>   


                 <NavigationalDetailsListWithStandardOperations<IProfilePolicyViewModel> 
                    uniqueIdentifier='discountprofileseffectiveroot' 
                    onBuildColumns={buildColumns} 
                    onLoadData={reloadData} 
                    hideReferesh={true} 
                    disabledItemSelect={true}
                    />
                 
        </PanelWithDefaultFooter>)
}
