import './DashboardGridControlItemContainer.scss'
import { lazy, Suspense, useLayoutEffect, useRef, useState } from "react"
import { IDashboardGridControlItemInstanceService } from './Contracts/IDashboardGridControlItemService'
import uuid from 'react-uuid'

export interface IDashboardGridControlDynamicItemToolbarProps  {
}

export interface IDashboardGridControlDynamicItemProps {
    id: string
    dataHash: string
    reloadHash: string
    itemDataService: IDashboardGridControlItemInstanceService
        
    editMode: boolean
    onDeleteItem: () => void
    onEditItem: () => void
    
    reloadWidget: () => void    

    width: number
    height: number

    selectedPeriod: string

    primaryCurrency: string
}

export interface IDashboardGridControlItemContainerProps {
    dashboardId: string,
    dashboardCurrency: string,
    id: string,
    dataHash: string
    name: string, 
    description: string,
    itemType: string,    
    itemDataService: IDashboardGridControlItemInstanceService
    editMode: boolean    
    selectedPeriod: string
    onDeleteItem: () => void  
    onEditItem: () => void  
}

export const DashboardGridControlItemContainer = (props: IDashboardGridControlItemContainerProps) => {

    const ref = useRef<any | null>(null);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const [DashboardDyamicItemComponent] = useState<any>(lazy(() => {    
        return import('../../DashboardWidgets/' + props.itemType).catch(error => {
          return import('../../DashboardWidgets/ErrorWidget')
        })      
    }))
        
    const [refreshId, setRefreshId] = useState<string>(uuid())
    
    const onReloadWidget = () => {
        setRefreshId(uuid())
    }
                
    useLayoutEffect(() => {
        setWidth(ref.current?.offsetWidth);
        setHeight(ref.current?.offsetHeight);
      }, [ref.current?.offsetWidth, ref.current?.offsetHeight]);
            
    return (
        <div ref={ref} style={{height: '100%'}}>
            <Suspense fallback={<div>Loading</div>}>                
                <DashboardDyamicItemComponent 
                    itemDataService={props.itemDataService} 
                    primaryCurrency={props.dashboardCurrency}                    
                    dataHash={props.dataHash} 
                    reloadHash={refreshId}                     
                    id={props.id}                     
                    reloadWidget={onReloadWidget}
                    editMode={props.editMode}
                    onDeleteItem={props.onDeleteItem}   
                    onEditItem={props.onEditItem}
                    width={width}
                    height={height}
                    selectedPeriod={props.selectedPeriod}
                    />                   
            </Suspense>
        </div>
    )    
}