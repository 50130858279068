import { useState } from "react"
import { PageContent } from "../lib/ds/components/PageContent"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { SdkTenantClient } from "../lib/sdk/SdkTenantClient"
import { useParams } from "react-router-dom"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { ISdkTenantSubscriptionModel } from "../lib/sdk/models/ISdkTenantSubscriptionModel"

export interface ISettingsSubscriptionsProps {
}

export const SettingsSubscriptions = (props: ISettingsSubscriptionsProps) => {

    const { tenantId } = useParams()         
    const authInContext = useAuthInContext(tenantId as string)
    
    const [subscriptions, setSubscriptions] = useState<ISdkTenantSubscriptionModel[]>([])

    useEffectAsync(async () => {

        const sdk = new SdkTenantClient(authInContext.currentToken as string);
        const loeadedSubscriptions = await sdk.getSubscriptions(tenantId as string)
        setSubscriptions(loeadedSubscriptions)
    }, [])

    return (
        <div className="mv-content-container">

            <PageHeader title={'Subscriptions'}>            
                <span>
                    The following list contains the associated subscriptions to this tenant and indicates consumption details relevant 
                    to your specific tenant.                    
                </span>                    
            </PageHeader>            

            <PageContent>
                <h3>Active Subscriptions</h3>
                <ul>
                { subscriptions.map((subscription) => (
                    <li>{subscription.name} <span style={{fontStyle:'italic'}}>({subscription.id.replace('hidden.', '')})</span></li>                                      
                ))}
                </ul>
            </PageContent>

        </div>)
}
