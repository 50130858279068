import uuid from "react-uuid"
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { IColumnsBuilder, ISubCommandDefinition } from "../DetailsListHelper/ColumnsBuilder"
import { useState } from "react"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { TriggerAddOrEditDialog } from "./TriggerAddOrEditDialog"
import { SdkTenantTriggerClient } from "../../sdk/SdkTenantTriggerClient"
import { ISdkObjectFromTemplate } from "../../sdk/models/ISdkObjectTemplate"
import { TriggerDetailsDialog } from "./TriggerDetailsDialog"

export interface ITriggerManagementComponentProps {
    tenantId: string    
}

export const TriggerManagementComponent = (props: ITriggerManagementComponentProps) => {

    const auth = useAuthInContext(props.tenantId)    

    const [isRefreshRequested, setRefreshRequested] = useState(false)
    const [isTriggerAddOrEditDialogVisible, setIsTriggerAddOrEditDialogVisible] = useState(false)    
    const [isTriggerHistoryDialogVisible, setIsTriggerHistoryDialogVisible] = useState(false)    
    const [itemToEdit, setItemToEdit] = useState<ISdkObjectFromTemplate | undefined>(undefined)

    const onEditTrigger = (item: ISdkObjectFromTemplate) => {        
        setItemToEdit(item)        
        setIsTriggerAddOrEditDialogVisible(true)
    }

    const onShowDetails = (item: ISdkObjectFromTemplate) => {        
        setItemToEdit(item)        
        setIsTriggerHistoryDialogVisible(true)
    }
    
    const columnSubCommandsReportName: ISubCommandDefinition<ISdkObjectFromTemplate>[] =  [                
        { iconName: 'faPen', description: 'Edit the widget definition', onClick: (item) => { onEditTrigger(item)  }}                
    ]
    
    const onBuildColumns = (builder: IColumnsBuilder<ISdkObjectFromTemplate>): void => {
        builder.IconColumn({name: "StatusIcon", headerIcon: 'faCircleInfo', iconClass: (item) => 'status' + (item.fieldValues['active'] && item.fieldValues['active'] === 'true' ? 'Active' : 'InActive'), iconName: (item) => item.fieldValues['active'] && item.fieldValues['active'] === 'true' ? 'fasActive' : 'fasInActive', onClick: (item) => { onShowDetails(item) }})
        builder.TextColumn({name: "Name", value: "name", maxWidth: 350, subCommands: columnSubCommandsReportName, onClick: (item) => { onShowDetails(item) }})
        builder.TextColumn({name: "Id", value: "id", maxWidth: 250, onClick: (item) => { onShowDetails(item) }})
        builder.TextColumn({name: "State", value: (item) => { return item.fieldValues['active'] && item.fieldValues['active'] === 'true' ? 'Trigger is active' : 'Trigger is disabled and will not be fired' }, maxWidth: 150, onClick: (item) => { onShowDetails(item) }})
    }

    const onLoadData = async (): Promise<ISdkObjectFromTemplate[]> => {        
        setRefreshRequested(false)        
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);        
        return await sdkClient.listTriggers()        
    }

    const onNewTriggerDefinition = () => {
        setItemToEdit(undefined)
        setIsTriggerAddOrEditDialogVisible(true)
    }

    const onDeleteTriggerDefinitions = async (items: ISdkObjectFromTemplate[]) => {        
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);        
        for (const item of items) {
            await sdkClient.deleteTrigger(item.id)
        }        
    }
    
    return (
        <>
            <NavigationalDetailsListWithStandardOperations 
                uniqueIdentifier={'workflowsroot' + uuid()}            
                onBuildColumns={onBuildColumns}                                
                onLoadData={onLoadData}
                onNewItem={onNewTriggerDefinition}  
                onDeleteItems={onDeleteTriggerDefinitions}                                                              
                hideReferesh={false} 
                preventInitialLoad={false}                        
                allowSorting={true}            
                disabledItemSelect={false}
                allowSearching={false}
                refresh={isRefreshRequested} />  
            <TriggerAddOrEditDialog isVisible={isTriggerAddOrEditDialogVisible} tenantId={props.tenantId as string} itemId={itemToEdit ? itemToEdit.id : undefined} dismissDialog={() => { setItemToEdit(undefined); setIsTriggerAddOrEditDialogVisible(false); setRefreshRequested(!isRefreshRequested)}}/>
            <TriggerDetailsDialog isVisible={isTriggerHistoryDialogVisible} tenantId={props.tenantId as string} item={itemToEdit as ISdkObjectFromTemplate} dismissDialog={() => { setItemToEdit(undefined); setIsTriggerHistoryDialogVisible(false); setRefreshRequested(!isRefreshRequested)}}/>
        </>
    )
}