import { IDropdownOption } from "@fluentui/react"
import { IFieldMappingItem, UnControlledFieldMappingItem } from "./UnControlledFieldMappingItem"
import { useEffect, useState } from "react"

export interface IFieldMappingListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    disabled?: boolean    

    items: IFieldMappingItem[]

    onItemsChanged?: (items: IFieldMappingItem[]) => void

    onElipsisClicked?: (item: IFieldMappingItem) => void

    onItemsPending?: (pending: boolean) => void

    onFilterAvailablePivotOperations?: (propertyName: string, availableOptions: IDropdownOption[] ) => IDropdownOption[]

    availableFields: IDropdownOption[]
    defaultPivotKey?: string
    availablePivotOperations: IDropdownOption[]
}

export const UnControlledFieldMappingList = (props: IFieldMappingListProps) => {
    
    const [pendingProperties, setPendingProperties] = useState<string[]>([])

    const onUpdateItem = (item: IFieldMappingItem) => {
        
        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items[index] = item
        }

        if (props.onItemsChanged) {
            props.onItemsChanged(props.items)            
        }
    }

    const onAddItem = (item: IFieldMappingItem) => {
        if (props.onItemsChanged) {
            props.onItemsChanged([...props.items, item])            
        }        
    }
    
    const onDeleteItem = (item: IFieldMappingItem) => {

        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items.splice(index, 1)

            if (props.onItemsChanged) {
                props.onItemsChanged(props.items)            
            }
        }        
    }

    const onChangesPending = (itemKey: string, pending: boolean) => {
        if (pending) {
            // add the property to the pending list
            if (!pendingProperties.includes(itemKey)) {
                setPendingProperties([...pendingProperties, itemKey])
            }
        } else {
            // remove the property from the pending list
            if (pendingProperties.includes(itemKey)) {
                const index = pendingProperties.indexOf(itemKey)
                pendingProperties.splice(index, 1)
                setPendingProperties([...pendingProperties])
            }
        }
    }

    useEffect(() => {

        if (!props.onItemsPending) { return; }

        if (pendingProperties.length === 0) {
            props.onItemsPending(false)
        } else {
            props.onItemsPending(true)
        }

    // eslint-disable-next-line        
    }, [pendingProperties])

    return (
        <div>
            <table cellSpacing={0}>   
                <tbody>
                    {props.items.map((item, index) => {
                        return (                            
                                <UnControlledFieldMappingItem 
                                    key={item.key} 
                                    item={item} 
                                    name={item.key} 
                                    onUpdateItem={onUpdateItem}  
                                    onDeleteItem={onDeleteItem}           
                                    onElipsisClicked={props.onElipsisClicked}                        
                                    first={index === 0} 
                                    disabled={props.disabled}
                                    availableFields={props.availableFields}
                                    defaultPivotKey={props.defaultPivotKey}
                                    availablePivotOperations={props.availablePivotOperations}
                                    onFilterAvailablePivotOperations={props.onFilterAvailablePivotOperations}
                                    onChangesPending={onChangesPending} />
                            )
                    })}   

                    <UnControlledFieldMappingItem  
                        key={'newMappingItem'} 
                        name={'newMappingItem'}                 
                        availableFields={props.availableFields} 
                        onUpdateItem={onAddItem} 
                        first={props.items.length === 0} 
                        disabled={props.disabled}
                        defaultPivotKey={props.defaultPivotKey}
                        availablePivotOperations={props.availablePivotOperations} 
                        onFilterAvailablePivotOperations={props.onFilterAvailablePivotOperations} />             
                </tbody>                          
            </table>
        </div>    
    )
}