import './DashboardGridControl.scss'
import { ISdkDashboardItem } from "../../../sdk/models/ISdkDashboardItem"
import { ScrollablePane, Spinner, SpinnerSize } from '@fluentui/react'
import GridLayout, { Layout } from "react-grid-layout";
import { DashboardGridControlItemContainer } from './DashboardGridControlItemContainer';
import { useState } from 'react';
import { IDashboardGridControlLayoutService } from './Contracts/IDashboardGridControlLayoutService';
import { useEffectAsync } from '../../Helpers/EffectAsyncHook';
import { IDashboardGridControlItemService } from './Contracts/IDashboardGridControlItemService';

export interface IDashboardGridControlProps {

    items: ISdkDashboardItem[]
    dataHash: string

    gridCurrency: string

    editMode: boolean

    layoutService: IDashboardGridControlLayoutService
    itemDataService: IDashboardGridControlItemService

    selectedPeriod: string

    onDeleteItem: (item: ISdkDashboardItem) => void
    onEditItem: (item: ISdkDashboardItem) => void
}

export const DashboardGridControl = (props: IDashboardGridControlProps) => {
 
    const [lastLayoutGeometry, setLastLayoutGeometry] = useState<Layout[]>()
    const [isPreparing, setIsPreparing] = useState<boolean>(true)


    const onLayoutChanged = (layout: Layout[]) => {
        setLastLayoutGeometry(layout)        
    }
    
    const getItemClassNames = (item: ISdkDashboardItem): string => {
        let classNames = 'react-grid-item dashboard-grid-widget'

        if (!props.editMode)
            classNames += ' react-grid-no-edit'

        return classNames
    }
            
    useEffectAsync(async () => {
        if (props.editMode)
            return

        if (lastLayoutGeometry) {
            // save the changed item layout in the dashboard
            await lastLayoutGeometry.forEach(async (layout: Layout) => {

                // get the item key ids 
                const itemKey = layout.i.split('.')

                // find the item
                const item = props.items.find((item: ISdkDashboardItem) => item.dashboardId === itemKey[0] && item.id === itemKey[1])
                if (!item) { return }

                // update the item layout 
                await props.layoutService.saveItemLayout(item, {x: layout.x, y: layout.y, w: layout.w, h: layout.h})                                    
            })

            // reset the last layout geometry
            setLastLayoutGeometry(undefined)
        } else {

            // update the the state only 
            await props.items.forEach(async (item: ISdkDashboardItem) => {                
                await props.itemDataService.saveItemState(item)
            })
        }
        
    }, [props.editMode])

    useEffectAsync(async () => {

        // set the loading state
        setIsPreparing(true)

        // initialize the dashboard
        console.log('Initializing dashboard data service...')
        await props.itemDataService.initializeDashboard(props.items)

        // reset loading state
        console.log('Dashboard data service initialized')
        setIsPreparing(false)

    }, [props.items])

    return (
        <div className="dashboard-grid">
            <ScrollablePane scrollContainerFocus={true} className="dashboard-grid-scroll-pane">
                { isPreparing ?
                    (<Spinner size={SpinnerSize.large} label="Preparing dashboard" labelPosition="bottom" style={{height: '100%'}} />) :
                    (                    
                        <GridLayout className="layout dashboard-grid-layout" cols={24} width={2100} isDraggable={props.editMode} isResizable={props.editMode} onLayoutChange={onLayoutChanged}>
                            {props.items.map((item: ISdkDashboardItem) => 
                                (<div key={item.dashboardId + '.' + item.id} data-grid={props.layoutService.getItemLayout(item)} className={getItemClassNames(item)}>
                                    <DashboardGridControlItemContainer 
                                        dashboardId={item.dashboardId} id={item.id} 
                                        dashboardCurrency={props.gridCurrency}
                                        name={item.name} description={item.description} 
                                        itemType={item.itemType} itemDataService={props.itemDataService.getItemInstanceService(item)}
                                        editMode={props.editMode} dataHash={props.dataHash}
                                        onDeleteItem={() => props.onDeleteItem(item)}
                                        onEditItem={() => props.onEditItem(item)}
                                        selectedPeriod={props.selectedPeriod}
                                        />
                                </div>
                                ))}
                        </GridLayout>                    
                    )
                }
            </ScrollablePane>
        </div>
    )
}
