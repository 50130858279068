import { ISdkCustomerConfigurationVault, ISdkCustomerConfigurationVaultCreate } from "./models/ISdkCustomerConfigurationVault";
import { ISdkCustomerConfigurationVaultTemplate } from "./models/ISdkCustomerConfigurationVaultTemplate";
import { ISdkObjectTemplate } from "./models/ISdkObjectTemplate";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerConfigurationVaultClient {

    private sdkClient: SdkClient<any>    
    private sdkTemplateClient: SdkClient<ISdkObjectTemplate>    
    
    constructor(tenantId: string, customerId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<any>('/api/v1/tenants/' + tenantId + '/svp/customers/' + customerId + '/vaults', token);
        this.sdkTemplateClient= SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + tenantId + '/svp/customers/' + customerId + '/vaults/templates', token);
    }

    listVaultTemplates(): Promise<ISdkObjectTemplate[]> {
        return this.sdkTemplateClient.list('/');        
    }

    listVaults(): Promise<ISdkCustomerConfigurationVault[]> 
    {                
        return this.sdkClient.list('/');                
    }

    listServiceConfigurationTemplates(): Promise<ISdkCustomerConfigurationVaultTemplate[]> 
    {
        return this.sdkClient.list('/configuration-templates');                
    }

    getVault(id: string): Promise<ISdkCustomerConfigurationVault> 
    {                
        return this.sdkClient.get('/' + id);                
    }

    createVault(vault: ISdkCustomerConfigurationVaultCreate): Promise<ISdkCustomerConfigurationVault> 
    {                    
        return this.sdkClient.post('/', vault).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })          
    }

    updatVault(id: string, vault: ISdkCustomerConfigurationVaultCreate): Promise<ISdkCustomerConfigurationVault> 
    {                        
        return this.sdkClient.put('/' + id, vault).then(async () => {
            return this.getVault(id)
        })               
    }

    deleteVault(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }
}