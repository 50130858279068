import { Callout, IDropdownOption } from "@fluentui/react"
import { useState } from "react"
import { FilterPill } from "./FilterPill"
import uuid from 'react-uuid';
import { IFilterItem, UnControlledFilterItem } from "../../forms/UnControlledFilterItem";


export interface IFilterPillAddProps {
    onAddFilter?: (filter: IFilterItem) => void

    availableFields: IDropdownOption[]
}

export const FilterPillAdd = (props: IFilterPillAddProps) => { 

    const [addCalloutVisible, setAddCalloutVisible] = useState(false)
    
    const keyId = 'filterAdd-' + uuid()

    const onClick = () => {
        setAddCalloutVisible(!addCalloutVisible)
    }

    return (        
        <FilterPill id={keyId} iconName={'faPlus'} label={'Add filter'} onClick={onClick}>
            <Callout 
                className={'mv-filter-pill-add-callout'}
                hidden={!addCalloutVisible} 
                role="dialog"
                gapSpace={20}
                target={'#' + keyId}
                onDismiss={onClick}
                setInitialFocus>

                <div style={{padding: '10px 20px', minWidth: '400px'}}>
                    <h2 style={{fontSize: '18px', fontWeight: '600' }}>Add filter</h2>                    
                    <UnControlledFilterItem 
                        key={'newFilterItem'} 
                        name={'newFilterItem'}                 
                        availableFields={props.availableFields} 
                        onUpdateFilterItem={(item) => {

                            setAddCalloutVisible(false)
                            
                            if (props.onAddFilter)
                                props.onAddFilter(item)
                        }} 
                        first={true}
                        single={true}
                        direction={'horizontal'} />
                </div>
            </Callout>        
        </FilterPill>
    )
}

