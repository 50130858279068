import { UnControlledListContainer } from "./UnControlledListContainer"
import { UnControlledListItemFormsContainer } from "./UnControlledListItemFormsContainer"
import { Control, FieldValues } from "react-hook-form"
import { Slot } from "@fluentui/react-components"
import uuid from "react-uuid"

export type UnControlledListFormsContainerProps<T extends FieldValues> = {
    disabled?: boolean    
    items: T[]
    itemDefaultValues?: T

    renderEditor: (control: Control<T, any>) => Slot<'div'>
    
    onAddItem?: (item: T) => Promise<void>
    onUpdateItem?: (item: T) => Promise<void>
    onDeleteItem?: (item: T) => Promise<void>
}

export const UnControlledListFormsContainer = <T extends FieldValues,>(props: UnControlledListFormsContainerProps<T>) => {

    const onUpdateItemIternal = (item: T): Promise<void> => {
        if (props.onUpdateItem) {
            return props.onUpdateItem(item)
        } else {
            return Promise.resolve()
        }
    }

    const onAddItemInternal = (item: T): Promise<void> => {
        if (props.onAddItem) {
            return props.onAddItem(item)
        } else {
            return Promise.resolve()
        }
    }

    const onDeleteItemInternal = (item: T): Promise<void> => {
        if (props.onDeleteItem) {
            return props.onDeleteItem(item)
        } else {
            return Promise.resolve()
        }
    }

    return (                
        <div style={{margin: '0px'}}>
            <UnControlledListContainer disabled={props.disabled} items={props.items}>
                {(item: T) => (<UnControlledListItemFormsContainer<T>  key={item.id} item={item} itemDefaultValues={props.itemDefaultValues} onUpdateItem={onUpdateItemIternal} onDeleteItem={onDeleteItemInternal} disabled={props.disabled}>
                    {(control) => props.renderEditor(control)}                                                
                </UnControlledListItemFormsContainer>)}
            </UnControlledListContainer>                            
            <UnControlledListItemFormsContainer<T> key={'%addItem'} itemDefaultValues={{...props.itemDefaultValues, id: uuid()} as any} onUpdateItem={onAddItemInternal} disabled={props.disabled}>
                {(control) => props.renderEditor(control)}                                                
            </UnControlledListItemFormsContainer>                
        </div>                    
    )    
}