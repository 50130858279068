import { IDropdownOption, PanelType, mergeStyleSets } from "@fluentui/react"
import { FluentProvider, webLightTheme } from "@fluentui/react-components"
import { useForm } from "react-hook-form"
import { IAppWindowBaseProps } from "../../ds/components/AppWindow"
import { getMonthDefinition, getRelativeMonthDefinition } from "../Helpers/DateHelpers"
import { PanelWithDefaultFooter } from "../DetailsListHelper/PanelWithDefaultFooter"
import { ControlledDropdown } from "../../forms/ControlledDropdown"
import { ControlledCheckbox } from "../../forms/ControlledCheckbox"

export interface IPeriodSelectorPanelProps extends IAppWindowBaseProps {    
    
    /* Basic properties */
    availablePeriods?: string[]
    onPeriodSelected: (period: string, forceNotification: boolean) => void
    allowauto?: boolean
    maxPeriods?: number

    /* Dialog properties */
    title: string
    description: string
    progressMessage: string

    /* additional options */
    showNotifyCheckbox?: boolean
} 

export const PeriodSelectorPanel = (props: IPeriodSelectorPanelProps) => {
        
    type SelectedPeriod = {
        period: string,
        forceNotification: boolean        
    }

    const { handleSubmit, control, reset } = useForm<SelectedPeriod>({
        defaultValues: {
            period: getMonthDefinition(new Date()),
            forceNotification: false
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });

    const dissmissDialog = () => {        
        reset({
            period: getMonthDefinition(new Date()),
            forceNotification: false
        }
    )
        if (props.dismissDialog)
            props.dismissDialog()
    }   

    const onSubmitDialog = () => {
        const submitHandler = handleSubmit((data: SelectedPeriod) => {            
            props.onPeriodSelected(data.period, data.forceNotification)
        })        
        return submitHandler()
    }

    let availablePeriods: IDropdownOption[] = []
    if (props.availablePeriods) {
        availablePeriods = props.availablePeriods.map((period) => { return { key: period, text: period }})
    } else {
        for(let i = -60; i <= 0; i++) {

            const monthString = getRelativeMonthDefinition(i);

            availablePeriods.push({
                key: monthString,
                text: monthString
            })
        }
    }

    // allow to automatic period selection
    if (props.allowauto === true) { availablePeriods.push({ key: 'auto', text: 'auto' }) }

    // reverse the order to show the newest period first
    availablePeriods.reverse()
        
    // cut the available periods to the max periods
    if (props.maxPeriods) { availablePeriods = availablePeriods.slice(0, props.maxPeriods) }

    // generate the styles
    const classNames = mergeStyleSets({
        checkbox: {
            marginTop: '10px'
        }
    })

    if (!props.isVisible)
        return (<></>)
    else 
        return (        
            <PanelWithDefaultFooter
                isOpen={props.isVisible}
                isPreparing={false}
                type={PanelType.medium}
                headerText={props.title}
                onDismiss={dissmissDialog}                                                
                submitLabel={'Select'}            
                isValid={true}                
                progessMessage={props.progressMessage}
                dissmissLabel={'Close'}                
                onSubmit={onSubmitDialog}>

                <FluentProvider theme={webLightTheme}>

                <p>
                    {props.description}
                    {props.allowauto && ' The value \'auto\' means the system detects automatically the most recent period!'}
                </p>    

                <ControlledDropdown key={'validUntil'} control={control} name={'period'} rules={{ required: 'a valid value is required'}} options={availablePeriods} />            

                { props.showNotifyCheckbox && (
                    <ControlledCheckbox control={control} name={'forceNotification'} label={'Send notification to recipients'} className={classNames.checkbox}/>
                )}

                </FluentProvider>
            </PanelWithDefaultFooter >
        )
}