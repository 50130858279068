import { ILinkProps } from "@fluentui/react";
import { useHref, useNavigate } from "react-router-dom";

export interface ILinkExProps extends ILinkProps {
    onNavigated?: () => void
    onNavigate?: () => void
}

export const LinkEx = (props: ILinkExProps) => {
    const nav = useNavigate();    

    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

        if (props.onNavigate) {
            props.onNavigate();
        } else {
            if (props.to && props.to.startsWith('http')) {
                window.open(props.to, props.target ? props.target : '_blank');
            } else {
                nav(props.to as string);
            }
        }

        e.preventDefault();

        if (props.onNavigated) {
            props.onNavigated();
        }
    }
    
    const hrefResolved = useHref(props.to)

    return (
        <a className={props.className} href={props.to && props.to.startsWith('http') ? props.to : hrefResolved} onClick={onClick}>{props.children}</a>
    )
}