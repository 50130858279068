import { IFilterItem } from "../../../../forms/UnControlledFilterItem";
import { ISdkDashboardItem } from "../../../../sdk/models/ISdkDashboardItem";
import { ISdkReportDefinition, ISdkReportDefinitionColumn } from "../../../../sdk/models/ISdkReportDefintion";
import { ISdkReportInstance } from "../../../../sdk/models/ISdkReportInstance";
import { SdkClientServiceDiscoveryService } from "../../../../sdk/SdkClientServiceDiscoveryService";
import { SdkCustomerReportClient } from "../../../../sdk/SdkCustomerReportClient";
import { IDashboardServiceWorkerClientGroupOperation, IGroupResults } from "../../../DashboardWidgets/Services/DashboardServiceWorkerClient";
import { IDashboardGridControlItemInstanceService, IDashboardGridControlItemService } from "../Contracts/IDashboardGridControlItemService";
import { IDashboardGridControlStateStorageService } from "../Contracts/IDashboardGridControlStateStorageService";

export class DashboardGridControlItemInstanceService implements IDashboardGridControlItemInstanceService {

    constructor(private _tenantId: string, private _item: ISdkDashboardItem, private _currentToken: string, private _itemService: DashboardGridControlItemService ) {}

    getAttributes() {
        return this._item.attributes;
    }
    
    getColumns() {         
        
        if (this._item.attributes && this._item.attributes.propertiesFormat && this._item.attributes.propertiesFormat.length > 0) {         
            const formatedColumns: ISdkReportDefinitionColumn[] = this._item.attributes.propertiesFormat.map((column: any) => {
                return {
                    id: column.property,
                    name: column.name || column.property,
                    type: column.format && column.format.length > 0 ? 'number#' + column.format : 'string',
                    dataSourceColumnId: undefined,
                    pivotOperation: 'none',                                                
                    discountProfiles: []
                }
            })  
                        
            return formatedColumns
        } else {
            const reportDefinition = this._itemService.getReportDefinition(this._item)
            if (!reportDefinition)
                return []

            return reportDefinition.columns
        }
    }
    
    getInstances(): ISdkReportInstance[] { 

        // get the report instances from cache
        const reportInstances = this._itemService.getReportInstances(this._item)
        if (!reportInstances)
            return []
            
        return reportInstances
    }

    async queryData(period: string, searchText?: string, filter?: IFilterItem[], groupBy?: string, groupOperations?: IDashboardServiceWorkerClientGroupOperation[], noItems?: boolean): Promise<[items: any[], groups: IGroupResults[]]> {
        const reportInstance = this._itemService.getReportInstances(this._item).find((instance) => instance.periodId === period || instance.id === period)
        if (!reportInstance) { return Promise.resolve([[],[]]) }

        return await this.queryDataEx(this._item.reportDefinitionId, reportInstance.id, searchText, filter, groupBy, groupOperations, noItems)
    }
    
    async queryDataEx(reportDefinitionId: string, reportInstanceId: string, searchText?: string, filter?: IFilterItem[], groupBy?: string, groupOperations?: IDashboardServiceWorkerClientGroupOperation[], noItems?: boolean): Promise<[items: any[], groups: IGroupResults[]]> {                            
        let reportInstance = this._itemService.getReportInstances(this._item).find((instance) => instance.reportId === reportDefinitionId && instance.id === reportInstanceId)                
        if (!reportInstance && this._item.reportDefinitionId !== reportDefinitionId) {

            var reportInstances = await this._itemService.getReportInstancesById(reportDefinitionId);
            if (!reportInstances) { return Promise.resolve([[],[]]) }
                
            reportInstance = reportInstances.find((instance) => instance.reportId === reportDefinitionId && (instance.id === reportInstanceId || instance.periodId === reportInstanceId))        
        }
        
        if (!reportInstance) { return [[],[]] }
        
        let requestQuery = ''

        if (searchText) {
            requestQuery += '&search=' + searchText
        }

        if (groupBy) {
            requestQuery += '&groupby=' + groupBy

            if (groupOperations) {                    
                groupOperations.forEach((groupOperation) => {
                    requestQuery += '&groupOp=' + groupOperation.property + ',' + groupOperation.operation
                })                
            }

            if (noItems) {
                requestQuery += '&noItems=true'
            }
        }

        if (filter) {
            filter.forEach((filter) => {
                var filterValue = encodeURIComponent(filter.operator + ',' + filter.field + ','  + filter.condition + ',' + filter.value + ',' + (filter.compare ? 'CaseInSensitive' : 'CaseSensitive'))
                requestQuery+= '&filterOp=' + filterValue
            })            
        }

        const requestUri = SdkClientServiceDiscoveryService.DiscoverUri(
            '/api/v1/tenants/' + this._tenantId + 
            '/reports/definitions/' + reportDefinitionId + 
            '/instances/' + reportInstance.id + 
            '/querydata?checkSum=' + reportInstance.checkSum +
            requestQuery)

        try {
            let fetchResult = await fetch(requestUri,{ headers: { 'Authorization': 'Bearer ' + this._currentToken } })  
            
            // this could happen when the service worker is not activated in time. In this case 
            // the system follows the strategy to wait a second and then reload the page 
            if (fetchResult.status === 404) {

                // wait for 5 seconds and try again
                await new Promise((resolve) => setTimeout(resolve, 1000))

                // refresh the page
                window.location.reload()                
            }

            return fetchResult.json()                                            
        } catch (error) {
            return [[],[]]
        }                        
    }

    getState(defaults?: any): any {
        
        // get the current state typically from the local browser storage
        const currentState = this._itemService.getItemState(this._item)

        // get the state from the server just in case we have a managed dashboard 
        const serverState = this._item.state ? { ...this._item.state } : {}

        // preparing the defaults 
        const defaultState = defaults ? defaults : {}

        // merge all together 
        const finalState =  {...defaultState, ...serverState, ...currentState}        

        // done
        return finalState
    }

    setState(state: any): void {
        this._itemService.setItemState(this._item, state)
    }

    saveState(): Promise<void> {
        return this._itemService.saveItemState(this._item)    
    }

    updateAndSaveState(state: any): Promise<void> {        
        const currentState = this._itemService.getItemState(this._item)
        this._itemService.setItemState(this._item, {...currentState, ...state})
        return this._itemService.saveItemState(this._item)    
    }
}

export class DashboardGridControlItemService implements IDashboardGridControlItemService {
    
    private _reportDefinitions: {[key: string]: ISdkReportDefinition} = {}
    private _reportDefinitionInstances: {[key: string]: ISdkReportInstance[]} = {}
    private _itemStates: {[key: string]: any} = {}
    private _dashboardInitialized: boolean = false

    constructor(private _tenantId: string, private _currentToken: string, private _stateStorageService: IDashboardGridControlStateStorageService) {}
    
    async initializeDashboard(items: ISdkDashboardItem[]): Promise<void> {

        // this is the step where the basic report definitons are processed, this measn the report definition will be registered
        // and the available periods are read. As soon this is happen the dashboard can be generated
                
        // step 1: get all reporting definitions in the dashboard 
        const usedReportDefinitions: string[] = []

        for(const item of items) {
            
            // disctinct report definitions
            if (!item.reportDefinitionId || usedReportDefinitions.indexOf(item.reportDefinitionId) !== -1)
                continue

            // add to the index
            usedReportDefinitions.push(item.reportDefinitionId)
        }
        
        // step 2: read all the available periods for the different data definitions
        const reportClient = new SdkCustomerReportClient(this._tenantId, undefined, this._currentToken)            
        
        for(const reportId of usedReportDefinitions) {
            const reportDefinition = await reportClient.getReportDefinition(reportId)            
            this._reportDefinitions[reportId] = reportDefinition

            // step 3: read all available periods for the different data definitions
            const reportInstances = await reportClient.getReportInstances(reportId)
            this._reportDefinitionInstances[reportId] = reportInstances.sort((a: any, b: any) => b.periodId.localeCompare(a.periodId))            
        }
        
        // stop 3: diagnosis reporting
        console.log('Referenced report definitions: ')
        Object.keys(this._reportDefinitions).forEach((reportDefinitionId: string) => {
            const reportDefinition = this._reportDefinitions[reportDefinitionId]
            const reportInstances = this._reportDefinitionInstances[reportDefinitionId]
            console.log('\tId: ' + reportDefinition.id + ', Name: ' + reportDefinition.name + ', Instances: ' + reportInstances.length)
        })

        // ensure the status are loaded correctly 
        console.log('Loading item states: ')
        for(const item of items) {
            const state = await this._stateStorageService.loadItemState(item)
            this._itemStates[item.id] = state
        }   
        
        // mark as initialized
        this._dashboardInitialized = true;
    }

    getItemInstanceService(item: ISdkDashboardItem): IDashboardGridControlItemInstanceService {
        return new DashboardGridControlItemInstanceService(this._tenantId, item, this._currentToken, this);
    }   

    getReportDefinitionById(reportDefinitionId: string): ISdkReportDefinition | undefined {
        return this._reportDefinitions[reportDefinitionId]
    }

    async getReportInstancesById(reportDefinitionId: string): Promise<ISdkReportInstance[]> {        
        const reportClient = new SdkCustomerReportClient(this._tenantId, undefined, this._currentToken)            
        const reportInstances = await reportClient.getReportInstances(reportDefinitionId)
        return reportInstances.sort((a: any, b: any) => b.periodId.localeCompare(a.periodId))            
    }


    getReportDefinition(item: ISdkDashboardItem): ISdkReportDefinition | undefined {
        if (!item.reportDefinitionId)
            return undefined

        return this.getReportDefinitionById(item.reportDefinitionId)
    }

    getReportInstances(item: ISdkDashboardItem): ISdkReportInstance[] {
        if (!item.reportDefinitionId)
            return []

        return this._reportDefinitionInstances[item.reportDefinitionId]
    }
    
    setItemState(item: ISdkDashboardItem, state: any): void {
        this._itemStates[item.id] = {...state}
    }

    getItemState(item: ISdkDashboardItem): any {
        return this._itemStates[item.id] || {}
    }

    saveItemState(item: ISdkDashboardItem): Promise<void> {
        if (!this._dashboardInitialized) { return Promise.resolve() }
        return this._stateStorageService.saveItemState(item, this._itemStates[item.id])        
    }
}