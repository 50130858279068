import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { UnControlledSchedulerField } from "./UnControlledSchedulerField";
import { ISdkSchedulerDefinition } from "../sdk/models/ISdkSchedulerDefinition";

export interface IControlledSchedulerFieldProps {    
}

export const ControlledSchedulerField = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IControlledSchedulerFieldProps) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
            <UnControlledSchedulerField 
                {...props}
                item={value || undefined}
                onSchedulerChanged={(item: ISdkSchedulerDefinition) => {
                  onChange(item)
                }}/>                    
        )}
    />
  );
};
