import { Icon, IDropdownOption, PrimaryButton, Stack } from "@fluentui/react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import uuid from "react-uuid"
import { ControlledDropdown } from "./ControlledDropdown"
import { ControlledTextField } from "./ControlledTextField"

export interface IFieldMappingItem {
    key: string
    field: string
    mappedField: string    
    pivotOperation: string
}

export interface IFieldMappingItemProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    
    disabled?: boolean        

    availableFields: IDropdownOption[]

    onUpdateItem: (item: IFieldMappingItem) => void    

    onDeleteItem?: (item: IFieldMappingItem) => void

    onElipsisClicked?: (item: IFieldMappingItem) => void

    onChangesPending?: (itemKey: string, pending: boolean) => void

    onFilterAvailablePivotOperations?: (propertyName: string, availableOptions: IDropdownOption[] ) => IDropdownOption[]

    item?: IFieldMappingItem 

    first?: boolean    

    defaultPivotKey?: string

    availablePivotOperations: IDropdownOption[]
}

export const UnControlledFieldMappingItem = (props: IFieldMappingItemProps) => {

    const [ filteredPivotOperations, setFilteredPivotOperations ] = useState<IDropdownOption[]>(props.availablePivotOperations)

    const { handleSubmit, control, reset, formState, getValues, watch } = useForm<IFieldMappingItem>({
        defaultValues: {
            key: '',
            field: '',
            mappedField: '', 
            pivotOperation: props.defaultPivotKey ? props.defaultPivotKey : 'none'          
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    useEffect(() => {

        if (props.item) {
            reset({...props.item})
        } else {
            reset({pivotOperation: props.defaultPivotKey ? props.defaultPivotKey : 'none'})
        }        

    // eslint-disable-next-line
    }, [props.item, props.defaultPivotKey])        

    // read the form state to initialize the proxy state
    // eslint-disable-next-line
    useEffect(() => {            
        if (props.onChangesPending) {            
            const values = getValues()
            props.onChangesPending(values.key, formState.isDirty)
        }
        
    // eslint-disable-next-line
    }, [formState.isDirty])
    
    const fieldPropertyWatcher = watch('field')
    useEffect(() => {
        if (!fieldPropertyWatcher) { setFilteredPivotOperations(props.availablePivotOperations); return; }

        if (props.onFilterAvailablePivotOperations) {
            setFilteredPivotOperations(props.onFilterAvailablePivotOperations(fieldPropertyWatcher, props.availablePivotOperations))
        }            
    // eslint-disable-next-line 
    }, [fieldPropertyWatcher])
        
    const onProcessItem = () => {

        const submitHandler = handleSubmit((data: IFieldMappingItem) => {

            // esure we have the correct key
            data.key = props.item ? props.item.key : uuid();

            // send the callback 
            props.onUpdateItem(data)            

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    const onDeleteItem = () => {

        const submitHandler = handleSubmit((data: IFieldMappingItem) => {

            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();

            // send the callback 
            if (props.onDeleteItem) {
                props.onDeleteItem(data) 
            }           

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }
    
    const renderStandardFieldEditor = () => {
        return (
            <>
                <td style={{width: '40%', paddingRight: '5px'}}>
                    <ControlledDropdown key={'fieldName'} control={control} name={'field'} placeholder={'Select field'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={props.availableFields} />                
                </td>
                <td style={{width: '40%', paddingRight: '5px'}}>
                    <div style={{marginTop: '10px'}}>
                        <ControlledTextField key={'mappedField'} control={control} name={'mappedField'} placeholder={'Optional new fieldname'} disabled={props.disabled} />
                    </div>
                </td>    
                <td style={{width: '20%', paddingRight: '5px'}}>
                    <ControlledDropdown key={'pivotOperation'} control={control} name={'pivotOperation'} disabled={props.disabled || !props.defaultPivotKey} options={filteredPivotOperations} />
                </td>  
            </>
        ) 
    }

    const renderCalculatedFieldEditor = () => {
        return (
            <>
                <td style={{width: '40%', paddingRight: '5px'}}>
                    <div style={{marginTop: '10px'}}>
                        <ControlledTextField key={'mappedField'} control={control} name={'mappedField'} placeholder={'Enter the field name'} disabled={props.disabled} />
                    </div>
                </td>
                <td style={{width: '40%', paddingRight: '5px'}} colSpan={2}>
                    <div style={{marginTop: '10px'}}>
                        <ControlledTextField key={'fieldName'} control={control} name={'field'} placeholder={'Enter the formular for field calculation'} disabled={props.disabled} />
                    </div>
                </td>                    
            </>
        )
    }

    const renderFieldEditor = () => {
        if (props.item && props.item.pivotOperation && props.item.pivotOperation === '$calculated') {
            return renderCalculatedFieldEditor()
        } else {
            return renderStandardFieldEditor()
        }
    }

    return (
        <tr style={{verticalAlign: 'top'}}> 
            { renderFieldEditor() }
            <td>
                <div style={{marginTop: '10px'}}>
                    <PrimaryButton style={{width: '10px', minWidth: '10px'}} disabled={props.disabled || !formState.isValid || !formState.isDirty} onClick={onProcessItem}>                            
                        <Icon iconName={props.item ? 'faCheck' : 'faPlus'}></Icon>
                    </PrimaryButton>                        
                </div>    
            </td>  

            { (props.item  && props.onElipsisClicked) && (
                <td>
                    <Stack.Item grow={0}>
                        <div style={{marginTop: '10px'}}>
                            <PrimaryButton style={{width: '35px', minWidth: '35px'}} disabled={props.disabled} onClick={() => props.onElipsisClicked ? props.onElipsisClicked(props.item as IFieldMappingItem) : undefined}><Icon iconName={'faEllipsis'}></Icon></PrimaryButton>
                        </div>
                    </Stack.Item>                                
                </td>)
            }          

            { (props.item  && props.onDeleteItem) && (
            <td>                
                <Stack.Item grow={0}>
                    <div style={{marginTop: '10px'}}>
                        <PrimaryButton style={{width: '35px', minWidth: '35px'}} disabled={props.disabled} onClick={onDeleteItem}><Icon iconName={'faTrashCan'}></Icon></PrimaryButton>                        
                    </div>    
                </Stack.Item>                                     
            </td>)}            
        </tr>
    )        
}