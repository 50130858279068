import { Link, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { useAuth } from "@mevodo/mv-react-authentication";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "../lib/authentication/AuthenticationProfileContext";
import { Stage } from "../lib/ds/ds";
import { AnimatedSubmitButton } from "../lib/forms/AnimatedSubmitButton";
import { ControlledCheckbox } from "../lib/forms/ControlledCheckbox";
import { ControlledTextField } from "../lib/forms/ControlledTextField";
import { SdkTenantClient } from "../lib/sdk/SdkTenantClient";
import { SdkCustomerDashboardClient } from "../lib/sdk/SdkCustomerDashboardClient";
import { useBranding } from "../lib/ds/components/BrandingProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { thin } from "@fortawesome/fontawesome-svg-core/import.macro";

export  const SignUpTenant = () => {
   
    const profileContext = useProfileContext();
    const branding = useBranding()
    
    // helper methods
    const auth = useAuth();       
    const navigate = useNavigate();
            
    type SignUpForm = {
        companyName: string
        companyAddress: string
        acceptTCs: boolean
    };
    
    const { handleSubmit, control } = useForm<SignUpForm>({
        defaultValues: {
            companyName: '',
            companyAddress: '',
            acceptTCs: false
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });

    const [submitError, setSubmitError] = useState<string>()
    const [submitProcessing, setSubmitProcessing] = useState<boolean>(false)

    const onSubmit: SubmitHandler<SignUpForm> = (data, e) => {

        setSubmitProcessing(true);
        const tenantClient = new SdkTenantClient(auth.currentToken as string)

        tenantClient.registerTenant({ name: data.companyName}).then((tenant) => {

            const dashboardClient = new SdkCustomerDashboardClient(tenant.id, 'root', 'CustomerGroup', auth.currentToken as string)
            return dashboardClient.createCustomerDashboard('Default Dashboard', 'root', 'CustomerGroup', true).then(() => {            
                navigate('/tenants/' + tenant.id)
                setSubmitError(undefined)
                setSubmitProcessing(false);
            })
        }).catch((error) => {  
            let errorMessage = 'Something went wrong creating the new tenant, please try again later'
            errorMessage += error.code ?  ' (' + error.code + ')' : ''
            setSubmitError(errorMessage)
            setSubmitProcessing(false);
        })        
    }
    
    // render the view 
    const renderTcLabel = () => {
        return (
          <span>
            I have read and agree to the {' '}
            <Link href="#" target="_blank" underline>
              terms and conditions
            </Link>            
          </span>
          
        );    
    }
    
    const renderSubmitErrorMessage = () => {
        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>                
                {submitError}
            </MessageBar>
        )
    }

    const stackTokens = { childrenGap: 15 };

    // figure out if create tenant is allowed
    const createTenantAllowed = (profileContext.profile.tenants.filter((t) => !t.managed).length > 0)

    return ( 
        <Stage brandText={branding.company?.name ? branding.company?.name : 'mevodo'} brandLogo={branding.company?.logos['fav32']} headerTitle={'Create new tenant in ' + (branding.company?.name ? branding.company?.name : 'Cloud Billing') } height="450px" isVisible={true}>        
            
            { createTenantAllowed ? 
                <div>
                    <p>Welcome! Please go through the question below to quick start with the service instantly in a new tenant. The initial setup does 
                    not take more than 5 minutes of your time. If you are unsure please keep the default values, all setting can be adjusted later on.</p>
                    
                    {submitError ? renderSubmitErrorMessage() : (<></>)}
                        
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack tokens={stackTokens}>                
                            <ControlledTextField label="Please fill out your company name" control={control} name={'companyName'} rules={{ required: 'A valid name of your company is required!' }} disabled={submitProcessing} />                         
                            <ControlledTextField label="Add your company address" control={control} name={'companyAddress'} rules={{ required: 'A valid company address is required'}} multiline rows={4} disabled={submitProcessing}/>
                            <ControlledCheckbox  onRenderLabel={renderTcLabel} control={control} name={'acceptTCs'} rules={{ required: 'Accepting the terms and conditions is required'}} disabled={submitProcessing}/>
                        </Stack> 
                        <p></p>
                        <AnimatedSubmitButton text="Finish Setup" processing={submitProcessing} disabled={submitProcessing}/>                                    
                    </form>
                </div> : <div>
                    <p style={{textAlign: 'center', padding: '50px'}}>
                        <FontAwesomeIcon icon={thin('face-pensive')} size='8x' />
                    </p>
                    <p>
                        <strong>We are sorry</strong> but it looks like your user account (<strong>{profileContext.profile.contact}</strong>) is not eligable to create new tenants in this 
                        service. Your service provider or your IT department should able to help you.
                    </p>
                </div> }

            <p style={{fontStyle: 'italic', position: 'absolute', bottom: 0}}>Your are logged in as user <strong>{profileContext.profile.contact}</strong> (<a href={'logout'}>logout</a>)</p>
        </Stage>                 
    );
  }