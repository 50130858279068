import { Spinner, SpinnerSize, Stack } from "@fluentui/react"
import { Outlet } from "react-router-dom"
import { NavigationMenu, NavigationMenuItem } from "../../ds/components/NavigationMenu"

export interface INavigationMenuSidebarRouteEntry {
    name: string
    route: string
    childs?: INavigationMenuSidebarRouteEntry[]
} 

export interface INavigationMenuSidebarProps {
    routes?: INavigationMenuSidebarRouteEntry[]
}

export const NavigationMenuSidebar = (props: INavigationMenuSidebarProps) => { 

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack horizontal disableShrink>                
                { props.routes ? (
                    <NavigationMenu>                    
                        
                        {props.routes && props.routes.map((route) => {
                            return (
                                <NavigationMenuItem key={route.name} name={route.name} route={route.route}>
                                    {route.childs && route.childs.map((child) => (
                                        <NavigationMenuItem key={child.name} name={child.name} route={child.route} />
                                    ))}                                
                                </NavigationMenuItem>)
                        })}                                        
                    </NavigationMenu>
                ) : (
                    <div style={{width: '208px'}}>
                        <Spinner size={SpinnerSize.small} style={{marginTop: '17px'}} label={'Preparing...'} />
                    </div>
                )}
                <div style={{width: 'calc(100% - 208px)', height: '100%', backgroundColor: 'white', overflow: 'scroll'}}>
                    <Outlet />                
                </div>
            </Stack>
        </Stack>    
    )    
}