import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import { ISdkEndCustomerServiceConfiguration, ISdkEndCustomerServiceConfigurationCreate } from "./models/ISdkEndCustomerServiceConfiguration";
import { ISdkObjectTemplate } from "./models/ISdkObjectTemplate";

export class SdkEndCustomerConfigurationClient {

    private sdkClient: SdkClient<ISdkEndCustomerServiceConfiguration>
    private sdkTemplateClient: SdkClient<ISdkObjectTemplate>
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkEndCustomerServiceConfiguration>('/api/v1/tenants/' + tenantId + '/configurations' , token);
        this.sdkTemplateClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + tenantId + '/configurations/templates' , token);
    }

    listTemplates(): Promise<ISdkObjectTemplate[]> 
    {               
        return this.sdkTemplateClient.list('');                
    }

    list(): Promise<ISdkEndCustomerServiceConfiguration[]>
    {
        return this.sdkClient.list('');
    }

    createConfiguration(configuration: ISdkEndCustomerServiceConfigurationCreate): Promise<ISdkEndCustomerServiceConfiguration> 
    {            
        return this.sdkClient.post('/', configuration).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })          
    }

    updatConfiguration(id: string, configuration: ISdkEndCustomerServiceConfigurationCreate): Promise<ISdkEndCustomerServiceConfiguration> 
    {                        
        return this.sdkClient.put('/' + id, configuration).then(async () => {
            return this.getConfiguration(id);
        })               
    }

    getConfiguration(id: string): Promise<ISdkEndCustomerServiceConfiguration> 
    {                
        return this.sdkClient.get('/' + id);                
    }

    deleteConfiguration(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }
}
