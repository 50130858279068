import { IAppWindowBaseProps } from "../../ds/components/AppWindow"
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate } from "../../sdk/models/ISdkObjectTemplate"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { SdkAddOrEditTemplateBasedObjectPanel } from "../../sdkdatacomponents/SdkAddOrEditTemplateBasedObjectPanel"
import { SdkTenantTriggerClient } from "../../sdk/SdkTenantTriggerClient"

export interface ITriggerAddOrEditDialogProps extends IAppWindowBaseProps {
    tenantId: string    
    itemId?: string
}

export const TriggerAddOrEditDialog = (props: ITriggerAddOrEditDialogProps) => {

    const auth = useAuthInContext(props.tenantId as string)
            
    const onLoadTemplates = () => {        
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.listTemplates()        
    }

    const onCreate = (item: ISdkObjectFromTemplateCreate) => {        
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.createTrigger(item)        
    }

    const onUpdate = (item: ISdkObjectFromTemplate) => {
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.updateTrigger(item.id, item)                
    }    

    const onLoad = async (itemId: string) => {
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);
        return await sdkClient.getTrigger(itemId)        
    }
    
    return (
        <SdkAddOrEditTemplateBasedObjectPanel 
            isVisible={props.isVisible} dismissDialog={props.dismissDialog}
            tenantId={props.tenantId as string} itemId={props.itemId} 
            objectTitle={'Trigger'}
            objectDescription={'Enter or adjust all required details to configure a new or update an existing trigger.'}
            templateSelectorLabel="Please select the trigger type from the list below."
            templateSelectorPlaceholder="Select type of the trigger"                
            onLoadTemplates={onLoadTemplates}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onLoad={onLoad}
        />  
    )
}