import { ISdkApiDefinition, ISdkApiDefinitionIndexItem } from "./models/ISdkApiDefinition";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantApiDefinitionClient {
    private _sdkClient: SdkClient<ISdkApiDefinition>
    private _sdkClientIndex: SdkClient<ISdkApiDefinitionIndexItem>

    constructor(
        private _tenantId: string,
        private _token: string)         
    {
        this._sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkApiDefinition>('/api/v1/tenants/' + this._tenantId + '/developmentcenter', this._token);
        this._sdkClientIndex = SdkClientServiceDiscoveryService.Discover<ISdkApiDefinitionIndexItem>('/api/v1/tenants/' + this._tenantId + '/developmentcenter', this._token);;
    }

    loadApis(): Promise<ISdkApiDefinitionIndexItem[]> {
        return this._sdkClientIndex.list('');
    }

    loadApi(apiId: string): Promise<ISdkApiDefinition> {
        return this._sdkClient.get('/' + apiId);
    }
}