import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { SearchGeneralPanel } from "./SearchGeneralPanel"
import { ISdkSearchResultItem } from "../lib/sdk/models/ISdkSearchResultItem"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { useState } from "react"
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient"
import { ISdkCustomerDetails } from "../lib/sdk/models/ISdkCustomer"
import { ServiceProviderCustomerManagementUIService } from "./ServiceProviderCustomerManagementUIService"

export interface IDeployFromTemplateCustomerDialogProps extends IAppWindowBaseProps {   
    tenantId: string    
    customerId?: string    
}

export const DeployFromTemplateCustomerDialog = (props: IDeployFromTemplateCustomerDialogProps) => {

    const auth = useAuthInContext(props.tenantId);           
     
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        
    const [targetCustomer, setTargetCustomer] = useState<ISdkCustomerDetails>()
    
    // establish the uiService
    const uiService = new ServiceProviderCustomerManagementUIService(props.tenantId, auth.currentToken ? auth.currentToken : '')
        
    useEffectAsync(async () => {
        
        // do nothing when no item set
        if (!props.customerId) { return }

        // set the loading mode
        setIsPreparing(true)

        // load the customer model including details
        const sdkClient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        const customer = await sdkClient.getCustomer(props.customerId)
        setTargetCustomer(customer)
        
        // disable the loading mode
        setIsPreparing(false)

    }, [props.customerId])
    
    const dissmissDialog = () => {
        setTargetCustomer(undefined)
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onOpenCustomerPortal = (customerId: string) => {     
        const sdkCLient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        return sdkCLient.getCustomer(customerId).then((customer) => {        
            uiService.openInCustomerContextExt(customer.tenantId, customer.id, '/portal')
            return Promise.resolve()
        })            
    }

    const onDeployFromCustomer = (customer: ISdkSearchResultItem) => {     
        
        if (!targetCustomer) { return Promise.resolve()}     
        
        const sdkCLient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        return sdkCLient.deployFromTeplateCustomer(targetCustomer?.id, customer.id).then(() => {                            
            return onOpenCustomerPortal(targetCustomer.id)
        }).catch((e)=> {            
            return Promise.reject(e)        
        })        
    }
    
    const description = 'Please enter a search creteria for the right customer you want to use as template ' +
                        'for deploying reports and dashboards to the customer ' + targetCustomer?.name + ' ('+ targetCustomer?.id+') . The search result ' + 
                        'can be verified, so the deployment starts after confirmation. Please ensure that both customer have the same subscriptions assigned!'

    let actions = [
        { text: 'Deploy from this customer', onClick: onDeployFromCustomer }        
    ]    
    
    return (
        <SearchGeneralPanel 
                isVisible={props.isVisible}
                dismissDialog={dissmissDialog}
                tenantId={props.tenantId}                 
                searchTypes={['customer']} 
                title={'Deploy Reports and Dashboards to customer ' + (targetCustomer ? targetCustomer.name : '')} 
                description={description} 
                searchPlaceholder={'Search for customers'} 
                searchResultHeader={'Customers'}
                actions={actions}
                isPreparing={isPreparing}
                exclude={targetCustomer ? [targetCustomer.id] : undefined}

        />)
}