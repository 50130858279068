import { useBoolean } from "@fluentui/react-hooks"
import { IColumn, IDropdownOption, MessageBar, MessageBarType, Selection } from "@fluentui/react"
import { useEffect, useState } from "react"
import { ConfirmDialog, IConfirmDialogService, useConfirmDialog } from "../../ds/components/ConfirmDialog"
import { ICommandBarService, useCommandBarService } from "./ComandBarService"
import { IColumnsBuilder, useColumnsBuilder } from "./ColumnsBuilder"
import { NavigationalDetailsList } from "./NavigationalDetailsList"
import { IFilterItem } from "../../forms/UnControlledFilterItem"
import { ICommandDescription } from "../../commands/ICommandDescription"
import { useEffectAsync } from "../Helpers/EffectAsyncHook"
import { ICommandPermission } from "../../commands/ICommandPermission"
import { extractErrorMessage } from "../../sdk/SdkClientErrorExtractor"

export interface INavigationalDetailsListWithStandardOperationsProps<T> {
    uniqueIdentifier: string
    refresh?: boolean
    preventInitialLoad?: boolean
    hideReferesh?:boolean
    onEvaluateCommandPermissions?: (commands: ICommandDescription[]) => Promise<ICommandPermission[]>
    onBuildColumns: (builder: IColumnsBuilder<T>) => void;
    onAddCommands?: (commandBarService: ICommandBarService, commandPermissions: ICommandPermission[], confirmDialogService: IConfirmDialogService) => void;
    onLoadData: (filters: IFilterItem[], searchText: string) => Promise<T[]>    
    onNewItem?: () => (void)
    newItemLabel?: string          
    onDeleteItems?: (items: T[]) =>  Promise<void>    
    onSelectedItemsChanged?: (selectedItems: T[], allItems: T[]) => void
    isItemSelected?: (item: T) => boolean 
    disabledItemSelect?: boolean
    embedded?: boolean
    allowSorting?: boolean
    defaultSortBy?: string 
    defaultSortDirection?: 'asc' | 'desc'
    allowSearching?: boolean
    allowFiltering?: boolean
    isSingleSelection?: boolean
}

export function NavigationalDetailsListWithStandardOperations<T>(props: INavigationalDetailsListWithStandardOperationsProps<T>) {
        
    const [items, setItems] = useState<T[]>([])    
    const [commandPermissions, setCommandPermissions] = useState<ICommandPermission[]>([])
    const [error, setError] = useState('')
    const [lastSearchText, setLastSearchText] = useState('')
    const [currentFilterItems, setCurrentFilterItems] = useState<IFilterItem[]>(() => {
        const items = localStorage.getItem('mv-grid-filters-' + props.uniqueIdentifier)
        if (items) {
            return JSON.parse(items)
        } else {
            return []
        }
    })
    const [availableSearchFields, setAvailableSearchFields] = useState<IDropdownOption[]>([])    
    const [isLoading, { setTrue: setLoading, setFalse: resetLoading}] = useBoolean(false)    
    const [multipleItemsSelected, { setTrue: enableMultipleItemsSelected, setFalse: disableMultipleItemsSelected}] = useBoolean(false)    
    const [didInitialLoad, setDidInitialLoad] = useState(false)
    const [didEvaluatePermissions, setEvaluatedPermissions] = useState(false)
    const [initialSortExecuted, setInitialSortExecuted] = useState(false)

    const [columns, setColumns] = useState<IColumn[]>([])    

    const [selection] = useState<Selection>(new Selection({
        onSelectionChanged: () => {

            const selectedItems = selection.getSelection() as T[]
            const allItems = selection.getItems() as T[]
            props.onSelectedItemsChanged?.(selectedItems, allItems)

            if (selectedItems.length === 0) {
                disableMultipleItemsSelected()
            } else {
                enableMultipleItemsSelected()
            }
        },
    
        onItemsChanged: () => {

            if (props.isItemSelected) {
                selection.getItems().forEach(item => {
                    if (props.isItemSelected && props.isItemSelected(item as T)) {
                        selection.setKeySelected(item.key as string, true, false)                        
                    }
                })
            }             
        }
    }))
       
    const handleErrorForPromise = (promise: Promise<any>) => {
        setError('')
        promise.finally(() => {
            resetLoading()
        }).catch((error) => {  
            setError(extractErrorMessage(error))
        })  
    }

    const columnsBuilder = useColumnsBuilder<T>()

    const defaultSortOperation = (name: string, descending: boolean, updatedColumns: IColumn[], getValue: (item: T) => string) => {                
        setItems(prevItems => prevItems.slice(0).sort((a: any, b: any) => {            
            let aValue = getValue(a)
            let bValue = getValue(b)

            const aValueNumber = Number(aValue === 'n/a' ? 0 : aValue)
            const bValueNumber = Number(bValue === 'n/a' ? 0 : bValue)

            if (isNaN(aValueNumber) || isNaN(bValueNumber)) {
                aValue = aValue.toLowerCase()
                bValue = bValue.toLowerCase()                
                return ((descending ? aValue < bValue : aValue > bValue) ? 1 : -1)
            } else {
                return ((descending ? aValueNumber < bValueNumber : aValueNumber > bValueNumber) ? 1 : -1)
            }
        }))                
        setColumns(updatedColumns)
    }

    useEffect(() => {
        
        // define the global sort handler 
        if (props.allowSorting) {
            columnsBuilder.SetSortOperation(defaultSortOperation)
        }

        // ask for the columns
        props.onBuildColumns(columnsBuilder)
        
        // build the columns
        const generatedColumns = columnsBuilder.Build()        
        
        // done
        setColumns(generatedColumns)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        
        // check if the system executed the initial sort
        if (initialSortExecuted) { return; }

        // check if columns are defined 
        if (!columns || columns.length === 0) { return; }

        // just in case we do not allow sorting or a default sorting is not defined
        if (!props.allowSorting || !props.defaultSortBy) { return; }

        // check if a sorting column exists, if so let's take this column
        let sortColumn = columns.find(c => c.isSorted)

        // otherwise search the correct column the system need to sort by
        if (!sortColumn) {                     
            sortColumn = columns.find(c => c.fieldName === props.defaultSortBy || c.name === props.defaultSortBy)

            // adjust the sort column in case desc is selected
            if (sortColumn) {
                if (props.defaultSortDirection === 'desc') { 
                    sortColumn.isSortedDescending = true 
                } else {
                    sortColumn.isSortedDescending = false 
                }
            }
        } 

        // if no sort column was found, end the process and bayil out 
        if (!sortColumn) { 
            setInitialSortExecuted(true)
            return; 
        }            
                
        // reset the sort state to prevent flipping the order
        sortColumn.isSorted = false        

        // call the onClick method to execute the correct sorting order
        sortColumn.onColumnClick!(undefined!, sortColumn)

        // set the initial sort executed
        setInitialSortExecuted(true)

    // eslint-disable-next-line     
    }, [items])

    const reload = () => {
        setLoading()     
        setInitialSortExecuted(false)   
        
        handleErrorForPromise(props.onLoadData(currentFilterItems, lastSearchText).then((items) => {                                    
            
            // set the items
            setItems(items)              
            
            // get the first item 
            if (items.length !== 0 && availableSearchFields.length === 0) {
                const firstItem = items[0] as any

                setAvailableSearchFields(Object.keys(firstItem).map(itemKey => {
                    return { key: itemKey, text: itemKey }
                }))
            }            
        }))
    }

    const newItem = () => {
        if (!props.onNewItem)
            return

        props.onNewItem()
    }

    // callback to remove the selected items    
    const [confirmDialog, confirmDialogService] = useConfirmDialog()

    const deleteSelectedItemsConfirmed = (): Promise<void> => {

        if (!props.onDeleteItems)
            return Promise.resolve()        

        return props.onDeleteItems(selection.getSelection() as T[]).then(() => {            
            reload()
        })
    } 

    const deleteSelectedItems = () => {  
        const selectedItems = selection.getSelection() as T[]
        confirmDialogService.ask(
            'Deleting ' + selectedItems.length + ' item' + (selectedItems.length > 1 ? 's' : ''), 
            'Do you really want to remove the selected items? All data associated with these items will be removed, restoring data is not possible!',
            deleteSelectedItemsConfirmed);
    }

    // build the commandbar
    const commandBarService = useCommandBarService()
    
    // add the default operations    
    commandBarService.Command('new', (props.newItemLabel ? props.newItemLabel : 'New'), 'faPlus', newItem, !commandPermissions.find(x => x.command.key === 'new')?.canExecute, !props.onNewItem)                    
    commandBarService.Command('delete', 'Delete', 'faTrashCan', deleteSelectedItems, !multipleItemsSelected || !commandPermissions.find(x => x.command.key === 'delete')?.canExecute, !props.onDeleteItems)        
    commandBarService.Command('refresh', 'Refresh', 'faRotate', reload, !commandPermissions.find(x => x.command.key === 'refresh')?.canExecute, props.hideReferesh)

    // ask for further 
    if (props.onAddCommands)
        props.onAddCommands(commandBarService, commandPermissions, confirmDialogService)

    // initialy load the data    
    useEffectAsync(async () => {  
        
        if (props.preventInitialLoad && !didInitialLoad) {
            setDidInitialLoad(true)
            return
        }        

        if (!didEvaluatePermissions) {            
            setEvaluatedPermissions(true)

            if (props.onEvaluateCommandPermissions) {
                const commandPermissions = await props.onEvaluateCommandPermissions(commandBarService.GetCommandDescriptions())
                setCommandPermissions(commandPermissions)                    
            } else {
                setCommandPermissions(commandBarService.GetCommandDescriptions().map(x => { return { command: x, canExecute: true } }))
            }
        }

        await reload()             
    // eslint-disable-next-line                                    
    }, [currentFilterItems, lastSearchText, props.refresh])
            
    const onSearch = (filters: IFilterItem[], searchText: string): void => {
        setCurrentFilterItems(filters)
        setLastSearchText(searchText)

        localStorage.setItem('mv-grid-filters-' + props.uniqueIdentifier, JSON.stringify(filters))
    }
        
    return (
        <>
            { error && error.length > 0 ? (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {'Error: ' + error}
                </MessageBar>
            ) : (<></>)}

            <NavigationalDetailsList 
                    uniqueIdentifier={props.uniqueIdentifier}
                    embedded={props.embedded}
                    dataItems={items}
                    columns={columns} 
                    isLoading={isLoading}                    
                    commandBarService={commandBarService.HasItems() ? commandBarService : undefined}
                    selection={props.disabledItemSelect ? undefined : selection}                                        
                    allowSearching={props.allowSearching}
                    availableSearchFields={availableSearchFields}
                    initialSearchText={lastSearchText}
                    initialSearchFilters={currentFilterItems}   
                    onSearch={onSearch}                 
                    allowFiltering={props.allowFiltering}
                    isSingelSelection={props.isSingleSelection}
                />  
            <ConfirmDialog {...confirmDialog}/>       
        </>
    )
}