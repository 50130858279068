import { ISdkObjectFromTemplateFields, ISdkObjectTemplate } from "./models/ISdkObjectTemplate";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerCustomPropertyClient {

    private sdkTemplateClient: SdkClient<ISdkObjectTemplate>
    private sdkClient: SdkClient<ISdkObjectFromTemplateFields>
    
    constructor(tenantId: string, customerId: string, token: string)         
    {                    
        this.sdkTemplateClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + tenantId + '/svp/customers/' + customerId + '/customproperties/effectivetemplates', token);        
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectFromTemplateFields>('/api/v1/tenants/' + tenantId + '/svp/customers/' + customerId + '/customproperties', token);        
    }

    listCustomPropertyTemplates(): Promise<ISdkObjectTemplate[]> 
    {        
        return this.sdkTemplateClient.list('');
    }
    
    writeCustomPropertyValues(propertyValues: ISdkObjectFromTemplateFields): Promise<Boolean> 
    {        
        return this.sdkClient.post('', propertyValues).then(() => {
            return true;
        }).catch(() => {
            return false;
        })        
    }

    readCustomPropertyValues(): Promise<ISdkObjectFromTemplateFields> 
    {        
        return this.sdkClient.get('')
    }
}

