import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useNavigate, useParams } from "react-router-dom"
import { DashboardGridComponent } from "../lib/components/DashboardGrid/GridSurface/DashboardGridComponent"
import { useQuery } from "../lib/ds/components/RouterExtensions"
import { SdkTenantDashboardClient } from "../lib/sdk/SdkTenantDashboardClient"

export const EndCustomerPortalDashboard = () => {
     
    // get data from uri
    const { tenantId, dashboardId } = useParams()     
    const { allowManagedEdit } = useQuery()

    const navigate = useNavigate()
    const auth = useAuthInContext(tenantId as string)

    const onDeleteDashboard = () => {
        
        const client = new SdkTenantDashboardClient(tenantId as string, auth.currentToken as string)
        return client.deleteDashboardDefinition(dashboardId as string).then((success) => {
            
            // show the error
            if (!success)
                return Promise.reject('Failed to delete dashboard') 
                            
            // navigate to the main page
            navigate('/tenants/' + tenantId + '/portal/?reload=1')    
            
            // done
            return Promise.resolve()
        })
    }

    // render the dashboard
    return (<DashboardGridComponent tenantId={tenantId as string} dashboardId={dashboardId as string} onDeleteDashboard={onDeleteDashboard} allowManagedEdit={allowManagedEdit && allowManagedEdit === 'true' ? true : false} reportCollection={'endCustomerCollection'} />)    
}