import { IDropdownOption } from "@fluentui/react"
import { IFilterItem } from "../../forms/UnControlledFilterItem"
import { FilterPill } from "./FilterPill"
import { FilterPillAdd } from "./FilterPillAdd"

export interface IFilterPillPanelProps {
    pills: IFilterItem[]
    availableFields: IDropdownOption[]

    onRemoveFilter?: (filter: IFilterItem) => void
    onAddFilter?: (filter: IFilterItem) => void
}

export const FilterPillPanel = (props: IFilterPillPanelProps) => { 
    
    return (
        <div style={{margin: '0 10px 0 10px', display: 'flex'}}>
            {props.pills.map((pill) => {
                return (<FilterPill filterItem={pill} onRemove={props.onRemoveFilter} />)
            })}
            <FilterPillAdd onAddFilter={props.onAddFilter} availableFields={props.availableFields} />
        </div>
    )
}