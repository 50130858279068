import { Control, FieldValues, useForm } from "react-hook-form";
import { UnControlledListItemContainer } from "./UnControlledListItemContainer"
import { Button, Slot, makeStyles } from "@fluentui/react-components";
import { Icon } from "@fluentui/react";
import { useEffect } from "react";

export type UnControlledListItemFormsContainerRenderFunction<T extends FieldValues> = (control: Control<T, any>) => Slot<'div'>;

export type UnControlledListItemFormsContainerProps<T extends FieldValues> = {
    item?: T

    itemDefaultValues?: T    

    children: UnControlledListItemFormsContainerRenderFunction<T>    

    onUpdateItem?: (item: T) => Promise<void>
    onDeleteItem?: (item: T) => Promise<void>

    disabled?: boolean    
}

export const useItemListItemBaseActionStyles = makeStyles({
    actionRoot: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '5px',
        minWidth: '75px',
        justifyContent: 'flex-end'
    },
    actionButton: {
        width: '35px', 
        minWidth: '35px',  
        height: '32px',            
        ':hover': {
            backgroundColor: 'var(--colorNeutralBackground1Hover)'
        },
        ':hover:disabled': {
            backgroundColor: 'inherit'
        }        
    },
    actionButtonIcon: {
        fontSize: '14px',
        fontWeight: '400'
    }
})


export const UnControlledListItemFormsContainer = <T extends FieldValues,>(props: UnControlledListItemFormsContainerProps<T>) => {        

    const styles = useItemListItemBaseActionStyles()

    const { handleSubmit, control, reset, formState } = useForm<T>({
        defaultValues: props.item ? props.item as any : props.itemDefaultValues ? {...props.itemDefaultValues} as any : {},
        reValidateMode: "onChange",
        mode: "all"
    });    

    useEffect(() => {
        if (props.item) {
            reset(props.item)
        } else {
            reset(props.itemDefaultValues)
        }      
    
    // eslint-disable-next-line  
    }, [props.item])

    // This ensures that the change tracking works correctly
    useEffect(() => {
        //console.log('formState.Dirty', formState.isDirty)
        //console.log('formState.Valid', formState.isValid)        
    }, [formState.isDirty, formState.isValid])
    
    const processUpdateItem = () => {
        const submitHandler = handleSubmit(async (data: T) => {
            if (props.onUpdateItem) { await props.onUpdateItem(data) }
            reset()
        })

        submitHandler()
    }

    const processDeleteItem = () => {
        const submitHandler = handleSubmit(async (data: T) => {
            if (props.onDeleteItem) { await props.onDeleteItem(data) }
            reset()
        })

        submitHandler()
    }    

    const renderActionsSlot = () => {

        const updateRequired = formState.isDirty && formState.isValid

        if (props.item) {            
            return (
                <div className={styles.actionRoot}>                
                    <Button className={styles.actionButton} appearance={'primary'} disabled={props.disabled || !updateRequired} icon={<Icon className={styles.actionButtonIcon} iconName={'faCheck'} />} onClick={processUpdateItem} />
                    <Button className={styles.actionButton} appearance={'primary'} disabled={props.disabled} icon={<Icon className={styles.actionButtonIcon} iconName={'faTrashCan'} />} onClick={processDeleteItem} />
                </div>       
            )
        } else {
            return (
                <div className={styles.actionRoot}>                
                    <Button className={styles.actionButton} appearance={'primary'} disabled={props.disabled || !updateRequired} icon={<Icon className={styles.actionButtonIcon} iconName={'faPlus'} />} onClick={processUpdateItem} />
                </div>       
            )
        }
    }
        
    return (<UnControlledListItemContainer disabled={props.disabled} editorSlot={props.children(control)} actionsSlot={renderActionsSlot()} />)
}