import { IDropdownOption } from "@fluentui/react";
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { IFilterItem } from "./UnControlledFilterItem";
import { UnControlledFilterItemList } from "./UnControlledFilterItemList";

export interface IControlledFilterItemListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    availableFields: IDropdownOption[]    
}
export const ControlledFilterItemList = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IControlledFilterItemListProps) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
            <UnControlledFilterItemList 
                {...props}
                items={value || []} 
                onFilterItemsChanged={(items: IFilterItem[]) => {                    
                    onChange(items)
                }} 
                availableFields={props.availableFields} />                    
        )}
    />
  );
};
