import { useEffect, useState } from "react"
import { ISdkDataintegrationStats } from "../sdk/models/ISdkDataintegrationStats"
import { getRelativeMonthDefinition } from "../components/Helpers/DateHelpers"
import { IStackBarChartEasyDataItem, StackBarChartEasy } from "../components/ChartComponents/StackBarChartEasy"

interface SdkDataintegrationStatsStackBarProps {
    item: ISdkDataintegrationStats
    height: number
}

export const SdkDataintegrationStatsStackBar = (props: SdkDataintegrationStatsStackBarProps) => { 

    const [items, setItems] = useState<IStackBarChartEasyDataItem[]>([])
    const [chartPeriods, setChartPeriods] = useState<{ id: string, name: string }[]>([])

    useEffect(() => {

        if (props.item == null) {
            setItems([])
            setChartPeriods([])
            return
        }

        const periods = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0].map(i => { 
            const monthDefinition = getRelativeMonthDefinition(i)            
            return { id: monthDefinition, name: monthDefinition }
        })
        setChartPeriods(periods)
        
        setItems(props.item.linkedDataSources.map(lds => {

            const categoryValues:number[] = []
            periods.forEach(p => {
                if (Object.keys(lds.stats).indexOf(p.id) === -1) {
                    categoryValues.push(0)                    
                } else {
                    categoryValues.push(lds.stats[p.id].reported)                    
                }
            })
            
            return {
                categoryValues: categoryValues,
                seriesName: lds.customerName
            }
        }))                         
    }, [props.item])
    return (
        <div style={{height: props.height + 'px'}}>
            <StackBarChartEasy items={items} categories={chartPeriods} />
        </div>
    )
}