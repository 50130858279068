import { useParams, useSearchParams } from "react-router-dom"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { DashboardManagementComponent } from "../lib/components/DashboardManagement/DashboardManagementComponent"
import { ISdkDashboardDefinition } from "../lib/sdk/models/ISdkDashboardDefinition"

export const ServiceProviderDashboardManage = () => {
                
    const { tenantId } = useParams()    
    const [, setSearchParams] = useSearchParams();
    
    const onDashboardRegistered = (dashboard: ISdkDashboardDefinition) => {
        setSearchParams({ updated: dashboard.id })
    }

    const onDashboardDeleted = (dashboard: ISdkDashboardDefinition) => {
        setSearchParams({ updated: dashboard.id })
    }
    
    return ( 
        <div className="mv-content-container">
            <PageHeader title={'Dashboards'}>            
                <span>Allows to manage all dashboards used to visualize service provider related data!</span>
            </PageHeader>

            <DashboardManagementComponent tenantId={tenantId as string} onDashboardRegistered={onDashboardRegistered} onDashboardDeleted={onDashboardDeleted} />        
        </div>
    )
}