import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import { ISdkBillyState } from "./models/ISdkBillyState";

export class SdkTenantBillyClient {

    private sdkClient: SdkClient<ISdkBillyState>    
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkBillyState>('/api/v1/tenants/' + tenantId + '/billy', token);
    }

    getState(action: 'init' | undefined): Promise<ISdkBillyState> {
        if (action) {
            return this.sdkClient.get('?action=' + action)
        } else {
            return this.sdkClient.get('')
        }
    }
    
    sendNotification(title: string, message: string, state: string): Promise<Boolean> {
        return this.sdkClient.post('/message', { title: title, description: message, state: state }).then(() => true)
    }
}