import { Icon } from "@fluentui/react"
import { IFilterItem } from "../../forms/UnControlledFilterItem"

export interface IFilterPillProps { 
    id?: string
    iconName?: string 
    
    label?: string
    filterItem?: IFilterItem
    
    onRemove?: (item: IFilterItem) => void
    onClick?: (item: IFilterItem) => void

    children?: React.ReactNode
}

export const FilterPill = (props: IFilterPillProps) => {

    const itemClicked = () => {
        if (props.onClick) {
            props.onClick(props.filterItem!)
        }
    }

    return (
        <div key={props.id} id={props.id} className="mv-sb-pill" style={{ cursor: 'pointer', display: 'flex', backgroundColor: 'rgb(72, 70, 68)', color:'rgb(255,255,255)', borderRadius: '16px', margin: '1px 5px 1px 5px', padding: '0 10px 0 10px', height: '22px', verticalAlign: 'middle' }}>                
            <div className="mv-sb-pill-content" onClick={itemClicked}>                                                
                { 
                    props.filterItem ? 
                    (
                        <>
                            <span className="mv-sb-pill-property" style={{lineHeight: '22px', fontSize: '12px'}} data-property={props.filterItem.field}>                        
                                {props.iconName ? (<Icon iconName={props.iconName} style={{paddingRight: '5px'}}/>) : (<></>)}
                                {props.filterItem.field}
                            </span>
                        
                            <span className="mv-sb-pill-operator" style={{lineHeight: '22px', fontSize: '12px', padding: '0 4px 0 4px'}} data-property={props.filterItem.condition}>                        
                                {props.filterItem.condition}
                            </span>
                                
                            <span className="mv-sb-pill-value" style={{lineHeight: '22px', fontSize: '12px', fontWeight: 'bolder'}} data-property={props.filterItem.value}>{props.filterItem.value}</span>                                                
                        </>                            
                    ) : ( 
                        <>  
                            <span className="mv-sb-pill-property" style={{lineHeight: '22px', fontSize: '12px'}}>                        
                                {props.iconName ? (<Icon iconName={props.iconName} style={{paddingRight: '5px'}}/>) : (<></>)}
                                {props.label}
                            </span>
                        </> 
                    )
                }
            </div>
            { 
                props.onRemove ?
                (   <div className="mv-sb-pill-remove">
                        <Icon iconName="faXmark" style={{lineHeight: '22px', fontSize: '12px', padding: '0 0 0 5px'}} onClick={() => { if (props.onRemove) { props.onRemove(props.filterItem!) } }} />                        
                    </div>
                )
                : (<></>)
            }

            <div className="mv-sb-pill-callouts">
                {props.children}
            </div>
        </div>
    )
}