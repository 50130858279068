import { useBoolean } from "@fluentui/react-hooks"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { ICommandBarService } from "../lib/components/DetailsListHelper/ComandBarService"
import { IFilterItem } from "../lib/forms/UnControlledFilterItem"
import { ISdkTenantNotification } from "../lib/sdk/models/ISdkTenantNotification"
import { SdkTenantNotificationClient } from "../lib/sdk/SdkTenantNotificationClient"
import { ManageResourceAccess } from "./ManageResourceAccess"
import { PanelShowNotifcationDetails } from "./PanelNotificationDetails"
import { useCommandPermissionEnforcer } from "../lib/commands/ComandPermissionEnforcer"
import { ICommandDescription } from "../lib/commands/ICommandDescription"
import { ICommandPermission } from "../lib/commands/ICommandPermission"

export interface ServiceProviderNotificationsProps {
    customerId?: string 
}

export const ServiceProviderNotifications = (props: ServiceProviderNotificationsProps) => {
        
    // get the current tenant state
    const { tenantId } = useParams()         

    // get the authenticaiton 
    const auth = useAuthInContext(tenantId as string)
                    
    // establish the item state    
    const [refresh, { toggle: toggleRefresh} ] = useBoolean(false)
    const [selectedDays, setSelectedDays] = useState<string[]>([
        dayjs().toISOString(),
        dayjs().add(-1, 'days').toISOString()
    ])
    const [detailsItem, setDetailsItem] = useState<ISdkTenantNotification>()
    
    // inject the state for the command bar
    const [isManageAccessOpen, { setTrue: showManageAccess, setFalse: hideManageAccess}] = useBoolean(false)    
    
    // icon map
    const iconMap: any = {        
        pending: 'faHourglassClock',
        running: 'faPlay',
        finished: 'faCheck',
        failed: 'faCircleExclamation',
        evicted: 'faSkull'
    }
    
    // ensure we load the tree initialy    
    useEffect(() => {
        toggleRefresh()
    // eslint-disable-next-line                                 
    }, [selectedDays]) 

    // allow to dismss the dialog when closed
    const dismissNotificationDialogs = () => {        
        setDetailsItem(undefined)        
    }

    const handleDissmissWithReload = (cb: () => void) => {
        return () => {
            cb()
            toggleRefresh()
        }
    }    

    const select2Days = () => {
        setSelectedDays([
            dayjs().toISOString(),
            dayjs().add(-1, 'days').toISOString()
        ])
    }

    const select5Days = () => {
        setSelectedDays([
            dayjs().toISOString(),
            dayjs().add(-1, 'days').toISOString(),
            dayjs().add(-2, 'days').toISOString(),
            dayjs().add(-3, 'days').toISOString(),
            dayjs().add(-4, 'days').toISOString()            
        ])
    }
           
    const onBuildColumns = (builder: IColumnsBuilder<ISdkTenantNotification>) => {
        builder.IconColumn({name: "Type", headerIcon: 'faBell', iconName: (item) => iconMap[item.notificationState], iconClass: (item) => 'icon ' + item.notificationState, onClick: (item) => { setDetailsItem(item) }})                        
        builder.TextColumn({name: "Task", value: "notificationTitle", maxWidth: 250, onClick: (item) => { setDetailsItem(item) }})                  
        builder.TextColumn({name: "Context", value: "contextType", maxWidth: 150, onClick: (item) => { setDetailsItem(item) }})                  
        builder.TextColumn({name: "Context Details", value: (event) => { 

            if (event.contextValue && event.contextId)
                return event.contextValue + ' (' + event.contextId + ')'
            else
                return ''

        } , maxWidth: 350, onClick: (item) => { setDetailsItem(item) }})                        
        builder.TextColumn({name: "Duration", value: (event) => {
            return ((new Date(event.lastUpdateAt).getTime() - new Date(event.createdAt).getTime()) / 1000).toFixed(2) + 's'
        }, maxWidth: 75, onClick: (item) => { setDetailsItem(item) }})     
        builder.TextColumn({name: "State", value: "notificationState", maxWidth: 50, onClick: (item) => { setDetailsItem(item) }})     
        builder.TextColumn({name: "Created at", value: "createdAt", maxWidth: 150, onClick: (item) => { setDetailsItem(item) }})        
    }

    const onLoadData = async (filters: IFilterItem[], searchText: string): Promise<ISdkTenantNotification[]> => {      
        
        try {
            // get all events
            const notificationClient = new SdkTenantNotificationClient(tenantId as string, auth.currentToken as string);
            
            // load and set the items
            let loadedNotifications: ISdkTenantNotification[] = []
            
            for (const day of selectedDays) {
                const filerRules = filters.map(f => {
                    return {                        
                        operator: f.operator,
                        field: f.field,
                        value: f.value,
                        condition: f.condition,
                        compare: f.compare ? 'CaseInSensitive' : 'CaseSensitive'
                    }
                })

                const items = await notificationClient.listNotifications(day, filerRules, searchText)
                loadedNotifications.push(...items)
            }
                        
            // done
            return loadedNotifications
 
        } catch(error: any) {
            return Promise.reject(error)
        }          
    }
        
    const onAddCommands = (commandBarService: ICommandBarService) => {                
        commandBarService.Command('managedAccess', 'Manage Access', 'faLockA', showManageAccess)
        commandBarService.FarCommand('2d', '2d\'s', 'faCalendar', select2Days, false, false, selectedDays.length === 2)
        commandBarService.FarCommand('5d', '5d\'s', 'faCalendar', select5Days, false, false, selectedDays.length === 5)                
    } 

    const permissionEnforcer = useCommandPermissionEnforcer(tenantId as string, 'notifications', 'svpnotifications')
    const onEvaluateCommandPermissions = async (commands: ICommandDescription[]): Promise<ICommandPermission[]> => {
        return await permissionEnforcer.enforce(commands)        
    }
    
    return (
        <div className="mv-content-container">

            <PageHeader title={'Notification Center'}>            
                <span>All events and messages related to your tenant and about all customers are aggregated in the notification center. In case of errors or data inconsistency use the events here for analysis</span>
            </PageHeader>

            <NavigationalDetailsListWithStandardOperations 
                uniqueIdentifier={'notificationsroot'}
                onEvaluateCommandPermissions={onEvaluateCommandPermissions}
                onBuildColumns={onBuildColumns}                                
                onLoadData={onLoadData}
                hideReferesh={false} 
                preventInitialLoad={true}
                refresh={refresh}                
                onAddCommands={onAddCommands}
                allowSorting={true}
                defaultSortBy={'Created at'}
                defaultSortDirection={'desc'}
                disabledItemSelect={true}
                allowSearching={true}
                allowFiltering={true} />
            <PanelShowNotifcationDetails tenantId={tenantId as string} isVisible={detailsItem !== undefined} notification={detailsItem as ISdkTenantNotification} dismissDialog={dismissNotificationDialogs} />
            <ManageResourceAccess isVisible={isManageAccessOpen} dismissDialog={handleDissmissWithReload(hideManageAccess)} tenantId={tenantId as string} resourceId={''} resourceName={'Notification Permissions'} resourceType={'notifications'} />            
        </div>
    ) 
}