import { AxiosError } from "axios"

export function extractErrorMessage(error: any, prefixMessage?: string): string {

    let errorMessage = ''
    const axiosError = error as AxiosError
    if (axiosError && axiosError.response && axiosError.response.status >= 400 && axiosError.response.status <= 500) {

        errorMessage = ' (' + axiosError.response.status + ', ' + axiosError.code + ')'        

        if (error.response.data) {
            prefixMessage = prefixMessage ? prefixMessage + ' ' + error.response.data : error.response.data
        } else if (axiosError.response.status === 401) {
            prefixMessage = ' Requestor is not authorized for this operation.'
        }
    } else if (error && error.code) {
        errorMessage = '(' + error.code + ')'
    }

    if (prefixMessage && prefixMessage.length > 0) {
        return prefixMessage + ' ' + errorMessage
    } else {
        return 'Something went wrong, please try again later. ' + errorMessage
    }
}