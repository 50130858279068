import { Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuth } from "@mevodo/mv-react-authentication";
import { useForm } from "react-hook-form";
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ControlledTextField } from "../lib/forms/ControlledTextField";
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient";

export interface IAddCustomerDialog extends IAppWindowBaseProps {   
     tenantId: string
     parent?: string
}

export const AddCustomerDialog = (props: IAddCustomerDialog) => {
        
    const auth = useAuth();           
        
    const [isSubmitProcessing, { setTrue: startSubmitProccesing, setFalse: stopSumitProcessing}] = useBoolean(false)

    type AddCustomerForm = {
        customerName: string    
        primaryCurrency: string    
    };
    
    const { handleSubmit, control, reset, formState } = useForm<AddCustomerForm>({
        defaultValues: {
            customerName: '',     
            primaryCurrency: 'EUR'       
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });    

    const dissmissDialog = () => {
        reset()        
        if (props.dismissDialog)
            props.dismissDialog()
    }    

    const onSubmit = (): Promise<string | void> => {

        startSubmitProccesing()

        var submitHandler = handleSubmit((data: AddCustomerForm) => {            
            const customerClient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
            return customerClient.createCustomer(data.customerName, data.primaryCurrency, props.parent ? props.parent : undefined)
        })
        
        return submitHandler()
            .finally(() => {
                stopSumitProcessing()
            })
            .then(() => {
                dissmissDialog()
            })
            
    }
  
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Add new customer'}
            onDismiss={dissmissDialog}                                                
            progessMessage={'Creating new customer...'}
            submitLabel={'Save'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid}
            onSubmit={onSubmit}
            errorMessagePrefix={'Something went wrong creating the new customer, please try again later'}>

                <p>Please enter all mandatory information to create a new customer. 
                The customer becomes accessible for all user with the role "Cloud 
                Billing Manager" automatically!</p>                 

                <form>
                    <Stack tokens={{ childrenGap: 15 }}>                
                        <ControlledTextField label="Customer Name" control={control} name={'customerName'} rules={{ required: 'A valid name of the customer is required'}} disabled={isSubmitProcessing} />                                             
                        <ControlledTextField label="Currency" control={control} name={'primaryCurrency'} rules={{ required: 'A valid name of the customer is required'}} disabled={isSubmitProcessing} />                                             
                    </Stack>                     
                </form>
        </PanelWithDefaultFooter>
    )
}