import { useParams } from "react-router-dom"
import { DashboardGridComponent } from "../lib/components/DashboardGrid/GridSurface/DashboardGridComponent"

export const ServiceProviderDashboardItem = () => {
            
    // get data from uri
    const { tenantId, dashboardId } = useParams()     
    
    // render the dashboard
    return (<DashboardGridComponent tenantId={tenantId as string} dashboardId={dashboardId as string} allowManagedEdit={false} reportCollection={'serviceProviderCollection'} />)    
}