import './PanelWithDefaultFooter.scss'

import { DefaultButton, ILayerProps, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, ProgressIndicator, Spinner, SpinnerSize } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { useCallback, useEffect, useState } from "react"
import { extractErrorMessage } from '../../sdk/SdkClientErrorExtractor'

export interface IPanelWithDefaultFooterProps {
    isOpen: boolean
    progessMessage: string
    submitLabel: string
    dissmissLabel: string
    headerText: string
    type?: PanelType;

    onDismiss?: () => void
    noDissmissButton?: boolean

    onReset?: () => void

    isValid: boolean
    onSubmit?: () => Promise<string | void  > 
    doubleConfirmMessage?: string

    children?: React.ReactNode

    isPreparing?: boolean

    errorMessagePrefix?: string

    layerProps?: ILayerProps;
}

export const PanelWithDefaultFooter = (props: IPanelWithDefaultFooterProps) => {

    const [isSubmitProcessing, { setTrue: startSubmitProccesing, setFalse: stopSumitProcessing}] = useBoolean(false)
    const [error, setError] = useState<string>()
    const [isDoubleConfirm, setIsDoubleConfirm] = useState<boolean>(false)

    useEffect(() => {
        setIsDoubleConfirm(false)
    }, [props.doubleConfirmMessage])
    
    const dissmissDialog = () => {
        
        setError(undefined)
        setIsDoubleConfirm(false)

        if (props.onReset) {
            props.onReset()
        }
        
        if (props.onDismiss) {
            props.onDismiss()
        }
    }

    const submitDialog = useCallback(() => {

        if (!props.onSubmit)
            return;

        startSubmitProccesing();

        props.onSubmit().then((error) => {

            stopSumitProcessing()

            if (error) {
                setError(extractErrorMessage(error, props.errorMessagePrefix))             
            } else {
                dissmissDialog()
            }            
        }).catch((error) => {
            setError(extractErrorMessage(error, props.errorMessagePrefix))
            stopSumitProcessing()
        })
        
    // eslint-disable-next-line         
    }, [props.onSubmit])

    const doubleSubmitDialog = useCallback(() => {

        // show the double confirm message 
        setIsDoubleConfirm(true)        
        
    // eslint-disable-next-line                 
    }, [props.onSubmit])
    
    const buttonStyles = { root: { marginRight: 8 } };
    
    const onRenderFooterContent = useCallback(() => (        
        <div>
          
          { isDoubleConfirm && (
            <div style={{marginBottom: '10px'}}>
                <MessageBar messageBarType={MessageBarType.severeWarning}>{'PLEASE CONFIRM: ' + props.doubleConfirmMessage}</MessageBar>                                        
            </div>
          )}

          { isSubmitProcessing ? (<ProgressIndicator label={props.progessMessage} />) : (<></>)}
          <PrimaryButton onClick={props.doubleConfirmMessage && !isDoubleConfirm ? doubleSubmitDialog : submitDialog} styles={buttonStyles} disabled={isSubmitProcessing || !props.isValid}>
            {isDoubleConfirm ? 'Confirm Hint' : props.submitLabel}
          </PrimaryButton>          
          { props.noDissmissButton ? (<></>) : (<DefaultButton onClick={dissmissDialog} disabled={isSubmitProcessing}>{props.dissmissLabel}</DefaultButton>)}
        </div>
      ),
      // eslint-disable-next-line 
      [isSubmitProcessing, isDoubleConfirm, props.isValid, props.submitLabel, props.dissmissLabel, submitDialog, doubleSubmitDialog])
    
    return (
        <Panel
            isOpen={props.isOpen}
            headerText={props.headerText}
            onDismiss={dissmissDialog}            
            onRenderFooterContent={onRenderFooterContent}
            type={props.type}
            isFooterAtBottom={true}
            layerProps={props.layerProps}>
            
            { props.isPreparing ? 
                (<Spinner size={SpinnerSize.large} label="Preparing view..." labelPosition="bottom" style={{height: '100%'}} />) : 
                (
                    <div style={{height: '100%'}}>
                        {props.children}

                        { error ? (
                            <div style={{marginTop: '10px'}}>
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                    {error}
                                </MessageBar>
                            </div>
                            ) : (<></>)}
                    </div>
                )
            }            
        </Panel>
    )
}