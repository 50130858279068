import { ISdkReportDefinition, ISdkReportDefinitionCreateRequest } from "./models/ISdkReportDefintion";
import { ISdkReportInstance } from "./models/ISdkReportInstance";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import { ISdkPivotOperation } from "./models/ISdkPivotOperation";

export class SdkCustomerReportClient {
    
    constructor(
        private _tenantId: string, 
        private _reportCollection: "serviceProviderCollection" | undefined,        
        _token: string,
        private _sdkClientReportDefinitions = SdkClientServiceDiscoveryService.Discover<ISdkReportDefinition>('/api/v1/tenants/' + _tenantId + '/reports/definitions', _token),
        private _sdkClientReportInstances = SdkClientServiceDiscoveryService.Discover<ISdkReportInstance>('/api/v1/tenants/' + _tenantId + '/reports/definitions', _token),        
        private _sdkClientReportOperations = SdkClientServiceDiscoveryService.Discover<ISdkPivotOperation[]>('/api/v1/tenants/' + _tenantId + '/reports/operations', _token))                          
    {}

    public getReportDefinition(reportId: string): Promise<ISdkReportDefinition> {          
        return this._sdkClientReportDefinitions.get('/' + reportId);                
    }

    public getReportDefinitions(): Promise<ISdkReportDefinition[]> {   
        if (this._reportCollection) {
            return this._sdkClientReportDefinitions.list('?collectionType=' + this._reportCollection)
        } else {             
            return this._sdkClientReportDefinitions.list('')
        }
    }    

    public getReportInstances(reportId: string): Promise<ISdkReportInstance[]> {
        return this._sdkClientReportInstances.list('/' + reportId + '/instances');        
    }

    public deleteReportInstance(reportId: string, instanceId: string): Promise<Boolean> {
        return this._sdkClientReportInstances.delete('/' + reportId + '/instances/' + instanceId)
    }
    
    public createReportDefinition(reportDefinitionCreateRequest: ISdkReportDefinitionCreateRequest): Promise<ISdkReportDefinition> {
        return this._sdkClientReportDefinitions.post('', reportDefinitionCreateRequest).then((location) => {
            return this._sdkClientReportDefinitions.getFromAbsoluteUri(location as string)
        });        
    }

    public cloneReportDefinition(reportId: string): Promise<ISdkReportDefinition> {
        return this._sdkClientReportDefinitions.post('/' + reportId + '/clone', {}).then((location) => {
            return this._sdkClientReportDefinitions.getFromAbsoluteUri(location as string)
        });        
    }

    public transferReportDefinition(reportId: string, newOwnerId: string): Promise<ISdkReportDefinition> {
        return this._sdkClientReportDefinitions.put('/' + reportId + '/transfer', { NewOwnerId: newOwnerId}).then((location) => {
            return this.getReportDefinition(reportId)
        });        
    }

    public updateReportDefinition(reportId: string, reportDefinitionCreateRequest: ISdkReportDefinitionCreateRequest): Promise<ISdkReportDefinition> {
        return this._sdkClientReportDefinitions.put('/' + reportId, reportDefinitionCreateRequest).then((location) => {
            return this._sdkClientReportDefinitions.getFromAbsoluteUri(location as string)
        });        
    }

    public deleteReportDefinition(reportId: string): Promise<Boolean> {
        return this._sdkClientReportDefinitions.delete('/' + reportId);
    }

    public triggerReportDefinitionRun(reportId: string): Promise<boolean> {
        return this.triggerReportDefinitionRunFor(reportId, 'discover', false);        
    }

    public triggerReportDefinitionRunFor(reportId: string, periodId: string, forceNotification: boolean): Promise<boolean> {
        return this._sdkClientReportDefinitions.post('/' + reportId + '/instances', { action: 'trigger', periodId: periodId, serviceProviderContext: this._reportCollection === 'serviceProviderCollection' ? true : false, forceNotifications: forceNotification}).then((location) => {
            return location != null ? true : false
        });        
    }

    public getPivotOperations(): Promise<ISdkPivotOperation[]> {
        return this._sdkClientReportOperations.get('/pivot');
    }
}