import { useAuth } from "@mevodo/mv-react-authentication"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { useEffect, useState } from "react"
import { ISdkSearchResultItem } from "../lib/sdk/models/ISdkSearchResultItem"
import { ActionButton, Panel, PanelType, SearchBox, Spinner, SpinnerSize } from "@fluentui/react"
import { FluentProvider, Slot, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, webLightTheme } from "@fluentui/react-components"
import { SdkSearchClient } from "../lib/sdk/SdkSearchClient"

export interface ISearchGeneralPanelProps extends IAppWindowBaseProps { 
    tenantId: string
    rootId?: string    
    searchTypes: string[]
    title: string
    description: string
    searchPlaceholder: string
    searchResultHeader: string
    actions: { text: string, onClick: (item: ISdkSearchResultItem) => Promise<void> }[],
    noResultSlot?: Slot<'div'>,
    bottomSlot?: Slot<'div'>,
    isPreparing?: boolean
    exclude?: string[]    
    onSearchStarted?: () => (searchText: string) => void       
    onSearchFinished?: (searchResult: ISdkSearchResultItem[]) => void
    disabled?: boolean
    externalSearchText?: string
}

export const SearchGeneralPanel = (props: ISearchGeneralPanelProps) => {

    const auth = useAuth()
    
    const [searchResultItems, setSearchResultItems] = useState<ISdkSearchResultItem[]>()
    const [searchInProgress, setSearchInProgress] = useState<boolean>(false)
    const [searchInProgressMessage, setSearchInProgressMessage] = useState<string>('Searching data...')
    
    const onDismissDialog = () => {
        
        setSearchResultItems(undefined)
        setSearchInProgress(false)

        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSearch = (searchText: string) => {

        setSearchInProgressMessage('Searching data...')
        setSearchInProgress(true)
        
        if (props.onSearchStarted) {
            props.onSearchStarted()(searchText)
        }

        const searchClient = new SdkSearchClient(props.tenantId, auth.currentToken as string)
        searchClient.search(searchText, props.rootId, props.searchTypes).then((searchResult) => {            
            setSearchResultItems(props.exclude ? searchResult.filter(r => !props.exclude?.includes(r.id)) : searchResult)
        }).catch(() => {
            
        }).finally(() => {

            if (props.onSearchFinished) {
                props.onSearchFinished(searchResultItems || [])
            }
            setSearchInProgress(false)            
        })
    }

    const onExecuteAction = (item: ISdkSearchResultItem, action: { text: string, onClick: (item: ISdkSearchResultItem) => Promise<void> }) => {
        setSearchInProgress(true)
        setSearchInProgressMessage('Executing action ' + action.text + '...')

        action.onClick(item).then(() => {
            setSearchInProgress(false)
            onDismissDialog()
        }).catch(() => {
            setSearchInProgress(false)            
        })
    }

    useEffect(() => {
        if (props.externalSearchText) {
            onSearch(props.externalSearchText)            
        }
    // eslint-disable-next-line
    }, [props.externalSearchText])


    const renderSearchIndicator =() => {
        return (
            <Spinner size={SpinnerSize.large} label={searchInProgressMessage} labelPosition="bottom" style={{height: '100%', marginTop: '40px'}}/>            
        )
    }

    const renderActionMap = (item: ISdkSearchResultItem) => {

        if (item.metadata && item.metadata['created'] && item.metadata['created'] === 'true') {
            return (
            <>
                <ActionButton onClick={() => {}} disabled={true} style={{fontStyle: 'italic'}}>Element still exists!</ActionButton>
            </>
            )
        } else {
            return (
                <>
                    { props.actions.map((action) => (
                        <ActionButton onClick={() => { onExecuteAction(item, action)}}>{action.text}</ActionButton>
                    ))}
                </>
            )
        }
    }

    const renderItemTitle = (item: ISdkSearchResultItem) => {

        if (item.metadata && item.metadata['created'] && item.metadata['created'] === 'true') {
            return (<span style={{color: 'rgb(161, 159, 157)', fontStyle: 'italic'}}>{item.text}</span>)
        } else {
            return (<span>{item.text}</span>)
        }
    }

    const renderSearchResults = () => {
        if (!searchResultItems) {
            return (<></>)
        }
        else if (searchResultItems && searchResultItems.length === 0) {
            return (
                <div style={{marginTop: '20px', textAlign: 'center'}}>
                    { props.noResultSlot ? props.noResultSlot : (<p>Currently no results found, please start a search request!</p>) }
                </div>
            )
        } else {
            return (
                <div style={{marginTop: '20px'}}>

                     <Table arial-label="Search results">
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key={'searchRestultText'}>{props.searchResultHeader}</TableHeaderCell>
                                <TableHeaderCell key={'actions'} />
                            </TableRow>
                        </TableHeader>
                        <TableBody>                        
                            {searchResultItems?.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <TableCellLayout>
                                            {renderItemTitle(item)}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout>                                            
                                            { renderActionMap(item) }
                                        </TableCellLayout>
                                    </TableCell>                              
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>      
                    { props.bottomSlot ? props.bottomSlot : (<></>) }                
                </div>
            )
        }
    }
    
    return (
        <Panel
            isOpen={props.isVisible}
            headerText={props.title}
            onDismiss={onDismissDialog}                        
            type={PanelType.medium}
            isFooterAtBottom={false}>
            
                { props.isPreparing ? (
                    <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%', marginTop: '20px'}}/>
                ) : (
                    <FluentProvider theme={webLightTheme}>
                        <p>{props.description}</p>                                  
                        <SearchBox placeholder={props.searchPlaceholder} iconProps={{iconName: 'filter'}} onSearch={onSearch} disabled={searchInProgress || props.disabled} />
                        { searchInProgress ?
                            renderSearchIndicator() :
                            renderSearchResults() }

                    </FluentProvider>
                )}
        </Panel>            
    )
}