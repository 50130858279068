import { PanelType } from "@fluentui/react";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useState } from "react";
import { IAppWindowBaseProps } from "../../ds/components/AppWindow";
import { IColumnsBuilder, ISubCommandDefinition } from "../DetailsListHelper/ColumnsBuilder";
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations";
import { PanelWithDefaultFooter } from "../DetailsListHelper/PanelWithDefaultFooter";
import { ISdkObjectFromTemplate } from "../../sdk/models/ISdkObjectTemplate";
import { SdkTenantTriggerClient } from "../../sdk/SdkTenantTriggerClient";
import { ISdkTriggerFireEvent } from "../../sdk/models/ISdkTriggerFireEvent";
import { ISdkTriggerAction } from "../../sdk/models/ISdkTriggerAction";

export interface ITriggerDetailsDialogProps extends IAppWindowBaseProps {
    item: ISdkObjectFromTemplate    
    tenantId: string
}

export const TriggerDetailsDialog = (props: ITriggerDetailsDialogProps) => {

    const auth = useAuthInContext(props.tenantId)            
    const [refresh, ] = useState<boolean>(false)
    const [triggeredFireEvents, setTriggeredFireEvents] = useState<ISdkTriggerFireEvent[]>([])  

    const onDismissDialog = () => {                
        if (props.dismissDialog)
            props.dismissDialog()
    }
    
    const buildColumnsFireEvents = (builder: IColumnsBuilder<ISdkTriggerFireEvent>): void  => 
    {                
        builder.IconColumn({name: "StatusIcon", headerIcon: 'faFire', iconName: 'faFire', iconClass: 'statusActive'})                                
        builder.DateTimeColumn({name: "Timestamp", maxWidth: 250,  value: 'eventTime' })                        
        builder.TextColumn({name: "Id", maxWidth: 250,  value: 'eventId' })                
    }

    const loadDataFireEvents = async (): Promise<ISdkTriggerFireEvent[]> => {             
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);   
        const loadedItems = await sdkClient.listTriggerFireEvents(props.item.id)
        setTriggeredFireEvents(loadedItems)
        return loadedItems
    }

    const columnSubCommandsTarget: ISubCommandDefinition<ISdkTriggerAction>[] =  [      
        { iconName: 'faArrowUpRightFromSquare', description: 'Edit the report definition', onClick: (item) => { 
            window.open(process.env.PUBLIC_URL + '/tenants/' + props.tenantId + '/svpreports/reports?open=IndividualReportsCollection.' + item.targetEntityId,'_blank')            
        }, disabled: (item) => item.targetEntityType !== 'ReportDefinition'}        
    ]

    const buildColumnsTriggerActions = (builder: IColumnsBuilder<ISdkTriggerAction>): void  => 
    {                
        builder.IconColumn({name: "StatusIcon", headerIcon: 'faArrowProgress', iconName: 'faBoxesPacking', iconClass: 'statusActive'})                                
        builder.TextColumn({name: "Operation", maxWidth: 250,  value: 'operation' })                        
        builder.TextColumn({name: "Target Entity", maxWidth: 250,  value: 'targetEntityType', subCommands: columnSubCommandsTarget })                        
        builder.TextColumn({name: "Target Id", maxWidth: 250,  value: 'targetEntityId', subCommands: columnSubCommandsTarget })                
    }

    const loadDataTriggerActions = (): Promise<ISdkTriggerAction[]> => {             
        const sdkClient = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);   
        return sdkClient.listTriggerActions(props.item.id)        
    }
        
    // ensure we only render when the item has a value
    if (!props.item) { return (<></>)}

    // render the content
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Trigger Details & Fire History'}
            onDismiss={onDismissDialog}
            onSubmit={() => Promise.resolve() }                    
            type={PanelType.large}   
            progessMessage={''}    
            submitLabel={'Close'}                  
            dissmissLabel={'Cancel'}   
            noDissmissButton={true}          
            isValid={true}>

            <div>                
                <p>The trigger <strong>{props.item.name} ({props.item.id})</strong> references to the 
                following actions which will be executed when ever the trigger becomes fired:</p>   
                
                <NavigationalDetailsListWithStandardOperations
                    uniqueIdentifier='triggerActions'   
                    embedded={true}
                    hideReferesh={true}       
                    refresh={refresh}
                    disabledItemSelect={true}                  
                    onBuildColumns={buildColumnsTriggerActions}
                    onLoadData={loadDataTriggerActions}                                        
                    allowSorting={true}
                    defaultSortBy={'operation'}/>                      

                <br/>
                <p>The trigger was fired <strong>{triggeredFireEvents.length} times</strong>. The following list shows the hisotry of the last fired events:</p>
            
                <NavigationalDetailsListWithStandardOperations
                    uniqueIdentifier='triggerFireEvents'   
                    embedded={true}
                    hideReferesh={true}       
                    refresh={refresh}
                    disabledItemSelect={true}                  
                    onBuildColumns={buildColumnsFireEvents}
                    onLoadData={loadDataFireEvents}                                        
                    allowSorting={true}                    
                    defaultSortBy={'eventTime'}/>                      
            </div>                           
        </PanelWithDefaultFooter>)

}