import { PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../../../ds/components/AppWindow"
import { ISdkDashboardItem } from "../../../sdk/models/ISdkDashboardItem"
import { FluentProvider } from "@fluentui/react-components"
import { PanelWithDefaultFooter } from "../../DetailsListHelper/PanelWithDefaultFooter"
import { DashboardGridWidgetSelectionAvailableReportDefinitionsDropdown } from "./DashboardGridWidgetSelectionAvailableDatasourceDropdown"
import { useState } from "react"
import { ISdkReportDefinition } from "../../../sdk/models/ISdkReportDefintion"
import { useEffectAsync } from "../../Helpers/EffectAsyncHook"
import { SdkCustomerDashboardWidgetTemplateClient } from "../../../sdk/SdkCustomerDashboardWidgetTemplateClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { ISdkDashboardWidgetTemplate } from "../../../sdk/models/ISdkDashboardWidgetTemplate"
import { DashboardGridWidgetPropertiesControl } from "./DashboardGridWidgetPropertiesControl"
import { useAppShellContext } from "../../../ds/components/AppShell"

export interface IDashboardGridWidgetPropertiesPanelProps extends IAppWindowBaseProps {    
    tenantId: string
    dashboardId: string   
    dashboardItem: ISdkDashboardItem
    onUpdateDashboardItem: (dashboardItem: ISdkDashboardItem) => Promise<void>  
    
    isReadonly: boolean
    reportCollection: 'serviceProviderCollection' | 'endCustomerCollection' 
} 

export const DashboardGridWidgetPropertiesPanel = (props: IDashboardGridWidgetPropertiesPanelProps) => {
    
    // inject the auth context
    const auth = useAuthInContext(props.tenantId);
        
    const appShellContext = useAppShellContext()
    
    const [availableReportDefinitions, setAvailableReportDefinitions] = useState<ISdkReportDefinition[]>([])    
    const [selectedReportDefinition, setSelectedReportDefinition] = useState<ISdkReportDefinition>()    
    const [usedwidgetTemplate, setUsedWidgetTemplate] = useState<ISdkDashboardWidgetTemplate>()    
    
    const [dashboardItemUpdated, setDashboardItemUpdated] = useState<ISdkDashboardItem>({...props.dashboardItem})    
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [isPreparing, setIsPreparing] = useState<boolean>(true)

    const onReportDefinitionsLoaded = (reportDefinitions: ISdkReportDefinition[]) => {
        
        // set the available report definitions
        setAvailableReportDefinitions(reportDefinitions)

        // find the used datasource from dashboardItem
        if (props.dashboardItem && props.dashboardItem.reportDefinitionId) {
            const reportDefinition = reportDefinitions.find(r => r.id === props.dashboardItem.reportDefinitionId)
            if (reportDefinition) { setSelectedReportDefinition(reportDefinition) }            
        }

        // done 
        setIsPreparing(false)
    }

    useEffectAsync(async () => {

         // find the used widget templates 
         if (props.dashboardItem && props.dashboardItem.itemType) {

            // load the available widgets
            const sdkCustomerDashboardWidgetTemplateClient = new SdkCustomerDashboardWidgetTemplateClient(props.tenantId as string, auth.currentToken as string);        
            const sdkAvailableWidgets = await sdkCustomerDashboardWidgetTemplateClient.getWidgetTemplates();
            
            // find the widget template
            const widgetTemplate = sdkAvailableWidgets.find(w => w.type === props.dashboardItem.itemType)   
            if (widgetTemplate) {
                setUsedWidgetTemplate(widgetTemplate)
            }
        }
    }, [selectedReportDefinition])

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }   

    const submitPanel = (dashboardItemUpdated: ISdkDashboardItem) => {

        // check if the report definition was changed
        if (props.dashboardItem.reportDefinitionId !== dashboardItemUpdated.reportDefinitionId) {

            // reset the state
            dashboardItemUpdated.state = {}
        }

        // get the properties format before update 
        const propertiesFormatBefore = props.dashboardItem.attributes && props.dashboardItem.attributes.propertiesFormat ? props.dashboardItem.attributes.propertiesFormat : []
        
        // get the properties format after update 
        const propertiesFormatAfter = dashboardItemUpdated.attributes && dashboardItemUpdated.attributes.propertiesFormat ? dashboardItemUpdated.attributes.propertiesFormat : []

        // check of the format properties are changed
        if (JSON.stringify(propertiesFormatBefore) !== JSON.stringify(propertiesFormatAfter)) {                
            // reset the state
            dashboardItemUpdated.state = {}
        }
        
        // update 
        return props.onUpdateDashboardItem(dashboardItemUpdated)
    }
    
    if (!props.isVisible)
        return (<></>)
    else 
        return (        
            <PanelWithDefaultFooter
                isOpen={props.isVisible}
                isPreparing={false}
                type={PanelType.medium}
                headerText={props.isReadonly ? 'Review widget properties' : 'Update widget properties'}
                onDismiss={dissmissDialog}                                                
                submitLabel={props.isReadonly ? 'Close' : 'Update'}            
                isValid={props.isReadonly || isDirty}                
                progessMessage={''}
                dissmissLabel={'Close'}
                noDissmissButton={props.isReadonly}
                onSubmit={props.isReadonly ? () => { return Promise.resolve()} : () => submitPanel(dashboardItemUpdated)}>

                <FluentProvider theme={appShellContext.themeV9}>                
                    <div>
                        <p>Please define the datasource used to feed the widget with data:</p>
                        <DashboardGridWidgetSelectionAvailableReportDefinitionsDropdown 
                            tenantId={props.tenantId} 
                            dashboardId={props.dashboardId} 
                            selectedReportDefinition={selectedReportDefinition} 
                            onDataLoaded={onReportDefinitionsLoaded}                            
                            onSelected={(reportDefinition) => { setSelectedReportDefinition(reportDefinition); setDashboardItemUpdated({...dashboardItemUpdated, reportDefinitionId: reportDefinition.id}); setIsDirty(true)}}  
                            reportCollection={props.reportCollection}
                            disabled={props.isReadonly || isPreparing}
                            />
                    </div>
                    
                    { selectedReportDefinition && usedwidgetTemplate && (
                        <DashboardGridWidgetPropertiesControl 
                            tenantId={props.tenantId} 
                            dashboardItem={dashboardItemUpdated} onUpdateDashboardItem={(item) => { setDashboardItemUpdated(item); setIsDirty(true) }} 
                            availableReportDefinitions={availableReportDefinitions}
                            availableColumns={selectedReportDefinition.columns} 
                            widgetTemplate={usedwidgetTemplate} 
                            disabled={props.isReadonly} />                        
                    )}
                </FluentProvider>
            </PanelWithDefaultFooter >
        )
}