import { Icon, IDropdownOption, PrimaryButton, Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ControlledDropdown } from "./ControlledDropdown";
import { ControlledTextField } from "./ControlledTextField";

export interface IFieldItem {
    key: string
    field: string    
}

export interface IFieldItemProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    
    disabled?: boolean    

    availableFields: IDropdownOption[]

    onUpdateItem: (item: IFieldItem) => void

    onDeleteItem?: (item: IFieldItem) => void

    item?: IFieldItem 

    first?: boolean
    single?: boolean    

    allowMappedFields?: boolean
    availableFieldTypes?: IDropdownOption[]
}

export const UnControlledFieldItem = (props: IFieldItemProps) => {
    
    const { handleSubmit, control, reset, formState } = useForm<IFieldItem & { subfield: string }>({
        defaultValues: {
            key: '',
            field: '',
            subfield: ''            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    const extractItemDetails = (item: IFieldItem) => {
        const values = {
            key: props.item?.key,
            field: props.item?.field,
            subfield: ''
        }

        if (item.field.indexOf(':') > 0) {
            const parts = item.field.split(':')
            values.field = parts[0]
            values.subfield = parts[1]
        }

        return values;
    }

    useEffect(() => {

        if (props.item) {                    
            const values = extractItemDetails(props.item)
            reset(values)        
        } else {
            reset({})
        }

    // eslint-disable-next-line
    }, [props.item])    

    // read the form state to initialize the proxy state
    // eslint-disable-next-line
    useEffect(() => {}, [formState.isDirty])

    const onProcessItem = () => {

        const submitHandler = handleSubmit((data: IFieldItem & { subfield: string }) => {

            // esure we have the correct key
            const fieldItem = { 
                key: props.item ? props.item.key : new Date().getTime().toString(),
                field: data.field
            }            

            // check if we have a subkey 
            if (data.subfield) { fieldItem.field = data.field + ':' + data.subfield }   

            // send the callback 
            props.onUpdateItem(fieldItem)            

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    const onDeleteItem = () => {

        const submitHandler = handleSubmit((data: IFieldItem) => {

            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();

            // send the callback 
            if (props.onDeleteItem) {
                props.onDeleteItem(data) 
            }           

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    // evaluate the itemtype of allowedd
    let itemType = undefined
    if (props.item && props.allowMappedFields && props.availableFieldTypes) {
        const itemDetails = extractItemDetails(props.item)
        itemType = props.availableFieldTypes.find(t => t.key === itemDetails.field)
    }
        
    // render the component
    return (
        <tr style={{verticalAlign: 'top'}}> 
            <td style={{width: '100%', paddingRight: '5px'}}>
                { itemType && itemType.text === 'map<string, string>' ? 
                    <div style={{display: 'block'}}>
                        <ControlledDropdown style={{float: 'left', width: '50%', marginRight: '5px'}} key={'field'} control={control} name={'field'} placeholder={ props.placeholder || 'Select field'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={props.availableFields} />
                        <ControlledTextField key={'subfield'} control={control} name={'subfield'} placeholder={'Define the child element in the selected property'} disabled={props.disabled} rules={{ required: 'a valid value is required'}}/>
                    </div> : 
                    <ControlledDropdown style={{flexGrow: 1, width: '100%'}} key={'field'} control={control} name={'field'} placeholder={ props.placeholder || 'Select field'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={props.availableFields} />                                    
                }
            </td>            
            <td>
                <div style={{marginTop: '10px'}}>
                    <PrimaryButton style={{width: '10px', minWidth: '10px'}} disabled={props.disabled || !formState.isValid || !formState.isDirty} onClick={onProcessItem}>                            
                        <Icon iconName={props.item ? 'faCheck' : 'faPlus'}></Icon>
                    </PrimaryButton>                        
                </div>    
            </td>
            <td>
                { (props.item  && props.onDeleteItem) 
                    ? (<Stack.Item grow={0}>
                        <div style={{marginTop: '10px'}}>
                            <PrimaryButton style={{width: '35px', minWidth: '35px'}} disabled={props.disabled} onClick={onDeleteItem}><Icon iconName={'faTrashCan'}></Icon></PrimaryButton>                        
                        </div>    
                    </Stack.Item>)
                    : (<Stack.Item grow={0}>
                        <div style={{marginTop: '10px'}}>
                            <div style={{width: '35px'}}>&nbsp; </div>
                        </div>
                    </Stack.Item>)
                }    
            </td>
        </tr>
    )        
}