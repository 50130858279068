import { IDropdownOption } from "@fluentui/react";
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { IFieldItem } from "./UnControlledFieldItem";
import { UnControlledFieldItemList } from "./UnControlledFieldItemList";

export interface IControlledFieldListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {    
  availableFields: IDropdownOption[]
  allowMappedFields?: boolean
  availableFieldTypes?: IDropdownOption[]
}

export const ControlledFieldList = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IControlledFieldListProps) => {
    
    return (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={props.defaultValue || undefined}
          render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
          }) => (
                <UnControlledFieldItemList 
                    {...props}
                    items={value || []} 
                    onItemsChanged={(items: IFieldItem[]) => {                    
                        onChange(items)
                    }} 
                    availableFields={props.availableFields}
                    />                    
            )}
        />
      );
}