import { Icon, IDropdownOption, PrimaryButton, Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ControlledDropdown } from "./ControlledDropdown";
import { ControlledNumberField } from "./ControlledNumberField";
import { ISdkCustomerDiscountProfileItem } from "../sdk/models/ISdkCustomerDiscountProfile";
import { getRelativeMonthDefinition } from "../components/Helpers/DateHelpers";

export interface IDiscountItem extends ISdkCustomerDiscountProfileItem {
    key: string    
}

export interface IDiscountItemFieldProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    
    disabled?: boolean        

    onUpdateDiscountItem: (item: IDiscountItem) => void

    onDeleteDiscountItem?: (item: IDiscountItem) => void

    item?: IDiscountItem 

    first?: boolean
}

export const UnControlledDiscountItem = (props: IDiscountItemFieldProps) => {

    const { handleSubmit, control, reset, formState } = useForm<IDiscountItem>({
        defaultValues: {
            validUntil: '',
            discount: 0            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    useEffect(() => {
        reset(props.item ? {...props.item} : {})        
    // eslint-disable-next-line
    }, [props.item])

    // read the form state to initialize the proxy state
    // eslint-disable-next-line
    useEffect(() => {}, [formState.isDirty])
    
    const availablePeriods: IDropdownOption[] = [{key: 'infinite', text: 'infinite'}]
    for(let i = -60; i <= 60; i++) {

        const monthString = getRelativeMonthDefinition(i);

        availablePeriods.push({
            key: monthString,
            text: monthString
        })
    }        
    
    const onProcessDiscountItem = () => {

        const submitHandler = handleSubmit((data: IDiscountItem) => {
            
            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();
            
            let newData = {...data}
            if (data.discount && typeof(data.discount) === 'string') {
                newData = {...data, discount: parseFloat(data.discount)}                
            }                

            // send the callback 
            props.onUpdateDiscountItem(newData)            

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    const onDeleteDiscountItem = () => {

        const submitHandler = handleSubmit((data: IDiscountItem) => {
            
            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();
            
            // send the callback 
            if (props.onDeleteDiscountItem) {
                props.onDeleteDiscountItem(data) 
            }           

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    return (
        <tr style={{verticalAlign: 'top'}}> 
            <td style={{width: '70%', paddingRight: '5px'}}>
                <div style={{marginTop: '10px'}}>
                    <ControlledNumberField key={'discount'} control={control} name={'discount'} placeholder={'Enter discount'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} />
                </div>
            </td>
            <td style={{width: '30%', paddingRight: '5px'}}>
                <ControlledDropdown key={'validUntil'} control={control} name={'validUntil'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={availablePeriods} />
            </td>            
            <td>
                <div style={{marginTop: '10px'}}>
                    <PrimaryButton style={{width: '10px', minWidth: '10px'}} disabled={props.disabled || !formState.isValid || !formState.isDirty} onClick={onProcessDiscountItem}>                            
                        <Icon iconName={props.item ? 'faCheck' : 'faPlus'}></Icon>
                    </PrimaryButton>                        
                </div>    
            </td>
            <td>
                { (props.item  && props.onDeleteDiscountItem) 
                    ? (<Stack.Item grow={0}>
                        <div style={{marginTop: '10px'}}>
                            <PrimaryButton style={{width: '35px', minWidth: '35px'}} disabled={props.disabled} onClick={onDeleteDiscountItem}><Icon iconName={'faTrashCan'}></Icon></PrimaryButton>                        
                        </div>    
                    </Stack.Item>)
                    : (<Stack.Item grow={0}>
                        <div style={{marginTop: '10px'}}>
                            <div style={{width: '35px'}}>&nbsp; </div>
                        </div>
                    </Stack.Item>)
                }    
            </td>
        </tr>
    )    
}