import { PageContent } from "../lib/ds/components/PageContent"
import { PageHeader } from "../lib/ds/components/PageHeader"

export interface ISettingsStatusOverviewProps {
}

export const SettingsStatusOverview = (props: ISettingsStatusOverviewProps) => {

    return (
        <div className="mv-content-container">

            <PageHeader title={'Service Status'}>            
                <span>
                    The service status page offers a comprehensive overview of the system's services, including connected services for your convenience. Please note 
                    that while we provide visibility into these connected services, any issue resolutions should be sought directly from their respective service operators.
                </span>                    
            </PageHeader>            

            <PageContent>
                <h3>Provided Platform Services</h3>
                <div>
                    These services extend a range of capabilities, each catered to diverse operational needs. To access detailed information regarding the status of these capabilities, you 
                    can simply click on the corresponding status badge. This action will seamlessly redirect you to the comprehensive status page, offering in-depth insights into the 
                    current operational state of each service.
                </div>
                <div style={{marginTop: '20px', padding: '5px', paddingTop: '10px', paddingLeft: '10px', backgroundColor: 'rgb(243, 242, 241)', borderRadius: '8px', width: '300px'}}>
                    <iframe title="Platform Services" src="https://status.mevodo.com/badge" width="290" height="40" frameBorder="0"></iframe>
                </div>

                <h3>Connected External Services</h3>
                <div>
                    The platform predominantly relies on external services, primarily for tasks such as data ingestion and user authentication. To gain a more comprehensive understanding 
                    of the current status and performance of these external services, you can conveniently click on the associated status badge. This action will promptly redirect you to 
                    a dedicated, in-depth status page.
                </div>
                <div style={{marginTop: '20px', padding: '5px', paddingTop: '10px', paddingLeft: '10px', backgroundColor: 'rgb(243, 242, 241)', borderRadius: '8px', width: '300px'}}>
                    <iframe title="Connected External Services" src="https://consumed-services.mevodo.com/badge" width="290" height="40" frameBorder="0"></iframe>
                </div>
            </PageContent>

        </div>)
}
