import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useState } from "react";
import { FullScreeLoadingIndicator } from "../components/FullScreenLoadingIndicator";
import { AuthenticationSubscriptionContext } from "./AuthenticationSubscriptionContext";
import { SdkTenantClient } from "../sdk/SdkTenantClient";
import { useParams } from "react-router-dom";
import { ISdkTenantSubscriptionModel } from "../sdk/models/ISdkTenantSubscriptionModel";
import { useEffectAsync } from "../components/Helpers/EffectAsyncHook";

export interface AuthenticationSubscriptionProviderProps {        
    children?: React.ReactNode
}

export default function AuthenticationSubscriptionProvider(props: AuthenticationSubscriptionProviderProps) {
    
    const { tenantId } = useParams();
    const auth = useAuthInContext(tenantId as string);   
    const [subscriptions, setSubscriptions] = useState<ISdkTenantSubscriptionModel[]>([]);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState<boolean>(false);    

    useEffectAsync(async () => {

        if (!tenantId) { 
            setSubscriptions([])
            return 
        }

        setLoadingSubscriptions(true)

        var client = new SdkTenantClient(auth.currentToken as string)
        var subscriptions = await client.getSubscriptions(tenantId as string)
        setSubscriptions(subscriptions)

        setLoadingSubscriptions(false)

    }, [tenantId])
    
    if (loadingSubscriptions) {
        return (<FullScreeLoadingIndicator />)
    } else {        
        return (
            <AuthenticationSubscriptionContext.Provider value={{subscriptions: subscriptions}}>            
                {props.children}            
            </AuthenticationSubscriptionContext.Provider>
        );
    }
}