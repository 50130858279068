import { PanelType } from "@fluentui/react";
import { IAppWindowBaseProps } from "../../ds/components/AppWindow";
import { PanelWithDefaultFooter } from "../DetailsListHelper/PanelWithDefaultFooter";
import { useEffectAsync } from "../Helpers/EffectAsyncHook";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useState } from "react";
import { ISdkUserProfile } from "../../sdk/models/ISdkUserProfile";
import { SdkTenantUserProfileClient } from "../../sdk/SdkTenantUserProfileClient";
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations";
import { IColumnsBuilder } from "../DetailsListHelper/ColumnsBuilder";
import { useProfileContext } from "../../authentication/AuthenticationProfileContext";

export interface IReportTransferOwnershipDialogProps extends IAppWindowBaseProps {
    tenantId: string
    
    onTransferToOwner: (newOwnerId: string) => Promise<void>
}

export const ReportTransferOwnershipDialog = (props: IReportTransferOwnershipDialogProps) => {

    const auth = useAuthInContext(props.tenantId)
    const profileContext = useProfileContext()

    const [isPreparing, setIsPreparing] = useState(true)
    const [availaleTeamMembers, setAvailableTeamMembers] = useState<ISdkUserProfile[]>([])        
    const [selectedTeamMember, setSelectedTeamMember] = useState<ISdkUserProfile | undefined>(undefined)

    useEffectAsync(async () => {

        if (!props.isVisible) { return }

        setIsPreparing(true)

        // load all discount profile for the reference report
        const sdk = new SdkTenantUserProfileClient(props.tenantId, auth.currentToken as string)
        const userProfiles  = await sdk.listUserProfiles()
        const filteredUserProfiles  = userProfiles
            .filter(up => up.contactAddresses.length > 0 && up.contactAddresses[0].indexOf('@applications') === -1 && up.userId.indexOf('@service-principals') === -1)
            .filter(up => up.userId !== profileContext.profile.profileId)

        setAvailableTeamMembers(filteredUserProfiles)

        setIsPreparing(false)
        
    }, [props.isVisible])

    const onDismiss = () => {
        if (props.dismissDialog) { props.dismissDialog()}
    }
        
    const onSubmit = async () => {
        if (props.onTransferToOwner && selectedTeamMember) {
            await props.onTransferToOwner(selectedTeamMember.userId)
        }
        onDismiss()
    }    

    const buildColumns = (builder: IColumnsBuilder<ISdkUserProfile>): void  => 
    {        
        builder.IconColumn({name: "Type", headerIcon: 'faUsers', iconName: (item) => {
            if (item.contactAddresses && item.contactAddresses.length > 0 && item.contactAddresses[0].indexOf('@applications') > -1) 
                return 'faCircleNodes'
            else if (item.contactAddresses && item.contactAddresses.length > 0 && item.contactAddresses[0].indexOf('@service-principals') > -1) 
                return 'faImagePortrait'
            else
                return 'faUser'
        }})
        builder.TextColumn({name: "Display Name", value: (item) => { return item.firstName + ' ' + item.lastName}, maxWidth: 250})                    
        builder.TextColumn({name: "Contact", value: (item) => { return item.contactAddresses ? item.contactAddresses.join(', ') : ''}, maxWidth: 80})                              
    }
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Select new owner'}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
            type={PanelType.medium} 
            progessMessage={'Changing owner...'} 
            submitLabel={'Apply'} 
            dissmissLabel={'Cancel'}             
            isValid={selectedTeamMember !== undefined}
            isPreparing={isPreparing}>

            <p>
                Please select the new owner for the reports. The system will transfer the onwership 
                of the reports to the new owner. The new owner will be able to see the reports in the
                reports list under the "My Reports" section.                            
            </p>

            <p>
                <strong>Please be aware that this process is not revertable! Only the new owner can tranfer the
                ownership back to the original owner.</strong>
            </p>
            <NavigationalDetailsListWithStandardOperations<ISdkUserProfile> 
                uniqueIdentifier='usersrootownerchange' 
                onBuildColumns={buildColumns} 
                onLoadData={() => { return Promise.resolve(availaleTeamMembers)}} 
                hideReferesh={true}                
                isSingleSelection={true}
                onSelectedItemsChanged={(items) => { setSelectedTeamMember(items[0])}}
                />
            
        </PanelWithDefaultFooter>            
    )    
}