import { IDiscountItem, UnControlledDiscountItem } from "./UnControlledDiscountItem"

export interface IDiscountItemFieldListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    disabled?: boolean    
    items: IDiscountItem[]
    onDiscountItemsChanged?: (items: IDiscountItem[]) => void
}

export const UnControlledDiscountItemList = (props: IDiscountItemFieldListProps) => {
    
    const onUpdateDiscountItem = (item: IDiscountItem) => {
        
        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items[index] = item
        }
        
        if (props.onDiscountItemsChanged) {
            props.onDiscountItemsChanged(props.items)            
        }
    }

    const onAddDiscountItem = (item: IDiscountItem) => {
        if (props.onDiscountItemsChanged) {            
            props.onDiscountItemsChanged([...props.items, item].sort((a, b) => { return a.validUntil < b.validUntil ? -1 : 1 }))            
        }        
    }

    const onDeleteDiscountItem = (item: IDiscountItem) => {

        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items.splice(index, 1)

            if (props.onDiscountItemsChanged) {
                props.onDiscountItemsChanged(props.items)            
            }
        }        
    }

    return (
        <div>
            <table cellSpacing={0}>   
                <tbody>
                    {props.items.map((item, index) => {
                        return (
                            
                                <UnControlledDiscountItem 
                                    key={item.key} 
                                    item={item} 
                                    name={item.key}                                     
                                    onUpdateDiscountItem={onUpdateDiscountItem} 
                                    onDeleteDiscountItem={onDeleteDiscountItem} 
                                    first={index === 0} 
                                    disabled={props.disabled} />
                            )
                    })}
                
                    <UnControlledDiscountItem 
                        key={'newDiscountItem'} 
                        name={'newDiscountItem'}                                         
                        onUpdateDiscountItem={onAddDiscountItem} 
                        first={props.items.length === 0} 
                        disabled={props.disabled} />
                </tbody>                          
            </table>
        </div>    
    )
}