import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { ISdkCheckAccessRequest } from "../sdk/models/ISdkCheckAccessRequest";
import { SdkProfileClient } from "../sdk/SdkProfileClient";
import { ICommandDescription } from "./ICommandDescription";
import { ICommandPermission } from "./ICommandPermission";

const alwaysAllowedCommands = ['refresh']
const commandKeyMapper: any = { 'new': 'create' }

export class CommandPermissionEnforcer {

    private _tenantId: string
    private _resourceType: string
    private _currentToken: string
    private _permissionType: string

    constructor(tenantId: string, resourceType: string, permissionType: string, currentToken: string) {
        this._tenantId = tenantId
        this._resourceType = resourceType
        this._currentToken = currentToken
        this._permissionType = permissionType
    }

    public async enforce(commands: ICommandDescription[]): Promise<ICommandPermission[]> {

        // generate a check access requets 
        const accessCheckRequest: ISdkCheckAccessRequest[] = commands.map((command) => {

            const permissionKey = commandKeyMapper[command.key] ? commandKeyMapper[command.key] : command.key            

            if (command.key === 'managedAccess') {
                return {
                    id: command.key,
                    resourceIdentifier: 'mevodo.cloud.service.accounts/' + this._tenantId + '/tenant',
                    permissionIdentifier: 'mevodo.cloud.service.accounts/tenants/update',
                }
            } else {
                return {
                    id: command.key,
                    resourceIdentifier: 'mevodo.cloud.service.cloudbilling/' + this._tenantId + '/' + this._resourceType,
                    permissionIdentifier: 'mevodo.cloud.service.cloudbilling/' + this._permissionType + '/' + permissionKey,
                }
            }
        })

        // ask the backend if we have access
        const sdkClient = new SdkProfileClient(this._currentToken);
        const accessCheckResult = await sdkClient.checkAccess(accessCheckRequest)

        // convert it back to the command permission structure        
        return commands.map((command) => {

            if (alwaysAllowedCommands.indexOf(command.key) !== -1)
                return { command, canExecute: true }
                
            return { command, canExecute: accessCheckResult.find(x => x.id === command.key)?.allowed ?? false}
        })
    }
}

export function useCommandPermissionEnforcer(tenantId: string, resourceType: string, permissionType: string) : CommandPermissionEnforcer {
    const auth = useAuthInContext(tenantId)
    return new CommandPermissionEnforcer(tenantId, resourceType, permissionType, auth.currentToken as string);
}