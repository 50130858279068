import { useAuth } from "@mevodo/mv-react-authentication"
import { useParams } from "react-router-dom"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { IFilterItem } from "../lib/forms/UnControlledFilterItem"
import { ISdkTenantInvoice } from "../lib/sdk/models/ISdkTenantInvoice"
import { SdkClientServiceDiscoveryService } from "../lib/sdk/SdkClientServiceDiscoveryService"
import { SdkTenantInvoiceClient } from "../lib/sdk/SdkTenantInvoiceClient"
import dayjs from 'dayjs'
import { useState } from "react"
import { PanelInvoiceDetails } from "./PanelInvoiceDetails"

export interface ServiceProviderInvoicesProps {
    customerId?: string 
}

export const ServiceProviderInvoices = (props: ServiceProviderInvoicesProps) => {

    // get the authenticaiton 
    const auth = useAuth()
    
    // get the current tenant state
    const { tenantId } = useParams()          
    
    // some more needed states
    const [clickedItem, setClickedItem] = useState<ISdkTenantInvoice | undefined>(undefined)
    
    const onBuildColumns = (builder: IColumnsBuilder<ISdkTenantInvoice>) => {
        // builder.IconColumn({name: "Type", headerIcon: 'faBell', iconName: (item) => iconMap[item.notificationState], iconClass: (item) => 'icon ' + item.notificationState, onClick: (item) => { setDetailsItem(item) }})                                
        builder.TextColumn({name: "Invoice Id", value: "invoiceId", maxWidth: 150, onClick: (item) => { setClickedItem(item) }})
        builder.TextColumn({name: "Invoice Date", value: (item) => dayjs(item.invoiceDate).format("YYYY-MM-DD"), maxWidth: 150, onClick: (item) => { setClickedItem(item) }})
        builder.TextColumn({name: "Data-Integrations", value: (item) => {
            return item.dataIntegrations.map(x => x.name).join(', ');

        }, maxWidth: 250, onClick: (item) => { setClickedItem(item) }})
        builder.TextColumn({name: "Providers", value: (item) => item.billingProviders.join(', '), maxWidth: 250, onClick: (item) => { setClickedItem(item) }})
        builder.TextColumn({name: "Value", value: "balance", maxWidth: 150, onClick: (item) => { setClickedItem(item) }})        
        builder.IconColumn({name: "Type", headerIcon: 'faGlobe', iconName: 'faDownload', onClick: (item) => { 
            const fullUri = SdkClientServiceDiscoveryService.DiscoverUri(item.downloadLink);            
            window.open(fullUri, '_blank')
        }})                        
    }
    
    const onLoadData = async (filters: IFilterItem[], searchText: string): Promise<ISdkTenantInvoice[]> => {           
        var sdkClient = new SdkTenantInvoiceClient(tenantId as string, auth.currentToken as string)
        return await sdkClient.listInvoices(searchText)        
    }
    
    return (
        <div className="mv-content-container">

            <PageHeader title={'Invoices'}>            
                <span>All invoices processed by the registered data integrations are reflected in the list below</span>
            </PageHeader>

            <NavigationalDetailsListWithStandardOperations 
                uniqueIdentifier={'invoicesroot'}            
                onBuildColumns={onBuildColumns}                                
                onLoadData={onLoadData}                
                hideReferesh={false} 
                preventInitialLoad={false}                        
                allowSorting={true}            
                disabledItemSelect={true}
                allowSearching={true}
                allowFiltering={false} />       

            <PanelInvoiceDetails tenantId={tenantId as string} isVisible={clickedItem ? true : false } invoice={clickedItem as ISdkTenantInvoice} dismissDialog={() => setClickedItem(undefined)}></PanelInvoiceDetails>
    </div>)
}
