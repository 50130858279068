import './NavigationMenu.scss'

import { INavLink, INavStyles, Nav } from "@fluentui/react"
import { useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"

export interface INavigationMenuGroupProps {
    name: string, 
    hidden?: boolean,
    children?: React.ReactNode    
}


export const NavigationMenuGroup = (props: INavigationMenuGroupProps) => {
    return(<>{props.children}</>)    
}

export interface INavigationMenuItemProps extends INavigationMenuProps {
    name: string, 
    route: string, 
    icon?: string,
    hidden?: boolean    
}

export const NavigationMenuItem = (_props: INavigationMenuItemProps) => {
    return (<></>)
}

export interface INavigationMenuProps {
    children?: React.ReactElement[] | React.ReactElement    
} 

export const NavigationMenu = (props: INavigationMenuProps) => {

    const navigate = useNavigate()
    const currentLocation = useLocation()    

    const [expandedItems, setExpandedItems] = useState<string[]>([])

    const navStyles: Partial<INavStyles> = {
        root: {
          width: 208,
          height: 350,
          boxSizing: 'border-box',
          border: '1px solid #eee',
          overflowY: 'auto',
        }        
    }
       
    const navigateWithRouter = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        navigate(item?.url as string)
        ev?.preventDefault();
    }    

    const propsChildren = Array.isArray(props.children) ? props.children as React.ReactElement[] : [ props.children as React.ReactElement ]

    const navLinks: INavLink[] = propsChildren?.filter((c) => !c.props.hidden )
        .map<INavLink>((c) => {
            if (c.props.children) {

                const childArray = c.props.children as React.ReactElement[]

                // build the group childs
                const navChildLinks: INavLink[] = childArray
                    .filter((cc: any) => !cc.props.hidden)
                    .map<INavLink>((cc: any) => {                
                        return {
                            name: cc.props.name,
                            url: cc.props.route,
                            key: cc.props.route,
                            onClick: navigateWithRouter
                        }
                    })  

                // build the group 
                return {
                    name: c.props.name,
                    url: '',
                    key: c.props.name,
                    isExpanded: expandedItems.includes(c.props.name),                     
                    links: navChildLinks                        
                }
            } else {
                return { 
                    name: c.props.name,
                    url: c.props.route,
                    key: c.props.route,
                    isExpanded: true,
                    onClick: navigateWithRouter
                }
            }
        })        
                    
    return (
        <Nav 
            className='mv-nav-menu'
            selectedKey={currentLocation.pathname}            
            styles={navStyles}
            groups={[{ links: navLinks}]}
            onLinkExpandClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {                                
                if (item?.isExpanded) {
                    var updatedExpandedItems = [...expandedItems]
                    var index = updatedExpandedItems.indexOf(item?.key as string)
                    if (index > -1) {
                        updatedExpandedItems.splice(index, 1)
                    }
                    setExpandedItems(updatedExpandedItems)
                } else {
                    setExpandedItems([...expandedItems, item?.key as string])
                }
            }}
            />
    )
}