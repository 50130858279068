import { ISdkDashboardDefinition } from "./models/ISdkDashboardDefinition";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerDashboardClient {

    private sdkClient: SdkClient<ISdkDashboardDefinition>    

    constructor(
        private _tenantId: string, 
        private _parentId: string,
        private _parentType: string,
        private _token: string)        
    {
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<any>('/api/v1/tenants/' + _tenantId + '/svp/dashboards', _token);
    }

    public listDashboardDefinitions(): Promise<ISdkDashboardDefinition[]> {
        return this.sdkClient.list('?parentId=' + this._parentId + '&parentType=' + this._parentType);                
    }    

    public createCustomerDashboard(name: string, parent: string, parenType: string, initialize?: boolean): Promise<ISdkDashboardDefinition> 
    {
        const payload = {
            name: name,
            targetType: parenType,
            targetId: parent,
            defaultItems: initialize ? true : false 
        }

        return this.sdkClient.post('', payload).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })        
    }

    public deleteDashboard(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }
}