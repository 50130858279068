import { Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useForm } from "react-hook-form";
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ControlledTextField } from "../lib/forms/ControlledTextField";
import { SdkCustomerGroupClient } from "../lib/sdk/SdkCustomerGroupClient";

export interface IAddCustomerGroupDialogProps extends IAppWindowBaseProps {   
     tenantId: string
     parent?: string
     groupType: 'standard' | 'partnerchannel'
}

export const AddCustomerGroupDialog = (props: IAddCustomerGroupDialogProps) => {
        
    const auth = useAuthInContext(props.tenantId);           
    
    const titleDescriptionMap = {
        "standard": {
            title: 'Add new group',
            description: 'Please enter all mandatory information to create a new group. The group becomes accessible for all user with the role "Cloud Billing Manager" automatically!',
            label: 'Group name'
        },
        "partnerchannel": {
            title: 'Add new partner channel',
            description: 'Please enter all mandatory information to create a new partner channel. The partner channel reflects a partner or reseller with dedicated access to all customers managed in this group',
            label: 'Partner channel name'
        }
    }

    const [isSubmitProcessing, { setTrue: startSubmitProccesing, setFalse: stopSumitProcessing}] = useBoolean(false)

    type AddCustomerGroupForm = {
        groupName: string        
    };
    
    const { handleSubmit, control, reset, formState } = useForm<AddCustomerGroupForm>({
        defaultValues: {
            groupName: ''            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
        
    const dissmissDialog = () => {
        reset()        
        if (props.dismissDialog)
            props.dismissDialog()
    }    

    const onSubmit = (): Promise<string | void> => {

        startSubmitProccesing()

        var submitHandler = handleSubmit((data: AddCustomerGroupForm) => {            
            const customerClient = new SdkCustomerGroupClient(props.tenantId, auth.currentToken as string)
            return customerClient.createCustomerGroup(data.groupName, props.groupType, props.parent ? props.parent : undefined)
        })
        
        return submitHandler()
            .finally(() => {
                stopSumitProcessing()
            })
            .then(() => {
                dissmissDialog()
            })
            .catch((error) => {  
                let errorMessage = 'Something went wrong creating the new group, please try again later'
                errorMessage += error.code ?  ' (' + error.code + ')' : ''            
                return errorMessage
            })                   
    }
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={titleDescriptionMap[props.groupType].title}
            onDismiss={dissmissDialog}                                                
            progessMessage={'Creating new group...'}
            submitLabel={'Save'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid}
            onSubmit={onSubmit}>

            <p>{titleDescriptionMap[props.groupType].description}</p>    

            <form>
                <Stack tokens={{ childrenGap: 15 }}>                
                    <ControlledTextField label={titleDescriptionMap[props.groupType].label} control={control} name={'groupName'} rules={{ required: 'A valid name of the customer group is required'}} disabled={isSubmitProcessing} />                                             
                </Stack> 
            </form>
        </PanelWithDefaultFooter>
    )
}