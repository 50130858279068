import { CommandBar, DetailsList, ICommandBarItemProps, SelectionMode, TooltipHost } from "@fluentui/react";
import { Navigate, useNavigate } from "react-router-dom";
import { useProfileContext } from "../lib/authentication/AuthenticationProfileContext";
import { Stage } from "../lib/ds/ds";
import { ISdkTenant } from "../lib/sdk/models/ISdkTenant";
import { useBranding } from "../lib/ds/components/BrandingProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, brands } from "@fortawesome/fontawesome-svg-core/import.macro";

export interface SelectTenantProps {
}

export function SelectTenant(props: SelectTenantProps) {
    
    const profileContext = useProfileContext()
    const branding = useBranding()
    
    const navigate = useNavigate()

    const selectNewTenant = (t: ISdkTenant) => {
        navigate('/tenants/' + t.id)
    }

    const createNewTenant = () => {
        navigate('/welcome/signup');
    }

    // navigate to the notenant page just in case we do not have a single tenant
    if (profileContext.profile.tenants.length === 0) { return (<Navigate replace to='/welcome/notenant' />) }

    // naviagte to the tenant just in case we have only 1
    if (profileContext.profile.tenants.length === 1) { return (<Navigate replace to={'/tenants/' + profileContext.profile.tenants[0].id} />) }

    // figure out if create tenant is allowed
    const createTenantAllowed = (profileContext.profile.tenants.filter((t) => !t.managed).length > 0)

    // in all other cases bring up the tenant chooser    
    const columns = [
        {key: 'icon', isIconOnly: true,  iconName: 'faBuildings', name: 'icon', minWidth: 16, maxWidth: 16, onRender: (item: ISdkTenant) =>  item.managed ? 
            (<TooltipHost content={'Managed Tenant'}><FontAwesomeIcon icon={regular('building')} /></TooltipHost>) : 
            (<TooltipHost content={'Management Tenant for Service Providers'}><FontAwesomeIcon icon={brands('hubspot')} /></TooltipHost>)},
        {key: 'name', name: 'Name', fieldName: 'name', minWidth:150, maxWidth: 350},
        {key: 'id', name: 'Tenant Id', fieldName: 'id', minWidth:150}        
    ]
    

    const commands: ICommandBarItemProps[] = []
    if (createTenantAllowed) {
        commands.push({key: 'createTenant', text: 'New Tenant', iconProps: { iconName: 'Add' }, onClick: createNewTenant })    
    }
    
    const detailsListonActiveItemChanged = (item: any): void => {        
        selectNewTenant(item as ISdkTenant)
      };

    return ( 
        <Stage brandText={branding.company?.name ? branding.company?.name : 'mevodo'} brandLogo={branding.company?.logos['fav32']} headerTitle={'Welcome to ' + (branding.company?.name ? branding.company?.name : 'Cloud Billing') } height="450px" isVisible={true}>
            
            <p>Welcome! It looks like you are member of multiple tenants. Please select the tenant you would like to work with</p>
                        
            {commands.length > 0 && <CommandBar items={commands} /> }
            <DetailsList items={profileContext.profile.tenants} selectionMode={SelectionMode.none} columns={columns} onActiveItemChanged={detailsListonActiveItemChanged } />                

            <p style={{fontStyle: 'italic', position: 'absolute', bottom: 0}}>Your are logged in as user <strong>{profileContext.profile.contact}</strong> (<a href={'logout'}>logout</a>)</p>        
        </Stage>                 
    );
}