import { ITextFieldProps, TextField } from "@fluentui/react";
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";

export const ControlledNumberField = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & ITextFieldProps) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
       <TextField
          {...props}
          onChange={onChange}          
          value={value}
          onBlur={onBlur}
          name={fieldName}
          errorMessage={error && error.message} 
          type="number"     
        />
      )}
    />
  );
};
