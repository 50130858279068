import { DefaultButton, MessageBar, MessageBarType, Panel, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ControlledCheckbox } from "../lib/forms/ControlledCheckbox"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { SdkTenantUserProfileClient } from "../lib/sdk/SdkTenantUserProfileClient"

export interface TenantUserManagementAddUserProps extends IAppWindowBaseProps {
    tenantId: string
}


export const TenantUserManagementAddUser = (props: TenantUserManagementAddUserProps) => { 
        
    // get the authenticaiton 
    const auth = useAuthInContext(props.tenantId as string)        
    
    const [error, setError] = useState<string>()
    const [isProcessing, { setTrue: startProccesing, setFalse: stopProcessing}] = useBoolean(false)

    const stackTokens = { childrenGap: 15 };
    
    const buttonStyles = { root: { marginRight: 8 } };


    type AddUserForm = {
        contactAddress: string      
        firstName: string, 
        lastName: string,  
        isTeamAdmin: boolean
    };
    
    const { handleSubmit, control, reset } = useForm<AddUserForm>({
        defaultValues: {
            contactAddress: '',
            firstName: '',
            lastName: '',
            isTeamAdmin: false
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });

    const onSubmit: SubmitHandler<AddUserForm> = (data, e) => {        
        startProccesing();

        const userProfileClient = new SdkTenantUserProfileClient(props.tenantId, auth.currentToken as string)
        userProfileClient.registerUser(data.firstName, data.lastName, data.contactAddress, data.isTeamAdmin).then((userProfile) => {
            stopProcessing();
            dissmissDialog();
        }).catch((error) => {  
            let errorMessage = 'Something went wrong creating the new customer, please try again later'
            errorMessage += error.code ?  ' (' + error.code + ')' : ''
            setError(errorMessage)
            stopProcessing();
        })          
    }

    const dissmissDialog = () => {
        reset();
        setError(undefined);
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onRenderFooterContent = useCallback(() => (        
        <div>
          
          { isProcessing ? (<ProgressIndicator label="Registering user..." />) : (<></>)}
          <PrimaryButton onClick={handleSubmit(onSubmit)} styles={buttonStyles} disabled={isProcessing}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={dissmissDialog} disabled={isProcessing}>Cancel</DefaultButton>
        </div>
      ),
      // eslint-disable-next-line 
      [isProcessing])

      
    return (
        <Panel
            isOpen={props.isVisible}
            headerText={'Register new user'}
            onDismiss={dissmissDialog}            
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}>
                <p>Please enter a valid contact address for the user should get access to the
                    tenant. Only administrators will have access to all capabilities of the tenant,
                    normal users need to get dedicated permissions</p>                 

                { error ? (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {'Error: ' + error}
                    </MessageBar>
                ) : (<></>)}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack tokens={stackTokens}>                
                        <ControlledTextField label="First Name" control={control} name={'firstName'} rules={{ required: 'A valid name is required'}} disabled={isProcessing} />                                             
                        <ControlledTextField label="Last Name" control={control} name={'lastName'} rules={{ required: 'A valid name is required'}} disabled={isProcessing} />                                             
                        <ControlledTextField label="User contact address" control={control} name={'contactAddress'} rules={{ required: 'A valid contact address is required'}} disabled={isProcessing} />                                             
                        <ControlledCheckbox label="Add as administrator" control={control} name={'isTeamAdmin'} disabled={isProcessing} />
                    </Stack>                     
                </form>
                
        </Panel>

    ) 
}

