import { ISelectorListViewItemProps, SelectorListView } from '../../SelectorListView/SelectorListView';
import { useWizard } from '../../PanelWizard/PanelWizard';
import { IDashboardGridWidgetSelectorWizardState } from './IDashboardGridWidgetSelectorWizardState';
import { IDashboardGridWidgetSelectorStepProps } from './IDashboardGridWidgetSelectorStepProps';
import { useEffect, useState } from 'react';
import { useAuthInContext } from '@mevodo/mv-react-authentication';
import { useParams } from 'react-router-dom';
import { SdkCustomerDashboardWidgetTemplateClient } from '../../../sdk/SdkCustomerDashboardWidgetTemplateClient';
import { ISdkDashboardWidgetTemplate } from '../../../sdk/models/ISdkDashboardWidgetTemplate';

export const DashboardGridWidgetSelectorStep001SelectWidget = (props: IDashboardGridWidgetSelectorStepProps) => {

    // inject the auth context
    const auth = useAuthInContext(props.tenantId);
    
    // get data from uri
    const { tenantId } = useParams()      
        
    // inject the wizard
    var wizard = useWizard<IDashboardGridWidgetSelectorWizardState>();
            
    const onSelectItem = (item: ISelectorListViewItemProps) => {        
        const castedItem = item as ISdkDashboardWidgetTemplate   
        wizard.setData({widgetTemplate: castedItem})

        if (castedItem.hasDatasource)
            wizard.goToStep('selectDatasource')            
        else
            wizard.goToStep('configureAttributes')            
    }    

    // set the widget state 
    const [availableWidgets, setAvailableWidgets] = useState<ISdkDashboardWidgetTemplate[]>([]);

    useEffect(() => {
        
        // establish the sdk client
        const sdkCustomerDashboardWidgetTemplateClient = new SdkCustomerDashboardWidgetTemplateClient(tenantId as string, auth.currentToken as string);

        // load the available widgets
        sdkCustomerDashboardWidgetTemplateClient.getWidgetTemplates().then((widgets) => {
            setAvailableWidgets(widgets);
        })

    // eslint-disable-next-line
    }, [])

    return (
        <div className='widget-selector'>            
            <div>Select a card to add more capabilities to your dashboards.</div>
            <SelectorListView items={availableWidgets} onClick={onSelectItem} />            
        </div>
    )
};