import { Icon, IDropdownOption, PrimaryButton, Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ControlledCheckbox } from "./ControlledCheckbox";
import { ControlledDropdown } from "./ControlledDropdown";
import { ControlledTextField } from "./ControlledTextField";

export interface IFilterItem {
    key: string
    operator: string
    field: string
    condition: string
    value: string
    compare: boolean
}
export interface IFilterItemFieldProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    
    disabled?: boolean    

    availableFields: IDropdownOption[]

    onUpdateFilterItem: (item: IFilterItem) => void

    onDeleteFilterItem?: (item: IFilterItem) => void

    item?: IFilterItem 

    first?: boolean
    single?: boolean

    direction?: 'horizontal' | 'vertical'
}

export const UnControlledFilterItem = (props: IFilterItemFieldProps) => {

    const { handleSubmit, control, reset, formState } = useForm<IFilterItem>({
        defaultValues: {
            key: '',
            operator: '',
            field: '',
            condition: '',
            value: '',
            compare: false,
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    useEffect(() => {
        reset(props.item ? {...props.item} : {})        
    // eslint-disable-next-line
    }, [props.item])

    // read the form state to initialize the proxy state
    // eslint-disable-next-line
    useEffect(() => {}, [formState.isDirty])
    
    let availableOperators: IDropdownOption[] = []

    if (props.first) {
        availableOperators = [
            { key: 'where', text: 'Where', selected: true },
        ]
    } else {
        availableOperators = [
            { key: 'and', text: 'and' },
            { key: 'or', text: 'or' },
        ]
    }
    
    const availableConditions: IDropdownOption[] = [
        { key: 'equal', text: '=' },
        { key: 'notEqual', text: '<>' },
        { key: 'lt', text: '<' },
        { key: 'lte', text: '<=' },
        { key: 'gt', text: '>' },
        { key: 'gte', text: '>=' },
        { key: 'contains', text: 'contains' },
        { key: 'notContains', text: 'notContains' },
        { key: 'startsWith', text: 'startsWith' },
        { key: 'notStartsWith', text: 'notStartsWith' },
        { key: 'hasValue', text: 'hasValue' },
    ]

    const onProcessFilterItem = () => {

        const submitHandler = handleSubmit((data: IFilterItem) => {

            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();

            // send the callback 
            props.onUpdateFilterItem(data)            

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    const onDeleteFilterItem = () => {

        const submitHandler = handleSubmit((data: IFilterItem) => {

            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();

            // send the callback 
            if (props.onDeleteFilterItem) {
                props.onDeleteFilterItem(data) 
            }           

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    if (props.direction === 'horizontal') {
        return (
            <div>
                { !props.single ? (
                        <ControlledDropdown key={'operator'} control={control} name={'operator'} placeholder={props.first ? undefined : 'Select operator'} rules={{ required: 'a valid value is required'}} disabled={props.disabled} options={availableOperators} />
                    ) : (
                        <></>
                    )
                }
                <ControlledDropdown key={'field'} control={control} name={'field'} placeholder={'Select field'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={props.availableFields} />
                <ControlledDropdown key={'condition'} control={control} name={'condition'} placeholder={'Select condition'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={availableConditions} />
                
                <p><ControlledTextField key={'value'} control={control} name={'value'} placeholder={'Enter value'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} /></p>

                <p><ControlledCheckbox key={'compare'} control={control} name={'compare'} disabled={props.disabled} label="Compare in-case-sensitive" /></p>

                <PrimaryButton disabled={props.disabled || !formState.isValid || !formState.isDirty} onClick={onProcessFilterItem}>Apply</PrimaryButton>                        
            </div>
        )
    } else {
        return (
            <tr style={{verticalAlign: 'top'}}> 
                { !props.single ? (
                    <td style={{width: '10%', paddingRight: '5px'}}>
                        <ControlledDropdown key={'operator'} control={control} name={'operator'} placeholder={props.first ? undefined : 'Select operator'} rules={{ required: 'a valid value is required'}} disabled={props.disabled} options={availableOperators} />
                    </td>
                    ) : (<></>)
                }
                <td style={{width: '30%', paddingRight: '5px'}}>
                    <ControlledDropdown key={'field'} control={control} name={'field'} placeholder={'Select field'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={props.availableFields} />
                </td>
                <td style={{width: '20%', paddingRight: '5px'}}>
                    <ControlledDropdown key={'condition'} control={control} name={'condition'} placeholder={'Select condition'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={availableConditions} />
                </td>
                <td style={{width: '40%', paddingRight: '5px'}}>
                    <div style={{marginTop: '10px'}}>
                        <ControlledTextField key={'value'} control={control} name={'value'} placeholder={'Enter value'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} />
                    </div>
                </td>
                <td>
                    <div style={{marginTop: '16px'}}>
                        <ControlledCheckbox key={'compare'} control={control} name={'compare'} disabled={props.disabled} />
                    </div>
                </td>
                <td>
                    <div style={{marginTop: '10px'}}>
                        <PrimaryButton style={{width: '10px', minWidth: '10px'}} disabled={props.disabled || !formState.isValid || !formState.isDirty} onClick={onProcessFilterItem}>                            
                            <Icon iconName={props.item ? 'faCheck' : 'faPlus'}></Icon>
                        </PrimaryButton>                        
                    </div>    
                </td>
                <td>
                    { (props.item  && props.onDeleteFilterItem) 
                        ? (<Stack.Item grow={0}>
                            <div style={{marginTop: '10px'}}>
                                <PrimaryButton style={{width: '35px', minWidth: '35px'}} disabled={props.disabled} onClick={onDeleteFilterItem}><Icon iconName={'faTrashCan'}></Icon></PrimaryButton>                        
                            </div>    
                        </Stack.Item>)
                        : (<Stack.Item grow={0}>
                            <div style={{marginTop: '10px'}}>
                                <div style={{width: '35px'}}>&nbsp; </div>
                            </div>
                        </Stack.Item>)
                    }    
                </td>
            </tr>
        )    
    }
}