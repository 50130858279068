import './ManageResourceAccess.scss'

import { Panel, PanelType, Persona, PersonaSize } from "@fluentui/react"
import { useAuth } from "@mevodo/mv-react-authentication"
import { capitalizeFirstLetter } from "../lib/components/Helpers/StringHelpers"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { SdkRoleAssignmentsControl } from "../lib/sdkdatacomponents/SdkRoleAssignmentsControl"

export interface IManageResourceAccessProps extends IAppWindowBaseProps {
    tenantId: string
    resourceName: string,
    resourceType: string,
    resourceId?: string 
}

export const ManageResourceAccess = (props: IManageResourceAccessProps) => { 

    const auth = useAuth()

    if (!props.isVisible) {
        return (<></>)
    }
    
    const resourceIdentifier = 'mevodo.cloud.service.cloudbilling/' + props.tenantId + '/' + props.resourceType + (props.resourceId ? '-' + props.resourceId : '')

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }    

    return (
        <Panel
            isOpen={props.isVisible}
            headerText={'Manage Access to Resource'}
            onDismiss={dissmissDialog}   
            type={PanelType.large}     
            className={'mv-managed-access-resource'}
            >    
                  <Persona                     
                    text={props.resourceName}
                    secondaryText={capitalizeFirstLetter(props.resourceType)}
                    size={PersonaSize.size56}  
                    showSecondaryText={true}                                                                               
                />
                                                    
                <p>                    
                    Add or remove role assignments for the specific resource. Please keep in mind that several assignments are 
                    controlled on a deeper level in the application. Every assignment can be removed here!
                </p>
                    
                <SdkRoleAssignmentsControl 
                    tenantId={props.tenantId} 
                    currentToken={auth.currentToken as string} 
                    contextValue={resourceIdentifier} 
                    contextDisplayName={props.resourceType + ': ' + props.resourceName}
                    contextDimension='resource' />                
        </Panel>
    )
}
