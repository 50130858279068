import { PanelType, Stack } from "@fluentui/react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { PanelWithDefaultFooter } from "../../components/DetailsListHelper/PanelWithDefaultFooter"
import { ControlledTextField } from "../../forms/ControlledTextField"
import { IAppWindowBaseProps } from "./AppWindow"

export interface IDeleteResourcePanelProps extends IAppWindowBaseProps {
    resourceName: string,     
    onDeleteResource?: () => Promise<void>
}

export const DeleteResourcePanel = (props: IDeleteResourcePanelProps) => {
    
    type DeleteForm  = {
        response: string              
    };

    const { register, control, reset, formState } = useForm<DeleteForm>({
        defaultValues: {
            response: ''            
        },
        reValidateMode: "onChange",
        mode: "all"
    });    
    
    const { isValid } = formState

    const [isProcessing, setIsProcssing] = useState<boolean>(false)

    const stackTokens = { childrenGap: 15 }

    const onSubmit = (): Promise<void> => {

        setIsProcssing(true)

        if (!props.onDeleteResource)
            return Promise.resolve()

        return props.onDeleteResource().finally(() => {            
            setIsProcssing(false)
            reset()                        
        })
    }

    const onDismiss = () => {
        setIsProcssing(false)
        reset()
        if (props.dismissDialog)
            props.dismissDialog()
    }

    register('response', {
        required: 'Please confirom by entering yes in the field below',
        pattern: { value: /yes/i, message: 'Please confirom by entering yes in the field below' }
    })
    
    return (
        <PanelWithDefaultFooter
        isOpen={props.isVisible}
        headerText={'Delete ' + props.resourceName +'?'}
        onDismiss={onDismiss}                                    
        type={PanelType.medium}
        progessMessage={'Deleting reosurce ' + props.resourceName}
        submitLabel={'Delete'}
        dissmissLabel={'Cancel'}                        
        isValid={isValid}
        onSubmit={onSubmit}>
            <p>
                Do you realy want to delete the reosurce '{props.resourceName}?'. This step can't be reverted and 
                all data associated with that resource becomes deleted as well. Please type 'yes' in the field below
                to commit the removal request:
            </p>

            <form>
                <Stack tokens={stackTokens}>                
                    <ControlledTextField label="Please enter 'yes' to approve" control={control} name={'response'} disabled={isProcessing} />                                                                     
                </Stack>                     
            </form>
            
    </PanelWithDefaultFooter>
    )
}