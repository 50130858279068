import { useForm } from "react-hook-form"
import { ControlledTextField } from "../../../forms/ControlledTextField"
import { ISdkDashboardItem } from "../../../sdk/models/ISdkDashboardItem"
import { ISdkDashboardWidgetTemplate } from "../../../sdk/models/ISdkDashboardWidgetTemplate"
import { ControlledCheckbox } from "../../../forms/ControlledCheckbox"
import { ControlledDropdown } from "../../../forms/ControlledDropdown"
import { useEffect, useState } from "react"
import { ISdkDashboardWidgetTemplateAttribute } from "../../../sdk/models/ISdkDashboardWidgetTemplateAttribute"
import { IDropdownOption, Label, mergeStyleSets } from "@fluentui/react"
import { ISdkReportDefinition, ISdkReportDefinitionColumn } from "../../../sdk/models/ISdkReportDefintion"
import { useEffectAsync } from "../../Helpers/EffectAsyncHook"
import { ControlledFieldList } from "../../../forms/ControlledFieldList"
import { ControlledFieldFormatMappingList } from "../../../forms/ControlledFieldFormatMappingList"

export interface IDashboardGridWidgetPropertiesControlProps {
    tenantId: string
    dashboardItem: ISdkDashboardItem
    widgetTemplate: ISdkDashboardWidgetTemplate

    onUpdateDashboardItem: (dashboardItem: ISdkDashboardItem) => void

    availableReportDefinitions: ISdkReportDefinition[];    
    availableColumns: ISdkReportDefinitionColumn[]

    disabled?: boolean
}

export const DashboardGridWidgetPropertiesControl = (props: IDashboardGridWidgetPropertiesControlProps) => {

    // generate the form handler 
    const { handleSubmit, control, formState, watch, reset } = useForm<any>({ mode: 'onChange', reValidateMode: 'onChange' });
        
    const submitHandler = handleSubmit((data) => {        
        const dashboardItemUpdated = {...props.dashboardItem, attributes: data, name: data.name || data.title, description: data.description}        
        return props.onUpdateDashboardItem(dashboardItemUpdated)
    })

    // observe all changes
    useEffectAsync(async () => {      
        if (!formState.isValid) { return }     
        if (!formState.isDirty) { return }     
        
        await submitHandler()
    }, [formState]);

    // available report definitions
    const [availableReportDefinitions, setAvailableReportDefinitions] = useState<IDropdownOption[]>([])

    useEffect(() => {
        setAvailableReportDefinitions(props.availableReportDefinitions.map((reportDefinition: ISdkReportDefinition) => { return { key: reportDefinition.id, text: reportDefinition.name } }))                    
    }, [props.availableReportDefinitions])
    
    // set the existing values
    useEffect(() => {        
        reset(props.dashboardItem.attributes)  
    // eslint-disable-next-line
    }, [props.dashboardItem])

    // define the available columsn as options 
    let propertyOptions: IDropdownOption[] = props.availableColumns.map(c => { return { key: c.id, text: c.name } })        
        
    // establish the field watcher 
    const propertyWatcher = watch(props.widgetTemplate.attributes.map(f => f.id))
    const [controlDisableState, setControlDisableState] = useState<{[key: string]: any}>({})

    // establish the disable state     
    useEffect(() => {

        let newControlDisableState: any = {}

        propertyWatcher.forEach((value: any, index: number) => {
            newControlDisableState[props.widgetTemplate.attributes[index].id] = value
        })

        setControlDisableState(newControlDisableState)

    // eslint-disable-next-line 
    }, propertyWatcher)
    
    const isControlDisabled = (controlAttribute: ISdkDashboardWidgetTemplateAttribute) => {

        if (props.disabled) { return true; }

        if (!controlAttribute.dependentOn) { return false; }
        
        const currentValue = controlDisableState[controlAttribute.dependentOn]
        if (currentValue) {

            if (controlAttribute.dependentOnValue) {
                return currentValue !== controlAttribute.dependentOnValue
            } else {
                return false
            }
        } else {
            return true
        }        
    }
    
    // define the styles for the component
    const classNames = mergeStyleSets({
        checkbox: {
            marginTop: '10px'
        }
    })
    
    return (
        <div>
            <p>Please configure your widget. It's possible to use variable substitution by just using the selected columns form the datasource</p>
            <form >         
                <ControlledTextField control={control} name="title" label="Title" defaultValue="" disabled={props.disabled} />       
                <ControlledTextField control={control} name="description" label="Description" defaultValue="" multiline resizable disabled={props.disabled}/>       
                { props.widgetTemplate.attributes.map(f => {

                    switch (f.type) {
                        case 'bool':
                            return (<ControlledCheckbox key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} className={classNames.checkbox} />)                                
                        case 'property':
                            return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} options={propertyOptions} />)
                        case 'list<property>':
                            return (
                                <>
                                    <Label key={f.id} style={{marginTop: '10px'}}>{f.name}</Label>                                    
                                    <ControlledFieldList key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} availableFields={propertyOptions} />                                
                                </>)
                        case 'value':
                            return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} options={f.values ? f.values.map(c => { return { key: c, text: c } }) : []} />)                                                            
                        case 'reportdefinition':
                            return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} options={availableReportDefinitions}/>)                                
                        case 'map<property, format>':
                            return (<ControlledFieldFormatMappingList key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} availableFields={propertyOptions} />)                                                        
                        default: 
                            return (<ControlledTextField key={f.id} label={f.name} control={control} name={f.id} rules={{ required: 'a valid valud is required'}} disabled={isControlDisabled(f)} />)                                
                    }
                })}
            </form>
        </div>
    )
}
