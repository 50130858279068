import './PageHeader.scss'

export interface IPageHeaderProps {
    title: string
    mode?: 'default' | 'compact'
    children?: React.ReactNode
}

export const PageHeader = (props: IPageHeaderProps) => {

    function renderDescritption() {
        if (!props.children) 
            return (<></>)

        return (
            <div className="mv-page-header-descriptio-wrapper">
                {props.children}
            </div>
        )
    }

    function renderHeadline() {
        if (props.mode === 'compact')
            return (<h3>{props.title}</h3>)
        else
            return (<h1>{props.title}</h1>)    
    }

    return (
        <div className="mv-page-header">
            <div className="mv-page-header-text-wrapper">
                {renderHeadline()}
            </div>
            {renderDescritption()}
        </div>
    )
}