import { ITextFieldProps, TextField } from "@fluentui/react";
import { useNotifications } from "reapop";

export const ClipboardTextField = (props: ITextFieldProps) => {

    const { notify } = useNotifications()
    
    const onCopyToClipboard = () => {
        if (!props.value) { return }
        navigator.clipboard.writeText(props.value ? props.value : '')

        notify('Copied value to clipboard', 'success', { dismissible: true, dismissAfter: 2000 })                
    }

    return (
        <TextField {...props} iconProps={{ iconName: 'faClipboard', style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => { onCopyToClipboard() } }}  />
    )
}
