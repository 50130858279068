import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { PanelType, Spinner, SpinnerSize, Stack } from "@fluentui/react"
import { useState } from "react"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { SdkCustomerGroupClient } from "../lib/sdk/SdkCustomerGroupClient"
import { useForm } from "react-hook-form"
import { ClipboardTextField } from "../lib/forms/ClipBoardTextField"
import { ISdkCustomerGroup } from "../lib/sdk/models/ISdkCustomerGroup"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { useBoolean } from "@fluentui/react-hooks"
import { ISdkObjectFromTemplateFields, ISdkObjectTemplate } from "../lib/sdk/models/ISdkObjectTemplate"
import { SdkCustomerGroupCustomPropertyClient } from "../lib/sdk/SdkCustomerGroupCustomPropertyClient"
import { buildViewModelFromFieldValues, extractFieldValues, renderFieldItem, sortTemplate } from "../lib/sdkdatacomponents/SdkTemplateBasedObjectHelpers"

type EditCustomerGroupForm = {
    groupName: string            
};

export interface IEditCustomerGroupDialogProps extends IAppWindowBaseProps {   
    tenantId: string
    groupId: string    
}

export const EditCustomerGroupDialog = (props: IEditCustomerGroupDialogProps) => {
    
    const auth = useAuthInContext(props.tenantId as string)

    const [isPreparing, setIsPreparing] = useState<boolean>(false)    
    const [isSubmitProcessing, { setTrue: startSubmitProccesing, setFalse: stopSubmitProcessing}] = useBoolean(false)    
    const [loadedTemplates, setLoadedTemplates] = useState<ISdkObjectTemplate[]>([])
    
    const [loadedGroup, setLoadedGroup] = useState<ISdkCustomerGroup>()

    const { handleSubmit, control, reset, formState } = useForm<EditCustomerGroupForm>({
        defaultValues: {            
            groupName: ''            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });    

    useEffectAsync(async () => {
        
        if (!props.isVisible) { return }

        // enable preparing state
        setIsPreparing(true)

        // load the group data 
        const client = new SdkCustomerGroupClient(props.tenantId as string, auth.currentToken as string)
        const group = await client.getCustomerGroup(props.groupId);
        setLoadedGroup(group)

        // build the form 
        let formModel: EditCustomerGroupForm = {
            groupName: group.name
        }

        // load the custom property templates
        const sdkCustomPropertyClient =new SdkCustomerGroupCustomPropertyClient(props.tenantId, group.id, auth.currentToken as string)
        const templates = await sdkCustomPropertyClient.listCustomPropertyTemplates()
        setLoadedTemplates(templates ?? [])           
        
        if (templates && templates.length > 0) {
            
            // load the custom property values 
            const customPropertyValues = await sdkCustomPropertyClient.readCustomPropertyValues()

            // combine the template fields in one 
            const selectedTemplate: ISdkObjectTemplate = {...templates[0], fields: []}
            templates.forEach(t => {
                t.fields.forEach(f => {
                    selectedTemplate.fields.push(f)
                })
            })

            // inject all field values
            const viewItem = buildViewModelFromFieldValues(customPropertyValues, selectedTemplate, {}, formModel as any)

            // set the viewItem as model 
            formModel = viewItem        
        }

        // set the values
        reset(formModel)

        // disable preparing state
        setIsPreparing(false)        
    }, [props.groupId])

    const dissmissDialog = () => {        
        reset()
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = (): Promise<string | void> => {

        // handle the submission of the base form 
        const submitHandler = handleSubmit((data: EditCustomerGroupForm) => {                        
            startSubmitProccesing()
            const client = new SdkCustomerGroupClient(props.tenantId as string, auth.currentToken as string)
            return client.updateCustomerGroup(props.groupId, data.groupName).then(() => {

                // build the field values of custom properties
                const fieldValues: ISdkObjectFromTemplateFields = {} 
            
                // visit every template and collect the field values
                loadedTemplates.forEach(t => {
                    const identifiedFieldValues = extractFieldValues(data, t)          
                    Object.keys(identifiedFieldValues).forEach(key => {
                        fieldValues[key] = identifiedFieldValues[key]
                    })
                })

                // write the custom properties back
                const sdkCustomPropertyClient =new SdkCustomerGroupCustomPropertyClient(props.tenantId, loadedGroup!.id, auth.currentToken as string)
                return sdkCustomPropertyClient.writeCustomPropertyValues(fieldValues)                
            })            
        })
        
        return submitHandler()
        .then(() => { dissmissDialog() })
        .finally(() => { stopSubmitProcessing()})        
    }

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}      
            type={PanelType.medium}      
            headerText={loadedGroup ? 'Edit group "' + loadedGroup.name + '"' : 'Edit group'}
            onDismiss={dissmissDialog}                                                
            progessMessage={'Updating group...'}
            submitLabel={'Update'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid}
            onSubmit={onSubmit}
            errorMessagePrefix={'Something went wrong updating the group, please try again later'}>
                { isPreparing ? (
                    <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                ) : (
                    <div>
                        <p>Groups or partner-channels are elements to structure the whole customer base following a defined 
                            dimension, e.g. regions, organizational structure or sales models. The following properties can 
                            be adjusted for groups and partner-channels.</p>
                        <form>
                            <Stack tokens={{ childrenGap: 15 }}>                                                            
                                <ClipboardTextField value={loadedGroup?.id} name={'id'} label={'Id'} readOnly={true} />                                                                                        
                                <ControlledTextField label="Group Name" control={control} name={'groupName'} rules={{ required: 'A valid name of the customer is required'}} disabled={isSubmitProcessing} />                                
                            </Stack>                     
                        </form>

                        { loadedTemplates && loadedTemplates.length > 0 && (
                            <>
                                <p>There are custom properties defined for this group, please fill out the custom properties to ensure the group is configured correctly</p>
                                
                                { sortTemplate(loadedTemplates).map(t => (
                                    <div>
                                        <h3>Category: {t.name}</h3>
                                        { t.description && <p>{t.description}</p> }

                                        { t.fields.map(f => (
                                            renderFieldItem(f, control, isSubmitProcessing, {}, () => false)
                                        ))}
                                        
                                    </div>
                                ))}
                            </>
                        )}
                        
                    </div>
                )}

        </PanelWithDefaultFooter>
    )
}
