import React from "react";
import { ISdkTenantSubscriptionModel } from "../sdk/models/ISdkTenantSubscriptionModel";

export interface AuthenticationSubscriptionContextProps {
    subscriptions: ISdkTenantSubscriptionModel[]
}

export const emptyAuthenticationSubscriptionContextProps: AuthenticationSubscriptionContextProps = {subscriptions: []}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AuthenticationSubscriptionContext = React.createContext<AuthenticationSubscriptionContextProps>(emptyAuthenticationSubscriptionContextProps);

export const useSubscriptionContext = () => {
    return React.useContext(AuthenticationSubscriptionContext);
};