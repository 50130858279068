import { PanelType } from "@fluentui/react"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useState } from "react"
import { IColumnsBuilder } from "../components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PanelWithDefaultFooter } from "../components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../ds/components/AppWindow"
import { ISdkUserProfile } from "../sdk/models/ISdkUserProfile"
import { SdkTenantUserProfileClient } from "../sdk/SdkTenantUserProfileClient"

export interface ISdkUserSelectorControlProps {
    tenantId: string
    currentToken: string

    onSelectionChanged?: (roles: ISdkUserProfile[]) => void
}

export const SdkUserSelectorControl = (props: ISdkUserSelectorControlProps) => {

    const buildColumns = (builder: IColumnsBuilder<ISdkUserProfile>): void  => 
    {                
        builder.IconColumn({name: "Type", headerIcon: 'faUsers', iconName: 'faUser'})
        builder.TextColumn({name: "Display Name", value: (item) => { return item.firstName + ' ' + item.lastName}, maxWidth: 250})            
        builder.TextColumn({name: "Id", value: "userId", maxWidth: 250})
        builder.TextColumn({name: "Contact Addresses", value: (item) => { return item.contactAddresses ? item.contactAddresses.join(', ') : ''}, maxWidth: 150})                              
    }

    const reloadData = (): Promise<ISdkUserProfile[]> => {
        const client = new SdkTenantUserProfileClient(props.tenantId, props.currentToken)
        return client.listUserProfiles()        
    }

    return (
        <NavigationalDetailsListWithStandardOperations<ISdkUserProfile> uniqueIdentifier='usersroot' onBuildColumns={buildColumns} onLoadData={reloadData} onSelectedItemsChanged={props.onSelectionChanged}/>
    )
}

export interface ISdkUserSelectorControlPanelProps extends IAppWindowBaseProps {
    tenantId: string        

    onSelectedUsers: (users: ISdkUserProfile[]) => Promise<void>
}

export const SdkUserSelectorControlPanel = (props: ISdkUserSelectorControlPanelProps) => {
    
    const auth = useAuthInContext(props.tenantId as string)        

    const [selectedUsers, setSelectedUsers] = useState<ISdkUserProfile[]>([])
    const [isValid, setIsValid] = useState<boolean>(false)

    const changedSelectedUsers = (users: ISdkUserProfile[]) => {
        setSelectedUsers(users)        
        setIsValid(users && users.length > 0)
    }

    const onSubmit = (): Promise<void> => {
        props.onSelectedUsers(selectedUsers);
        return Promise.resolve();
    }

    return (        
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Select user to assign'}
            onDismiss={props.dismissDialog}                                    
            type={PanelType.large}
            progessMessage={'Assigning role and user to resource...'}
            submitLabel={'Assign'}
            dissmissLabel={'Cancel'}
            isValid={isValid}
            onSubmit={onSubmit}>
                <p>Please select one or more users you want to execute the operation with from the list below:</p>
                <SdkUserSelectorControl tenantId={props.tenantId} currentToken={auth.currentToken as string} onSelectionChanged={changedSelectedUsers} />
        </PanelWithDefaultFooter>

    )
}