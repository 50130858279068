import { Selection, SelectionMode } from "@fluentui/react";
import { useState } from "react";

export function useSelectionManager<T>(onSelectedItemsChanged?: (items: T[]) => void ): [T[], Selection] {
    
    const [selectedItems, setSelectedItems] = useState<T[]>([])

    const selection = new Selection({
        onSelectionChanged: selectionChanged,
        selectionMode: SelectionMode.multiple    
    })
    
    function selectionChanged(): void {            

        if(selection.getSelectedCount() > 0) {                

            const selectedItemsIndexes = selection.getSelectedIndices()
            const allItemsInSelection = selection.getItems()

            const selectedItems: T[] = selectedItemsIndexes.map((index: number) => {
                return allItemsInSelection[index] as T
            })

            setSelectedItems(selectedItems) 
            
            if (onSelectedItemsChanged)
                onSelectedItemsChanged(selectedItems)
                
        } else {            
            setSelectedItems([])            

            if (onSelectedItemsChanged)
                onSelectedItemsChanged([])
        }                  
    }
    
    return [selectedItems, selection]    
} 