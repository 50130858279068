import { ISdkFilterRule } from "./models/ISdkFilterRule";
import { ISdkTenantNotification } from "./models/ISdkTenantNotification";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantNotificationClient {

    private sdkNotificationClient: SdkClient<ISdkTenantNotification>    
    private sdkNotificationLogClient: SdkClient<string[]>    
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkNotificationClient = SdkClientServiceDiscoveryService.Discover<ISdkTenantNotification>('/api/v1/tenants/' + tenantId + '/svp/notifications', token);
        this.sdkNotificationLogClient = SdkClientServiceDiscoveryService.Discover<string[]>('/api/v1/tenants/' + tenantId + '/svp/notifications', token);    
    }

    listNotifications(dayData?: string, filters?: ISdkFilterRule[], searchText?: string): Promise<ISdkTenantNotification[]> 
    {        
        return this.sdkNotificationClient.search('', {
            filter: filters ? filters : [],
            searchText: searchText ? searchText : '',
            data: {
                day: dayData ? dayData : undefined
            }
        })        
    }

    getEventLogs(notifcationId: string): Promise<string[]> {

        return this.sdkNotificationLogClient.get('/' + encodeURIComponent(notifcationId) + '/logs')        
    }
}