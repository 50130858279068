import { Panel, PanelType } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useEffect, useState } from "react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { MetaDataList } from "../lib/ds/components/MetaDataList"
import { ISdkTenantNotification } from "../lib/sdk/models/ISdkTenantNotification"
import { SdkTenantNotificationClient } from "../lib/sdk/SdkTenantNotificationClient"

export interface IPanelShowNotifcationDetailsProps extends IAppWindowBaseProps {
    tenantId: string
    notification: ISdkTenantNotification    
} 

export const PanelShowNotifcationDetails = (props: IPanelShowNotifcationDetailsProps) => {
    
    if (!props.isVisible)
        return (<></>)

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    return (
        <Panel isOpen={props.isVisible} onDismiss={dissmissDialog} type={PanelType.large} headerText={'Notification Details: ' + props.notification.notificationTitle}>
            <ShowNotifcationDetails {...props} />                    
        </Panel>
    )
}

export const ShowNotifcationDetails = (props: IPanelShowNotifcationDetailsProps) => {
    
    const auth = useAuthInContext(props.tenantId as string)
    const [logContent, setLogContent] = useState<string[]>([])
    const [isLoading, { setTrue: setLoading, setFalse: resetLoading}] = useBoolean(false)    
    
    useEffect(() => {

        setLoading();

        const sdkClient = new SdkTenantNotificationClient(props.tenantId, auth.currentToken as string)

        sdkClient.getEventLogs(props.notification.notificationId).then((logs) => {
            setLogContent(logs)
            resetLoading();
        })

    // eslint-disable-next-line         
    }, [props.notification, props.tenantId, auth.currentToken])    

    const duration = (new Date(props.notification.lastUpdateAt).getTime() - new Date(props.notification.createdAt).getTime()) / 1000

    return (
        <>            
            <div> 
                <h3>Notification Summary</h3>
                {props.notification.notificationDescription}
            </div>

            <MetaDataList items={[
                { name: 'Status', value: props.notification.notificationState },
                { name: 'Duration', value: String(duration) + ' seconds'  },
                { name: 'Created at', value: props.notification.createdAt },
                { name: 'Finished at', value: props.notification.lastUpdateAt },
                { name: 'Notification ID', value: props.notification.notificationId },
            ]} />            
            
            <div style={{display:  isLoading ? 'none' : 'block'}}>
                <h3>Log-Information</h3>
                <div className="mv-terminal">                    
                    <pre>
                        {logContent.map((l) => {
                            return l + '\n'
                        })}
                    </pre>
                </div>
            </div>
        </>
    )
}