import React from "react";
import { ISdkProfile } from "../sdk/models/ISdkProfile";

export interface AuthenticationProfileContextProps {
    profile: ISdkProfile    
}

export const emptyAuthenticationProfileContextProps: AuthenticationProfileContextProps = {profile: {profileId: '', firstName: '', lastName: '', contact: '', avatarUri: '', tenants: []}}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AuthenticationProfileContext = React.createContext<AuthenticationProfileContextProps>(emptyAuthenticationProfileContextProps);

export const useProfileContext = () => {
    return React.useContext(AuthenticationProfileContext);
};