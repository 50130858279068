import { useBoolean } from "@fluentui/react-hooks"
import { IColumnsBuilder, ISubCommandDefinition } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { useParams } from "react-router-dom"
import { SdkTenantIdentityProviderClient } from "../lib/sdk/SdkTenantIdentityProviderClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { AddIdentityProviderPanel } from "./AddIdentityProviderPanel"
import { ISdkObjectFromTemplate } from "../lib/sdk/models/ISdkObjectTemplate"
import { useState } from "react"
import { EditIdentityProviderPanel } from "./EditIdentityProviderPanel"
import uuid from "react-uuid"
import { useNotifications } from "reapop"
import { ShowScimSettingsPanel } from "./ShowScimSettingsPanel"

export interface ISettingsIdentityProvidersProps {

}
export const SettingsIdentityProviders = (props: ISettingsIdentityProvidersProps) => {
    
    const { tenantId } = useParams();
    const auth = useAuthInContext(tenantId as string)

    const [isAddOpen, { setTrue: showAdd, setFalse: hideAdd}] = useBoolean(false)        
    const [isEditOpen, { setTrue: showEdit, setFalse: hideEdit}] = useBoolean(false)        
    const [isScimSettingsOpen, { setTrue: showScimSettings, setFalse: hideScimSettings}] = useBoolean(false)        
    const [refresh, { toggle: toggleRefresh} ] = useBoolean(false)

    const [editItemId, setEditItemId] = useState<string | undefined>(undefined)
    const [editItemScimToken, setEditItemScimToken] = useState<string | undefined>(undefined)
    
    const { notify } = useNotifications()
    
    const columnClickOperation = (item: ISdkObjectFromTemplate) => {
        setEditItemId(item.id)
        showEdit()
    }

    const calculateRequiredActions = (item: ISdkObjectFromTemplate): string => {
        const verificationCertificateValid = item.fieldValues && item.fieldValues['verificationCertificateValid'] === 'true' ? true : false
        const settingsValid = item.fieldValues && item.fieldValues['settingsValid'] === 'true' ? true : false

        let message = ''
        if (!verificationCertificateValid) { message += 'Verification Certificate is missing' }
        if (!settingsValid) { message += message.length > 0 ? ', Single Sign On Location not set' : 'Single Sign On Location not set' }
        
        if (message.length === 0) { message = 'All settings are valid' } else { message += ' - Please upload the Federation Metadata XML file!' }
        return message        
    }

    const calculateItemStatus = (item: ISdkObjectFromTemplate): string => {
        const verificationCertificateValid = item.fieldValues && item.fieldValues['verificationCertificateValid'] === 'true' ? true : false
        const settingsValid = item.fieldValues && item.fieldValues['settingsValid'] === 'true' ? true : false

        if (!verificationCertificateValid || !settingsValid) { return 'interrupted' } else { return 'valid' }        
    }

    const downloadMetaDatafile = (item: ISdkObjectFromTemplate) => {        
        const notification = notify('Requesting metadata file for the identity provider service...', 'loading')                
        const sdkClient = new SdkTenantIdentityProviderClient(tenantId as string, auth.currentToken as string); 
        sdkClient.requestMetadataLocation(item.id).then((metadataLocation) => {
            window.open(metadataLocation + '?nc=' + uuid(), '_blank')  
            notify({...notification, dismissAfter: 3000, status: 'success'})            
        }).catch((error) => {
            notify({...notification, dismissible: true, status: 'error'})          
        })
    }

    const enableScim = (item: ISdkObjectFromTemplate) => {
        const notification = notify('Enabling SCIM for the identity provider service', 'loading')                        
        const sdkClient = new SdkTenantIdentityProviderClient(tenantId as string, auth.currentToken as string); 
        sdkClient.enable_scim(item.id).then((scimDetails) => {
            setEditItemScimToken(scimDetails.token)
            setEditItemId(item.id)            
            showScimSettings()
            return notify({...notification, dismissAfter: 3000, status: 'success'})            
        }).catch((error) => {
            toggleRefresh()
            return notify({...notification, dismissible: true, status: 'error'})          
        })
    }

    const disableScim = (item: ISdkObjectFromTemplate) => {
        const notification = notify('Disabling SCIM for the identity provider service', 'loading')                        
        const sdkClient = new SdkTenantIdentityProviderClient(tenantId as string, auth.currentToken as string); 
        sdkClient.disable_scim(item.id).then(() => {
            return notify({...notification, dismissAfter: 3000, status: 'success'})                     
        }).catch((error) => {
            return notify({...notification, dismissible: true, status: 'error'})          
        }).finally(() => {
            toggleRefresh()
        })
    }

    const showScimSettingsPanel = (item: ISdkObjectFromTemplate) => {
        setEditItemId(item.id)
        showScimSettings()
    }

    const columnSubCommandsName: ISubCommandDefinition<ISdkObjectFromTemplate>[] =  [
        { iconName: 'faDownload', description: 'Download metadata file to upload into identity and access management service', onClick: (item) => downloadMetaDatafile(item), filter: (item: ISdkObjectFromTemplate) => item.type === 'identity.saml2' },                        
        { iconName: 'faBolt', description: 'Enable SCIM', onClick: (item) => enableScim(item), filter: (item: ISdkObjectFromTemplate) => item.fieldValues.scimEnabled === 'false', disabled: (item) => calculateItemStatus(item) !== 'valid', elipsis: true},                
        { iconName: 'faBolt', description: 'Show SCIM Settings', onClick: (item) => showScimSettingsPanel(item), filter: (item: ISdkObjectFromTemplate) => item.fieldValues.scimEnabled === 'true', elipsis: true},                
        { iconName: 'faBolt', description: 'Disable SCIM', onClick: (item) => disableScim(item), filter: (item: ISdkObjectFromTemplate) => item.fieldValues.scimEnabled === 'true', elipsis: true},                
    ]

    const buildColumns = (builder: IColumnsBuilder<ISdkObjectFromTemplate>): void  => 
    {        
        builder.IconColumn({name: "Type", headerIcon: 'faIdBadge', iconName: (item) => 'fas' + calculateItemStatus(item), iconClass: (item) => 'status' + calculateItemStatus(item), onClick: columnClickOperation})        
        builder.TextColumn({name: "Name", value: "name", maxWidth: 250, onClick: columnClickOperation, subCommands: columnSubCommandsName})
        builder.TextColumn({name: "Id", value: "id", maxWidth: 250, onClick: columnClickOperation})        
        builder.TextColumn({name: "Protocol", value: (item) => item.fieldValues['scimEnabled'] === 'true' ? "SAML2 & SCIM" : "SAML2", maxWidth: 250, onClick: columnClickOperation})
        builder.TextColumn({name: "Required Actions", value: (item) => calculateRequiredActions(item), maxWidth: 250, onClick: columnClickOperation})
    }

    const reloadData = async (): Promise<ISdkObjectFromTemplate[]> => {
        const sdkClient = new SdkTenantIdentityProviderClient(tenantId as string, auth.currentToken as string); 
        return await sdkClient.list()        
    }

    const onDelete = async (items: ISdkObjectFromTemplate[]): Promise<void> => {    
        const sdkClient = new SdkTenantIdentityProviderClient(tenantId as string, auth.currentToken as string); 

        for(let i = 0; i < items.length; i++) {        
            await sdkClient.delete(items[i].id)
        }
        
        return Promise.resolve()
    }

    const onAdd  = (): Promise<void> => {
        showAdd()
        return Promise.resolve()
    }    

    const handleDissmissWithReload = (cb: () => void) => {
        return () => {
            cb()         
            setEditItemId(undefined)
            toggleRefresh()                      
        }
    }

    return (
        <div className="mv-content-container">

            <PageHeader title={'Enterprise Single Sign On'}>            
                <span>Identity Providers like Azure Active Directory, OneLogin or Okta can be integrated via SAML2. This page allows 
                    to register multiple identity providers in the system. Providers implementing SCIM (System for Cross-domain Identity Management)
                    can provision new persons and custom roles fully automatically</span>
            </PageHeader>            
        
            <NavigationalDetailsListWithStandardOperations<ISdkObjectFromTemplate> 
                uniqueIdentifier='tenantsroot'
                onBuildColumns={buildColumns} 
                onLoadData={reloadData}                             
                onNewItem={onAdd}           
                onDeleteItems={onDelete}    
                refresh={refresh}
            />       

            <AddIdentityProviderPanel isVisible={isAddOpen} dismissDialog={handleDissmissWithReload(hideAdd)} tenantId={tenantId as string} />                    
            <EditIdentityProviderPanel isVisible={isEditOpen} dismissDialog={handleDissmissWithReload(hideEdit)} tenantId={tenantId as string} itemId={editItemId} />
            <ShowScimSettingsPanel isVisible={isScimSettingsOpen} dismissDialog={handleDissmissWithReload(hideScimSettings)} tenantId={tenantId as string} itemId={editItemId} itemToken={editItemScimToken} />
        </div>
    )
}