import { IPropertyMapItem, UnControlledPropertyMapItem } from "./UnControlledPropertyMapItem"

export interface IUnControlledPropertyMapItemListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    disabled?: boolean
    
    items: IPropertyMapItem[]

    onItemsChanged?: (items: IPropertyMapItem[]) => void

    placeholderLeft?: string
    placeholderRight?: string
}

export const UnControlledPropertyMapItemList = (props: IUnControlledPropertyMapItemListProps) => {
    
    const onUpdateItem = (item: IPropertyMapItem) => {
        
        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items[index] = item
        }

        if (props.onItemsChanged) {
            props.onItemsChanged(props.items)            
        }
    }

    const onAddFilterItem = (item: IPropertyMapItem) => {
        if (props.onItemsChanged) {
            props.onItemsChanged([...props.items, item])            
        }        
    }

    const onDeleteItem = (item: IPropertyMapItem) => {

        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items.splice(index, 1)

            if (props.onItemsChanged) {
                props.onItemsChanged(props.items)            
            }
        }        
    }

    return (
        <div>
            <table cellSpacing={0}>   
                <tbody>
                    {props.items.map((item, index) => {
                        return (
                            
                                <UnControlledPropertyMapItem 
                                    key={item.key} 
                                    item={item} 
                                    name={item.key} 
                                    onUpdateItem={onUpdateItem} 
                                    onDeleteItem={onDeleteItem}                                     
                                    disabled={props.disabled} 
                                    placeholderLeft={props.placeholderLeft}
                                    placeholderRight={props.placeholderRight} />
                            )
                    })}
                
                    <UnControlledPropertyMapItem 
                        key={'newFilterItem'} 
                        name={'newFilterItem'}                                         
                        onUpdateItem={onAddFilterItem}                         
                        disabled={props.disabled}
                        placeholderLeft={props.placeholderLeft}
                        placeholderRight={props.placeholderRight} />
                </tbody>                          
            </table>
        </div>    
    )
}