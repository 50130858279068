import uuid from "react-uuid";
import { ISdkCheckAccessRequest, ISdkCheckAccessResponse } from "./models/ISdkCheckAccessRequest";
import { ISdkProfile } from "./models/ISdkProfile";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkProfileClient {

    private sdkClient: SdkClient<ISdkProfile>
    private sdkCheckAccessClient: SdkClient<ISdkCheckAccessResponse[]>

    constructor(token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkProfile>('/api/v1/session', token);
        this.sdkCheckAccessClient = SdkClientServiceDiscoveryService.Discover<ISdkCheckAccessResponse[]>('/api/v1/session/checkaccess', token);
    }

    async getProfile(tenantId?: string): Promise<ISdkProfile> {
        if (tenantId) {
            return await this.sdkClient.get('?tenantId=' + tenantId);        
        } else {
            return await this.sdkClient.get('');        
        }
    }    

    async checkAccess(accessCheckRequest: ISdkCheckAccessRequest[]): Promise<ISdkCheckAccessResponse[]> {
        return await this.sdkCheckAccessClient.postToGetBody('', accessCheckRequest)        
    }

    async hasAccess(resourceIdentifier: string, permissionIdentifier: string): Promise<boolean> {
        const accessCheckRequest: ISdkCheckAccessRequest[] = [ 
            {
                id: uuid(),
                resourceIdentifier: resourceIdentifier,
                permissionIdentifier: permissionIdentifier,
            }
        ]
        const accessCheckResult = await this.checkAccess(accessCheckRequest)
        return accessCheckResult[0].allowed
    }
}