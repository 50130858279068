import { PanelType, Stack } from "@fluentui/react"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { PanelWithDefaultFooter } from "../components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../ds/components/AppWindow"
import { ControlledTextField } from "../forms/ControlledTextField"
import { ISdkDashboardDefinition } from "../sdk/models/ISdkDashboardDefinition"
import { SdkTenantDashboardClient } from "../sdk/SdkTenantDashboardClient"


export interface ISdkAddOrEditDashboardControlPanelProps extends IAppWindowBaseProps {
    tenantId: string    

    item?: ISdkDashboardDefinition
    onDashboardRegistered?: (dashboard: ISdkDashboardDefinition) => void    
}

export const SdkAddOrEditDashboardControlPanel = (props: ISdkAddOrEditDashboardControlPanelProps) => {
    
    type AddDashboardForm = {
        name: string              
    };

    const auth = useAuthInContext(props.tenantId)        
        
    const [isProcessing, setIsProcssing] = useState<boolean>(false)

    const stackTokens = { childrenGap: 15 };
        
    const { getValues, control, reset, formState } = useForm<AddDashboardForm>({
        defaultValues: {
            name: ''            
        },
        reValidateMode: "onChange",
        mode: "all"
    });    

    const { isValid } = formState

    const onSubmit = (): Promise<string | void> => {

        setIsProcssing(true)

        const values = getValues()        
        const dashboardClient = new SdkTenantDashboardClient(props.tenantId, auth.currentToken as string)
        const upsertPromise = props.item ? dashboardClient.updateDashboardDefinition(props.item.id, values.name, props.item.default) : dashboardClient.registerDashboardDefinition(values.name)        
        return upsertPromise.then((dashboard: ISdkDashboardDefinition) => {
            
            if (props.onDashboardRegistered)
                props.onDashboardRegistered(dashboard)
            
            setIsProcssing(false)
            reset()

            return Promise.resolve()
        })            
    }

    const onDismiss = () => {
        setIsProcssing(false)
        reset()
        if (props.dismissDialog)
            props.dismissDialog()
    }

    useEffect(() => {

        if (!props.item) { return }

        reset({
            name: props.item.name            
        })

    // eslint-disable-next-line
    }, [props.item])

    return (        
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={props.item ? 'Update Dashboard' : 'Create Dashboard'}
            onDismiss={onDismiss}                                    
            type={PanelType.medium}
            progessMessage={props.item ? 'Updating Dashboard' : 'Registering Dashboard'}
            submitLabel={props.item ? 'Update' : 'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={isValid}
            onSubmit={onSubmit}>
                <p>Please enter the details for the new dashboard:</p>

                <form>
                    <Stack tokens={stackTokens}>                
                        <ControlledTextField label="Name" control={control} name={'name'} rules={{ required: 'A valid name is required'}} disabled={isProcessing} />                                                                     
                    </Stack>                     
                </form>
                
        </PanelWithDefaultFooter>
    )
}
