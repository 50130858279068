import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { IDiscountItem } from "./UnControlledDiscountItem";
import { UnControlledDiscountItemList } from "./UnControlledDiscountItemList";

export interface IControlledDiscountItemListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {    
}

export const ControlledDiscountItemList = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IControlledDiscountItemListProps) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
            <UnControlledDiscountItemList 
                {...props}
                items={value || []} 
                onDiscountItemsChanged={(items: IDiscountItem[]) => {                    
                    onChange(items)
                }} />                    
        )}
    />
  );
};
