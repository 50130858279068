import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { FormEvent, useState } from "react";
import { ISdkReportDefinition } from "../../../sdk/models/ISdkReportDefintion";
import { SdkCustomerReportClient } from "../../../sdk/SdkCustomerReportClient";
import { useEffectAsync } from "../../Helpers/EffectAsyncHook";

export interface IDashboardGridWidgetSelectionAvailableReportDefinitionsDropdownProps {
    tenantId: string,
    dashboardId: string,    
    selectedReportDefinition?: ISdkReportDefinition
    onSelected: (reportDefinition: ISdkReportDefinition) => void
    onDataLoaded?: (reportDefinitions: ISdkReportDefinition[]) => void
    disabled?: boolean
    reportCollection: 'serviceProviderCollection' | 'endCustomerCollection'   
}

export const DashboardGridWidgetSelectionAvailableReportDefinitionsDropdown = (props: IDashboardGridWidgetSelectionAvailableReportDefinitionsDropdownProps) => {

    // inject the authentication context
    const auth = useAuthInContext(props.tenantId);           
         
    // available datasources
    const [availableReportDefinitions, setAvailableReportDefinitions] = useState<ISdkReportDefinition[]>()
    
     useEffectAsync(async () => {

        // generate the report definitions
        const sdkReportDefinitionClient = new SdkCustomerReportClient(props.tenantId, props.reportCollection === 'serviceProviderCollection' ? 'serviceProviderCollection' : undefined, auth.currentToken as string);
        const sdkReportDefinitions = await sdkReportDefinitionClient.getReportDefinitions()        

        // filter out all abstract report definitions
        const nonAbstractReportDefinitions = sdkReportDefinitions.filter(d => d.abstract === false)

        // set the result as available 
        setAvailableReportDefinitions(nonAbstractReportDefinitions)             

        // notify that all is loaded
        props.onDataLoaded && props.onDataLoaded(nonAbstractReportDefinitions)
        
    // eslint-disable-next-line
    }, [])
          
    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
    
    const onChangeDatasource = (event: FormEvent<HTMLDivElement>, item?: IDropdownOption<ISdkReportDefinition>): void => {    
        props.onSelected(item?.data  as ISdkReportDefinition)
    };

    return (
        <Dropdown
            label="Report-Definition"
            selectedKey={props.selectedReportDefinition ? props.selectedReportDefinition.id : undefined}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onChangeDatasource}
            placeholder="Select an report definition"
            options={availableReportDefinitions ? availableReportDefinitions.map(d => { return { key: d.id, text: d.name, data: d} }) : []}
            styles={dropdownStyles}
            disabled={props.disabled}
        />
    )
}