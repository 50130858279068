import { PanelType } from "@fluentui/react"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useState } from "react"
import { IColumnsBuilder } from "../components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PanelWithDefaultFooter } from "../components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../ds/components/AppWindow"
import { ISdkRole } from "../sdk/models/ISdkRole"
import { SdkTenantRoleClient } from "../sdk/SdkTenantRoleClient"

export interface ISdkRoleSelectorControlProps {
    tenantId: string
    currentToken: string

    onSelectionChanged?: (roles: ISdkRole[]) => void
}

export const SdkRoleSelectorControl = (props: ISdkRoleSelectorControlProps) => {
    
    const buildColumns = (builder: IColumnsBuilder<ISdkRole>): void  => 
    {                
        builder.TextColumn({name: "Role", maxWidth: 250, value: 'roleIdentifier'})                        
        builder.TextColumn({name: "Description", maxWidth: 250, value: 'roleDescription'})    
    }

    const reloadData = (): Promise<ISdkRole[]> => {
        const client = new SdkTenantRoleClient(props.tenantId, props.currentToken)
        return client.loadRoles()        
    }

    return (
        <NavigationalDetailsListWithStandardOperations<ISdkRole> uniqueIdentifier='rolesroot' onBuildColumns={buildColumns} onLoadData={reloadData} onSelectedItemsChanged={props.onSelectionChanged}/>
    )
}


export interface ISdkRoleSelectorControlPanelProps extends IAppWindowBaseProps {
    tenantId: string
    userId: string
    resourceIdentifier: string

    onSelectedRoles: (roles: ISdkRole[]) => Promise<void>
}

export const SdkRoleSelectorControlPanel = (props: ISdkRoleSelectorControlPanelProps) => {
        
    const auth = useAuthInContext(props.tenantId as string)        

    const [selectedRoles, setSelectedRoles] = useState<ISdkRole[]>([])
    const [isValid, setIsValid] = useState<boolean>(false)

    const changedSelectedRoles = (roles: ISdkRole[]) => {
        setSelectedRoles(roles)        
        setIsValid(roles && roles.length > 0)
    }

    const onSubmit = (): Promise<void> => {
        props.onSelectedRoles(selectedRoles);
        return Promise.resolve();
    }    

    return (        
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Select roles to assign'}
            onDismiss={props.dismissDialog}                                    
            type={PanelType.large}
            progessMessage={'Assigning role to user...'}
            submitLabel={'Assign'}
            dissmissLabel={'Cancel'}
            isValid={isValid}
            onSubmit={onSubmit}>
                <p>Please select one or more roles to assign from the list below:</p>
                <SdkRoleSelectorControl tenantId={props.tenantId} currentToken={auth.currentToken as string} onSelectionChanged={changedSelectedRoles} />
        </PanelWithDefaultFooter>
    
    )
}