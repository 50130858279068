import { PanelType, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useForm } from "react-hook-form";
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ControlledTextField } from "../lib/forms/ControlledTextField";
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient";
import { ClipboardTextField } from "../lib/forms/ClipBoardTextField";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { ISdkCustomerDetails } from "../lib/sdk/models/ISdkCustomer";
import { useState } from "react";
import { SdkCustomerCustomPropertyClient } from "../lib/sdk/SdkCustomerCustomPropertyClient";
import { ISdkObjectFromTemplateFields, ISdkObjectTemplate } from "../lib/sdk/models/ISdkObjectTemplate";
import { buildViewModelFromFieldValues, extractFieldValues, renderFieldItem, sortTemplate } from "../lib/sdkdatacomponents/SdkTemplateBasedObjectHelpers";

export interface IEditCustomerDialogProps extends IAppWindowBaseProps {   
     tenantId: string
     parent?: string
     itemId?: string
}

export const EditCustomerDialog = (props: IEditCustomerDialogProps) => {
        
    const auth = useAuthInContext(props.tenantId);           
        
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        
    const [isSubmitProcessing, { setTrue: startSubmitProccesing, setFalse: stopSubmitProcessing}] = useBoolean(false)
    const [loadedCustomer, setLoadedCustomer] = useState<ISdkCustomerDetails>()
    const [loadedTemplates, setLoadedTemplates] = useState<ISdkObjectTemplate[]>([])

    type EditCustomerForm = {
        customerName: string        
        primaryCurrency: string
    };
    
    useEffectAsync(async () => {
        
        // do nothing when no item set
        if (!props.itemId) { return }

        // set the loading mode
        setIsPreparing(true)

        // load the customer model including details
        const sdkClient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        const customer = await sdkClient.getCustomer(props.itemId)
        setLoadedCustomer(customer)

        // load the custom property templates
        const sdkCustomPropertyClient =new SdkCustomerCustomPropertyClient(props.tenantId, customer.id, auth.currentToken as string)
        const templates = await sdkCustomPropertyClient.listCustomPropertyTemplates()
        setLoadedTemplates(templates ?? [])
        
        // generate the model which is used
        let model: EditCustomerForm = {
            customerName: customer.name,    
            primaryCurrency: customer.primaryCurrency        
        }

        if (templates && templates.length > 0) {
            
            // load the custom property values 
            const customPropertyValues = await sdkCustomPropertyClient.readCustomPropertyValues()

            // combine the template fields in one 
            const selectedTemplate: ISdkObjectTemplate = {...templates[0], fields: []}
            templates.forEach(t => {
                t.fields.forEach(f => {
                    selectedTemplate.fields.push(f)
                })
            })

            // inject all field values
            const viewItem = buildViewModelFromFieldValues(customPropertyValues, selectedTemplate, {}, model as any)

            // set the viewItem as model 
            model = viewItem        
        }
        
        // reset the form values
        reset(model)

        // disable the loading mode
        setIsPreparing(false)

    }, [props.itemId])

    const { handleSubmit, control, reset, formState } = useForm<EditCustomerForm>({
        defaultValues: {            
            customerName: '',
            primaryCurrency: 'EUR'           
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });    

    const dissmissDialog = () => {
        reset()        
        if (props.dismissDialog)
            props.dismissDialog()
    }    

    const onSubmit = (): Promise<string | void> => {

        startSubmitProccesing()

        var submitHandler = handleSubmit((data: EditCustomerForm) => {            
            const sdkCLient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
            return sdkCLient.updateCustomer(loadedCustomer?.id as string, data.customerName, data.primaryCurrency).then(() => {
                
                // build the field values of custom properties
                const fieldValues: ISdkObjectFromTemplateFields = {} 
            
                // visit every template and collect the field values
                loadedTemplates.forEach(t => {
                    const identifiedFieldValues = extractFieldValues(data, t)          
                    Object.keys(identifiedFieldValues).forEach(key => {
                        fieldValues[key] = identifiedFieldValues[key]
                    })
                })

                // write the custom properties back
                const sdkCustomPropertyClient =new SdkCustomerCustomPropertyClient(props.tenantId, loadedCustomer!.id, auth.currentToken as string)
                return sdkCustomPropertyClient.writeCustomPropertyValues(fieldValues)
            })
        })
        
        return submitHandler()
            .finally(() => {
                stopSubmitProcessing()
            })
            .then(() => {
                dissmissDialog()
            })
            
    }
      
    const isCustomerInPartnerChannel =  loadedCustomer && loadedCustomer.fieldValues && loadedCustomer.fieldValues['PartnerChannelOriginId'] && loadedCustomer.fieldValues['PartnerChannelOriginName'] ? true : false

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}      
            type={PanelType.medium}      
            headerText={'Edit customer'}
            onDismiss={dissmissDialog}                                                
            progessMessage={'Updating customer...'}
            submitLabel={'Update'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid}
            onSubmit={onSubmit}
            errorMessagePrefix={'Something went wrong updating the customer, please try again later'}>

                { isPreparing ? (
                    <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                ) : (
                    <div>
                        { isCustomerInPartnerChannel && (                            
                            <div>
                                <p>The customer is a managed customer by the responsible tier 1 service provider. The customer id and the original customer name are visualized for 
                                    conviniency reasons. The values are readonly and can't be changed. Whenever the tier 1 service provider is asking for the customer id or name
                                    reflect these values.</p>
                                <Stack tokens={{ childrenGap: 15 }}>                            
                                    <ClipboardTextField value={loadedCustomer?.fieldValues['PartnerChannelOriginId']} name={'pcorgid'} label={'Tier 1 Customer Id'} readOnly={true} />                        
                                    <ClipboardTextField value={loadedCustomer?.fieldValues['PartnerChannelOriginName']} name={'pcorgname'} label={'Tier 1 Customer Name'} readOnly={true} />                        
                                </Stack>
                            </div>
                        )}

                        { loadedCustomer && loadedCustomer.fieldValues && Object.keys(loadedCustomer.fieldValues).find(f => f.startsWith('ExternalCustomerId.')) &&(
                            <div>
                                <p>The customer contains managed data sources linked to a data integration. Every data integration is reflecting a more complex system, e.g. the 
                                    Microsoft Partner Center for Azure Customers. The external customer id below reflects the id used in the connected system. The value is readonly and can't be changed.</p>
                                    <Stack tokens={{ childrenGap: 15 }}>                            
                                        { Object.keys(loadedCustomer.fieldValues).filter(f => f.startsWith('ExternalCustomerId.')).map((key, index) => (
                                            <ClipboardTextField value={loadedCustomer?.fieldValues[key]} name={key} label={'External Customer Id in data integration ' + key.split('.')[1]} readOnly={true} />                        
                                        ))}
                                    </Stack>
                            </div>
                        )}
                        
                        <p>The list below contains all relevant properties associated with the customer. Some of the fields are readonly and can't be changed. All other fields 
                            can be adjusted as needed.</p>                 

                        <form>
                            <Stack tokens={{ childrenGap: 15 }}>                                                            
                                <ClipboardTextField value={loadedCustomer?.id} name={'id'} label={'Id'} readOnly={true} />                                                                                        
                                <ControlledTextField label="Customer Name" control={control} name={'customerName'} rules={{ required: 'A valid name of the customer is required'}} disabled={isSubmitProcessing} />
                                <ControlledTextField label="Currency" control={control} name={'primaryCurrency'} rules={{ required: 'A valid name of the customer is required'}} disabled={isCustomerInPartnerChannel || isSubmitProcessing} />
                            </Stack>                     
                        </form>

                        { loadedTemplates && loadedTemplates.length > 0 && (
                            <>
                                <p>There are custom properties defined for this customer, please fill out the customer properties to ensure the customer is configured correctly</p>
                                
                                { sortTemplate(loadedTemplates).map(t => (
                                    <div>
                                        <h3>Category: {t.name}</h3>
                                        { t.description && <p>{t.description}</p> }

                                        { t.fields.map(f => (
                                            renderFieldItem(f, control, isSubmitProcessing, {}, () => false)
                                        ))}
                                        
                                    </div>
                                ))}
                            </>
                        )}
                    </div>)}
        </PanelWithDefaultFooter>
    )
}