import { Icon, Spinner, SpinnerSize } from "@fluentui/react"
import { PageContent } from "../lib/ds/components/PageContent"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { ClipboardTextField } from "../lib/forms/ClipBoardTextField"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { useEffect, useState } from "react"
import { SdkTenantBillyClient } from "../lib/sdk/SdkTenantBillyClient"
import { ISdkBillyState } from "../lib/sdk/models/ISdkBillyState"
import { useParams } from "react-router-dom"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { AnimatedSubmitButton } from "../lib/forms/AnimatedSubmitButton"

export interface ISettingsBillyConnectionManagementProps {
}

export const SettingsBillyConnectionManagement = (props: ISettingsBillyConnectionManagementProps) => {

    // get the current tenant state
    const { tenantId } = useParams()         
        
    // inject the auth 
    const auth = useAuthInContext(tenantId as string);

    // define the state 
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [connectionState, setConnectionState] = useState<ISdkBillyState>()    
    const [isRequesting, setIsRequesting] = useState<boolean>(false)
    
    const [requestCounter, setRequestCounter] = useState(0);

    useEffectAsync(async () => {        
        const sdk = new SdkTenantBillyClient(tenantId as string, auth.currentToken as string)
        const state = await sdk.getState(undefined)
        setConnectionState(state);           
        setIsLoading(false)
    }, [])
    
    
    useEffect(() => {
        if (connectionState && connectionState.connectionState === 'pending') {
        
            const timer = setTimeout(() => {
                const counter = requestCounter + 1;
                setRequestCounter(counter);

                if (counter % 5 === 0) {
                    const sdk = new SdkTenantBillyClient(tenantId as string, auth.currentToken as string)
                    sdk.getState(undefined).then(state=> {
                        setConnectionState(state);           
                    })                    
                }
            }, 1000);

            return () => clearTimeout(timer);
        }
    
    // eslint-disable-next-line 
    }, [requestCounter, connectionState]);

    const requestConnectionCode = () => {
        
        // start the processing
        setIsRequesting(true)        

        // request the connection code 
        const sdk = new SdkTenantBillyClient(tenantId as string, auth.currentToken as string)
        sdk.getState('init').then(state => {
            setConnectionState(state)            
        })        
    }

    const sendTestNotification = () => {

        // start the processing
        setIsRequesting(true)        

        const sdk = new SdkTenantBillyClient(tenantId as string, auth.currentToken as string)
        sdk.sendNotification('Test Notification', 'This is a test notification to demonstrate the capabilities of Billy', 'failed').then(() => {
            setIsRequesting(false)
        })
    }

    const renderConnectionSteps = () => {
        return (
            <PageContent>
                <div>
                    <h4>Step 1: Activate Billy in Microsoft Teams</h4>
                    <p>
                        To activate the bot in Microsoft Teams, you can follow the instructions provided in 
                        the documentation below <a href="/">here.</a>
                    </p>                    
                </div>
                <div>
                    <h4>Step 2: Connect your account with Billy</h4>
                    <p>
                        As soon you activated Billy in Microsoft Teams, you can connect your account. Billy should 
                        ask you for an activation account. Please request a connection code and provide the generated 
                        one to activate your account:
                    </p>                    
                    {connectionState && connectionState.pendingCode ? (
                        <div style={{maxWidth: '80%', marginTop: '20px'}}>                        
                            <ClipboardTextField value={'/connect:' + connectionState.pendingCode} />
                        </div>
                    ) : (                        
                        <AnimatedSubmitButton 
                            style={{width: '250px'}} 
                            title="Request connection code" 
                            onClick={requestConnectionCode} 
                            processing={isRequesting}
                            processingText="Requesting connection code..."/>                    
                    )}                                        
                </div>
                <div>
                    <h4>Step 3: Wait for confirmation</h4>
                    <p> 
                        Billy will confirm the connection as soon the connection code was entered. Wait until the 
                        confirmation message is received here.
                    </p>

                    <p>
                        <strong>State: </strong>
                        connection is {connectionState?.connectionState} (checks every 5 seconds)
                    </p>
                </div>
            </PageContent>  
        )
    } 

    const renderConnectedBot = () => {
        return (
            <PageContent>
                <div>
                    <Icon iconName="faCheck" style={{color: 'green'}}></Icon>&nbsp;The connection to Billy is currently vital and you will receive notifications throug
                    Microsoft Teams. 
                </div>  
                <div>
                    <AnimatedSubmitButton 
                            style={{width: '250px', marginTop: '20px'}} 
                            title="Send test notification" 
                            onClick={sendTestNotification} 
                            processing={isRequesting}
                            processingText="Sending notification..."/>                                     
                </div>
            </PageContent>            
        )
    }

    const renderPageContent = () => { 

        if (connectionState && connectionState.connectionState !== 'connected') {
            return renderConnectionSteps()
        } else {
            return renderConnectedBot()
        }
    }

    if (isLoading) {
        return (
            <Spinner size={SpinnerSize.large} label="Requesting connection state to Billy" labelPosition="bottom" style={{height: '100%'}} />
        )
    } else {
        return (
            <div className="mv-content-container">

                <PageHeader title={'Connection to Billy'}>            
                    <span>
                        Meet Billy, our highly efficient and reliable bot designed specifically for the 
                        purpose of providing timely notifications and facilitating seamless interactions 
                        with our platform.
                    </span>                    
                </PageHeader>

                 {renderPageContent()}
            </div>
        )
    }
}
