import { IAppWindowBaseProps } from "../ds/components/AppWindow";
import { useState } from "react";
import { PanelWithDefaultFooter } from "../components/DetailsListHelper/PanelWithDefaultFooter";
import { IDropdownOption, ILayerProps, PanelType, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { useEffectAsync } from "../components/Helpers/EffectAsyncHook";
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate, ISdkObjectTemplate, ISdkObjectTemplateField } from "../sdk/models/ISdkObjectTemplate";
import { SdkAvailableObjectTemplatesDropdown } from "./SdkAvailableObjectTemplatesDropdown";
import { buildViewModelFromFieldValues, buildViewModelFromOptionalFieldValues, extractFieldValues, extractOptionalFieldValues, renderTemplate } from "./SdkTemplateBasedObjectHelpers";
import { SdkDependentOnWatcherControl } from "./SdkDependentOnWatcherControl";

export interface ISdkAddOrEditTemplateBasedObjectPanelProps extends IAppWindowBaseProps {   
    tenantId: string    
    itemId?: string

    objectTitle: string,
    objectDescription: string,
    objectDescriptionHidden?: boolean,

    templateSelectorDisabled?: boolean
    templateSelectorLabel: string
    templateSelectorPlaceholder: string

    optionalFields?: ISdkObjectTemplateField[]
    
    onLoadTemplates: () => Promise<ISdkObjectTemplate[]>
    onCreate: (data: ISdkObjectFromTemplateCreate) => Promise<any | void>
    onUpdate?: (data: ISdkObjectFromTemplate) => Promise<any | void>
    onLoad: (itemId: string) => Promise<ISdkObjectFromTemplate | undefined>
    onLoadObjectList?: (fieldDefinition: ISdkObjectTemplateField) => Promise<IDropdownOption[]>

    layerProps?: ILayerProps;
}

export const SdkAddOrEditTemplateBasedObjectPanel = (props: ISdkAddOrEditTemplateBasedObjectPanelProps) => {
    
    const [isProcessing, setIsProcssing] = useState<boolean>(false)    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        
    
    // handle template selction  
    const [availableTemplates, setAvailableTemplates] = useState<ISdkObjectTemplate[]>([])   
    const [selectedTemplate, setSelectedTemplate] = useState<ISdkObjectTemplate | undefined>(undefined)    
    const [availableObjectLists, setAvailableObjectLists] = useState<{ [key: string]: IDropdownOption[] }>({})

    const dissmissDialog = () => {
        setIsProcssing(false)
        reset()        
        setSelectedTemplate(undefined)
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const { handleSubmit, control, reset, formState, watch } = useForm<any>({        
        reValidateMode: "onSubmit",
        mode: "all"
    });

    const onSubmit = (): Promise<string | void> => {   
        
        setIsProcssing(true)

        // check if we have a selected template 
        if (!selectedTemplate) {
            return Promise.reject('No template was selected')
        }
        
        var submitHandler = handleSubmit((data: any) => {            
            
            // build the field values 
            const fieldValues: { [key: string]: any } = extractFieldValues(data, selectedTemplate)          
            const optionalFieldValues: { [key: string]: any } = extractOptionalFieldValues(data, props.optionalFields)         
                        
            // build the model 
            const model: ISdkObjectFromTemplateCreate = {
                type: selectedTemplate?.type as string,
                name: data.name,
                comment: data.comment,
                fieldValues: fieldValues,
                optionalFieldValues: optionalFieldValues               
            }
                    
            if (props.itemId && props.onUpdate) {
                return props.onUpdate({...model, id: props.itemId as string})                
            } else {
                return props.onCreate(model)
            }
        })

        return submitHandler()
            .finally(() => {
                setIsProcssing(false)
            })
            .then(() => {
                dissmissDialog()
            })
            .catch((error) => {  
                let errorMessage = 'Something went wrong, please try again later'
                errorMessage += error.code ?  ' (' + error.code + ')' : ''
                return errorMessage
            })          
    }

    const onSelectedTemplate = (template: ISdkObjectTemplate) => setSelectedTemplate(template)                    

    const injectAvailableObjectList = async (template: ISdkObjectTemplate) => {
        // load all the object lists to use them 
        const localAvailableObjectLists: { [key: string]: IDropdownOption[] } = {}

        if (template.fields) {
            for (const field of template.fields) {                            
                if ((field.type === 'list<object>' || field.type === 'object') && props.onLoadObjectList) {
                    const objectList = await props.onLoadObjectList(field)
                    localAvailableObjectLists[field.id] = objectList
                }
            }
        }

        if (props.optionalFields) {
            for(const field of props.optionalFields) {             
                if ((field.type === 'list<object>' || field.type === 'object') && props.onLoadObjectList) {
                    const objectList = await props.onLoadObjectList(field)
                    localAvailableObjectLists[field.id] = objectList
                }
            }
        }
        
        setAvailableObjectLists(localAvailableObjectLists)
        return localAvailableObjectLists
    }

    useEffectAsync( async () => {

        // in the case we load an item do not reset it after template selection
        if (!props.isVisible) { return }     
        if (props.itemId) {  return }
        if (!selectedTemplate) { return }

        var defaultValues: any = {}
    
        if (selectedTemplate?.fields) {
            for (const field of selectedTemplate?.fields) {                            
                if (field.default) {
                    defaultValues[field.id] = field.default            
                }                
            }
        }

        props.optionalFields?.forEach((field) => {
            if (field.default) {
                defaultValues[field.id] = field.default
            }
        })        
        
        reset(defaultValues)      
        
        await injectAvailableObjectList(selectedTemplate)       

    // eslint-disable-next-line 
    }, [selectedTemplate])

    // load data when opening the dialog
    useEffectAsync(async () => {

        // do nothing when we are not visible 
        if (!props.isVisible) { return }

        // enter the preparing state
        setIsPreparing(true)

        // load all templates and set them in the UI
        const templates = await props.onLoadTemplates()
        setAvailableTemplates([...templates])     
        
        // leave the preparing state when no items needs to be loaded
        if (!props.itemId) { 
            reset({})            
            setIsPreparing(false)  
            return 
        }        

        // load the item
        var item = await props.onLoad(props.itemId as string)
        if (!item) { return }
        
        // set the right template 
        const template = templates.find(t => t.type === item?.type)
        setSelectedTemplate(template)

        // ensure we have a template
        if (!template) { return }

        // inject the available object lists
        const localAvailableObjectLists = await injectAvailableObjectList(template)       
        
        // generate the view model
        let viewItem: any = {
            name: item.name,
            comment: item.comment              
        }

        // inject all field values
        viewItem = buildViewModelFromFieldValues(item.fieldValues, template, localAvailableObjectLists, viewItem)

        // inject the optional field values
        if (item.optionalFieldValues) {
            viewItem = buildViewModelFromOptionalFieldValues(item.optionalFieldValues, viewItem)            
        }        

        reset(viewItem)

        setIsPreparing(false)

    }, [props.isVisible, props.itemId])       
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={(props.itemId ? 'Update ' : 'Add ') + props.objectTitle}
            onDismiss={dissmissDialog}                                    
            type={PanelType.medium}
            progessMessage={(props.itemId ? 'Updating existing ' : 'Creating new ') + props.objectTitle}
            submitLabel={props.itemId ? 'Update' : selectedTemplate && selectedTemplate.submitTitle ? selectedTemplate.submitTitle : 'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid && formState.isDirty}            
            onSubmit={onSubmit}
            layerProps={props.layerProps}>
                
                { isPreparing ? (
                    <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                ) : (
                    <div>
                        <p>{props.objectDescription}</p> 

                        <Stack tokens={{ childrenGap: 15 }}>   
                            <Stack.Item>                            
                                <SdkAvailableObjectTemplatesDropdown tenantId={props.tenantId} availableTemplates={availableTemplates} onSelected={onSelectedTemplate} selectedTemplate={selectedTemplate} label={props.templateSelectorLabel} placeholder={props.templateSelectorPlaceholder} disabled={props.templateSelectorDisabled}/>
                            </Stack.Item>  

                            { selectedTemplate && (
                            <Stack.Item>
                                <SdkDependentOnWatcherControl template={selectedTemplate!} watch={watch}>
                                    { (isControlDisabledByDependency) => renderTemplate(control, isProcessing, props.objectDescriptionHidden ? true : false, availableObjectLists, selectedTemplate!, isControlDisabledByDependency, props.optionalFields) }
                                </SdkDependentOnWatcherControl>                                
                            </Stack.Item>           
                            )}

                            { selectedTemplate && selectedTemplate.type === 'custom.azureblob' && (
                            <Stack.Item>                     
                                <h3>How to upload data to a custom datasource?</h3>
                                <p>A custom datasource allows to upload a csv file for every period containing the 
                                    consumption data. The following step-by-step guide navigates through the process.
                                    Cominig back to this guide is always possible at any time by just clicking at the datasource.</p>
                                <ol>
                                    <li><strong>Generate a CSV file with the required data following this reference documentation.</strong><br/><br/>
                                        <a href="https://help.mevodo.com/support/solutions/articles/103000285035" rel="noreferrer" target="_blank">Custom Datasource Consumption File Format</a>
                                        <br/><br/>
                                    </li>
                                    <li><strong>Setup the Powershell SDK as describe in this article.</strong><br/><br/>
                                        <a href="https://help.mevodo.com/support/solutions/articles/103000259749-getting-started-with-powershell-core" rel="noreferrer" target="_blank">Getting Started with the Powershell SDK</a>
                                        <br/><br/>
                                    </li>
                                    <li><strong>Ensure an integration application is registered as described here.</strong><br/><br/>
                                        <a href="https://help.mevodo.com/support/solutions/articles/103000063437-register-an-application-for-integration" rel="noreferrer" target="_blank">Register an application for integration</a>
                                        <br/><br/>
                                    </li>
                                    <li><strong>Upload custom data as described in the corresponding scenario.</strong><br/><br/>
                                        <a href="https://help.mevodo.com/support/solutions/articles/103000285037-upload-consumption-data-into-a-custom-datasource-powershell-" rel="noreferrer" target="_blank">Upload consumption data via Powershell SDK</a>
                                        <br/><br/>
                                    </li>
                                </ol>    
                                <p>Should the system not accept the CSV consumption data file, the referenced sample file from the documentation should be used the ensure the whole workflow is functional!</p>

                            </Stack.Item>)}
                        </Stack>                                     
                    </div>
                )}
        </PanelWithDefaultFooter>
    )
}