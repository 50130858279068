import { IDropdownOption, Label } from "@fluentui/react"
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form"
import { UnControlledListFormsContainer } from "./UnControlledListFormsContainer"
import { ControlledTextField } from "./ControlledTextField"
import { ControlledDropdown } from "./ControlledDropdown"
import { makeStyles } from "@fluentui/react-components"
import uuid from "react-uuid"

const makeFieldFormatMappingListDataEditorStyle = makeStyles({
    root: {        
        display: 'flex',
        flexDirection: 'row',                
        width: '100%',        
        columnGap: '10px'
    },
    elements: {
        width: '100%'
    },
    property: {
        minWidth: '180px'
    }            
});

export interface IControlledFieldFormatMappingListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {    
    availableFields: IDropdownOption[]            
    onItemsPending?: (pending: boolean) => void
}
  
export const ControlledFieldFormatMappingList = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IControlledFieldFormatMappingListProps) => {

    const editorStyles = makeFieldFormatMappingListDataEditorStyle()

    return (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={props.defaultValue || undefined}
          render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
          }) => {
                            
            const onAddProperty = (data: any) => {
                
                // set a valud id
                data.id = uuid()   
                                
                // set the name if needed
                if (!data.name || data.name === '') {
                    const selectedField = props.availableFields.find(f => f.key === data.property)
                    data.name = selectedField?.text || ''
                }

                // update the collection
                const extendedList = [...(value || []), data]
                onChange(extendedList)
                return Promise.resolve()
            }
        
            const onDeleteProperty = (data: any) => {
                const reducedList = (value || []).filter((p: { id: any }) => p.id !== data.id)
                onChange(reducedList)
                return Promise.resolve()
            }
        
            const onUpdateProperty = (data: any) => {

                // set the name if needed
                if (!data.name || data.name === '') {
                    const selectedField = props.availableFields.find(f => f.key === data.property)
                    data.name = selectedField?.text || ''
                }

                // update the collection
                const updatedList = (value || []).map((p: { id: any }) => p.id === data.id ? data : p)
                onChange(updatedList)
                return Promise.resolve()
            }
            
            return (
                <>
                    <Label key={props.id} style={{marginTop: '10px'}} disabled={props.disabled}>{props.label}</Label>                                                
                    <UnControlledListFormsContainer disabled={props.disabled} items={value || []} itemDefaultValues={{property: '', name: '', format: '', id: ''}} onAddItem={onAddProperty} onDeleteItem={onDeleteProperty} onUpdateItem={onUpdateProperty} renderEditor={(controlEditor) => (
                        <div className={editorStyles.root}>
                            <ControlledDropdown className={editorStyles.property} key={'property'} control={controlEditor} name={'property'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} options={props.availableFields} />                        
                            <ControlledTextField className={editorStyles.elements} key={'name'} control={controlEditor} name={'name'} placeholder={props.disabled ? '' : 'optional name'} disabled={props.disabled} />
                            <ControlledTextField className={editorStyles.elements} key={'format'} control={controlEditor} name={'format'} placeholder={props.disabled ? '' : 'format | #currency'} disabled={props.disabled} />                            
                        </div>            
                    )} />
                </>  
            )}
          } 
        />
      );
}  


     