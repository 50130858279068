import { useBoolean } from "@fluentui/react-hooks"
import { useState } from "react"
import { IColumnsBuilder } from "../components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ISdkRole } from "../sdk/models/ISdkRole"
import { ISdkRoleAssignment, ISdkRoleAssignmentEx } from "../sdk/models/ISdkRoleAssignment"
import { ISdkUserProfile } from "../sdk/models/ISdkUserProfile"
import { SdkTenantResourceProfileClient } from "../sdk/SdkTenantResourceClient"
import { SdkTenantUserProfileClient } from "../sdk/SdkTenantUserProfileClient"
import { SdkRoleSelectorControlPanel } from "./SdkRoleSelectorControl"
import { SdkUserSelectorControlPanel } from "./SdkUserSelectorControl"

export interface ISdkRoleAssignmentsControlProps {
    tenantId: string    
    currentToken: string    
    contextValue:  string
    contextDisplayName: string
    contextDimension: 'user' | 'resource'
}

export const SdkRoleAssignmentsControl = (props: ISdkRoleAssignmentsControlProps) => {
    
    let resourceIdentifier: string = 'mevodo.cloud.service.accounts/' + props.tenantId + '/tenant'
    if (props.contextDimension === 'resource') 
        resourceIdentifier = props.contextValue as string
        
    const [isSelectRolesOpen, { setTrue: showSelectedRoles, setFalse: hideSelectedRoles}] = useBoolean(false)
    const [isSelectUsersOpen, { setTrue: showSelectedUsers, setFalse: hideSelectedUsers}] = useBoolean(false)
    
    const [isRefreshRequested, { toggle: requestRefresh}] = useBoolean(false)

    const [selectedUsers, setSelectedUsers] = useState<ISdkUserProfile[]>([])
    
    const buildColumns = (builder: IColumnsBuilder<ISdkRoleAssignment>): void  => 
    {        
        builder.TextColumn({name: "Name", maxWidth: 250,  value: (item: ISdkRoleAssignment) => (item as ISdkRoleAssignmentEx).userDisplayName, isColumnHidden: () => props.contextDimension === 'user' })        
        builder.TextColumn({name: "Id", maxWidth: 250,  value: 'userId', isColumnHidden: () => props.contextDimension === 'user' })        
        builder.TextColumn({name: "Role", maxWidth: 250, value: 'roleIdentifier'})                                
        builder.TextColumn({name: "Resource", maxWidth: 250, value: 'resourceContext', isColumnHidden: () => props.contextDimension === 'resource'})    
    }
    
    const reloadData = (): Promise<ISdkRoleAssignment[]> => {

        if (props.contextDimension === 'user') {
            const client = new SdkTenantUserProfileClient(props.tenantId, props.currentToken as string)
            return client.loadRoleAssignments(props.contextValue)
        } else {
            const client = new SdkTenantResourceProfileClient(props.tenantId, props.currentToken as string)
            return client.loadRoleAssignments(props.contextValue)
        }
    }

    const onAdd = (): Promise<void> => {        
        if (props.contextDimension === 'user') {
            showSelectedRoles()
            return Promise.resolve();
        } else {
            showSelectedUsers();
            return Promise.resolve();
        }
    }

    const onDismissRolesPanel = () => {
        hideSelectedRoles()        
        requestRefresh();
    }

    const onDismissUsersPanel = () => {
        hideSelectedUsers()
        requestRefresh();
    }

    const onDeleteItems = (items: ISdkRoleAssignment[]): Promise<void> => {
        const client = new SdkTenantUserProfileClient(props.tenantId, props.currentToken as string)
        return Promise.all(items.map((roleAssignment) => client.deleteRoleAssignment(roleAssignment.userId, roleAssignment.roleIdentifier, roleAssignment.resourceContext))).then(() => Promise.resolve()).catch(() => Promise.reject())         
    }

    const onSelectedUsers = (users: ISdkUserProfile[]): Promise<void> => {  
        setSelectedUsers(users)
        showSelectedRoles();
        return Promise.resolve();
    }

    const onSelectedRoles = (roles: ISdkRole[]): Promise<void> => {  

        if (props.contextDimension === 'user') {
            const client = new SdkTenantUserProfileClient(props.tenantId, props.currentToken as string)
            return Promise.all(roles.map((role) => client.addRoleAssignment(props.contextValue, role.roleIdentifier, resourceIdentifier))).then(() => Promise.resolve()).catch(() => Promise.reject())        
        } else {            
            const client = new SdkTenantUserProfileClient(props.tenantId, props.currentToken as string)

            let promisses: Promise<Boolean>[] = []

            selectedUsers.forEach((user) =>{
                roles.forEach((role) => {                    
                    promisses.push(client.addRoleAssignment(user.userId, role.roleIdentifier, resourceIdentifier))
                })
            })

            return Promise.all(promisses).then(() => Promise.resolve()).catch(() => Promise.reject())                        
        }        
    }    
    
    return (        
        <>
            <NavigationalDetailsListWithStandardOperations<ISdkRoleAssignment> uniqueIdentifier='assignmentsroot' refresh={isRefreshRequested}  onBuildColumns={buildColumns} onLoadData={reloadData} onNewItem={onAdd} onDeleteItems={onDeleteItems} />
            <SdkRoleSelectorControlPanel isVisible={isSelectRolesOpen} tenantId={props.tenantId} dismissDialog={onDismissRolesPanel} userId={props.contextValue} resourceIdentifier={resourceIdentifier} onSelectedRoles={onSelectedRoles}/>
            <SdkUserSelectorControlPanel isVisible={isSelectUsersOpen} tenantId={props.tenantId} dismissDialog={onDismissUsersPanel} onSelectedUsers={onSelectedUsers} />
        </>
    )
        
}
