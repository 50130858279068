import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useState } from "react"
import uuid from "react-uuid"
import { IColumnsBuilder, ISubCommandDefinition } from "../DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ISdkCustomerDiscountProfile } from "../../sdk/models/ISdkCustomerDiscountProfile"
import { AddOrEditDiscountProfileDialog } from "../../../svp/AddOrEditDiscountProfileDialog"
import { useBoolean } from "@fluentui/react-hooks"
import { useEffectAsync } from "../Helpers/EffectAsyncHook"
import { SdkCustomerReportClient } from "../../sdk/SdkCustomerReportClient"
import { ISdkReportDefinition } from "../../sdk/models/ISdkReportDefintion"
import { SdkCustomerDiscountProfileClient } from "../../sdk/SdkCustomerDiscountProfileClient"

export interface IReportDiscountProfileManagementComponentProps {
    tenantId: string,
    reportCollectionType: "serviceProviderCollection" | undefined
}

export const ReportDiscountProfileManagementComponent = (props: IReportDiscountProfileManagementComponentProps) => {

    const auth = useAuthInContext(props.tenantId)    
    
    const [isRefreshRequested, setRefreshRequested] = useState(false)
    const [isAddDiscountProfileOpen, { setTrue: showAddDiscountProfile, setFalse: hideAddDiscountProfile}] = useBoolean(false)            
    const [itemToEdit, setItemToEdit] = useState<ISdkCustomerDiscountProfile | undefined>(undefined)
    const [availableReportDefinitionTemplates, setAvailableReportDefinitionTemplates] = useState<ISdkReportDefinition[]>([])

    useEffectAsync(async () => {

        // load all report definitions
        const sdkReportDefinitionClient = new SdkCustomerReportClient(props.tenantId, 'serviceProviderCollection', auth.currentToken as string);
        const reportDefinitions = await sdkReportDefinitionClient.getReportDefinitions()
        setAvailableReportDefinitionTemplates(reportDefinitions)
    }, [])

    const columnSubCommandsName: ISubCommandDefinition<ISdkCustomerDiscountProfile>[] =  [                
        { iconName: 'faPen', description: 'Edit the report definition', onClick: (item) => { onEditDiscountProfile(item)  }}        
    ]

    const columnSubCommandsId: ISubCommandDefinition<ISdkCustomerDiscountProfile>[] =  [
        { iconName: 'faClipboard', description: 'Copies content to the clipboard', onClick: (item: ISdkCustomerDiscountProfile) => {navigator.clipboard.writeText(item.id)}}        
    ]
    
    const onEditDiscountProfile = (item: ISdkCustomerDiscountProfile) => {
        setItemToEdit(item)        
        showAddDiscountProfile()
    }

    const onBuildColumns = (builder: IColumnsBuilder<ISdkCustomerDiscountProfile>): void => {
        builder.TextColumn({name: "Name", value: "name", maxWidth: 250, subCommands: columnSubCommandsName})
        builder.TextColumn({name: "Id", value: "id", maxWidth: 150, subCommands: columnSubCommandsId })            
    }

    const onLoadData = async (): Promise<ISdkCustomerDiscountProfile[]> => {
        
        // load the discount profiles
        const sdkDiscountProfileClient = new SdkCustomerDiscountProfileClient(props.tenantId, auth.currentToken as string);
        return await sdkDiscountProfileClient.listDiscountProfiles('reports', 'Reports')        
    }

    const onNewReportDefinition = () => {   
        showAddDiscountProfile()     
    }

    const onDeleteDiscountProfile = async (items: ISdkCustomerDiscountProfile[]) => {
        const sdkDiscountProfileClient = new SdkCustomerDiscountProfileClient(props.tenantId, auth.currentToken as string);

        for (const item of items) {
            await sdkDiscountProfileClient.deleteDiscountProfile(item.id)
        }            
    }
    
    return (    
        <>
            <NavigationalDetailsListWithStandardOperations 
                uniqueIdentifier={'reportsdiscountprofilesroot' + uuid()}            
                onBuildColumns={onBuildColumns}                                
                onLoadData={onLoadData}
                onNewItem={onNewReportDefinition}  
                onDeleteItems={onDeleteDiscountProfile}                
                hideReferesh={false} 
                preventInitialLoad={false}                        
                allowSorting={true}            
                disabledItemSelect={false}
                allowSearching={false}
                refresh={isRefreshRequested} />      

            <AddOrEditDiscountProfileDialog isVisible={isAddDiscountProfileOpen} reportDefinitionOptions={availableReportDefinitionTemplates} dismissDialog={() => { hideAddDiscountProfile(); setItemToEdit(undefined); setRefreshRequested(!isRefreshRequested) }} parent={'reports'} parentType={'reports'} tenantId={props.tenantId} itemId={itemToEdit && isAddDiscountProfileOpen? itemToEdit.id : undefined} />      
        </>
    )
}

