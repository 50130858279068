import { Icon, PrimaryButton, Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "./ControlledTextField";

export interface IPropertyMapItem {   
    key: string 
    left: string
    right: string    
}

export interface IPropertyMapItemProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {    
    item?: IPropertyMapItem

    onUpdateItem: (item: IPropertyMapItem) => void

    onDeleteItem?: (item: IPropertyMapItem) => void

    placeholderLeft?: string
    placeholderRight?: string

    disabled?: boolean    
    direction?: 'horizontal' | 'vertical'
}

export const UnControlledPropertyMapItem = (props: IPropertyMapItemProps) => {

    const { handleSubmit, control, reset, formState } = useForm<IPropertyMapItem>({
        defaultValues: {
            left: '',
            right: ''            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    useEffect(() => {
        reset(props.item ? {...props.item} : {})        
    // eslint-disable-next-line
    }, [props.item])

    // read the form state to initialize the proxy state
    // eslint-disable-next-line
    useEffect(() => {}, [formState.isDirty])
        
    const onProcessItem = () => {

        const submitHandler = handleSubmit((data: IPropertyMapItem) => {

            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();

            // send the callback 
            props.onUpdateItem(data)            

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    const onDeleteFilterItem = () => {

        const submitHandler = handleSubmit((data: IPropertyMapItem) => {

            // esure we have the correct key
            data.key = props.item ? props.item.key : new Date().getTime().toString();

            // send the callback 
            if (props.onDeleteItem) {
                props.onDeleteItem(data) 
            }           

            // reset this form in case we handled a new item
            if (!props.item) {
                reset()
            }
        })

        submitHandler();
    }

    if (props.direction === 'horizontal') {
        return (
            <div>                
                <p><ControlledTextField key={'left'} control={control} name={'left'} placeholder={props.placeholderLeft || 'Enter value'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} /></p>
                <p><ControlledTextField key={'right'} control={control} name={'right'} placeholder={props.placeholderRight || 'Enter value'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} /></p>                
                <PrimaryButton disabled={props.disabled || !formState.isValid || !formState.isDirty} onClick={onProcessItem}>Apply</PrimaryButton>                        
            </div>
        )
    } else {
        return (
            <tr style={{verticalAlign: 'top'}}> 
                <td style={{width: '50%', paddingRight: '5px'}}>
                    <div style={{marginTop: '10px'}}>
                        <ControlledTextField key={'left'} control={control} name={'left'} placeholder={props.placeholderLeft || 'Enter value'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} />
                    </div>
                </td>
                <td style={{width: '50%', paddingRight: '5px'}}>
                    <div style={{marginTop: '10px'}}>
                        <ControlledTextField key={'right'} control={control} name={'right'} placeholder={props.placeholderRight || 'Enter value'} disabled={props.disabled} rules={{ required: 'a valid value is required'}} />
                    </div>
                </td>
                <td>
                    <div style={{marginTop: '10px'}}>
                        <PrimaryButton style={{width: '10px', minWidth: '10px'}} disabled={props.disabled || !formState.isValid || !formState.isDirty} onClick={onProcessItem}>                            
                            <Icon iconName={props.item ? 'faCheck' : 'faPlus'}></Icon>
                        </PrimaryButton>                        
                    </div>    
                </td>
                <td>
                    { (props.item  && props.onDeleteItem) 
                        ? (<Stack.Item grow={0}>
                            <div style={{marginTop: '10px'}}>
                                <PrimaryButton style={{width: '35px', minWidth: '35px'}} disabled={props.disabled} onClick={onDeleteFilterItem}><Icon iconName={'faTrashCan'}></Icon></PrimaryButton>                        
                            </div>    
                        </Stack.Item>)
                        : (<Stack.Item grow={0}>
                            <div style={{marginTop: '10px'}}>
                                <div style={{width: '35px'}}>&nbsp; </div>
                            </div>
                        </Stack.Item>)
                    }    
                </td>
            </tr>
        )    
    }
}