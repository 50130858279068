import { ISdkCustomerDataFilterPolicy, ISdkCustomerDataFilterPolicyCreateRequest } from "./models/ISdkCustomerDataFilterPolicy";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerDataFilterPolicyClient {

    private sdkClient: SdkClient<ISdkCustomerDataFilterPolicy>    
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkCustomerDataFilterPolicy>('/api/v1/tenants/' + tenantId + '/svp/datafilterpolicies', token);
    }

    listDataFilterPolicies(parent?: string, parentType?: string): Promise<ISdkCustomerDataFilterPolicy[]> 
    {        
        const parentId = parent ? parent : 'root'
        const parentTypeValue = parentType ? parentType : 'CustomerGroup'

        return this.sdkClient.list('?parentId=' + parentId + '&parentType=' + parentTypeValue);        
    }    

    listEffectiveDataFilterPolicies(parent?: string, parentType?: string): Promise<ISdkCustomerDataFilterPolicy[]> 
    {        
        const parentId = parent ? parent : 'root'
        const parentTypeValue = parentType ? parentType : 'CustomerGroup'

        return this.sdkClient.list('?parentId=' + parentId + '&parentType=' + parentTypeValue + '&effective=true');        
    }

    createDataFilerPolicy(createRequest: ISdkCustomerDataFilterPolicyCreateRequest): Promise<ISdkCustomerDataFilterPolicy> 
    {                    
        return this.sdkClient.post('', createRequest).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })               
    }

    updateDataFilterPolicy(id: string, createRequest: ISdkCustomerDataFilterPolicyCreateRequest): Promise<ISdkCustomerDataFilterPolicy> 
    {                    
        return this.sdkClient.put('/' + id, createRequest).then(async () => {
            return this.getDataFilterPolicy(id)
        })               
    }

    getDataFilterPolicy(id: string): Promise<ISdkCustomerDataFilterPolicy>
    {
        return this.sdkClient.get('/' + id + '/details')
    }

    deleteDataFilterPolicy(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }    
}

