import { DatePicker, Dropdown, IDropdownOption, Icon, Stack } from "@fluentui/react"
import { FormEvent, useEffect, useMemo, useState } from "react"
import { Badge, makeStyles, mergeClasses } from "@fluentui/react-components"
import { DayOfWeek, DaysOfWeek, ISdkSchedulerDefinition, UnitOfTime } from "../sdk/models/ISdkSchedulerDefinition"

export interface  IControlledSchedulerFieldProps {
    item?: ISdkSchedulerDefinition
    onSchedulerChanged?: (item: ISdkSchedulerDefinition) => void
}

const useStyles = makeStyles({
    controlDatePicker: {
      maxWidth: "300px",
      minWidth: "150px",
    },
    controlWeekDayBadge: {
        marginTop: '10px',
        cursor: 'pointer'      
    },
    controlWeekDayBadgeInActive: {
        backgroundColor: '#A6A6A6'
    },
    controlDropDown: {
        marginTop: '8px',
        marginBottom: '8px'
    }
  });

export const UnControlledSchedulerField = (props: IControlledSchedulerFieldProps) => {

    const [referenceDate, setReferenceDate] = useState<Date | null | undefined>(props.item?.referenceDate || new Date())
    const [interval, setInterval] = useState<number>(props.item?.interval || 1)
    const [unitOfTime, setUnitOfTime] = useState<UnitOfTime>(props.item?.unitOfTime || 'day')
    const [daysOfWeek, setDaysOfWeek] = useState<DaysOfWeek>(props.item?.daysOfWeek || [ 'mo', 'tu', 'we', 'th', 'fr', 'sa', 'su' ])    

    const availableIntervals = useMemo(() => { 
        // return interval 1 to 99
        let intervals = []
        for (let i = 1; i < 100; i++) {
            intervals.push({ key: i, text: i.toString() })
        }

        return intervals
    }, [])

    const availableUnitOfTimes = [
        { key: 'day', text: 'day' },
        { key: 'week', text: 'week' },
        { key: 'month', text: 'month' },
        { key: 'year', text: 'year' }
    ]

    const onBadgeChanged = (badgeId: DayOfWeek) => {
        if (daysOfWeek.includes(badgeId)) {
            setDaysOfWeek(daysOfWeek.filter(x => x !== badgeId))
        } else {
            setDaysOfWeek([...daysOfWeek, badgeId])
        }
    }
    
    useEffect(() => {
        if (props.onSchedulerChanged) {
            props.onSchedulerChanged({ referenceDate: referenceDate as Date, interval: interval, unitOfTime: unitOfTime, daysOfWeek: daysOfWeek })
        }
    
    // eslint-disable-next-line
    }, [referenceDate, interval, unitOfTime, daysOfWeek])
    
    useEffect(() => {

        if (!props.item) { return }        

        if (props.item.referenceDate !== referenceDate) { setReferenceDate(new Date(props.item.referenceDate)) }
        if (props.item.interval !== interval) { setInterval(props.item.interval) }
        if (props.item.unitOfTime !== unitOfTime) { setUnitOfTime(props.item.unitOfTime) }
        if (props.item.daysOfWeek !== daysOfWeek) { setDaysOfWeek(props.item.daysOfWeek) }        
    
    // eslint-disable-next-line
    }, [props.item])

    const classNames = useStyles()

    return (
        <Stack>
            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                <Icon iconName='Calendar' />
                <p>The scheduler starts at </p>
                <DatePicker
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Select date..." 
                    className={classNames.controlDatePicker}
                    value={referenceDate as Date | undefined}
                    onSelectDate={setReferenceDate}
                    />
            </Stack>    
            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                <Icon iconName='Clock' />
                <p>and is executed every</p>
                <Dropdown options={availableIntervals} className={classNames.controlDropDown} selectedKey={interval} onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption): void => { setInterval(item?.key as number) }}/>
                <Dropdown options={availableUnitOfTimes} className={classNames.controlDropDown}  selectedKey={unitOfTime} onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption): void => { setUnitOfTime(item?.key as UnitOfTime) }} />                
            </Stack>
            { unitOfTime === 'week' && (
                <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 10 }}>                
                    <Badge className={daysOfWeek.includes('mo') ? classNames.controlWeekDayBadge : mergeClasses(classNames.controlWeekDayBadge, classNames.controlWeekDayBadgeInActive)} size="extra-large" onClick={() => onBadgeChanged('mo')}>M</Badge>
                    <Badge className={daysOfWeek.includes('tu') ? classNames.controlWeekDayBadge : mergeClasses(classNames.controlWeekDayBadge, classNames.controlWeekDayBadgeInActive)} size="extra-large" onClick={() => onBadgeChanged('tu')}>T</Badge>
                    <Badge className={daysOfWeek.includes('we') ? classNames.controlWeekDayBadge : mergeClasses(classNames.controlWeekDayBadge, classNames.controlWeekDayBadgeInActive)} size="extra-large" onClick={() => onBadgeChanged('we')}>W</Badge>
                    <Badge className={daysOfWeek.includes('th') ? classNames.controlWeekDayBadge : mergeClasses(classNames.controlWeekDayBadge, classNames.controlWeekDayBadgeInActive)} size="extra-large" onClick={() => onBadgeChanged('th')}>T</Badge>
                    <Badge className={daysOfWeek.includes('fr') ? classNames.controlWeekDayBadge : mergeClasses(classNames.controlWeekDayBadge, classNames.controlWeekDayBadgeInActive)} size="extra-large" onClick={() => onBadgeChanged('fr')}>F</Badge>
                    <Badge className={daysOfWeek.includes('sa') ? classNames.controlWeekDayBadge : mergeClasses(classNames.controlWeekDayBadge, classNames.controlWeekDayBadgeInActive)} size="extra-large" onClick={() => onBadgeChanged('sa')}>S</Badge>
                    <Badge className={daysOfWeek.includes('su') ? classNames.controlWeekDayBadge : mergeClasses(classNames.controlWeekDayBadge, classNames.controlWeekDayBadgeInActive)} size="extra-large" onClick={() => onBadgeChanged('su')}>S</Badge>                
                </Stack>
            )}

            { unitOfTime === 'month' && (
                <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 10 }}>                
                    <p>on day {referenceDate?.getDate()}</p>
                </Stack>
            )}

            { unitOfTime === 'year' && (
                <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 10 }}>                
                    <p>on {referenceDate?.toLocaleString('en-US', { month: 'long' })} {referenceDate?.getDate()}</p>
                </Stack>
            )}
        </Stack> 
    )
}
