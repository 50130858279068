import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { UnControlledPropertyMapItemList } from "./UnControlledPropertyMapItemList";
import { IPropertyMapItem } from "./UnControlledPropertyMapItem";

export interface IControlledPropertyMapItemListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    placeholderLeft?: string
    placeholderRight?: string
}

export const ControlledPropertyMapItemList = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IControlledPropertyMapItemListProps) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
            <UnControlledPropertyMapItemList 
                {...props}
                items={value || []} 
                placeholderLeft={props.placeholderLeft}
                placeholderRight={props.placeholderRight}
                onItemsChanged={(items: IPropertyMapItem[]) => {                    
                    onChange(items)
                }} />                    
        )}
    />
  );
};
