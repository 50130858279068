import { useAuth } from "@mevodo/mv-react-authentication";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ISdkSearchResultItem } from "../lib/sdk/models/ISdkSearchResultItem";
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient";
import { ServiceProviderCustomerManagementUIService } from "./ServiceProviderCustomerManagementUIService";
import { SearchGeneralPanel } from "./SearchGeneralPanel";

export interface ISearchCustomerPanelProps extends IAppWindowBaseProps { 
    tenantId: string
    dashboardId?: string
    parentId: string    
}

export const SearchCustomerPanel = (props: ISearchCustomerPanelProps) => {

    const auth = useAuth()
    
    // establish the uiService
    const uiService = new ServiceProviderCustomerManagementUIService(props.tenantId, auth.currentToken ? auth.currentToken : '')

    const onOpenDashboard = (customer: ISdkSearchResultItem) => {        
        const sdkCLient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)

        return sdkCLient.getCustomer(customer.id).then((customer) => {
            uiService.openInCustomerContextExt(customer.tenantId, customer.id, '/portal/' + props.dashboardId + '?allowManagedEdit=true')
            return Promise.resolve()
        })
    }

    const onOpenCustomerPortal = (customer: ISdkSearchResultItem) => {     
        const sdkCLient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        return sdkCLient.getCustomer(customer.id).then((customer) => {        
            uiService.openInCustomerContextExt(customer.tenantId, customer.id, '/portal')
            return Promise.resolve()
        })            
    }

    const onJumpToCustomer = (customer: ISdkSearchResultItem) => {
        window.open(process.env.PUBLIC_URL + '/tenants/' + props.tenantId + '/svp/customers?parent=' + customer.id + '&type=customer','_blank')        
        return Promise.resolve()
    }
    
    const description =  props.dashboardId ? 
        'Please enter a search criteria for customers you would like to use for the dashboard.' :
        'Please enter a search criteria for customers. Valid values are name or id fragments.'

    let actions = [
        { text: 'Open Portal', onClick: onOpenCustomerPortal },
        { text: 'Go to', onClick: onJumpToCustomer }
    ]

    if (props.dashboardId) {
        actions = [
            { text: 'Edit Dashboard', onClick: onOpenDashboard },
            ...actions
        ]
    }
    
    return (
        <SearchGeneralPanel 
                isVisible={props.isVisible}
                dismissDialog={props.dismissDialog}
                tenantId={props.tenantId} 
                rootId={props.parentId} 
                searchTypes={['customer']} 
                title={props.dashboardId ? 'Select customer context for dashboard' : 'Search for customers'} 
                description={description} 
                searchPlaceholder={'Search for customers'} 
                searchResultHeader={'Customers'}
                actions={actions}
        />)
}