import { SdkClient } from "./SdkClient";

export class SdkClientServiceDiscoveryService {

    public static Discover<T>(resourcePrefix: string, token: string, optionalHeaders?: {[key: string]: string}) : SdkClient<T> {
        
        let discoveredHost = 'http://localhost:5082'
        let pathPrefix = ''

        if (window.location.hostname !== 'localhost') {
            discoveredHost = 'https://' + window.location.hostname 
            pathPrefix = '/cloudbilling'
        }

        return new SdkClient<T>(discoveredHost, pathPrefix, resourcePrefix, token, optionalHeaders);
    }

    public static DiscoverUri(resourcePrefix: string) : string {
        
        let discoveredHost = 'http://localhost:5082'
        let pathPrefix = ''

        if (window.location.hostname !== 'localhost') {
            discoveredHost = 'https://' + window.location.hostname 
            pathPrefix = '/cloudbilling'
        }

        if (resourcePrefix.startsWith('/cloudbilling')) {
            pathPrefix = ''
        }

        return discoveredHost + pathPrefix + resourcePrefix;
    }
    
    public static DiscoverUriAccounts(resourcePrefix: string) : string {        
        const cbUri = this.DiscoverUri(resourcePrefix)
        if (cbUri.includes('localhost'))
            return 'https://apps.staging.mevodo.com/accounts' + resourcePrefix
        else 
            return cbUri.replace('/cloudbilling', '/accounts')        
    }
}