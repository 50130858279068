import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { IUnControlledFileContentFieldProps, UnControlledFileContentField } from "./UnControlledFileContentField";

export const ControlledFileContentField = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IUnControlledFileContentFieldProps) => {    
    return (
        <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue || undefined}
        render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
        }) => {    
                        
            return (
                <UnControlledFileContentField
                  {...props}
                  onContentChange={(content?: string) => {
                    onChange(content ? content as string : '')
                }}
                  value={value}
                  onBlur={onBlur}
                  name={fieldName}
                  errorMessage={error && error.message}                
                />
              )}}
        />
    );
};
