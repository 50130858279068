import { IBreadcrumbItem } from "@fluentui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export interface IBreadcrumbManager<T> {
    activateItem<T>(item: T, to: (item:T) => string): Promise<void>
    onLoadItem: (callback: (key: string, type: string) => Promise<T>) => void
}

export function useBreadcrumbManager<T>(cachedItems: T[], keyField: string, textField: string, parentField: string, typeField: string, typeFieldMap: {[key: string]: string}): [IBreadcrumbItem[], IBreadcrumbManager<T>] {
    const [breadcrumbItems, setBreadCrumbItems]  = useState<IBreadcrumbItem[]>([])
    const navigate = useNavigate()

    let callback: (key: string, type: string) => Promise<T> 

    function navigateTo(ev: any, uri: string)
    {
        navigate(uri)
        ev?.preventDefault();
    }

    async function activateItem<T>(item: T, to: (item:T) => string): Promise<void> {

        // read out the important attributes
        const key: string = (item as any)[keyField]                
        const type: string = (item as any)[typeField]

        // check if the item currently activated
        if (breadcrumbItems.length > 0 && breadcrumbItems[breadcrumbItems.length - 1].key === key)
            return Promise.resolve();
        
        // this checks if the key is part the breadcrumb as this means we are going back
        const existingBreadCrumbItem: IBreadcrumbItem | undefined = breadcrumbItems.find(i => i.key === key)
        if (existingBreadCrumbItem) {                    
            const indexOf = breadcrumbItems.indexOf(existingBreadCrumbItem)
            setBreadCrumbItems(breadcrumbItems.slice(0, indexOf + 1))
        } else {

            // check if the parent in the old state then we can take the group for the breadcrum directly from this location 
            const preloadedItem = cachedItems ? cachedItems.find(i => (i as any)[keyField] === key) : undefined
            if (preloadedItem) {
                const newBreaedCrumbItems: IBreadcrumbItem[] = breadcrumbItems
                newBreaedCrumbItems.push({key: (preloadedItem as any)[keyField], text: (preloadedItem as any)[textField], onClick: (ev) => { navigateTo(ev, to(preloadedItem as any)) }})                
            } else {

                 // at this point we have nothing and must build the breadcrumb with API calls
                 let nextBreadCrumbKey: string = key
                 let nextBreadCrumbType: string = type

                 const localBreadCrumbList: IBreadcrumbItem[] = []
 
                 while(nextBreadCrumbKey)
                 {
                    if (!callback)
                        return;

                    const loadedItem = await callback(nextBreadCrumbKey, nextBreadCrumbType)                     
                    localBreadCrumbList.push({key: nextBreadCrumbKey, text: (loadedItem as any)[textField], onClick: (ev) => { navigateTo(ev, to(loadedItem as any))}})
                    nextBreadCrumbKey = (loadedItem as any)[parentField] ? (loadedItem as any)[parentField] : undefined                                                        
                    nextBreadCrumbType = (loadedItem as any)[typeField] ? (loadedItem as any)[typeField] : undefined
                    if (typeFieldMap[nextBreadCrumbType]) {
                        nextBreadCrumbType = typeFieldMap[nextBreadCrumbType]
                    }
                 }                                 
 
                 // asign the breadcrum 
                 setBreadCrumbItems(localBreadCrumbList.reverse())                 
            }
        }
        
        return Promise.resolve()
    }

    function onLoadItem(cb: (key: string, type: string) => Promise<T>) {
        callback = cb              
    }

    return [
        breadcrumbItems, 
        {
            activateItem: activateItem,
            onLoadItem: onLoadItem
        }
    ]
}