import { Button } from "@fluentui/react-components"
import { regular, thin } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { DashboardHomeService } from "../lib/components/DashboardManagement/DashboardHomeService"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { DashboardGridComponent } from "../lib/components/DashboardGrid/GridSurface/DashboardGridComponent"

export const ServiceProviderDashboardOverview = () => {
      
    const { tenantId } = useParams()    
    const navigate = useNavigate()
    const auth = useAuthInContext(tenantId as string)

    const [defaultDashboardId, setDefaultDashboardId] = useState<string | undefined>(undefined)
    
    const onCreateNewDashboard = () => {
        navigate('/tenants/' + tenantId + '/svpdashboards/manage?action=create')
    }

    const onSetDefaultDashboard = () => {
        navigate('/tenants/' + tenantId + '/svpdashboards/manage')
    }

    useEffectAsync(async () => {
        const homeService = new DashboardHomeService(tenantId as string, auth.currentToken as string)
        const loadedDefaultDashboardId = await homeService.getDefaultDashboardId()
        if(loadedDefaultDashboardId === undefined) {
            setDefaultDashboardId('')
        } else {
            setDefaultDashboardId(loadedDefaultDashboardId)        
        }
    }, [])
    
    if (defaultDashboardId === undefined) {
        return <></>
    } else if (defaultDashboardId !== '') {
        return (<DashboardGridComponent tenantId={tenantId as string} dashboardId={defaultDashboardId as string} allowManagedEdit={false} reportCollection={'serviceProviderCollection'} />)            
    } else {
        return (
            <div className="mv-content-container" style={{textAlign: 'center'}}>
                <p style={{marginTop: '50px'}}><FontAwesomeIcon icon={thin('gauge-circle-plus')} size={'6x'} /></p>
                <p style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', maxWidth: '500px'}}>
                    Dashboards can help to visualize important numbers, trends or performance indicators across all your customers. The Manage 
                    Dashboard sections allows to create custom dashboards, don't forget defining one which will replace this page. Having a 
                    default dashboard here will help the whole team on a daily basis!</p>  
                <div style={{marginTop: '20px'}}>
                    <Button style={{marginRight: '5px'}} onClick={onSetDefaultDashboard}><FontAwesomeIcon icon={regular('star')} />&nbsp;&nbsp;Set the Default Dashboard</Button>
                    <Button style={{marginLeft: '5px'}} onClick={onCreateNewDashboard}><FontAwesomeIcon icon={regular('plus')} />&nbsp;&nbsp;Create new Dashboards</Button>
                </div>          
            </div>
        )
    }
}