import { UseFormWatch } from "react-hook-form"
import { ISdkObjectTemplate, ISdkObjectTemplateField } from "../sdk/models/ISdkObjectTemplate"
import { useEffect, useState } from "react"

export interface ISdkDependentOnWatcherControlProps {
    template: ISdkObjectTemplate
    watch: UseFormWatch<any>
    children: (isControlDisabledByDependency: (templateField: ISdkObjectTemplateField) => boolean) => JSX.Element
}

export const SdkDependentOnWatcherControl = (props: ISdkDependentOnWatcherControlProps) => {

    // establish the property watcher to manage dependent on   
    const [controlDisableState, setControlDisableState] = useState<{[key: string]: any}>({})     
    const propertyWatcher: string[] = props.watch(props.template.fields.map(f => f.id))    

    useEffect(() => {
        let newControlDisableState: any = {}        

        propertyWatcher.forEach((value: any, index: number) => {
            newControlDisableState[props.template.fields[index].id] = value
        })

        setControlDisableState(newControlDisableState)
    // eslint-disable-next-line        
    }, propertyWatcher)   
    
    const isControlDisabledByDependency = (templateField: ISdkObjectTemplateField) => {

        if (!templateField.dependentOn) { return false; }
    
        const currentValue = controlDisableState[templateField.dependentOn]
        if (currentValue) {
            return false        
        } else {
            return true
        }                
    }

    return props.children(isControlDisabledByDependency)
}