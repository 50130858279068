import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import { ISdkObjectTemplate } from "./models/ISdkObjectTemplate";
import { ISdkServiceCredential, ISdkServiceCredentialCreate, ISdkServiceCredentialReconsent } from "./models/ISdkServiceCredential";

export class SdkCustomerServiceCredentialsClient {

    private sdkClient: SdkClient<any>        
    private sdkTemplatesClient: SdkClient<ISdkObjectTemplate>

    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<any>('/api/v1/tenants/' + tenantId + '/svp/servicecredentials', token);        
        this.sdkTemplatesClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + tenantId + '/svp/servicecredentials/templates', token);
    }
        
    listServiceCredetials(): Promise<ISdkServiceCredential[]> 
    {                
        return this.sdkClient.list('/')
    }

    listServiceCredetialsTemplates(): Promise<ISdkObjectTemplate[]> 
    {        
        return this.sdkTemplatesClient.list('');            
    }

    createServiceCredentials(credentials: ISdkServiceCredentialCreate): Promise<string> 
    {            
        const payload = {
            name: credentials.name,
            type: credentials.type,
            comment:credentials.comment,
            fieldValues: credentials.fieldValues,
            finalLocation: credentials.finalLocation
        }

        return this.sdkClient.post('/', payload).then(async (location) => {
            return location as string
        })        
    }

    getServiceCredetials(id: string): Promise<ISdkServiceCredential> 
    {                
        return this.sdkClient.get('/' + id)
    }

    deleteServiceCredentials(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }

    updateServiceCredetials(id: string, serviceCredential: ISdkServiceCredentialCreate): Promise<string>
    {        
        return this.sdkClient.put('/' + id, serviceCredential).then(async (location) => {
            return location as string
        })              
    }

    reconsentServiceCredetials(id: string, serviceCredential: ISdkServiceCredentialReconsent): Promise<string>
    {        
        return this.sdkClient.put('/' + id + '/reconsent', serviceCredential).then(async (location) => {
            return location as string
        })              
    }

    checkConnectivity(id: string): Promise<Boolean> 
    {
        return this.sdkClient.postToGetStatus('/' + id + '/check', undefined);
    }
}