import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate } from "../lib/sdk/models/ISdkObjectTemplate"
import { SdkAddOrEditTemplateBasedObjectPanel } from "../lib/sdkdatacomponents/SdkAddOrEditTemplateBasedObjectPanel"
import { IObjectFromTemplateViewProps } from "../svp/IObjectFromTemplateViewProps"
import { SdkEndCustomerConfigurationClient } from "../lib/sdk/SdkEndCustomerConfigurationClient"

export const AddOrEditServiceConfigurationPanel = (props: IObjectFromTemplateViewProps) => {
        
    const auth = useAuthInContext(props.tenantId as string)
            
    const onLoadTemplates = () => {        
        const sdkClient = new SdkEndCustomerConfigurationClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.listTemplates()
    }

    const onCreate = (item: ISdkObjectFromTemplateCreate) => {        
        const sdkClient = new SdkEndCustomerConfigurationClient(props.tenantId as string, auth.currentToken as string);                            
        return sdkClient.createConfiguration(item)
    }

    const onUpdate = (item: ISdkObjectFromTemplate) => {
        const sdkClient = new SdkEndCustomerConfigurationClient(props.tenantId as string, auth.currentToken as string);                            
        return sdkClient.updatConfiguration(item.id, item)
    }    

    const onLoad = async (itemId: string) => {
        const sdkClient = new SdkEndCustomerConfigurationClient(props.tenantId as string, auth.currentToken as string);                            
        return sdkClient.getConfiguration(itemId)               
    }
    
    return (
        <SdkAddOrEditTemplateBasedObjectPanel 
            isVisible={props.isVisible} dismissDialog={props.dismissDialog}
            tenantId={props.tenantId as string} itemId={props.itemId} 
            objectTitle={'Service Configuration'}
            objectDescription={'Enter or adjust all required details to configure a new service following the selected template.'}
            templateSelectorLabel="Please select the serivce type from the list below."
            templateSelectorPlaceholder="Select a service type"                
            onLoadTemplates={onLoadTemplates}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onLoad={onLoad}
        />  
    )
}