import { IDropdownOption } from "@fluentui/react"
import { IFilterItem, UnControlledFilterItem } from "./UnControlledFilterItem"

export interface IFilterItemListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    disabled?: boolean

    availableFields: IDropdownOption[]

    items: IFilterItem[]

    onFilterItemsChanged?: (items: IFilterItem[]) => void
}

export const UnControlledFilterItemList = (props: IFilterItemListProps) => {
    
    const onUpdateFilterItem = (item: IFilterItem) => {
        
        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items[index] = item
        }

        if (props.onFilterItemsChanged) {
            props.onFilterItemsChanged(props.items)            
        }
    }

    const onAddFilterItem = (item: IFilterItem) => {
        if (props.onFilterItemsChanged) {
            props.onFilterItemsChanged([...props.items, item])            
        }        
    }

    const onDeleteFilterItem = (item: IFilterItem) => {

        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items.splice(index, 1)

            if (props.onFilterItemsChanged) {
                props.onFilterItemsChanged(props.items)            
            }
        }        
    }

    return (
        <div>
            <table cellSpacing={0}>   
                <tbody>
                    {props.items.map((item, index) => {
                        return (
                            
                                <UnControlledFilterItem 
                                    key={item.key} 
                                    item={item} 
                                    name={item.key} 
                                    availableFields={props.availableFields} 
                                    onUpdateFilterItem={onUpdateFilterItem} 
                                    onDeleteFilterItem={onDeleteFilterItem} 
                                    first={index === 0} 
                                    disabled={props.disabled} />
                            )
                    })}
                
                    <UnControlledFilterItem 
                        key={'newFilterItem'} 
                        name={'newFilterItem'}                 
                        availableFields={props.availableFields} 
                        onUpdateFilterItem={onAddFilterItem} 
                        first={props.items.length === 0} 
                        disabled={props.disabled} />
                </tbody>                          
            </table>
        </div>    
    )
}