import { DefaultButton, Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator } from "@fluentui/react";
import { useState } from "react";
import { extractErrorMessage } from "../../sdk/SdkClientErrorExtractor";

const modalPropsStyles = { main: { maxWidth: 450 } };

export interface IConfirmDialogProps {
    title: string,
    text: string,
    isOpen: boolean,    
    onConfirmOperation?: () => Promise<void>
    onDeclineOperation?: () => Promise<void>
    onDismiss: () => void
}

// const x : IDialogProps
export const ConfirmDialog = (props: IConfirmDialogProps) => {    
  
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | undefined>()

    const onConfirm = () => {
      if (props.onConfirmOperation)
      {
          // reset the error
          setError(undefined)

          // render the execution spinner
          setLoading(true)

          // execute the operation
          props.onConfirmOperation().then(() => {
            
            // dissmis the spinner
            props.onDismiss();

          }).catch((error) => {

            // show error
            setError(extractErrorMessage(error))                        

          }).finally(() => {
              
            // dissmis the spinner
            setLoading(false)
          })
      } else {                
        props.onDismiss()
      }
    }

    const onDismiss = () => {

      setError(undefined)
      
      if (props.onDeclineOperation) {
        props.onDeclineOperation().finally(() => {      
          props.onDismiss()          
        })
      } else {
        props.onDismiss()
      }
    }

    const renderFooterContent = () => {
      if (props.onConfirmOperation) {
        return (
          <DialogFooter>
            <PrimaryButton onClick={() => onConfirm()} text={error ? 'Retry' : 'Yes'} />
            <DefaultButton onClick={() => onDismiss()} text={'No'} />
          </DialogFooter>
        )
      } else {
        return (
          <DialogFooter>              
            <PrimaryButton onClick={() => onDismiss()} text={'Ok'} />
          </DialogFooter>
        )
      }
    }

    return (
        <Dialog 
          hidden={!props.isOpen}           
          dialogContentProps={{ type: DialogType.normal, subText: props.text, title: props.title }} 
          modalProps={{ isBlocking: true }}
          styles={modalPropsStyles}
          onDismiss={() => onDismiss()}                  
          >
          { error ? <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar> : <></> }
          { loading ? 
            <ProgressIndicator label={'Executing operation...'} /> : 
            renderFooterContent()
          }       
        </Dialog>       
    );
  };

export interface IConfirmDialogService {
  ask: (title: string, text: string, confirmedOperation: () => Promise<void>, declineOperation?: () => Promise<void>) => void  
  show: (title: string, text: string) => void
}

export const useConfirmDialog = (): [IConfirmDialogProps, IConfirmDialogService] => {

  const emptyDialogProps = {
    title: '', text: '', isOpen: false, 
    onConfirmOperation: () => { return Promise.resolve() },
    onDismiss: () => {}
  }

  const [dialogProps, setDialogProps] = useState<IConfirmDialogProps>(emptyDialogProps)

  const onDismiss = () => {
    setDialogProps(emptyDialogProps)
  }

  function ask(title: string, text: string, confirmedOperation: () => Promise<void>, declineOperation?: () => Promise<void>) {
    setDialogProps({title: title, text: text, isOpen: true, onConfirmOperation: confirmedOperation, onDeclineOperation: declineOperation, onDismiss: onDismiss})
  }
  
  function show(title: string, text: string) {
    setDialogProps({title: title, text: text, isOpen: true, onDismiss: onDismiss})
  }

  return [
    dialogProps, 
    {
      ask: ask,
      show: show
    }
  ]
}