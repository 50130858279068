import { ISdkTenant, ISdkTenantCreateModel } from "./models/ISdkTenant";
import { ISdkTenantSettingValue } from "./models/ISdkTenantSettingValue";
import { ISdkTenantSubscriptionModel } from "./models/ISdkTenantSubscriptionModel";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantClient {

    private sdkClient: SdkClient<ISdkTenant>
    private sdkSubscriptionClient: SdkClient<ISdkTenantSubscriptionModel>
    private sdkSettingsClient: SdkClient<ISdkTenantSettingValue>
    
    constructor(token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkTenant>('/api/v1/tenants', token);
        this.sdkSubscriptionClient = SdkClientServiceDiscoveryService.Discover<ISdkTenantSubscriptionModel>('/api/v1/tenants', token);
        this.sdkSettingsClient = SdkClientServiceDiscoveryService.Discover<ISdkTenantSettingValue>('/api/v1/tenants', token);
    }

    registerTenant(tenant: ISdkTenantCreateModel): Promise<ISdkTenant> {
        return this.sdkClient.post('', tenant).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })
    }

    deleteTeanant(tenant: ISdkTenant): Promise<void> {
        return this.sdkClient.delete('/' + tenant.id).then((result) => {
            if (result) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        })
    }

    upsertSetting(tenantId: string, category: string, key: string, value: string): Promise<boolean> {
        return this.sdkSettingsClient.put('/' + tenantId + '/settings/' + category + '/' + key, { value: value }).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    readSetting(tenantId: string, category: string, key: string): Promise<string | undefined> {
        return this.sdkSettingsClient.get('/' + tenantId + '/settings/' + category + '/' + key).then((valueModel) => {
            return valueModel.value
        }).catch(() => {
            return undefined
        })
    }

    getSubscriptions(tenantId: string): Promise<ISdkTenantSubscriptionModel[]> {
        return this.sdkSubscriptionClient.list('/' + tenantId + '/subscriptions');
    }
}