import { useParams } from "react-router-dom"
import { useState } from "react";
import { SdkTenantApiDefinitionClient } from "../lib/sdk/SdkTenantApiDefinitionClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { PageHeader } from "../lib/ds/components/PageHeader";
import { PageContent } from "../lib/ds/components/PageContent";

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"
import "./ServiceProviderDevelopmentCenterApiViewer.scss"

export interface IServiceProviderDevelopmentCenterApiViewerProps {

}

export const ServiceProviderDevelopmentCenterApiViewer = (props: IServiceProviderDevelopmentCenterApiViewerProps) => {    

    const { tenantId, apiId } = useParams();        
    const authInContext = useAuthInContext(tenantId as string)

    const [swaggerTitle, setSwaggerTitle] = useState<string>('')      
    const [swaggerDescription, setSwaggerDescription] = useState<string>('')
    const [swaggerEndpoint, setSwaggerEndpoint] = useState<string>('')      
    const [swaggerToken, setSwaggerToken] = useState<string>('')
    const [pending, setPending] = useState<boolean>(true)

    useEffectAsync( async() => {   
        setPending(true)        
        const sdkClient = new SdkTenantApiDefinitionClient(tenantId as string, authInContext.currentToken as string)
        const api = await sdkClient.loadApi(apiId as string)
        setSwaggerTitle(api.swaggerTitle)
        setSwaggerDescription(api.swaggerDescription)
        setSwaggerToken(api.swaggerToken)
        setSwaggerEndpoint(api.swaggerUrl)                    
        setPending(false)        
    }, [tenantId, apiId])

    if (pending) { return (<></>) }

    return (
        <div className="mv-content-container">
            <PageHeader title={swaggerTitle}>            
                <span>The system supports direct access to the RESTful API set which makes it easy to 
                    build integrations. The API specs is defined in an <a href="https://swagger.io/specification/" target="_blank" rel="noreferrer">Open API</a> sepcifcation.
                    &nbsp;{swaggerDescription}
                </span>
            </PageHeader>
            
            <PageContent>                    
                
                <SwaggerUI url={swaggerEndpoint} tryItOutEnabled={false} requestInterceptor={(req) => { 
                    
                    // add the token to the requets
                    req.headers['Authorization'] = 'Bearer ' + swaggerToken

                    // return the request
                    return req
                }}/>
            </PageContent>
        </div>
    )
}