import { ISdkDashboardItem } from "../../../../sdk/models/ISdkDashboardItem";
import { SdkTenantDashboardClient } from "../../../../sdk/SdkTenantDashboardClient";
import { IDashboardGridControlStateStorageService } from "../Contracts/IDashboardGridControlStateStorageService";

export class DashboardGridControlServerStorageStateStorageService implements IDashboardGridControlStateStorageService {

    constructor(private _tenantId: string, private _token: string, private _adminToken?: string) 
    {}
    
    saveItemState(item: ISdkDashboardItem, state: any): Promise<void> {
        
        // log
        console.log('Writing state for widget ' + item.id + ' to server, the state contains the following information:')
        
        // dump
        const itemState = state || {}        
        console.log(itemState)

        // store
        const sdkCustomerDashboardClient = new SdkTenantDashboardClient(this._tenantId, this._token, this._adminToken);
        return sdkCustomerDashboardClient.updateDashboardDefinitionItem(item.dashboardId, {...item, state: itemState} as ISdkDashboardItem).then(() => {
            return Promise.resolve();
        })            
    }

    loadItemState(item: ISdkDashboardItem): Promise<any> {
        return Promise.resolve<any>(item.state ? item.state : {})        
    }
}