import { PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../../../ds/components/AppWindow"
import { PanelWizard } from "../../PanelWizard/PanelWizard"
import { DashboardGridWidgetSelectorStep003ConfigureAttributes } from "./DashboardGridWidgetSelectorStep003ConfigureAttributes"
import { DashboardGridWidgetSelectorStep002SelectReportDefinition } from "./DashboardGridWidgetSelectorStep002SelectReportDefinition"
import { DashboardGridWidgetSelectorStep001SelectWidget } from "./DashboardGridWidgetSelectorStep001SelectWidget"
import { ISdkDashboardItem } from "../../../sdk/models/ISdkDashboardItem"

export interface IDashboardGridWidgetSelectorPanelProps extends IAppWindowBaseProps {    
    tenantId: string
    dashboardId: string   
    reportCollection: 'serviceProviderCollection' | 'endCustomerCollection'   

    onAddDashboardItem: (dashboardItem: ISdkDashboardItem) => Promise<void>
} 

export const DashboardGridWidgetSelectorPanel = (props: IDashboardGridWidgetSelectorPanelProps) => {
    
    if (!props.isVisible)
        return (<></>)

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    return (        
        <PanelWizard isOpen={props.isVisible} onDismiss={dissmissDialog} type={PanelType.medium} headerText={'Add widget to dashboard'}>
            <DashboardGridWidgetSelectorStep001SelectWidget step="selectWidgets" hasFooterNavigation={false} tenantId={props.tenantId} dashboardId={props.dashboardId} onAddDashboardItem={props.onAddDashboardItem} reportCollection={props.reportCollection}/>
            <DashboardGridWidgetSelectorStep002SelectReportDefinition step="selectDatasource" hasFooterNavigation={true} tenantId={props.tenantId} dashboardId={props.dashboardId} onAddDashboardItem={props.onAddDashboardItem} reportCollection={props.reportCollection}/>                                
            <DashboardGridWidgetSelectorStep003ConfigureAttributes step="configureAttributes" hasFooterNavigation={true} isLastPage={true} tenantId={props.tenantId} dashboardId={props.dashboardId} onAddDashboardItem={props.onAddDashboardItem} reportCollection={props.reportCollection}/>
        </PanelWizard>                        
    )
}