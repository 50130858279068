import { useAuth } from "@mevodo/mv-react-authentication";
import { useComponentDidMount } from "@mevodo/mv-react-helpers";
import { useState } from "react";
import { FullScreeLoadingIndicator } from "../components/FullScreenLoadingIndicator";
import { ISdkProfile } from "../sdk/models/ISdkProfile";
import { SdkProfileClient } from "../sdk/SdkProfileClient";
import { AuthenticationProfileContext } from "./AuthenticationProfileContext";

export interface AuthenticationProfileProviderProps {        
    children?: React.ReactNode
}

export default function AuthenticationProfileProvider(props: AuthenticationProfileProviderProps) {
    
    const auth = useAuth();   
    const [profile, setProfile] = useState<ISdkProfile>();

    useComponentDidMount(() => {
        var client = new SdkProfileClient(auth.currentToken as string)
        client.getProfile().then((profile) => {                  
            setProfile(profile);            
        })
    })

    if (!profile) {
        return (<FullScreeLoadingIndicator />)
    } else {        
        return (
            <AuthenticationProfileContext.Provider value={{profile: profile}}>            
                {props.children}            
            </AuthenticationProfileContext.Provider>
        );
    }
}