import { IDropdownOption } from "@fluentui/react"
import { IFieldItem, UnControlledFieldItem } from "./UnControlledFieldItem"

export interface IFieldItemListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    disabled?: boolean

    availableFields: IDropdownOption[]
    
    items: IFieldItem[]

    onItemsChanged?: (items: IFieldItem[]) => void

    allowMappedFields?: boolean
    availableFieldTypes?: IDropdownOption[]
}

export const UnControlledFieldItemList = (props: IFieldItemListProps) => {
    
    const onUpdateItem = (item: IFieldItem) => {
        
        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items[index] = item
        }

        if (props.onItemsChanged) {
            props.onItemsChanged(props.items)            
        }
    }

    const onAddItem = (item: IFieldItem) => {
        if (props.onItemsChanged) {
            props.onItemsChanged([...props.items, item])            
        }        
    }

    const onDeleteItem = (item: IFieldItem) => {

        const index = props.items.findIndex(i => i.key === item.key)
        if (index >= 0) {
            props.items.splice(index, 1)

            if (props.onItemsChanged) {
                props.onItemsChanged(props.items)            
            }
        }        
    }

    return (
        <div style={props.style}>
            <table cellSpacing={0}>   
                <tbody>
                    {props.items.map((item, index) => {
                        return (
                            
                                <UnControlledFieldItem 
                                    key={item.key} 
                                    item={item} 
                                    name={item.key} 
                                    availableFields={props.availableFields} 
                                    onUpdateItem={onUpdateItem} 
                                    onDeleteItem={onDeleteItem} 
                                    first={index === 0} 
                                    disabled={props.disabled}
                                    allowMappedFields={props.allowMappedFields}
                                    availableFieldTypes={props.availableFieldTypes} />
                            )
                    })}
                
                    <UnControlledFieldItem 
                        key={'newFieldItem'} 
                        name={'newFieldItem'}                 
                        availableFields={props.availableFields} 
                        onUpdateItem={onAddItem} 
                        first={props.items.length === 0} 
                        disabled={props.disabled} 
                        placeholder={props.placeholder} />
                </tbody>                          
            </table>
        </div>    
    )
}