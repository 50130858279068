import { useAuth } from "@mevodo/mv-react-authentication";
import { SdkCustomerConfigurationVaultClient } from "../lib/sdk/SdkCustomerConfigurationVaultClient";
import { IObjectFromTemplateViewProps } from "./IObjectFromTemplateViewProps";
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate, ISdkObjectTemplateField } from "../lib/sdk/models/ISdkObjectTemplate";
import { SdkAddOrEditTemplateBasedObjectPanel } from "../lib/sdkdatacomponents/SdkAddOrEditTemplateBasedObjectPanel";

export const AddOrEditCustomerVaultDialog = (props: IObjectFromTemplateViewProps) => { 

    const auth = useAuth()
        
    const onLoadTemplates = () => {       
        const sdkClient = new SdkCustomerConfigurationVaultClient(props.tenantId, props.parent as string, auth.currentToken as string);                  
        return sdkClient.listVaultTemplates()        
    }

    const onCreate = (item: ISdkObjectFromTemplateCreate) => {                       
        const sdkClient = new SdkCustomerConfigurationVaultClient(props.tenantId, props.parent as string, auth.currentToken as string)
        return sdkClient.createVault(item)
    }

    const onUpdate = (item: ISdkObjectFromTemplate) => {
        const sdkClient = new SdkCustomerConfigurationVaultClient(props.tenantId, props.parent as string, auth.currentToken as string)
        return sdkClient.updatVault(item.id, item)
    }    

    const onLoad = async (itemId: string) => {
        const sdkClient = new SdkCustomerConfigurationVaultClient(props.tenantId, props.parent as string, auth.currentToken as string)
        return sdkClient.getVault(itemId)        
    }

    const onLoadObjectList = (fieldDefinition: ISdkObjectTemplateField) => {

        if (fieldDefinition.id.indexOf('templates') === -1) { return Promise.resolve([]) }

        const sdkClient = new SdkCustomerConfigurationVaultClient(props.tenantId, props.parent as string, auth.currentToken as string)
        return sdkClient.listServiceConfigurationTemplates().then(items => {
            return items.map(i => { return { key: i.id, text: i.name }})
        })        
    }
    
    const objectDescription = 
        'A configuration vault allows customers to manage configurations, settings and secrets ' + 
        'self served. The system supports Azure Key Vault as a secure backend for configuration settings. ' +
        'Please enter the access credentials for the Azure Key Vault to create a new configuration vault. ' +
        'The Credentials should be configured in a way that the system can only add items but never read the values ' +
        'to reduce attack vectors to a bare minimum!'

    return (
        <SdkAddOrEditTemplateBasedObjectPanel 
            isVisible={props.isVisible} dismissDialog={props.dismissDialog}
            tenantId={props.tenantId as string} itemId={props.itemId} 
            objectTitle={'Vault'}
            objectDescription={objectDescription}
            templateSelectorLabel="Please select the vault type you want to use"
            templateSelectorPlaceholder="Select a vault type"                
            onLoadTemplates={onLoadTemplates}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onLoad={onLoad}
            onLoadObjectList={onLoadObjectList}
        />  
    )

}
