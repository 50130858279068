import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { SdkCustomerDatasourceClient } from "../lib/sdk/SdkCustomerDatasourceClient"
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate } from "../lib/sdk/models/ISdkObjectTemplate"
import { IObjectFromTemplateViewProps } from "./IObjectFromTemplateViewProps"
import { SdkAddOrEditTemplateBasedObjectPanel } from "../lib/sdkdatacomponents/SdkAddOrEditTemplateBasedObjectPanel"

export const AddOrEditCustomerDatasource = (props: IObjectFromTemplateViewProps) => {
    
    const auth = useAuthInContext(props.tenantId as string)
        
    const onLoadTemplates = () => {        
        const datasourceTemplateService = new SdkCustomerDatasourceClient(props.tenantId as string, props.parent as string, auth.currentToken as string);
        return datasourceTemplateService.listDatasourceTemplates()                
    }

    const onCreate = (item: ISdkObjectFromTemplateCreate) => {                       
       const datasourceClient = new SdkCustomerDatasourceClient(props.tenantId as string, props.parent as string, auth.currentToken as string)            
       return datasourceClient.createDatasource(item)
    }

    const onUpdate = (item: ISdkObjectFromTemplate) => {
       const datasourceClient = new SdkCustomerDatasourceClient(props.tenantId as string, props.parent as string, auth.currentToken as string)            
       return datasourceClient.updatDatasource(item.id, item)            
    }    

    const onLoad = async (itemId: string) => {
        const datasourceClient = new SdkCustomerDatasourceClient(props.tenantId as string, props.parent as string, auth.currentToken as string)
        const item = await datasourceClient.getDatasource(props.itemId as string)
        
        return {
            id: item.id,
            name: item.name,
            comment: item.comment,
            type: item.type,
            fieldValues: item.fieldValues,
            optionalFieldValues: {}            
        }       
    }
    
    return (
        <SdkAddOrEditTemplateBasedObjectPanel 
            isVisible={props.isVisible} dismissDialog={props.dismissDialog}
            tenantId={props.tenantId as string} itemId={props.itemId} 
            objectTitle={'Datasource'}
            objectDescription={'Enter or adjust all required details to configure the data source'}
            templateSelectorLabel="Please select the datasource type from the list below."
            templateSelectorPlaceholder="Select a datasource template"                
            onLoadTemplates={onLoadTemplates}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onLoad={onLoad}
        />  
    )
}