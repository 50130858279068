import { useBranding } from './BrandingProvider';
import './NavigationBar.scss'
import { Icon } from '@fluentui/react';

export interface INavigationBarProps {
    title: string
    icon?: string
    children?: React.ReactNode
}

export const NavigationBar = (props: INavigationBarProps) => {

    const branding = useBranding()

    return (
        <div className="mv-navigation-bar" style={{backgroundColor: branding.company?.colors.primary, color: branding.company?.colors.primaryFont}}>            
            <div className='mv-app-chooser'>
                <Icon iconName='Waffle' />  
            </div>
            <div className="mv-header" >
                <img src={props.icon} alt={'logo'} />                
                <span>{props.title}</span>                                                
            </div>    
            <div className='mv-content'>
                {props.children}
            </div>
        </div>
    )
}