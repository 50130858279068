import './ControlledDropdown.scss';

import { Dropdown, IDropdownOption, IDropdownProps } from "@fluentui/react";
import { FormEvent } from "react";
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";

export const ControlledDropdown = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IDropdownProps) => {
    
    // set the options
    let options = props.options

    // check if we need to inject a placeholder
    if (props.placeholder) {
    
        // define the placeholder
        const placeHolderEntry = { key: '$$placeholder$$', text: props.placeholder } 

        // add a placeholder entry
        options = props.options ? [ placeHolderEntry, ...props.options ] : [placeHolderEntry]
    }

    return (
        <Controller            
            name={props.name}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue || undefined}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => {                
                
                let calculatedSelectedKey = props.selectedKey ? props.selectedKey : (value ? value : undefined)
                if (!calculatedSelectedKey && props.placeholder) {
                    calculatedSelectedKey = '$$placeholder$$'                   
                } else if (!calculatedSelectedKey && options.length > 0) {
                    onChange(options[0].key as string)
                }

                return (
                    <Dropdown             
                        {...props}                        
                        selectedKey={calculatedSelectedKey}
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {                            
                            onChange(item?.key as string)  
                                                    
                            if (props.onChange)
                                props.onChange(event, item)
                        }}                        
                        options={options}                                                            
                    />                
                )}}
        />
    )
}