import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate, ISdkObjectTemplate } from "./models/ISdkObjectTemplate";
import { ISdkTriggerAction } from "./models/ISdkTriggerAction";
import { ISdkTriggerFireEvent } from "./models/ISdkTriggerFireEvent";

export class SdkTenantTriggerClient {
    
    private sdkClient: SdkClient<ISdkObjectFromTemplate>
    private sdkClientLog: SdkClient<ISdkTriggerFireEvent>
    private sdkClientAction: SdkClient<ISdkTriggerAction>
    private sdkTemplateClient: SdkClient<ISdkObjectTemplate>

    constructor(
        private _tenantId: string, 
        private _token: string)        
    {
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectFromTemplate>('/api/v1/tenants/' + _tenantId + '/triggers', _token);                   
        this.sdkClientLog = SdkClientServiceDiscoveryService.Discover<ISdkTriggerFireEvent>('/api/v1/tenants/' + _tenantId + '/triggers', _token);                   
        this.sdkClientAction = SdkClientServiceDiscoveryService.Discover<ISdkTriggerAction>('/api/v1/tenants/' + _tenantId + '/triggers', _token);                   
        this.sdkTemplateClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + _tenantId + '/triggers/definitions', _token);
    }

    public listTemplates(): Promise<ISdkObjectTemplate[]> {
        return this.sdkTemplateClient.list('');                
    }

    public createTrigger(trigger: ISdkObjectFromTemplateCreate): Promise<ISdkObjectFromTemplate> 
    {            
        return this.sdkClient.post('/', trigger).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })        
    }

    public updateTrigger(triggerId: string, trigger: ISdkObjectFromTemplateCreate): Promise<ISdkObjectFromTemplate> 
    {            
        return this.sdkClient.put('/' + triggerId, trigger).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })        
    }

    public listTriggers(): Promise<ISdkObjectFromTemplate[]> {
        return this.sdkClient.list('');                
    }

    public deleteTrigger(triggerId: string): Promise<Boolean> {
        return this.sdkClient.delete('/' + triggerId);
    }

    public getTrigger(triggerId: string): Promise<ISdkObjectFromTemplate> {
        return this.sdkClient.get('/' + triggerId);                
    }

    public listTriggerFireEvents(triggerId: string): Promise<ISdkTriggerFireEvent[]> {
        return this.sdkClientLog.list('/' + triggerId + '/events');                
    }

    public listTriggerActions(triggerId: string): Promise<ISdkTriggerAction[]> {
        return this.sdkClientAction.list('/' + triggerId + '/actions');                
    }
}
