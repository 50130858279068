import './MetaDataList.scss'

export interface IMeteDataListItemProps {
    name: string, 
    value: string | Date, 
    actionTitle?: string
    onActionClick?: () => void
}

export interface IMetaDataListProps {
    items: IMeteDataListItemProps[]
}

export const MetaDataList = (props: IMetaDataListProps) => {

    return (
        <div className={'mv-metadatalist'}>
            <div className={'mv-metadatalist-items-container'}>

                {props.items.map((item) => {
                    return (
                        <div className="mv-metadataitem" key={item.name}>
                            <div className="header">{item.name}</div>
                            <div className="value">{item.value}</div>
                        </div>
                    )
                })}            
            </div>
        </div>
    )
}