import React, { ReactNode } from "react";
import { ComponentProps, ComponentState, ForwardRefComponent, Slot, assertSlots, getIntrinsicElementProps, slot } from "@fluentui/react-components"
import { UnControlledListItemContainerSlots } from "./UnControlledListItemContainer";

export type UnControlledListItemRenderFunction = (item: any) => ReactNode;

export type UnControlledListContainerSlots = {
    root: Slot<'div'>    
}

export type UnControlledListContainerProps = Omit<ComponentProps<UnControlledListContainerSlots>, 'children'> & {
    disabled?: boolean
    items: any[]

    children: UnControlledListItemRenderFunction
    onAddItem?: () => void
}

export type UnControlledListContainerState = ComponentState<UnControlledListContainerSlots> & Required<Pick<UnControlledListContainerProps, 'disabled' | 'items'>> & {
    renderItem: UnControlledListItemRenderFunction
}

const useUnControlledListContainer = (props: UnControlledListContainerProps, ref: React.Ref<HTMLDivElement>): UnControlledListContainerState => {
    const root = slot.always(getIntrinsicElementProps('div', {...props, ref}), { elementType: 'div' });
    
    return {
        
        components: {      
            root: 'div'            
        },
        
        root: root,
        disabled: !!props.disabled,           
        items: props.items,        
        renderItem: props.children
    };
};

export const renderUnControlledListContainer = (state: UnControlledListContainerState) => {     
        
    assertSlots<UnControlledListItemContainerSlots>(state);
        
    return (
        <div {...state.root}>
            {state.items.map(item => state.renderItem(item))}
        </div>
    );    
};

export const UnControlledListContainer: ForwardRefComponent<UnControlledListContainerProps> = React.forwardRef((props, ref) => {
    const state = useUnControlledListContainer(props, ref)    
    return renderUnControlledListContainer(state)    
})

