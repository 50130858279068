import { Checkbox, ICheckboxProps } from "@fluentui/react";
import { useState } from "react";
import { Controller, FieldError, FieldValues, Path, UseControllerProps } from "react-hook-form";

export const ControlledCheckbox = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & ICheckboxProps) => {

  const renderLabeWithError = (error: FieldError) => {
    return (
      <span>{props.onRenderLabel ? props.onRenderLabel() : props.label}&nbsp;<span style={{color: 'rgb(164, 38, 44)' }}>- {error.message}</span></span>
    )
  }
  
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Controller    
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({
        field: { onChange, name: fieldName, value },
        fieldState: { error }
      }) => {                        
                
        setIsChecked(value)

        return (
          <Checkbox 
              {...props}  
              onChange={(ev, checked) => { 
                setIsChecked(checked ? true : false)
                onChange(checked) 
                if (props.onChange) { props.onChange(ev, checked) }
              }}         
              
              checked={isChecked}            
              name={fieldName}   
              onRenderLabel={error ? () => renderLabeWithError(error) : props.onRenderLabel }            
          />                        
        )}
      }
    />
  );
};
