import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { IAppWindowBaseProps } from "../../ds/components/AppWindow"
import { Panel, PanelType } from "@fluentui/react"
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { IColumnsBuilder } from "../DetailsListHelper/ColumnsBuilder"
import { SdkAuditLogClient } from "../../sdk/SdkAuditLogClient"
import { ISdkAuditLogItem } from "../../sdk/models/ISdkAuditLogItem"
import { ActivityLogDetailsPanel } from "./ActivityLogDetailsPanel"
import { useState } from "react"

export interface IActivityLogPanelProps extends IAppWindowBaseProps {
    tenantId: string
    objectDisplayName: string
    objectType: string
    objectId: string

}

export const ActivityLogPanel = (props: IActivityLogPanelProps) => {
    
    const auth = useAuthInContext(props.tenantId as string)
      
    const [isDetailsPanelVisible, setIsDetailsPanelVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState<ISdkAuditLogItem | undefined>(undefined)

    const dissmissDialog = () => {
        if (props.dismissDialog) {
            props.dismissDialog()
        }
    }

    const onColumnClicked = (item: ISdkAuditLogItem) => {
        setSelectedItem(item)
        setIsDetailsPanelVisible(true)
    }

    const buildColumns = (builder: IColumnsBuilder<ISdkAuditLogItem>): void  => 
    {   
        builder.IconColumn({name: "Avatar", headerIcon: 'faUser', iconName: 'faUser', onClick: onColumnClicked})             
        builder.DateTimeColumn({name: "Date", maxWidth: 200, value: 'eventDate', onClick: onColumnClicked })        
        builder.TextColumn({name: "Action", maxWidth: 130, value: 'targetObjectAction', onClick: onColumnClicked }) // CREATE; DELETE; ...            
        builder.TextColumn({name: "Activity", maxWidth: 250, value: 'activity', onClick: onColumnClicked }) // Human readable message        
        builder.TextColumn({name: "Target", maxWidth: 200, value: (item) => item.targetObjectType + ': ' + item.targetObjectName + ' (' +  item.targetObjectId + ')', onClick: onColumnClicked }) // Object which was touched        
        builder.TextColumn({name: "Initiated by", maxWidth: 200, value: (item) => item.initiatedByName + ' (' + item.initiatedBy + ')', onClick: onColumnClicked }) // User triggered it       
    }
     
    const reloadData = async (): Promise<ISdkAuditLogItem[]> => {
        const sdkClient = new SdkAuditLogClient(auth.currentToken as string);

        let dimensionType = "subject"
        if (props.objectType === 'tenant') { dimensionType = 'tenant' }
        else if (props.objectType === 'self') { dimensionType = 'emitter' }

        return await sdkClient.getLogs(props.tenantId, dimensionType, props.objectId)                
    }

    return (
        <Panel
            isOpen={props.isVisible}            
            headerText={'Activity-Log / History of ' + props.objectDisplayName}
            type={PanelType.large}
            onDismiss={dissmissDialog}                        
            isFooterAtBottom={true}>

                <p>The following list shows the activity history for the given object. Every action is logged and can be reviewed here.</p>
                                
                <NavigationalDetailsListWithStandardOperations<ISdkAuditLogItem> 
                    uniqueIdentifier='activitylogitems' 
                    onBuildColumns={buildColumns} 
                    onLoadData={reloadData} 
                    allowSorting={true}
                    defaultSortBy={'Date'}
                    defaultSortDirection={'desc'}
                    disabledItemSelect={true}                    
                    hideReferesh={true}
                />   
                <ActivityLogDetailsPanel isVisible={isDetailsPanelVisible} item={selectedItem} dismissDialog={() => { setIsDetailsPanelVisible(false); setSelectedItem(undefined) }} />            
        </Panel>
    )
}