import { ISdkDashboardItem } from "../../../../sdk/models/ISdkDashboardItem";
import { SdkTenantDashboardClient } from "../../../../sdk/SdkTenantDashboardClient";
import { IDashboardGridControlItemLayout, IDashboardGridControlLayoutService } from "../Contracts/IDashboardGridControlLayoutService";

export class DashboardGridControlServerStorageLayoutService implements IDashboardGridControlLayoutService {

    constructor(private _tenantId: string, private _token: string, private _adminToken?: string) 
    {}

    getItemLayout(item: ISdkDashboardItem): IDashboardGridControlItemLayout {
        return { x: item.x, y: item.y, w: item.w, h: item.h }
    }   

    saveItemLayout(item: ISdkDashboardItem, layout: IDashboardGridControlItemLayout): Promise<void> {
        const sdkCustomerDashboardClient = new SdkTenantDashboardClient(this._tenantId, this._token, this._adminToken);
        return sdkCustomerDashboardClient.updateDashboardDefinitionItem(item.dashboardId, {...item, x: layout.x, y: layout.y, w: layout.w, h: layout.h} as ISdkDashboardItem).then(() => {
            return Promise.resolve();
        })        
    }
}