import { useDropzone } from 'react-dropzone';
import { makeStyles } from "@fluentui/react-components";
import { IconButton, ITextFieldProps } from '@fluentui/react';

const useDropzoneStyles = makeStyles({
    rootSection: {
        display: 'flex',
        flexDirection: 'column',        
        marginTop: '5px',
    },
    dropzone: {
        dispplay: 'flex',
        flexGrow: 1,        
        alignItems: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '20px',
        paddingBottom: '20px',
        borderLeftWidth: '2px',
        borderRightWidth: '2px',
        borderTopWidth: '2px',
        borderBottomWidth: '2px',    
        borderLeftColor: '#eeeeee',
        borderRightColor: '#eeeeee',
        borderTopColor: '#eeeeee',
        borderBottomColor: '#eeeeee',
        backgroundColor:'#fafafa',
        color: '#bdbdbd',
        borderLeftStyle: 'dashed',
        borderRightStyle: 'dashed',
        borderTopStyle: 'dashed',
        borderBottomStyle: 'dashed',
        textAlign: 'center',
    }
});

export interface IUnControlledFileContentFieldProps extends ITextFieldProps {
    onContentChange?: (value?: string) => void
    acceptedContentTypes?: string[]
}

export const UnControlledFileContentField = (props: IUnControlledFileContentFieldProps) => {
    
    const dropzoneStyles = useDropzoneStyles();

    const acceptedContentTypes: { [key: string]: string[] } = {}
    if (props.acceptedContentTypes) {
        props.acceptedContentTypes.forEach((contentType: string) => {
            acceptedContentTypes[contentType] = []
        })
    }

    const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} = useDropzone({
        maxFiles: 1, 
        accept: acceptedContentTypes,
        onDrop: (acceptedFiles, fileRejections, onChange) => {
            if (acceptedFiles.length !== 1) { return; }        
            const reader = new FileReader()    
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => { 
                const fileContent = reader.result        
                props.onContentChange && props.onContentChange(fileContent as string)
            }
            reader.readAsText(acceptedFiles[0])        
        }
    });
        
    const onDeleteValue = () => {
        props.onContentChange && props.onContentChange(undefined)
    }

    const getColorStyle = () => {
        return {
            borderColor: isDragAccept ? 'rgb(50, 49, 48)' : isDragReject ? 'red' : '#bdbdbd'
        }
    }
    return (
        <>
            { props.label && (<label><strong>{props.label}</strong></label>)}

            <section className={dropzoneStyles.rootSection}>
                
                { props.value ? (
                    <div className={dropzoneStyles.dropzone}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p>File was recevied without any errors, feel free to reset the uploaded data by pressing the trash bin icon!</p>
                            <IconButton iconProps={{ iconName: 'faTrashCan' }} onClick={onDeleteValue} />
                        </div>
                    </div>
                ) : (                
                    <div {...getRootProps({className: dropzoneStyles.dropzone, isFocused, isDragAccept, isDragReject})} style={getColorStyle()}>
                        <input {...getInputProps()} />                
                        <p>Please drag 'n' drop the requested file into this area or click to open the file browser!</p>                        
                    </div>                    
                )}                
            </section>
        </>
    )
}