import './AppContent.scss';

export interface IAppContentProps {
    children?: React.ReactNode
}

export const AppContent = (props: IAppContentProps) => {
    return (
        <div className="mv-app-content">
            {props.children}
        </div>)
}