import { SdkTenantClient } from "../../sdk/SdkTenantClient"

export class DashboardHomeService {

    private _tenantClient: SdkTenantClient

    constructor(private _tenantId: string, private _token: string) {
        this._tenantClient = new SdkTenantClient(this._token)
    }

    async getDefaultDashboardId(): Promise<string | undefined> {        
        return await this._tenantClient.readSetting(this._tenantId, 'svpdashboards', 'defaultdashboardid')        
    }

    async setDefaultDashboardId(dashboardId: string): Promise<void> {
        await this._tenantClient.upsertSetting(this._tenantId, 'svpdashboards', 'defaultdashboardid', dashboardId)
    }
}