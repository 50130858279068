import { Label, PanelType, Spinner, SpinnerSize, Stack } from "@fluentui/react"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { useState } from "react"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { ControlledFilterItemList } from "../lib/forms/ControlledFilterItemList"
import { IFilterItem } from "../lib/forms/UnControlledFilterItem"
import { useForm } from "react-hook-form"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { SdkCustomerReportClient } from "../lib/sdk/SdkCustomerReportClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { ISdkCustomerDataFilterPolicyCreateRequest } from "../lib/sdk/models/ISdkCustomerDataFilterPolicy"
import { SdkCustomerDataFilterPolicyClient } from "../lib/sdk/SdkCustomerDataFilterPolicyClient"

export interface IAddOrEditDataFilterPolicyDialogProps extends IAppWindowBaseProps {   
    tenantId: string
    parent: string
    parentType: string
    itemId?: string    
}

export const AddOrEditDataFilterPolicyDialog = (props: IAddOrEditDataFilterPolicyDialogProps) => {

    const [isProcessing, setIsProcssing] = useState<boolean>(false)    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        
    
    const [availableFields, setAvailableFields] = useState<{key: string, text: string}[]>([])

    const auth = useAuthInContext(props.tenantId as string);           
    
    const warningMessage =  'Changing data filter policies can affect many customers in the system. Adding ' +
                            'a data filter policy or changing an existing one will trigger a recalculation of all ' +
                            'affected customers. This process can take up to 72hrs depending on the number and size of ' +
                            'these customers. Please be patient and verify the progression in the notifications area.' 


    type AddDateFilterPolicyBaseData = {
        name: string
        description: string                
        excludeDataFilter: IFilterItem[]
    };

    const { handleSubmit, control, reset, formState } = useForm<AddDateFilterPolicyBaseData>({
        defaultValues: {
            name: '',
            description: '',
            excludeDataFilter: []            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });

    // load the fields
    useEffectAsync(async () => {

        if (props.isVisible) {

            setIsPreparing(true)

            // generate the service we need
            const sdkReportDefinitionClient = new SdkCustomerReportClient(props.tenantId, undefined, auth.currentToken as string);
            const metersReport = await sdkReportDefinitionClient.getReportDefinition('StaticDefaultCollection.D0E7511F-605F-43F8-93D5-7E2D4B7F8DE2')            

            setAvailableFields(metersReport.columns.map(c => {
                return {
                    key: c.id,
                    text: c.name
                }
            }))
            
            setIsPreparing(false)
        }

    // eslint-disable-next-line
    }, [props.isVisible])

    // load the item to edit
    useEffectAsync(async () => {

        if (!props.itemId) { 
            reset({
                name: '',
                description: '',                
                excludeDataFilter: []                
            })

            return
        }

        setIsPreparing(true)

        const sdkClient = new SdkCustomerDataFilterPolicyClient(props.tenantId, auth.currentToken as string)
        const item = await sdkClient.getDataFilterPolicy(props.itemId)

        reset({
            name: item.name,
            description: item.description,                        
            excludeDataFilter: item.excludeFilterRules.map((r, i) => { 
                return {
                    key: 'dataselector' + i,
                    operator: r.operator,
                    field: r.field,
                    condition: r.condition,
                    value: r.value,
                    compare: r.compare && r.compare === 'CaseInSensitive' ? true : false                        
                } 
            })            
        })
                
        setIsPreparing(false)

    }, [props.itemId])
    

    const dissmissDialog = () => {
        setIsProcssing(false)
        reset()       
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = (): Promise<string | void> => {

        // handle the submission of the base form 
        const submitHandler = handleSubmit((data: AddDateFilterPolicyBaseData) => {
                        
            let parentType = 'CustomerGroup'
            if (props.parentType === 'customer') { parentType = 'Customer' }            

            const createRequest: ISdkCustomerDataFilterPolicyCreateRequest = {
                targetId: props.parent,
                targetType: parentType,
                name: data.name,
                description: data.description,                                            
                excludeFilterRules: data.excludeDataFilter.map(f => {
                    return {                        
                        operator: f.operator,
                        field: f.field,
                        value: f.value,
                        condition: f.condition,
                        compare: f.compare ? 'CaseInSensitive' : 'CaseSensitive'
                    }
                })
            }

            // at this point we can create a new item and close the dialog
            const sdkClient = new SdkCustomerDataFilterPolicyClient(props.tenantId, auth.currentToken as string)

            if (props.itemId) {
                return sdkClient.updateDataFilterPolicy(props.itemId, createRequest)            
            } else {
                return sdkClient.createDataFilerPolicy(createRequest)            
            }
        })
        
        return submitHandler()
        .then(() => { dissmissDialog() })
        .finally(() => { setIsProcssing(false)})        
    }    

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={props.itemId ? 'Update data filter policy' : 'Add new date filter policy'}
            onDismiss={dissmissDialog}                                    
            type={PanelType.large}
            progessMessage={(props.itemId ? 'Updating existing' : 'Creating new') + ' data filter policy...'}
            submitLabel={props.itemId ? 'Update' : 'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid && formState.isDirty}
            onSubmit={onSubmit}
            errorMessagePrefix= {props.itemId? 'Error updating the data filter policy!' : 'Error creating the data filter policy!'}
            doubleConfirmMessage={warningMessage}>

                { isPreparing ? (
                    <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                ) : (
                    <div>
                        <div style={{marginBottom: '10px'}}>
                            <p>Data Filter Policies can control which data stored in the system is usabel for processing and monitoring. Excluded data are not relevant in any 
                                report anymore indepdent from reports on service provider level or reports on the customer level. It's important to understand that 
                                changing the policies has impact on all customers in the hierarchy and triggers a re-calculation of all dependent reports</p> 
                            
                            <p>All required information are request below, please enter all deatils to create a data filter policy:</p>                                                    
                        </div>
                                                                    
                        <form>
                            <Stack tokens={{ childrenGap: 15 }}>                
                                <ControlledTextField label={'Policy Name'} control={control} name={'name'} rules={{ required: 'a valid valud is required'}} disabled={isProcessing} />                                                                
                                <ControlledTextField label={'Description'} control={control} name={'description'} disabled={isProcessing} multiline resizable />                                                        
                            </Stack>
                        </form>   

                        <div>
                            <Label style={{marginTop: '10px'}}>Exclude Filter</Label>
                            <ControlledFilterItemList control={control} name={'excludeDataFilter'} availableFields={availableFields} disabled={isProcessing} rules={{required: 'at least one rule item is required'}} />                            
                        </div>                        
                    </div>
                )}
            
        </PanelWithDefaultFooter>)
}