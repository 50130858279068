import { ISdkRoleAssignmentEx } from "./models/ISdkRoleAssignment";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";


export class SdkTenantResourceProfileClient {

    private _sdkRoleAssignmentClient: SdkClient<ISdkRoleAssignmentEx>

    constructor(
        private _tenantId: string,
        private _token: string)         
    {                            
        this._sdkRoleAssignmentClient = SdkClientServiceDiscoveryService.Discover<ISdkRoleAssignmentEx>('/api/v1/tenants/' + this._tenantId + '/resources', this._token);
    }

    loadRoleAssignments(resourceIdentifier: string): Promise<ISdkRoleAssignmentEx[]> {
        return this._sdkRoleAssignmentClient.list('/' + btoa(resourceIdentifier) + '/assignments');
    }
}