import { mergeStyleSets } from "@fluentui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ISdkReportDefinition, ISdkReportDefinitionColumn } from "../../../sdk/models/ISdkReportDefintion";
import { useColumnsBuilder } from "../../DetailsListHelper/ColumnsBuilder";
import { NavigationalDetailsList } from "../../DetailsListHelper/NavigationalDetailsList";
import { useWizard } from "../../PanelWizard/PanelWizard";
import { DashboardGridWidgetSelectionAvailableReportDefinitionsDropdown } from "./DashboardGridWidgetSelectionAvailableDatasourceDropdown";
import { IDashboardGridWidgetSelectorStepProps } from "./IDashboardGridWidgetSelectorStepProps";
import { IDashboardGridWidgetSelectorWizardState } from "./IDashboardGridWidgetSelectorWizardState";

export const DashboardGridWidgetSelectorStep002SelectReportDefinition = (props: IDashboardGridWidgetSelectorStepProps) => {
    
    // get data from uri
    const { tenantId } = useParams()      
    
    // handle datasource selction 
    const [selectedReportDefinition, setSelectedReportDefinition] = useState<ISdkReportDefinition>()    
    
    // inject the wizard
    var wizard = useWizard<IDashboardGridWidgetSelectorWizardState>();
    
    // get the wizard data 
    const wizardData = wizard.getData()
    
    // set the next wizard page we are going to    
    wizard.onGoToNextStep(() => {           
        wizardData.widgetReportDefinition = selectedReportDefinition as ISdkReportDefinition;        
        wizard.goToStep('configureAttributes') 
    })

    // allow to move forward
    wizard.setNextValid(true)
    
    const columnsBuilder = useColumnsBuilder<ISdkReportDefinitionColumn>();
    const columns = columnsBuilder    
        .TextColumn({name: "Id", value: "id", maxWidth: 150})            
        .TextColumn({name: "Name", value: "name", maxWidth: 150})
        .TextColumn({name: "Type", value: "type", maxWidth: 50})        
        .Build()   

    const classNames = mergeStyleSets({
        datasourceProperties: {
            marginTop: '20px'
        }
    })

    const renderListItemMapper = () => {        
        if (!selectedReportDefinition) 
            return (<></>)

        return (        
            <div className={classNames.datasourceProperties}>
                <label className="ms-Label ms-Dropdown-label root-200" >These properties are available in the selected datasource</label>      
                <NavigationalDetailsList                      
                    uniqueIdentifier={'datasourceproperties'}
                    dataItems={selectedReportDefinition.columns}
                    columns={columns} 
                    isLoading={false} />
            </div>
        )                            
    }

    // render unser interface
    return (
        <div>
            <p>Please select an existing used by the widget in this dashboard</p>
            <DashboardGridWidgetSelectionAvailableReportDefinitionsDropdown 
                tenantId={tenantId as string} 
                dashboardId={props.dashboardId} 
                selectedReportDefinition={selectedReportDefinition} 
                reportCollection={props.reportCollection}
                onSelected={(reportDefinition) => setSelectedReportDefinition(reportDefinition)}  />
            {renderListItemMapper()}
        </div>        
    )
}