import { useAuth } from "@mevodo/mv-react-authentication";
import { useEffect } from "react";

export const Logout = () => {
    
    const auth = useAuth();       

    useEffect(() => {

        // destroy the session 
        auth.destroySession(true)        

    // eslint-disable-next-line             
    }, [])
    
    return (<></>)
}