import { useBoolean } from "@fluentui/react-hooks"
import { useAuth, useAuthInContext } from "@mevodo/mv-react-authentication"
import { useEffect, useMemo, useState } from "react"
import uuid from "react-uuid"
import { ConfirmDialog, useConfirmDialog } from "../../../ds/components/ConfirmDialog"
import { DeleteResourcePanel } from "../../../ds/components/DeleteResourcePanel"
import { ISdkDashboardItem } from "../../../sdk/models/ISdkDashboardItem"
import { SdkTenantDashboardClient } from "../../../sdk/SdkTenantDashboardClient"
import { ControlledCommandBar, useCommandBarService } from "../../DetailsListHelper/ComandBarService"
import { useEffectAsync } from "../../Helpers/EffectAsyncHook"
import { DashboardGridWidgetSelectorPanel } from "../SelectorPanel/DashboardGridWidgetSelectorPanel"
import { IDashboardGridControlLayoutService } from "./Contracts/IDashboardGridControlLayoutService"
import { DashboardGridControl } from "./DashboardGridControl"
import { DashboardGridControlItemService } from "./Services/DashboardGridControlItemService"
import { DashboardGridControlLocalStorageLayoutService } from "./Services/DashboardGridControlLocalStorageLayoutService"
import { DashboardGridControlLocalStorageStateStorageService } from "./Services/DashboardGridControlLocalStorageStateStorageService"
import { DashboardGridControlServerStorageLayoutService } from "./Services/DashboardGridControlServerStorageLayoutService"
import { DashboardGridControlServerStorageStateStorageService } from "./Services/DashboardGridControlServerStorageStateStorageService"
import { DashboardGridWidgetPropertiesPanel } from "../SelectorPanel/DashboardGridWidgetPropertiesPanel"
import { PeriodSelectorPanel } from "../../Generic/PeriodSelectorPanel"

export interface IDashboardGridComponentProps {
    tenantId: string
    dashboardId: string
    allowManagedEdit: boolean
    reportCollection: 'serviceProviderCollection' | 'endCustomerCollection'   
    onDeleteDashboard?: () => Promise<void>
}

export const DashboardGridComponent = (props: IDashboardGridComponentProps) => {

    const [items, setItems] = useState<ISdkDashboardItem[]>([])    
    const [itemForProperties, setItemForProperties] = useState<ISdkDashboardItem | undefined>(undefined)    
    const [dataHash, setDataHash] = useState<string>(uuid())
    const [isEditMode, setEditMode] = useState<boolean>(false)
    const [isDashboardSelectorVisible, { setTrue: showDashboardSelector, setFalse: hideDashboardSelector}] = useBoolean(false) 
    const [reloadOperation, { toggle: toggleReload}] = useBoolean(true)   
    const [isDeletePanelVisible, { setTrue: showDeletePanel, setFalse: hideDeletePanel}] = useBoolean(false) 
    const [isSelectPeriodPanelVisible, { setTrue: showSelectPeriodPanel, setFalse: hideSelectPeriodPanel}] = useBoolean(false) 
    
    const [isDashboardManaged, setDashboardManaged] = useState<boolean>(false) 
    const [dashboardName, setDashboardName] = useState<string>() 
    const [dashboardCurrency, setDashboardCurrency] = useState<string>('EUR') 

    const [selectedPeriod, setSelectedPeriod] = useState<string>('auto')        
    
    // use the authentication context
    const auth = useAuthInContext(props.tenantId);
    const authAdmin = useAuth();
    
    // ensure we have different tokens otherwise the allow managed edit will not work
    let allowManagedEdit = props.allowManagedEdit
    if (auth.currentToken === authAdmin.currentToken) {
        allowManagedEdit = false
    }
    
    // establish the layout service
    const layoutService = useMemo<IDashboardGridControlLayoutService>(() => 
        isDashboardManaged && !allowManagedEdit ? 
            new DashboardGridControlLocalStorageLayoutService() :            
            new DashboardGridControlServerStorageLayoutService(props.tenantId, auth.currentToken as string, allowManagedEdit ? authAdmin.currentToken as string : undefined)
        // eslint-disable-next-line                       
        , [isDashboardManaged])

    
    // establish the sdk client
    const sdkCustomerDashboardClient = new SdkTenantDashboardClient(props.tenantId, auth.currentToken as string, allowManagedEdit ? authAdmin.currentToken as string : undefined);

    // establish the item data service    
    const itemDataService = useMemo<DashboardGridControlItemService>(() =>        
        new DashboardGridControlItemService(
            props.tenantId, auth.currentToken as string, 
            isDashboardManaged && !allowManagedEdit ? 
                new DashboardGridControlLocalStorageStateStorageService(props.tenantId) :        
                new DashboardGridControlServerStorageStateStorageService(props.tenantId, auth.currentToken as string, allowManagedEdit ? authAdmin.currentToken as string : undefined))
        // eslint-disable-next-line                           
        , [isDashboardManaged])
    
    // get the confirmDialog 
    const [confirmDialog, confirmDialogService] = useConfirmDialog()

    // load the dashboard items
    useEffectAsync(async () => {

        console.log('Loading the dashboard definition for '+ props.dashboardId + '...')
        const dashbBoardDefinition = await sdkCustomerDashboardClient.getDashboardDefinition(props.dashboardId)
        setDashboardManaged(dashbBoardDefinition.managed)
        
        if (allowManagedEdit) {
            setDashboardName(dashbBoardDefinition.name + ' - Important: Your are changing a managed dashboard. Changes will be effective for all customers!')
        } else {
            setDashboardName(dashbBoardDefinition.name)
        }

        setDashboardCurrency(dashbBoardDefinition.primaryCurrency)

        console.log('Loading the dashboard items for '+ props.dashboardId + '...')        
        const dashboardItems = await sdkCustomerDashboardClient.getDashboardItems(props.dashboardId)
        if (dashboardItems) { 
            setItems([...dashboardItems]) 
        } else { 
            setItems([])                         
        }

        setDataHash(uuid())

    }, [reloadOperation, props.dashboardId])

    useEffect(() => {

        if (!allowManagedEdit) { return }

        confirmDialogService.ask(
            'Important: Editing managed dashboards', 
            'You are editing a managed dashboard in the context of this customer. This customer context make it easy to work with real data. Be aware that all changes will be effective for all customers this dashboard is assigned to!', 
            () => Promise.resolve(), 
            () => {
                window.close();
                return Promise.resolve() 
            }
        )
    // eslint-disable-next-line                   
    }, [allowManagedEdit])
    const dismissWithReload = (hideOperation: () => void): () => void  => {
        return () => {
            hideOperation()
            toggleReload()
        }
    }

    // handle the delete request 
    const onDeleteItemFromDashboard = async (item: ISdkDashboardItem) => {
        confirmDialogService.ask(
            'Delete dashboard item',
            'Do you really want to delete the dashboard item "' + (item.name ? item.name : item.itemType) + '"?',
            () => {         
                return sdkCustomerDashboardClient.deleteDashboardDefinitionItem(props.dashboardId, item.id).then(() => {
                    toggleReload()
                })            
            }
        )
    }

    const onAddDashboardItem = (dashboardItem: ISdkDashboardItem): Promise<void>  => {
        return sdkCustomerDashboardClient.addDashboardDefinitionItem(props.dashboardId, dashboardItem).then((result) => {
            if (result) {
                return Promise.resolve()
            } else {
                return Promise.reject()
            }
        })
    }

    const onEditDashboardItem = async (item: ISdkDashboardItem) => {
        setItemForProperties(item)
    }

    const onUpdateDashboardItem = async (item: ISdkDashboardItem) => {
        return sdkCustomerDashboardClient.updateDashboardDefinitionItem(props.dashboardId, item).then((result) => {
            if (result) {
                return Promise.resolve()
            } else {
                return Promise.reject()
            }
        })
    }

    const onPeriodSelected = (periodId: string, forceNotification: boolean) => {
        setSelectedPeriod(periodId)     
    }
    
    // define the command bar items     
    const commandBarService = useCommandBarService() 
    commandBarService.Command('add', 'Add Cards', 'faPlus', () => { showDashboardSelector() }, (isDashboardManaged && !allowManagedEdit) || isEditMode)
    commandBarService.Command('commit', 'Commit Layout', 'faCheck', () => { setEditMode(false) }, false, !isEditMode)                
    commandBarService.Command('edit', 'Edit', 'faPen', () => { setEditMode(true) }, isDashboardManaged && !allowManagedEdit, isEditMode)
    commandBarService.Command('refresh', 'Refresh', 'faRotate', toggleReload, isEditMode)
    commandBarService.Command('selectPeriod', 'Select Period', 'faCalendars', () => {showSelectPeriodPanel()}, isEditMode)
    commandBarService.Command('delete', 'Delete', 'faTrashCan', showDeletePanel, isDashboardManaged || isEditMode, !props.onDeleteDashboard)
        
    // calculate the dashboard name information
    commandBarService.FarCommand('dashboardName', dashboardName || '', 'faCircleInfo', undefined)

    // render the control
    return (
        <div className="mv-content-container standalone">    
            <ControlledCommandBar service={commandBarService} />          
            { items && items.length > 0 ? (
                <DashboardGridControl items={items} gridCurrency={dashboardCurrency} editMode={isEditMode} layoutService={layoutService} itemDataService={itemDataService} onDeleteItem={onDeleteItemFromDashboard} onEditItem={onEditDashboardItem} dataHash={dataHash} selectedPeriod={selectedPeriod} />
            ) : (<></>)}
            <DashboardGridWidgetSelectorPanel isVisible={isDashboardSelectorVisible} dismissDialog={dismissWithReload(hideDashboardSelector)} tenantId={props.tenantId} dashboardId={props.dashboardId} onAddDashboardItem={onAddDashboardItem} reportCollection={props.reportCollection}/>   
            <DeleteResourcePanel isVisible={isDeletePanelVisible} dismissDialog={hideDeletePanel} resourceName={props.dashboardId as string} onDeleteResource={props.onDeleteDashboard} />
                        
            <PeriodSelectorPanel 
                title={'Select Dashboard Period'} description={'Please select a the period for the dashboard.'} progressMessage={'Preparing dashboard for new period'}
                allowauto={true} maxPeriods={24}
                isVisible={isSelectPeriodPanelVisible} dismissDialog={dismissWithReload(hideSelectPeriodPanel)} onPeriodSelected={onPeriodSelected} />
                
            { itemForProperties && (<DashboardGridWidgetPropertiesPanel reportCollection={props.reportCollection} isVisible={!!itemForProperties} isReadonly={!isEditMode} dismissDialog={isEditMode ? dismissWithReload(() => { setItemForProperties(undefined)}) : () => { setItemForProperties(undefined) }} tenantId={props.tenantId} dashboardId={props.dashboardId} dashboardItem={itemForProperties} onUpdateDashboardItem={onUpdateDashboardItem} />) }
            <ConfirmDialog {...confirmDialog}/>
        </div>
    )
}