import { useEffectAsync } from "../components/Helpers/EffectAsyncHook";
import { SdkCustomerGroupClient } from "../sdk/SdkCustomerGroupClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useState } from "react";
import { Spinner, SpinnerSize, mergeStyleSets } from "@fluentui/react";
import { FluentProvider, HeadlessFlatTreeItemProps, Tree, TreeItem, TreeItemLayout, TreeItemProps, useHeadlessFlatTree_unstable, webLightTheme } from "@fluentui/react-components";

export interface ISdkCustomerGroupTreeProps {
    tenantId: string
    virutalRootId: string      
    onChange?: (groupId:string) => void
}

type FlatItem = HeadlessFlatTreeItemProps & { content: string };

export const SdkCustomerGroupTree = (props: ISdkCustomerGroupTreeProps) => {

    const auth = useAuthInContext(props.tenantId as string)

    const [isPreparing, setIsPreparing] = useState<boolean>(true)        
    const [treeItems, setTreeItems] = useState<FlatItem[]>([])
    const [selectedItem, setSelectedItem] = useState<TreeItemProps>()

    const defaultOpenTrees = [
        'root'
    ];

    useEffectAsync(async () => {
                
        // load the childs for the root
        const client = new SdkCustomerGroupClient(props.tenantId, auth.currentToken as string)
        const allGroups = await client.listCustomerGroups(props.virutalRootId, true)

        // map it to the flat tree list 
        setTreeItems([
            { value: 'root', content: 'Root' },
            ...allGroups.map(g => {
            if (g.parentId === props.virutalRootId) {
                return { value: g.id, parentValue: 'root', content: g.name }
            } else {
                return { value: g.id, parentValue: g.parentId, content: g.name }
            }
        })])
        
        // done 
        setIsPreparing(false)
            
    }, [props.virutalRootId, props.tenantId])

    const onTreeItemClicked = (e: any, item: TreeItemProps) => {
        setSelectedItem(item)
        props.onChange && props.onChange(item.value as string)        
    }

    const flatTree = useHeadlessFlatTree_unstable(treeItems, { defaultOpenItems: defaultOpenTrees });
            
    const classNames = mergeStyleSets({
        selectedItemLayout: {            
            fontWeight: 'bolder !important',
            fontStyle: 'italic',

        },

        nonSelectedItemLayout: {            
        }
    })

    if (isPreparing) {
        return (<Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>)
    } else {        
        return (
            
            <FluentProvider theme={webLightTheme}>
                <Tree {...flatTree.getTreeProps()} aria-label="Tree">
                    {Array.from(flatTree.items(), (flatTreeItem) => {
                    const { content, ...treeItemProps } = flatTreeItem.getTreeItemProps();
                    return (
                        <TreeItem {...treeItemProps} key={flatTreeItem.value}>
                            <TreeItemLayout className={treeItemProps.value === selectedItem?.value ? classNames.selectedItemLayout : classNames.nonSelectedItemLayout} onClick={(e) => onTreeItemClicked(e, treeItemProps) }>{content}</TreeItemLayout>
                        </TreeItem>
                    );
                    })}
                </Tree>
            </FluentProvider>
        )
    }
}