import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useEffect, useState } from "react"
import uuid from "react-uuid"
import { IColumnsBuilder, ISubCommandDefinition } from "../DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ISdkDashboardDefinition } from "../../sdk/models/ISdkDashboardDefinition"
import { SdkTenantDashboardClient } from "../../sdk/SdkTenantDashboardClient"
import { SdkAddOrEditDashboardControlPanel } from "../../sdkdatacomponents/SdkAddOrEditDashboardControlPanel"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffectAsync } from "../Helpers/EffectAsyncHook"
import { DashboardHomeService } from "./DashboardHomeService"
import { ConfirmDialog, useConfirmDialog } from "../../ds/components/ConfirmDialog"

export interface IDashboardManagementComponentProps {
    tenantId: string    
    onDashboardRegistered: (dashboard: ISdkDashboardDefinition) => void
    onDashboardDeleted: (dashboard: ISdkDashboardDefinition) => void
}

export const DashboardManagementComponent = (props: IDashboardManagementComponentProps) => {

    const auth = useAuthInContext(props.tenantId as string)        
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const [isAddDashboardDialogVisible, setIsAddDashboardDialogVisible] = useState(false)    
    const [isRefreshRequested, setRefreshRequested] = useState(false)
    const [itemToEdit, setItemToEdit] = useState<ISdkDashboardDefinition | undefined>(undefined)    
    
    const [defaultDashboardId, setDefaultDashboardId] = useState<string | undefined>(undefined)
    
    const [confirmDialog, confirmDialogService] = useConfirmDialog()
    
    const onClickColumn = (item: ISdkDashboardDefinition) => {
        navigate('/tenants/' + props.tenantId + '/svpdashboards/dashboards/' + item.id)
    }
    
    const onEditDashboard = (item: ISdkDashboardDefinition) => {
        setItemToEdit(item)
        setIsAddDashboardDialogVisible(true)
    }

    useEffectAsync(async () => {
        const homeService = new DashboardHomeService(props.tenantId, auth.currentToken as string)
        const loadedDefaultDashboardId = await homeService.getDefaultDashboardId()
        if(loadedDefaultDashboardId === undefined) {
            setDefaultDashboardId('')
        } else {
            setDefaultDashboardId(loadedDefaultDashboardId)        
        }
    }, [])
    
    const onSetDefaultDashboard = (item: ISdkDashboardDefinition) => {
        confirmDialogService.ask('Set as default dashboard', 'Are you sure you want to set this dashboard as the default dashboard?', async () => { 
            const homeService = new DashboardHomeService(props.tenantId, auth.currentToken as string)
            await homeService.setDefaultDashboardId(item.id)
            setDefaultDashboardId(item.id)                    
            window.location.reload()            
        })    
    }

    const onDeleteDefaultDashboard = (item: ISdkDashboardDefinition) => {
        confirmDialogService.ask('Remove default dashboard', 'Are you sure you want to remove the default dashboard?', async () => { 
            const homeService = new DashboardHomeService(props.tenantId, auth.currentToken as string)
            await homeService.setDefaultDashboardId('')
            setDefaultDashboardId('')                    
            window.location.reload()            
        })    
    }
    
    const columnSubCommandsDashboardName: ISubCommandDefinition<ISdkDashboardDefinition>[] =  [         
        { iconName: 'faPen', description: 'Edit the report definition', onClick: (item) => { onEditDashboard(item)  }}               
    ]

    const columnSubCommandsStatus: ISubCommandDefinition<ISdkDashboardDefinition>[] =  [         
        { iconName: 'faStar', description: 'Set as default dashboard', onClick: (item) => { onSetDefaultDashboard(item)  }, filter: (item) => { return item.id !== defaultDashboardId }},
        { iconName: 'faTrashCan', description: 'Set as default dashboard', onClick: (item) => { onDeleteDefaultDashboard(item)  }, filter: (item) => { return item.id === defaultDashboardId }}        
    ]

    const columnSubCommandsDashboardId: ISubCommandDefinition<ISdkDashboardDefinition>[] =  [
        { iconName: 'faClipboard', description: 'Copies content to the clipboard', onClick: (item: ISdkDashboardDefinition) => {navigator.clipboard.writeText(item.id)}}        
    ]

    const onBuildColumns = (builder: IColumnsBuilder<ISdkDashboardDefinition>): void => {
        builder.TextColumn({name: "Name", value: "name", maxWidth: 250, onClick: onClickColumn, subCommands: columnSubCommandsDashboardName})
        builder.TextColumn({name: 'Status', value: (item) => item.id === defaultDashboardId ? 'Default Dashboard' : '', maxWidth: 250, onClick: onClickColumn, subCommands: columnSubCommandsStatus})
        builder.TextColumn({name: "Id", value: "id", maxWidth: 150, onClick: onClickColumn, subCommands: columnSubCommandsDashboardId })            
    }

    const onLoadData = async (): Promise<ISdkDashboardDefinition[]> => {        
        setRefreshRequested(false)                            
        const dashboardClient = new SdkTenantDashboardClient(props.tenantId, auth.currentToken as string)
        return await dashboardClient.getDashboardDefinitions()                        
    }

    const onDeleteItems = async (items: ISdkDashboardDefinition[]) => {
        const dashboardClient = new SdkTenantDashboardClient(props.tenantId, auth.currentToken as string)        
        for (const item of items) {
            await dashboardClient.deleteDashboardDefinition(item.id)
            props.onDashboardDeleted(item)
        }
    }

    const onNewDashboard = () => {
        setIsAddDashboardDialogVisible(true)
    }
    
    const onDashboardRegistered = (dashboard: ISdkDashboardDefinition) => {
        setItemToEdit(undefined)
        setIsAddDashboardDialogVisible(false)
        setRefreshRequested(true)
        props.onDashboardRegistered(dashboard) 
    }

    useEffect(() => {
        if (searchParams && searchParams.get('action') === 'create') {
           setIsAddDashboardDialogVisible(true) 
           setSearchParams({})
        }
    // eslint-disable-next-line
    }, [searchParams])

    if (defaultDashboardId === undefined) {
        return <></>
    } else {
        return (    
            <>
                <NavigationalDetailsListWithStandardOperations 
                    uniqueIdentifier={'dashboardsroot' + uuid()}            
                    onBuildColumns={onBuildColumns}                                
                    onLoadData={onLoadData}
                    onNewItem={onNewDashboard}  
                    onDeleteItems={onDeleteItems}                                                              
                    hideReferesh={false} 
                    preventInitialLoad={false}                        
                    allowSorting={true}            
                    disabledItemSelect={false}
                    allowSearching={false}
                    refresh={isRefreshRequested} />   

                <SdkAddOrEditDashboardControlPanel isVisible={isAddDashboardDialogVisible} dismissDialog={() => { setIsAddDashboardDialogVisible(false)}} onDashboardRegistered={onDashboardRegistered} tenantId={props.tenantId} item={itemToEdit} />
                <ConfirmDialog {...confirmDialog}/>
            </>
        )
    }
}