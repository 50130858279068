import { ComponentProps, ComponentState, ForwardRefComponent, Slot, assertSlots, makeStyles, slot } from "@fluentui/react-components";
import React from "react";

export type UnControlledListItemContainerSlots = {
    editorSlot?: Slot<'div'>
    actionsSlot?: Slot<'div'>
}

export type UnControlledListItemContainerProps = Omit<ComponentProps<UnControlledListItemContainerSlots>, 'key'> & {
    disabled?: boolean    
}

export type UnControlledListItemContainerState = ComponentState<UnControlledListItemContainerSlots> & Required<Pick<UnControlledListItemContainerProps, 'disabled'>>

const makeUnControlledListItemContainerStyles = makeStyles({
    root: {        
        display: 'flex',
        flexDirection: 'row',                
        width: '100%',        
        columnGap: '10px',
        marginBottom: '5px'
    },
    editor: {
        display: 'flex',            
        flexDirection: 'row',
        flexGrow: 1,
        columnGap: '5px',
        '& div.ms-Dropdown-container': {
            marginTop: '0px'
        }     
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '5px'
    }    
});


const useUnControlledListItemContainer = (props: UnControlledListItemContainerProps, ref: React.Ref<HTMLDivElement>): UnControlledListItemContainerState => {
    const editorSlot = slot.optional(props.editorSlot, { elementType: 'div' });
    const actionsSlot = slot.optional(props.actionsSlot, { elementType: 'div' });

    return {        
        components: {      
            editorSlot: 'div',
            actionsSlot: 'div'
        },
        
        editorSlot: editorSlot,
        actionsSlot: actionsSlot,
        disabled: !!props.disabled,            
    };
};

export const renderUnControlledListItemContainer = (state: UnControlledListItemContainerState) => {     
        
    assertSlots<UnControlledListItemContainerSlots>(state);
    
    const styles = makeUnControlledListItemContainerStyles();

    return (
        <div className={styles.root}>
            {state.editorSlot ? <div className={styles.editor} {...state.editorSlot} /> : <div style={{fontStyle: 'italic'}}>This is an editor slot, please provide an implementation</div>}
            {state.actionsSlot ? <div className={styles.actions} {...state.actionsSlot} /> : <div style={{fontStyle: 'italic'}}>This is an actions slot, please provide an implementation</div>}
        </div>
    );    
};

export const UnControlledListItemContainer: ForwardRefComponent<UnControlledListItemContainerProps> = React.forwardRef((props, ref) => {
    const state = useUnControlledListItemContainer(props, ref)    
    return renderUnControlledListItemContainer(state)    
})