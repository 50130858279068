import { useParams } from "react-router-dom";
import { PageHeader } from "../lib/ds/components/PageHeader";
import { SdkEndCustomerConfigurationClient } from "../lib/sdk/SdkEndCustomerConfigurationClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations";
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder";
import { useBoolean } from "@fluentui/react-hooks";
import { useState } from "react";
import { ISdkEndCustomerServiceConfiguration } from "../lib/sdk/models/ISdkEndCustomerServiceConfiguration";
import { AddOrEditServiceConfigurationPanel } from "./AddOrEditServiceConfigurationPanel";

export interface ISettingsServiceConfigurationProps {
}

export const SettingsServiceConfiguration = (props: ISettingsServiceConfigurationProps) => {

    const { tenantId } = useParams();
  
    const authInContext = useAuthInContext(tenantId as string)
        
    const [isAddOrEditConfigurationsOpen, { setTrue: showAddOrEditConfigurations, setFalse: hideAddOrEditConfigurations}] = useBoolean(false)    
    const [itemtoEdit, setItemToEdit] = useState<ISdkEndCustomerServiceConfiguration>()    
    const [refresh, { toggle: toggleRefresh} ] = useBoolean(false)

    const onColumnCellClick = (item: ISdkEndCustomerServiceConfiguration) => {
        setItemToEdit(item)
        showAddOrEditConfigurations()
    }
    
    const buildColumns = (builder: IColumnsBuilder<ISdkEndCustomerServiceConfiguration>): void  => 
    {        
        builder.IconColumn({name: "Type", headerIcon: 'faUsers', iconName: 'faUsers', onClick: onColumnCellClick})        
        builder.TextColumn({name: "Id", value: "id", maxWidth: 250, onClick: onColumnCellClick})
        builder.TextColumn({name: "Name", value: "name", maxWidth: 250, onClick: onColumnCellClick})        
    }

    const reloadData = (): Promise<ISdkEndCustomerServiceConfiguration[]> => {
        const sdkClient = new SdkEndCustomerConfigurationClient(tenantId as string, authInContext.currentToken as string);
        return sdkClient.list()
    }

    const addConfiguration = (): Promise<void> => {        
        showAddOrEditConfigurations()
        return Promise.resolve()
    }
    
    const onDeleteItems = (items: ISdkEndCustomerServiceConfiguration[]): Promise<void> => {   
        const sdkClient = new SdkEndCustomerConfigurationClient(tenantId as string, authInContext.currentToken as string);
        return Promise.all(items.map((item) => sdkClient.deleteConfiguration(item.id))).then(() => {
            return Promise.resolve()
        })        
    }    

    // build the commandbar    
    const handleDissmissWithReload = (cb: () => void) => {
        return () => {
            cb()  
            setItemToEdit(undefined)
            toggleRefresh()                      
        }
    }
        
    return (
        <div className="mv-content-container">

            <PageHeader title={'Service Configurations'}>            
                <span>Service Configurations provide the necessary capabilities for this service to access your specific tenant.</span>
            </PageHeader>            

            <NavigationalDetailsListWithStandardOperations<ISdkEndCustomerServiceConfiguration> 
                uniqueIdentifier='usersroot2' 
                onBuildColumns={buildColumns} 
                onLoadData={reloadData} 
                onDeleteItems={onDeleteItems} 
                onNewItem={addConfiguration} 
                newItemLabel='Add Configuration'                 
                refresh={refresh} />

            <AddOrEditServiceConfigurationPanel isVisible={isAddOrEditConfigurationsOpen} dismissDialog={handleDissmissWithReload(hideAddOrEditConfigurations)} tenantId={tenantId as string} itemId={itemtoEdit ? itemtoEdit.id : undefined} />        
        </div>
    )
}