import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter";
import { IDropdownOption, Label, MessageBar, MessageBarType, PanelType, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { SdkTenantIdentityProviderClient } from "../lib/sdk/SdkTenantIdentityProviderClient";
import { ISdkObjectFromTemplate } from "../lib/sdk/models/ISdkObjectTemplate";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { ClipboardTextField } from "../lib/forms/ClipBoardTextField";
import { SdkClientServiceDiscoveryService } from "../lib/sdk/SdkClientServiceDiscoveryService";
import { useForm } from "react-hook-form";
import { SdkTenantRoleClient } from "../lib/sdk/SdkTenantRoleClient";
import { ControlledFieldList } from "../lib/forms/ControlledFieldList";

export interface IShowScimSettingsPanelProps extends IAppWindowBaseProps {       
    tenantId: string
    itemId?: string
    itemToken?: string
}

export const ShowScimSettingsPanel = (props: IShowScimSettingsPanelProps) => {
      
    const [isProcessing, { setTrue: startProccesing, setFalse: stopProcessing}] = useBoolean(true)
    const [idp, setIdp] = useState<ISdkObjectFromTemplate | undefined>(undefined)
    const [existingRoles, setExistingRoles] = useState<IDropdownOption[]>([])

    const auth = useAuthInContext(props.tenantId);

    const dissmissDialog = () => {        
        
        startProccesing()
        reset({})

        if (props.dismissDialog)
            props.dismissDialog()

        return Promise.resolve()
    }  
    
    useEffectAsync(async () => {
        if (!props.itemId) { return }

        startProccesing()

        const sdkClient = new SdkTenantIdentityProviderClient(props.tenantId, auth.currentToken as string);
        const idp = await sdkClient.load(props.itemId)
        setIdp(idp)

        const sdkClientRoles = new SdkTenantRoleClient(props.tenantId, auth.currentToken as string);
        const roles = await sdkClientRoles.loadRoles()
        setExistingRoles(
            roles.sort((a, b) => a.roleIdentifier.localeCompare(b.roleIdentifier))
            .filter(r => r.roleIdentifier.toLocaleLowerCase().indexOf('advanced') === -1)
            .map((r) => ({key: r.roleIdentifier, text: r.roleIdentifier})))

        const defaultRolesValues: string = idp.fieldValues['scimDefaultRoles'] as string || ''
        if (defaultRolesValues) {
            const readDefaultRoles = defaultRolesValues.split(';').map((r) => ({key: r, field: r}))
            reset({defaultRoles: readDefaultRoles})
        }

        stopProcessing()

    }, [props.itemId])
                
    const scimTenantUrl = SdkClientServiceDiscoveryService.DiscoverUriAccounts('/protocols/scim/tenants/' + props.tenantId + '/identityproviders/' + props.itemId ?? 'unknown')
    
    const { handleSubmit, control, formState, reset } = useForm<any>({ mode: 'onChange', reValidateMode: 'onChange' });

    const onSubmit = () => {

        startProccesing()

        const submitHandler = handleSubmit((data) => {
            const sdkClient = new SdkTenantIdentityProviderClient(props.tenantId, auth.currentToken as string);

            const roles: string[] = []
            data.defaultRoles.forEach((element: any) => {
                roles.push(element.field)
            })

            return sdkClient.update_scim(props.itemId as string, roles)            
        })

        return submitHandler()
            .finally(() => {
                stopProcessing()
            })
            .then(() => {
                dissmissDialog()
            })
            .catch((error) => {  
                let errorMessage = 'Something went wrong updating the scim settings, please try again later'
                errorMessage += error.code ?  ' (' + error.code + ')' : ''            
                return errorMessage
            })       
    }
            
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            type={PanelType.medium}
            headerText={'SCIM Settings'}            
            progessMessage={'Creating new identity provider...'}
            submitLabel={'Update'}            
            isValid={formState.isValid && !isProcessing}            
            dissmissLabel={'Close'}
            errorMessagePrefix={''}        
            onDismiss={dissmissDialog}
            onSubmit={onSubmit}>                            

                { isProcessing ? (
                    <Spinner size={SpinnerSize.large} label="Processing SCIM Settings" labelPosition="bottom" style={{height: '100%'}} />
                ) : (
                    <div>
                        <p>Please use the following information of SCIM to configure the relying party e.g. EntraId or others.</p>

                        <Stack tokens={{ childrenGap: 5 }} style={{marginTop: '5px'}}>                                             
                            <Stack.Item>
                                <ClipboardTextField value={idp?.id} name={'id'} label={'Unique Id of the Identity-Provider'} readOnly={true} />
                            </Stack.Item>                                                
                            <Stack.Item>
                                <ClipboardTextField value={scimTenantUrl} name={'tenantUri'} label={'Tenant URL used from SCIM service to communication with this application'} readOnly={true} />
                            </Stack.Item>                                                
                        </Stack>   

                        { props.itemToken && (
                            <div style={{paddingTop: '10px'}}>
                                <MessageBar messageBarType={MessageBarType.severeWarning}>{'Important: The secret token below is generated during the SCIM activation process, please store this token safely. It will not be generated and displayed a second time. If this token gets lost, disable scim and activate scim again to get a now one!' }</MessageBar>                        
                                <Stack tokens={{ childrenGap: 5 }} style={{marginTop: '5px'}}>                                             
                                    <Stack.Item>
                                        <ClipboardTextField value={props.itemToken} name={'token'} label={'Secret Token to authorize'} readOnly={true} />
                                    </Stack.Item>                                                
                                </Stack>   
                            </div>
                        )}             

                        <p style={{paddingTop: '5px'}}>
                            In the section below the behaviour of the SCIM implementation can be adjusted. Please use these settings to 
                            adopt your organisational requirements and the pre-defined behavour of the identity provider.
                        </p>
                        <Stack tokens={{ childrenGap: 5 }} style={{marginTop: '5px'}}>                                             
                            <Stack.Item>
                                <Label>Default Roles for new users</Label>
                                <ControlledFieldList key={'defaultRoles'} placeholder={'Select a default role'} control={control} name={'defaultRoles'} availableFields={existingRoles} />                              
                            </Stack.Item>                                                                            
                        </Stack>                            
                    </div>                    
                )}                
        </PanelWithDefaultFooter>
  
    )
}