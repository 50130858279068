import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { FormEvent, useEffect, useState } from "react";
import { ISdkObjectTemplate } from "../sdk/models/ISdkObjectTemplate";

export interface ISdkAvailableObjectTemplatesDropdownProps {
    tenantId: string
    selectedTemplate: ISdkObjectTemplate | undefined
    availableTemplates: ISdkObjectTemplate[]
    onSelected: (template: ISdkObjectTemplate) => void
    label?: string
    placeholder?: string    
    disabled?: boolean
}

interface IDropdownOptionDatasource extends IDropdownOption {
    datasourceTemplate: ISdkObjectTemplate
}

export const SdkAvailableObjectTemplatesDropdown = (props: ISdkAvailableObjectTemplatesDropdownProps) => {

    // available datasources
    const [availableTemplates, setAvailableTemplates] = useState<IDropdownOptionDatasource[]>()
    
     useEffect(() => {

        setAvailableTemplates(props.availableTemplates.map(d => { 
            return { key: d.id, text: d.name, datasourceTemplate: d}
        }))    

    // eslint-disable-next-line
    }, [props.tenantId, props.availableTemplates])
          
    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: '100%' } };
    
    const onChangeDatasource = (event: FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {    
        props.onSelected((item as IDropdownOptionDatasource).datasourceTemplate)
    };

    return (
        <Dropdown
            label={props.label ? props.label : 'UNKNOWN'}
            selectedKey={props.selectedTemplate ? props.selectedTemplate.id : undefined}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onChangeDatasource}
            placeholder={props.placeholder ? props.placeholder : 'UNKNOWN'}
            options={availableTemplates ? availableTemplates : []}
            styles={dropdownStyles}
            disabled={props.disabled}
        />
    )
}