import { useAuth } from "@mevodo/mv-react-authentication";
import { SdkCustomerDataIntegrationClient } from "../lib/sdk/SdkCustomerDataIntegrationClient";
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate } from "../lib/sdk/models/ISdkObjectTemplate";
import { SdkAddOrEditTemplateBasedObjectPanel } from "../lib/sdkdatacomponents/SdkAddOrEditTemplateBasedObjectPanel";
import { IObjectFromTemplateViewProps } from "./IObjectFromTemplateViewProps";

export const AddOrEditCustomerDataIntegration = (props: IObjectFromTemplateViewProps) => {

    const auth = useAuth()

    const onLoadTemplates = () => {         
        const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId as string, auth.currentToken as string);
        return sdkClient.listDataIntegrationTemplates()         
    }

   const onCreate = (item: ISdkObjectFromTemplateCreate) => {                       
       const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId as string, auth.currentToken as string)            
       return sdkClient.createDataIntegration({
           name: item.name,
           comment: item.comment,    
           type: item.type,
           fieldValues: item.fieldValues,
           manageCustomers: item.optionalFieldValues?.manageCustomers ? true : false,
           parentGroupPath: item.optionalFieldValues?.parentGroupForNewCustomers as string
       })           
    }

   const onUpdate = (item: ISdkObjectFromTemplate) => {
       const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId as string, auth.currentToken as string)            
       return sdkClient.updateDataIntegration(item.id, {            
           name: item.name,
           comment: item.comment,    
           type: item.type,
           fieldValues: item.fieldValues,
           manageCustomers: item.optionalFieldValues?.manageCustomers ? true : false,
           parentGroupPath: item.optionalFieldValues?.parentGroupForNewCustomers as string
       })              
    }

   const onLoad = async (itemId: string) => {
       const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId as string, auth.currentToken as string);                 
       var item = await sdkClient.getDataIntegration(props.itemId as string)

       return {
           id: item.id,
           name: item.name,
           comment: item.comment,
           type: item.type,
           fieldValues: item.fieldValues,
           optionalFieldValues: {
               'manageCustomers': item.manageCustomers,
               'parentGroupForNewCustomers': item.parentGroupPath
           }
       }
    }

    return (
        <SdkAddOrEditTemplateBasedObjectPanel 
                isVisible={props.isVisible} dismissDialog={props.dismissDialog} 
                tenantId={props.tenantId as string} itemId={props.itemId}
                objectTitle={'Data Integration'}
                objectDescription={'Enter or adjust all required details to configure the data integration'}
                templateSelectorLabel="Active Data Integration Template"
                templateSelectorPlaceholder="Select a template"
                optionalFields={[
                    { id: 'manageCustomers', name: 'Manage Customers automatically', type: 'boolean', values: undefined, default: true, disabled: false },
                    { id: 'parentGroupForNewCustomers', name: 'Parent Group for new customers', type: 'string', values: undefined }
                ]}
                onLoadTemplates={onLoadTemplates}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onLoad={onLoad}
                />  
    )
}