import { IColumn, Icon, IconButton, Link, IContextualMenuItem } from "@fluentui/react"
import { useNavigate } from "react-router-dom"
import { useBranding } from "../../ds/components/BrandingProvider";
import { ISdkEnvironmentBranding } from "../../sdk/models/ISdkEnvironmentBranding";

import numbro from 'numbro'
import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime)

export interface IBaseColumnProps<T> {
    name: string,    
    onClick?: (item:T) => void,
    to?: (item:T, opening: boolean) => string,  
    isColumnHidden?: () => boolean,
    getRenderValue?: (item: T, value: string) => string      
    getRenderStyle?: (item: T) => any      
}

export interface ITextColumnProps<T> extends IBaseColumnProps<T> {
    value: string | ((item: T) => string), 
    maxWidth: number,
    subCommands?: ISubCommandDefinition<T>[],
    formatPattern?: string,
    sort?: (name: string, descending: boolean, updatedColumns: IColumn[], getValue: (item: T) => string) => void    
}

export interface ICurrencyColumnProps<T> extends IBaseColumnProps<T> {
    value: (item: T) => string, 
    currency: (item: T) => string,
    maxWidth: number,
    subCommands?: ISubCommandDefinition<T>[],    
    sort?: (name: string, descending: boolean, updatedColumns: IColumn[], getValue: (item: T) => string) => void    
}

export interface IPercentColumnProps<T> extends IBaseColumnProps<T> {
    value: (item: T) => number, 
    mantissa: number,    
    maxWidth: number,
    subCommands?: ISubCommandDefinition<T>[],    
    sort?: (name: string, descending: boolean, updatedColumns: IColumn[], getValue: (item: T) => string) => void    
}

export interface IIconColumnProps<T> extends IBaseColumnProps<T> {    
    headerIcon: string,     
    iconName: string | ((item: T) => string), 
    iconClass?: string | ((item: T) => string)
}

export interface IColumnsBuilder<T> {
    SetSortOperation: (sortHandler: (name: string, descending: boolean, updatedColumns: IColumn[], getValue: (item: T) => string) => void) => IColumnsBuilder<T>        
    TextColumn: (props: ITextColumnProps<T>) => IColumnsBuilder<T>        
    NumberColumn: (props: ITextColumnProps<T>) => IColumnsBuilder<T>        
    CurrencyColumn: (props: ICurrencyColumnProps<T>) => IColumnsBuilder<T>        
    PercentColumn: (props: IPercentColumnProps<T>) => IColumnsBuilder<T>        
    DateTimeColumn: (props: ITextColumnProps<T>) => IColumnsBuilder<T>
    IconColumn: (props: IIconColumnProps<T>) => IColumnsBuilder<T>    
    Build: () => IColumn[]
}

export interface ISubCommandDefinition<T> {
    iconName: string
    description: string
    elipsis?: boolean
    onClick: (item:T) => void
    filter?: (item:T) => boolean
    disabled?: (item:T) => boolean    
}

export class ColumnsBuilder<T> implements IColumnsBuilder<T> {
    
    private _navigate = useNavigate()
    private _sortHandler?: (name: string, descending: boolean, updatedColumns: IColumn[], getValue: (item: T) => string) => void

    private columns: IColumn[] = []

    constructor(private _branding: ISdkEnvironmentBranding | undefined) {}

    private getBaseColumn(name: string, minWidth: number, maxWidth: number) : IColumn {
        return {
            key: '0',
            name: name,
            minWidth: minWidth,
            maxWidth: maxWidth,        
            isResizable: true,
            isSorted: false,
            isIconOnly: false,
            showSortIconWhenUnsorted: false
        }
    }

    private addColumn(column: IColumn) {
        column.key ='column' + this.columns.length
        this.columns.push(column)
    }

    private getOnClickHandler(props: IBaseColumnProps<T>) : (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>, item: T ) =>  void  {

        const nav = this._navigate;      

        return (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>, item: T ): void => {
            if (props.onClick) {
                props.onClick(item);
            } else if (props.to) {
                const toLocation = props.to(item, true)
                if (toLocation !== '') { 

                    if (toLocation.startsWith('new:')) {
                        window.open(process.env.PUBLIC_URL + toLocation.replace('new:', ''),'_blank')                        
                    } else {
                        nav(toLocation) 
                    }
                }                        
            }
        }                
    }

    

    private addRenderingTemplate(column: IColumn, getValueOperation: (column: IColumn, item: T) => string, props: ITextColumnProps<T>) {

        const onClickHandler = this.getOnClickHandler(props)
                
        const iconButtonStyles = this._branding?.colors.primaryHover && this._branding?.colors.primaryHover !== '' ? {
            root: {  
                width: '40px', 
                height: '16px'                
            }, 
            
            rootHovered: { 
                color: this._branding.colors.primaryHover,
            } 
        } : undefined

        column.onRender = function(item: T) {

            const textValue = getValueOperation(column, item)
            
            const onRenderSubCommands = () => {

                let subcommandCounter = 0
                
                if (!props.subCommands) {
                    return (<></>)
                }

                // get all the active subcommands
                const activeSubCommands = props.subCommands.filter(c => c.filter ? c.filter(item) : true)

                // extract all the items for the ellipsis menu                
                const ellipsisMenuItems: IContextualMenuItem[] = activeSubCommands.filter(c => c.elipsis).map((c) => {
                    return {
                        key: 'subcommand' + subcommandCounter++,
                        iconProps: { iconName: c.iconName },
                        text: c.description,
                        onClick: (ev) => { c.onClick(item); if (ev) { ev.preventDefault()} },
                        disabled: c.disabled && c.disabled(item) ? true : false
                    }
                })

                return (
                    <div className="nameFieldCommandBar">                                        
                        {activeSubCommands.filter(c => !c.elipsis).map((c) => { 
                            return (
                                <IconButton  
                                    key={'subcommand' + subcommandCounter++}                   
                                    iconProps={{ iconName: c.iconName }}
                                    title={c.description}
                                    onClick={(ev) => { c.onClick(item); ev.preventDefault() }}  
                                    styles={iconButtonStyles} 
                                    disabled={c.disabled && c.disabled(item) ? true : false}                                  
                                    />
                            )
                        })} 
                        { ellipsisMenuItems && ellipsisMenuItems.length > 0 && (
                            <IconButton key={'sys.elipsis'} iconProps={{iconName: 'faEllipsisVertical'}} styles={iconButtonStyles} menuProps={{items: ellipsisMenuItems}} onRenderMenuIcon={() => null} />
                        )}
                    </div>
                )
            }                       
            
            const linkStyles = {
                textDecoration: 'none',
                cursor: 'pointer', 
                color: 'inherit'                                                    
            }

            const onRenderValue = () => {

                let renderableTextValue = textValue
                
                if (textValue && props.getRenderValue) {
                    renderableTextValue = props.getRenderValue(item, textValue)
                }

                if (textValue && props.formatPattern) {   
                    try {                 
                        renderableTextValue = numbro(renderableTextValue).format(props.formatPattern)                    
                    } catch(e) {
                        renderableTextValue = numbro(0).format(props.formatPattern)                    
                    }
                }

                if (props.onClick) {
                    return (<Link onClick={(ev) => onClickHandler(ev, item)} style={linkStyles} dangerouslySetInnerHTML={{__html: renderableTextValue}} />)                    
                } else if (props.to) {
                    const toLocation = props.to(item, false)
                    return (<Link to={toLocation} onClick={(ev) => onClickHandler(ev, item)} style={linkStyles} dangerouslySetInnerHTML={{__html: renderableTextValue}} />)                                    
                }
                else {
                    return (<span dangerouslySetInnerHTML={{__html: renderableTextValue}} />)
                }                
            }

            const onGetNameFieldStyles = (): any => {
                if (props.getRenderStyle) {
                    return props.getRenderStyle(item)
                } else {
                    return {}
                }
            }

            return (            
                <div className="fieldRendererWithCommands">                    
                    <div className="nameField" style={onGetNameFieldStyles()}>
                        {onRenderValue()}                                            
                    </div>
                    
                    {onRenderSubCommands()}              
                </div>
            )
        }
    }   
    
    private addSortHandler(column: IColumn, props: ITextColumnProps<T>, getValueOperation: (column: IColumn, item: T) => string) {

        // define if the column is sortable or not
        column.showSortIconWhenUnsorted = props.sort ? true : false

        // if we have no sort operation done 
        if (!column.showSortIconWhenUnsorted)
            return

        // assign the sort handler
        column.onColumnClick = (ev: React.MouseEvent<HTMLElement>, clickedColumn: IColumn) => {
            
            // flip the sort direction 
            if (column.isSorted)
                column.isSortedDescending = !column.isSortedDescending

            // ensure all other columns are not have the sort status 
            this.columns.forEach(c => c.isSorted = false)
            
            // set this column as the sorted one 
            column.isSorted = true
            
            const valueOperation = (item: T) => {
                return getValueOperation(column, item)
            }

            // execute the sort operation 
            props.sort!(column.name, column.isSortedDescending!, this.columns, valueOperation)    
        }
    }

    private getDynamicValueOperation(column: IColumn, columnValueField: string | null, value: string | ((item: T) => string) | undefined): (column: IColumn, item: T) => string {
        
        if (!value)
            return (column, item) => { return '' }

        if (typeof(value) === 'string') {
                    
            if (columnValueField) 
                (column as any)[columnValueField] = value as any as string

            return (column, item) => {
                if (columnValueField)
                    return (item as any)[(column as any)[columnValueField] as string]
                else 
                    return value as any as string
            }

        } else {
            return (column, item) => {
                return (value as ((item: T) => string))(item)
            }
        } 
    }

    public SetSortOperation(sortHandler: (name: string, descending: boolean, updatedColumns: IColumn[], getValue: (item: T) => string) => void): IColumnsBuilder<T> {
        this._sortHandler = sortHandler
        return this;
    }

    public TextColumn(props: ITextColumnProps<T>): IColumnsBuilder<T> {
        
        // check if this column is hidden
        if (props.isColumnHidden && props.isColumnHidden())
            return this;

        // get the base column
        let column = this.getBaseColumn(props.name, 50, props.maxWidth)

        // define the value operation we have for the column
        const valueOperation = this.getDynamicValueOperation(column, 'fieldName', props.value);

        // add the rendering options
        this.addRenderingTemplate(column, valueOperation, props)
        
        // add the sort handle if activated 
        if (!props.sort && this._sortHandler) { 
            props.sort = this._sortHandler
        }

        this.addSortHandler(column, props, valueOperation)

        // add to the columnes
        this.addColumn(column)
        
        // done
        return this;
    }

    public NumberColumn(props: ITextColumnProps<T>): IColumnsBuilder<T> { 
        return this.TextColumn(props)
    }

    public CurrencyColumn(props: ICurrencyColumnProps<T>): IColumnsBuilder<T> { 
        return this.TextColumn({...props, getRenderValue: (item: T, stringValue) => {                        
            
            // request the currency
            let currencyValue = props.currency(item)
            if (!currencyValue) { currencyValue = '€' }

            // define the format settings 
            const formatSettings = {
                currencySymbol: currencyValue,
                currencyPosition: 'postfix' as any,
                thousandSeparated: true,
                mantissa: 2,
                spaceSeparated: true                
            }

            // convert to value to a currency value format
            try {
                return numbro(stringValue).formatCurrency(formatSettings)
            } catch(e) {
                return numbro(0).format('(0.00 a)') + ' ' + currencyValue
            }            
        }, getRenderStyle: (item) => {
            return { textAlign: 'right' }
        }})
    }

    public DateTimeColumn(props: ITextColumnProps<T>): IColumnsBuilder<T> {         
        return this.TextColumn({...props, value: (item) => {                        
            const guessedTimezone = dayjs.tz.guess()
            const dt = dayjs.utc((item as any)[props.value as string]).tz(guessedTimezone)
            return dt.format('YYYY-MM-DD - HH:mm:ss') + ' (' + dt.fromNow() + ')'
        }})        
    }

    public PercentColumn(props: IPercentColumnProps<T>): IColumnsBuilder<T> {                 
        return this.TextColumn({...props, value: (item: T) => { 
            
            // get the value
            const value = props.value(item)

            // convert to string
            return value.toString()

        }, getRenderValue: (item: T, stringValue) => {                                    
            return numbro(stringValue).format({
                output: 'percent',
                mantissa: props.mantissa,
                spaceSeparated: true
            })
        }, getRenderStyle: (item) => {
            return { textAlign: 'right' }
        }})
    }

    public IconColumn(props: IIconColumnProps<T>): IColumnsBuilder<T> {

        // check if this column is hidden
        if (props.isColumnHidden && props.isColumnHidden())
            return this;
            
        // get the base column
        let column = this.getBaseColumn(props.name, 16, 16)
        column.isIconOnly=true
        column.showSortIconWhenUnsorted=false

        // define the value operation to get the headerIcon
        const valueOperationHeaderIcon = this.getDynamicValueOperation(column, null, props.headerIcon);

        column.onRenderHeader = () => {
            return (
                <Icon iconName={valueOperationHeaderIcon(column, {} as T)} />
            )
        }

        // define the value operation to get the iconName
        const valueOperationIconName = this.getDynamicValueOperation(column, null, props.iconName);
        const valueOperationIconClass = this.getDynamicValueOperation(column, null, props.iconClass);

        const onClickHandler = this.getOnClickHandler(props)

        const onRenderIconValue = (item: T) => {
            
            const className = valueOperationIconClass(column, item)            

            if (props.onClick) {
                return (<Link onClick={(ev) => onClickHandler(ev, item)} style={{textDecoration: 'none', cursor: 'pointer', color: 'inherit'}}><Icon iconName={valueOperationIconName(column, item)} className={className} /></Link>)                    
            } else if (props.to) {
                const toLocation = props.to(item, false)
                return (<Link to={toLocation} onClick={(ev) => onClickHandler(ev, item)} style={{textDecoration: 'none', cursor: 'pointer', color: 'inherit'}}><Icon iconName={valueOperationIconName(column, item)} className={className} /></Link>)                    
            } else {
                return (<Icon iconName={valueOperationIconName(column, item)} className={className} />)
            }
        }

        column.onRender = onRenderIconValue

        // add to the columnes
        this.addColumn(column)
                
        // done
        return this
    }            

    public Build(): IColumn[] {
        return this.columns
    }
}

export function useColumnsBuilder<T>(): IColumnsBuilder<T> {
    const branding = useBranding()
    return new ColumnsBuilder<T>(branding.company)
}