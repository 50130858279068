import { ISdkObjectFromTemplate, ISdkObjectTemplate } from "./models/ISdkObjectTemplate";
import { ISdkScimDetails } from "./models/ISdkScimDetails";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantIdentityProviderClient {

    private sdkClient: SdkClient<ISdkObjectFromTemplate>
    private sdkTemplateClient: SdkClient<ISdkObjectTemplate>
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectFromTemplate>('/api/v1/tenants/' + tenantId + '/idps', token);
        this.sdkTemplateClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + tenantId + '/idps/templates', token);
    }
    
    listTemplates(): Promise<ISdkObjectTemplate[]> 
    {        
        return this.sdkTemplateClient.list('');            
    }

    registerIdentityProvider(name: string, type: string): Promise<ISdkObjectFromTemplate> {
        return this.sdkClient.post('', {name: name, type: type}).then((location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })
    }

    list(): Promise<ISdkObjectFromTemplate[]> 
    {                
        return this.sdkClient.list('')
    }
    
    load(idpId: string): Promise<ISdkObjectFromTemplate> 
    {                
        return this.sdkClient.get('/' + idpId)
    }

    update_metadata_from_idp(itemToUpdate: ISdkObjectFromTemplate): Promise<ISdkObjectFromTemplate> 
    {                
        return this.sdkClient.put('/' + itemToUpdate.id + '/metadata_from_idp', itemToUpdate).then(() => {
            return this.sdkClient.get('/' + itemToUpdate.id)
        })
    }

    enable_scim(itemId: string): Promise<ISdkScimDetails>
    {                
        return this.sdkClient.postToGetBody('/' + itemId + '/scim', {})
    }

    update_scim(itemId: string, defaultRoles: string[]): Promise<ISdkObjectFromTemplate>
    {                
        return this.sdkClient.put('/' + itemId + '/scim', { DefaultRoles: defaultRoles}).then(() => {
            return this.sdkClient.get('/' + itemId)
        })
    }

    disable_scim(itemId: string): Promise<void>
    {                
        return this.sdkClient.delete('/' + itemId + '/scim').then(() => {
            return Promise.resolve()
        })
    }
    
    delete(idpid: string): Promise<Boolean> {
        return this.sdkClient.delete('/' + idpid)
    }

    requestMetadataLocation(idpId: string): Promise<string | undefined> {
        return this.sdkClient.post('/' + idpId + '/metadata', {}).then((location) => {
            return location as string
        }).catch(() => {
            return undefined
        })
    }    
}