import { Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuth } from "@mevodo/mv-react-authentication";
import { useForm } from "react-hook-form";
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ControlledTextField } from "../lib/forms/ControlledTextField";
import { SdkCustomerDashboardClient } from "../lib/sdk/SdkCustomerDashboardClient";

export interface IAddCustomerDashboardProps extends IAppWindowBaseProps {   
     tenantId: string
     parent: string
     parentType: string
}

export const AddCustomerDashboard = (props: IAddCustomerDashboardProps) => {
        
    const auth = useAuth();           
    
    const [isSubmitProcessing, { setTrue: startSubmitProccesing, setFalse: stopSumitProcessing}] = useBoolean(false)

    type AddCustomerDashboardForm = {
        name: string        
    };
    
    const { handleSubmit, control, reset, formState } = useForm<AddCustomerDashboardForm>({
        defaultValues: {
            name: ''            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
        
    const dissmissDialog = () => {
        reset()        
        if (props.dismissDialog)
            props.dismissDialog()
    }    

    const onSubmit = (): Promise<string | void> => {

        startSubmitProccesing()

        var submitHandler = handleSubmit((data: AddCustomerDashboardForm) => {            
            const dashboardClient = new SdkCustomerDashboardClient(props.tenantId, props.parent, props.parentType, auth.currentToken as string)
            return dashboardClient.createCustomerDashboard(data.name, props.parent, props.parentType === 'customer' ? 'Customer' : 'CustomerGroup',)
        })
        
        return submitHandler()
            .finally(() => {
                stopSumitProcessing()
            })
            .then(() => {
                dissmissDialog()
            })
            .catch((error) => {  
                let errorMessage = 'Something went wrong creating the new dashboard, please try again later'
                errorMessage += error.code ?  ' (' + error.code + ')' : ''            
                return errorMessage
            })                   
    }
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Add new dashboard'}
            onDismiss={dissmissDialog}                                                
            progessMessage={'Creating new dashboard...'}
            submitLabel={'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid}
            onSubmit={onSubmit}>

            <p>Please enter all mandatory information to create a new dashboard. When you are 
            creating a new dashboard on the group level all customer in the group and in inherited
            groups will see this dashboard!</p>    

            <form>
                <Stack tokens={{ childrenGap: 15 }}>                
                    <ControlledTextField label="Dashboard name" control={control} name={'name'} rules={{ required: 'A valid name of the dashboard is required'}} disabled={isSubmitProcessing} />                                             
                </Stack> 
            </form>
        </PanelWithDefaultFooter>
    )
}