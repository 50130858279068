import { IDropdownOption } from "@fluentui/react";
import { Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import { IFieldMappingItem } from "./UnControlledFieldMappingItem";
import { UnControlledFieldMappingList } from "./UnControlledFieldMappingList";

export interface IControlledFieldMappingListProps extends React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {    
  availableFields: IDropdownOption[]
  defaultPivotKey?: string
  availablePivotOperations: IDropdownOption[]  

  onElipsisClicked?: (item: IFieldMappingItem) => void
  onItemsPending?: (pending: boolean) => void
  onFilterAvailablePivotOperations?: (propertyName: string, availableOptions: IDropdownOption[] ) => IDropdownOption[]
}

export const ControlledFieldMappingList = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(props: UseControllerProps<TFieldValues, TName> & IControlledFieldMappingListProps) => {
    
    return (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={props.defaultValue || undefined}
          render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
          }) => (
                <UnControlledFieldMappingList 
                    {...props}
                    items={value || []} 
                    onItemsChanged={(items: IFieldMappingItem[]) => {                    
                        onChange(items)                        
                    }}
                    onItemsPending={props.onItemsPending}
                    availableFields={props.availableFields}
                    defaultPivotKey={props.defaultPivotKey}
                    availablePivotOperations={props.availablePivotOperations}
                    onFilterAvailablePivotOperations={props.onFilterAvailablePivotOperations}
                    />                    
            )}
        />
      );
}