import getUserLocale from 'get-user-locale';

export function getCurrencySymbol(currencyCode: string): string {
    try {
        const locale = getUserLocale()
        const symbol = getCurrencySymbolForLocale(locale, currencyCode)

        if (symbol === 'US$')
            return '$'
        else
            return symbol
        
    } catch(e) {
        return currencyCode
    }
}


export function getCurrencySymbolForLocale(locale: string, currency: string): string {
    return (0).toLocaleString(
      locale,
      {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    ).replace(/\d/g, '').trim()
  }