import { IButtonStyles, PartialTheme, ThemeProvider } from '@fluentui/react'
import NotificationsSystem, { bootstrapTheme, POSITIONS, setUpNotifications, useNotifications } from 'reapop';
import './AppShell.scss'
import { useBranding } from './BrandingProvider';
import { Helmet } from "react-helmet";
import { FluentProvider, PartialTheme as PartialThemaV9, webLightTheme } from '@fluentui/react-components';
import React from 'react';

export interface AppShellContextProps {
    themeV9: PartialThemaV9
}

export const emptyAppShellContextProps: AppShellContextProps = {themeV9: webLightTheme}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AppShellContext = React.createContext<AppShellContextProps>(emptyAppShellContextProps);

export const useAppShellContext = () => {
    return React.useContext(AppShellContext);
};

export interface AppShellProps {
    children?: React.ReactNode
}

export function AppShell(props: AppShellProps) 
{
    const {notifications, dismissNotification} = useNotifications()
    const branding = useBranding()

    setUpNotifications({
        defaultProps: {
            position: POSITIONS.bottomLeft,
            dismissible: true,
            allowHTML: true,
        },
    })
    
    const primaryHoverButtonColor = branding.company?.colors.primaryHover && branding.company?.colors.primaryHover !== '' ? branding.company?.colors.primaryHover : 'rgb(16, 110, 190)'     

    const myThemeButtonStyles: IButtonStyles = {
        rootHovered: {
            backgroundColor: primaryHoverButtonColor,
            borderColor: primaryHoverButtonColor            
        }
    }

    const myTheme: PartialTheme = {
        palette: {
          themePrimary: '#484644',          
          themeDark: '#242322',          
        },
        components: { 
            PrimaryButton: { styles: myThemeButtonStyles }         
        }
      };
    
    const myThemeV9: PartialThemaV9 = {
        ...webLightTheme,   
        
        colorNeutralBackground1Hover: primaryHoverButtonColor,
        //colorNeutralForeground1Hover: 'white',
        
        colorBrandBackground: '#484644',
        colorCompoundBrandStroke: primaryHoverButtonColor,        
        colorCompoundBrandBackground: primaryHoverButtonColor,
        colorCompoundBrandBackgroundHover: '#484644',
                    
        borderRadiusMedium: '0px',
        borderRadiusLarge: '0px',
        borderRadiusSmall: '0px',        
        borderRadiusNone: '0px',      
    }

    return (
        <AppShellContext.Provider value={{themeV9: myThemeV9}}>
            <ThemeProvider theme={myTheme}>
                <FluentProvider theme={myThemeV9}>  
                    <Helmet>                
                        <title>{branding.company?.name || 'Cloud Billing'}</title>
                        <link rel="icon" type="image/png" sizes="16x16" href={branding.company?.logos.fav16} />
                        <link rel="icon" type="image/png" sizes="32x32" href={branding.company?.logos.fav32} />
                    </Helmet>
                    <div className="mv-app-shell">            
                        {props.children}
                    </div>
                    <div>
                        <NotificationsSystem
                            // 2. Pass the notifications you want Reapop to display.
                            notifications={notifications}
                            // 3. Pass the function used to dismiss a notification.
                            dismissNotification={(id) => dismissNotification(id)}
                            // 4. Pass a builtIn theme or a custom theme.
                            theme={bootstrapTheme}
                        />
                    </div>
                </FluentProvider>
            </ThemeProvider>
        </AppShellContext.Provider>
    )
}






