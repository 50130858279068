import { useParams } from "react-router-dom"
import { useState } from "react";
import { INavigationMenuSidebarRouteEntry, NavigationMenuSidebar } from "../lib/components/DetailsListHelper/NavigationMenuSideBar";
import { useSubscriptionContext } from "../lib/authentication/AuthenticationSubscriptionContext";
import { SdkProfileClient } from "../lib/sdk/SdkProfileClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";

export const SettingsManagement = () => {

    const { tenantId } = useParams();            
    const auth = useAuthInContext(tenantId as string)

    const [menuItems, setMenuItems] = useState<INavigationMenuSidebarRouteEntry[] | undefined>(undefined)
            
    const subContext = useSubscriptionContext()
    

    // read all the dashboards the user created for this tenant
    useEffectAsync(async () => {
        
        setMenuItems(undefined)

        // prepare the access check service
        const accessCheckClient = new SdkProfileClient(auth.currentToken as string);

        // the menuitems 
        const buildedMenuItems: INavigationMenuSidebarRouteEntry[] = []

        // add the manage dashboards item
        buildedMenuItems.push({name: 'Users', route: '/tenants/' + tenantId + '/settings/users'})

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.serviceprovider'))) {
            buildedMenuItems.push({name: 'Tenants', route: '/tenants/' + tenantId + '/settings/tenants'})
        }
        
        // ask the backend if we have access to this resource
        const hasEnterpriseSSOPermission = await accessCheckClient.hasAccess('mevodo.cloud.service.accounts/' + tenantId + '/tenant', 'mevodo.cloud.service.accounts/idps/list')
        if (hasEnterpriseSSOPermission) {
            buildedMenuItems.push({name: 'Enterprise SSO', route: '/tenants/' + tenantId + '/settings/identityproviders'})
        }

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.serviceprovider'))) {
            buildedMenuItems.push({name: 'Billy', route: '/tenants/' + tenantId + '/settings/billy'})
        }

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.managedcustomer'))) {
            buildedMenuItems.push({name: 'Services', route: '/tenants/' + tenantId + '/settings/services'})
        }

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => s.id.endsWith('.serviceprovider'))) {
            buildedMenuItems.push({name: 'Status', route: '/tenants/' + tenantId + '/settings/status'})
        }

        if (subContext && subContext.subscriptions && subContext.subscriptions.find((s) => !s.id.endsWith('.managedcustomer'))) {
            buildedMenuItems.push({name: 'Subscriptions', route: '/tenants/' + tenantId + '/settings/subscriptions'})
        }

        setMenuItems(buildedMenuItems)
    }, [tenantId, subContext])

    return (<NavigationMenuSidebar routes={menuItems} />)
}