import dayjs from 'dayjs'

export const getMonthDefinition = (date: Date): string => {
    return dayjs(date).format('YYYY-MM')    
}

export const getCurrentMonthDefinition = (): string => {    
    return getMonthDefinition(new Date())
}

export const getRelativeMonthDefinition = (months: number): string => {    
    return dayjs().add(months, 'month').format('YYYY-MM')    
}