import { DefaultButton, MessageBar, MessageBarType, Panel, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { SdkCustomerDatasourceClient } from "../lib/sdk/SdkCustomerDatasourceClient"

export interface IImportCustomerDatasourceDialogProps extends IAppWindowBaseProps {
    tenantId: string
    parent?: string
}

export const ImportCustomerDatasourceDialog = (props: IImportCustomerDatasourceDialogProps) => { 

    const auth = useAuthInContext(props.tenantId);           
    
    // load all the templates
    const [error, setError] = useState<string>()
    const [isProcessing, { setTrue: startProccesing, setFalse: stopProcessing}] = useBoolean(false)

    type ImportDataSourceForm = {
        url: string,
        clientId: string,
        secret: string                
    };

    const { handleSubmit, control, reset } = useForm<ImportDataSourceForm>();

    const dissmissDialog = () => {        
        reset()
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit: SubmitHandler<ImportDataSourceForm> = (data, e) => {        
        startProccesing()
        setError('')

        // parse the service URL
        const serviceUrl = new URL(data.url)
        
        // create the auth uri 
        const authUri = serviceUrl.origin + '/cb1/sts/issue/oauth/token'        

        // exchange clientId and secret for a token
        // curl -X POST -d "client_id={{YOUR CLIENT ID}}&client_secret={{YOUR CLIENT SECERT}}&grant_type=client_credentials" https://apps.mevodo.com/cb1/sts/issue/oauth/token
        const authData = new URLSearchParams();
        authData.append('client_id', data.clientId);
        authData.append('client_secret', data.secret);
        authData.append('grant_type', 'client_credentials');

        console.log("Generating token... ")
        fetch(authUri, { method: 'post', body: authData }).then((response) => {            
            if (!response.ok)
                throw new Error('Error while fetching token: ' + response.statusText)

            return response.json().then((token) => { 

                // get all datasources 
                console.log("Receiving all containers... ")
                const getDataSourcesUri = serviceUrl.origin + serviceUrl.pathname.replace('/app2/teams', '/api/v2/csps').replace('/apps/accounts', '/accounts')
                return fetch(getDataSourcesUri, { headers: { 'Authorization': 'Bearer ' + token.access_token } }).then(containerResponse => {
                    if (!containerResponse.ok)
                        throw new Error('Error while fetching datasources: ' + containerResponse.statusText)

                    console.log("Creating missing containers... ")
                    return containerResponse.json().then( async (containers) => {

                        console.log(containers)

                        const datasourceClient = new SdkCustomerDatasourceClient(props.tenantId, props.parent as string, auth.currentToken as string)  
                        const existingDataSources = await datasourceClient.listDatasources()
                        
                        const createPromises = containers.map((container: any) => {

                            // check if container still exists 
                            const existingContianer = existingDataSources.find(c => c.name === container.accountName)
                            if (existingContianer) {
                                console.log('Container ' + container.name + ' already exists')
                                return Promise.resolve()
                            }

                            // create the datasource 
                            return datasourceClient.createDatasource({
                                name: container.accountName,
                                type:"azure.costmgnt.container",
                                comment:"Imported",
                                fieldValues: {
                                    accountName: container.accountName,
                                    accountKey: container.accountKey,
                                    reportContainer: container.reportContainer,
                                    reportFolder: container.reportFolder
                                },
                                optionalFieldValues: {}
                            });
                        })

                        return Promise.allSettled(createPromises)                        
                    })
                })                                
            })            
        }).catch(() => {

            setError('Error while importing datasource')

        }).finally(() => {                                    
            stopProcessing()
            if (!error)
                dissmissDialog()
        })            
    }
    
    const stackTokens = { childrenGap: 15 };

    const buttonStyles = { root: { marginRight: 8 } };
        
    const onRenderFooterContent = useCallback(() => (        
        <div>
          
          { isProcessing ? (<ProgressIndicator label="Importing datasource..." />) : (<></>)}
          <PrimaryButton onClick={handleSubmit(onSubmit)} styles={buttonStyles} disabled={isProcessing}>Import</PrimaryButton>
          <DefaultButton onClick={dissmissDialog} disabled={isProcessing}>Cancel</DefaultButton>
        </div>
      ),
      // eslint-disable-next-line 
      [isProcessing])

    return (
        <Panel
            isOpen={props.isVisible}
            headerText={'Import Datasources'}
            onDismiss={dissmissDialog}            
            onRenderFooterContent={onRenderFooterContent}            
            isFooterAtBottom={true}>

            { error ? (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {'Error: ' + error}
                    </MessageBar>
                ) : (<></>)}

            <Stack tokens={stackTokens}>   
                <Stack.Item>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    
                        <ControlledTextField label={'Service Url'} control={control} name={'url'} rules={{ required: 'a valid url is needed'}} disabled={isProcessing} />                                                                     

                        <ControlledTextField label={'ClientId'} control={control} name={'clientId'} disabled={isProcessing} />                                                                     
                        <ControlledTextField label={'Secret'} control={control} name={'secret'} disabled={isProcessing} />                                                                     
                        
                    </form>
                </Stack.Item>                                                      
            </Stack>                                 
  </Panel>
    )
}