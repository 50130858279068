import { IDropdownOption, PanelType, Spinner, SpinnerSize, mergeStyleSets } from "@fluentui/react"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useForm } from "react-hook-form"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { getMonthDefinition, getRelativeMonthDefinition } from "../lib/components/Helpers/DateHelpers"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ControlledDropdown } from "../lib/forms/ControlledDropdown"
import { ISdkDataIntegration } from "../lib/sdk/models/ISdkDataIntegration"
import { SdkCustomerDataIntegrationClient } from "../lib/sdk/SdkCustomerDataIntegrationClient"
import { ControlledCheckbox } from "../lib/forms/ControlledCheckbox"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { useState } from "react"
import { ControlledFieldList } from "../lib/forms/ControlledFieldList"
import { IFilterItem } from "../lib/forms/UnControlledFilterItem"

export interface ISyncDataintegrationPeriodSelectorPanel extends IAppWindowBaseProps { 
    tenantId: string    
    dataIntegration?: ISdkDataIntegration
}
export const SyncDataintegrationPeriodSelectorPanel = (props: ISyncDataintegrationPeriodSelectorPanel) => {

    const auth = useAuthInContext(props.tenantId as string)

    type SelectedPeriod = {
        period: string,
        forcePrevious: boolean,
        cleanUpData: boolean,
        includedCustomers: IFilterItem[]
    }

    const [availableCustomers, setAvailableCustomers] = useState<IDropdownOption[]>([])
    const [isPreparing, setIsPreparing] = useState<boolean>(false)

    const { handleSubmit, control, reset, formState, watch } = useForm<SelectedPeriod>({
        defaultValues: {
            period: getMonthDefinition(new Date())            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    const onDismissDialog = () => {            

        reset()

        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = (): Promise<void> => {    
        
        const submitHandler = handleSubmit((data: SelectedPeriod) => {                                
            const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId as string, auth.currentToken as string);
            return sdkClient.syncDataIntegration(props.dataIntegration!.id, data.period, data.cleanUpData, data.forcePrevious, data.includedCustomers ? data.includedCustomers.map(c => c.field) : [])                                        
        })

        return submitHandler()        
    }

    useEffectAsync(async () => {

        if (!props.dataIntegration)
            return

        setIsPreparing(true)

        const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId, auth.currentToken as string);                 
        const stats = await sdkClient.getDataIntegrationStats(props.dataIntegration.id as string)
        if (!stats.linkedDataSources || stats.linkedDataSources.length === 0) {
            setAvailableCustomers([])            
        } else {

            const sortedCustomers = stats.linkedDataSources.sort((a, b) => a.customerName.localeCompare(b.customerName))

            setAvailableCustomers(sortedCustomers.map(ds => {
                return {
                    key: ds.customerId,
                    text: ds.customerName
                }        
            }))
        }

        setIsPreparing(false)

    }, [props.dataIntegration])

    const availablePeriods: IDropdownOption[] = []
    for(let i = -60; i <= 0; i++) {

        const monthString = getRelativeMonthDefinition(i);

        availablePeriods.push({
            key: monthString,
            text: monthString
        })
    }

    availablePeriods.reverse()

    const classNames = mergeStyleSets({
        checkbox: {
            marginTop: '10px'
        }
    })

    const showForcePrevious = props.dataIntegration && props.dataIntegration.type.indexOf('MSFT.PartnerCenter') !== -1;
    
    const propertyWatcher = watch(['includedCustomers'])        
    const noCustomerLimitMessage = 'The synchronization will be executed for all customers the data integration is associated with. Do you really want to start a synchronization for all customers?'
    const customerLimitMessage = 'The synchronization will be executed for the selected customers only. Do you really want to start a synchronization for the selected customers?'
        
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Manual synchronization for ' + props.dataIntegration?.name}
            onDismiss={onDismissDialog}
            onSubmit={onSubmit}
            type={PanelType.medium} 
            progessMessage={'Trigger operation....'} 
            submitLabel={'Start Sychronization'} 
            dissmissLabel={'Cancel'}
            doubleConfirmMessage={propertyWatcher[0] && propertyWatcher[0].length > 0 ? customerLimitMessage : noCustomerLimitMessage}
            isValid={formState.isValid}>

            { isPreparing ?
                    (<Spinner size={SpinnerSize.large} label="Preparing data..." labelPosition="bottom" style={{height: '100%'}} />) :
                    (<>
                        <p>
                            A data integration contains data for several customers and includes an allocation logic to 
                            distribute the consumption to the different customers. Please select the period a data 
                            synchronization should be executed for:
                        </p>    

                        <ControlledDropdown key={'validUntil'} control={control} name={'period'} label={'Period for synchronization'} rules={{ required: 'a valid value is required'}} options={availablePeriods} />            

                        <p><strong>Advanced options:</strong></p>

                        { showForcePrevious && (
                            <ControlledCheckbox key={'forcePrevious'} control={control} name={'forcePrevious'} label={'Prefer unbilled-previous usage workspace even when invoice is available'} className={classNames.checkbox} />            
                        )}

                        <ControlledCheckbox key={'cleanUpData'} control={control} name={'cleanUpData'} label={'Removing existing data of the selected period during the data-synchronization'} className={classNames.checkbox} />            

                        <p style={{marginTop: '20px'}}><strong>Limit synchronization to dedicated customers:</strong></p>
                        <p style={{marginBottom: '0'}}>
                            The synchronization can be limited to dedicated customer, please select specific customer or let the list 
                            empty to execute the synchronization to all customers the data integration is associated with:                                                   
                        </p>
                        <ControlledFieldList key={'includedCustomers'} control={control} name={'includedCustomers'} placeholder={'Select and add a customer'} disabled={isPreparing} availableFields={availableCustomers} />                                
                        
                    </>)
            }                            
        </PanelWithDefaultFooter>            
    )
}