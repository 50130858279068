import { ISdkCustomer, ISdkCustomerDetails } from "./models/ISdkCustomer";
import { ISdkTenantSubscriptionModel } from "./models/ISdkTenantSubscriptionModel";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerClient {

    private sdkClient: SdkClient<ISdkCustomer>    
    private sdkCustomerDetailsClient: SdkClient<ISdkCustomerDetails>    
    private sdkCustomerSubscriptionClient: SdkClient<ISdkTenantSubscriptionModel>    
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkCustomer>('/api/v1/tenants/' + tenantId + '/svp/customers', token);
        this.sdkCustomerDetailsClient = SdkClientServiceDiscoveryService.Discover<ISdkCustomerDetails>('/api/v1/tenants/' + tenantId + '/svp/customers', token);
        this.sdkCustomerSubscriptionClient = SdkClientServiceDiscoveryService.Discover<ISdkTenantSubscriptionModel>('/api/v1/tenants/' + tenantId + '/svp/customers', token);
    }

    listCustomers(parent?: string): Promise<ISdkCustomer[]> 
    {        
        const parentGroupId = parent ? parent : 'root'
        return this.sdkClient.list('?parentGroupId=' + parentGroupId);        
    }

    createCustomer(name: string, primaryCurrency: string, parent?: string): Promise<ISdkCustomer> 
    {
        const parentGroupId = parent ? parent : 'root'

        const payload = {
            name: name,
            primaryCurrency: primaryCurrency,
            parentId: parentGroupId
        }

        return this.sdkClient.post('', payload).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })               
    }

    updateCustomer(id: string, name: string, primaryCurrency: string): Promise<ISdkCustomer> 
    {
        const payload = {
            name: name,
            primaryCurrency: primaryCurrency        
        }

        return this.sdkClient.put('/' + id, payload).then(async (location) => {
            return this.getCustomer(id)
        })               
    }

    deleteCustomer(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }

    issueToken(id: string): Promise<string>
    {
        return this.sdkClient.getIndirectType<string>('/' + id + '/tokens');
    }


    getCustomer(id: string): Promise<ISdkCustomerDetails>
    {
        return this.sdkCustomerDetailsClient.get('/' + id + '/details')
    }

    moveCustomer(id: string, newParentGroupId: string): Promise<Boolean>
    {
        return this.sdkClient.postToGetStatus('/' + id + '/move', { targetParentId: newParentGroupId});
    }

    getSubscriptions(id: string): Promise<ISdkTenantSubscriptionModel[]> {
        return this.sdkCustomerSubscriptionClient.list('/' + id + '/subscriptions');
    }

    setSubscriptions(id: string, subscritions: string[]): Promise<Boolean> {
        return this.sdkCustomerSubscriptionClient.postToGetStatus('/' + id + '/subscriptions', subscritions);        
    }

    deployFromTeplateCustomer(id: string, templateCustomerId: string): Promise<Boolean> {
        return this.sdkClient.postToGetStatus('/' + id + '/deploy', { templateCustomerId: templateCustomerId });
    }
}