import './NavigationalDetailsList.scss'
import { Breadcrumb, DetailsListLayoutMode, IBreadcrumbItem, IColumn, ShimmeredDetailsList, Selection, MarqueeSelection, SelectionMode, IDropdownOption, IGroup, IRenderFunction, IDetailsGroupDividerProps } from "@fluentui/react"
import { ControlledCommandBar, ICommandBarService } from "./ComandBarService"
import { SearchBarControl } from './SearchBarControl'
import { IFilterItem } from '../../forms/UnControlledFilterItem'

export interface INavigationalDetailsListProps { 
    uniqueIdentifier: string
    title?: string,
    dataItems: any[]
    dataGroups?: IGroup[]
    onRenderDataGroupHeader?: IRenderFunction<IDetailsGroupDividerProps>;
    breadcrumbItems?: IBreadcrumbItem[]
    columns: IColumn[]
    isLoading: boolean,
    commandBarService?: ICommandBarService
    selection?: Selection
    isSingelSelection?: boolean
    embedded?: boolean
    allowSearching?: boolean
    allowFiltering?: boolean
    availableSearchFields?: IDropdownOption[]
    initialSearchFilters?: IFilterItem[]
    initialSearchText?: string
    onSearch?: (filters: IFilterItem[], searchText: string) => void
}

export interface INavigationalDetailsListChildrenProps extends INavigationalDetailsListProps {
    children?: React.ReactNode
}

function MarqueeSelectionOptional(props: INavigationalDetailsListChildrenProps) {
    
    if (props.selection) {
        return (
            <MarqueeSelection selection={props.selection}>
                {props.children}        
            </MarqueeSelection>
        )
    } else {
        return (<>{props.children}</>)
    }
}

export function NavigationalDetailsList(props: INavigationalDetailsListProps) {
          
    function renderBreadCrumbOrTitle() {

        if (props.breadcrumbItems) {
            return (
                <div className="mv-breadcrumb-wrapper">
                    <Breadcrumb items={props.breadcrumbItems} maxDisplayedItems={10} />
                </div>
            ) 
        } else if (props.title) {
            const breadcrumbItems: IBreadcrumbItem[] = [ { text: props.title, key: 'root' }]
            return (
                <div className="mv-breadcrumb-wrapper">
                    <Breadcrumb items={breadcrumbItems} maxDisplayedItems={1} />                    
                </div>
            )
        }
    }
    
    const classNames = 
        (props.selection ? 'hasSelection' : 'noSelection') + ' ' +
        (props.embedded ? 'embedded' : 'notEmbedded')

    return (
        <div className="mv-navigational-detaillist">
            { props.commandBarService ? (
                <ControlledCommandBar service={props.commandBarService} />
            ) : 
            ( <></>)}

            { props.allowSearching ? (
                <SearchBarControl availableFields={props.availableSearchFields!} onSearch={props.onSearch} initialFilters={props.initialSearchFilters!} allowFiltering={props.allowFiltering ? true : false} />                
            )
            : (<></>)}

            {renderBreadCrumbOrTitle()}

            <MarqueeSelectionOptional {...props}>
                <ShimmeredDetailsList
                    className={classNames}
                    items={props.dataItems}  
                    groups={props.dataGroups}  
                    groupProps={{
                        showEmptyGroups: true,
                        onRenderHeader: props.onRenderDataGroupHeader
                    }}          
                    columns={props.columns}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}                  
                    enableShimmer={props.isLoading}
                    selection={props.selection}
                    selectionPreservedOnEmptyClick={false}
                    selectionMode={props.selection ? props.isSingelSelection ? SelectionMode.single : SelectionMode.multiple : SelectionMode.none }
                    setKey={props.uniqueIdentifier}
                />
            </MarqueeSelectionOptional>
        </div>
    )
}