import { useParams } from "react-router-dom"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { TriggerManagementComponent } from "../lib/components/Triggers/TriggerManagementComponent"

export interface IServiceProviderTriggersManagementProps {

}

export const ServiceProviderTriggersManagement = (props: IServiceProviderTriggersManagementProps) => {

    const { tenantId } = useParams()    

    return ( 
        <div className="mv-content-container">
            <PageHeader title={'Triggers'}>            
                <span>                    
                    Triggers are a type of mechanism used to automatically perform certain actions in response to specific events. They are 
                    commonly used to create reports on a scheduled manner, but can also offer ither starting points. This view allows to manage 
                    all triggers available in the system.</span>
            </PageHeader>

            <TriggerManagementComponent tenantId={tenantId as string} />        
        </div>
    )
}