import { PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { SdkCustomerGroupTree } from "../lib/sdkdatacomponents/SdkCustomerGroupTree"
import { useState } from "react"
import { ServiceProviderCustomerManagementUIService } from "./ServiceProviderCustomerManagementUIService"

export interface IMoveResourceDialogProps extends IAppWindowBaseProps {
    tenantId: string
    sourceParentGroupId: string
    resourceIds: string[]
}

export const MoveResourceDialog = (props: IMoveResourceDialogProps) => { 
    
    const auth = useAuthInContext(props.tenantId as string)
    const uiService = new ServiceProviderCustomerManagementUIService(props.tenantId, auth.currentToken as string)

    const [selectedGroup, setSelectedGroup] = useState<string>()
    const [doubleConfirmMessage, setDoubleConfirmMessage] = useState<string | undefined>()

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onExecuteMoveOperation = () => {

        if (!selectedGroup) { return Promise.reject() }

        const customerClient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        return Promise.all<Boolean>(props.resourceIds.map(r => customerClient.moveCustomer(r, selectedGroup))).then(() => {
            return Promise.resolve()
        })
    }

    const onSelectedGroupChanged = (groupId: string) => {

        // reset the confirm message
        setDoubleConfirmMessage(undefined)

        // try to get the partner channel for the target group
        uiService.loadPartnerChannelItem(groupId).then(partnerChannelTargetGroup => {
            
            // try to get the parter channel for the source group
            return uiService.loadPartnerChannelItem(props.sourceParentGroupId).then(partnerChannelSourceGroup => {

                // check of we move into or from a partner channel
                let isMoveWithPartnerChannel = false
                if (!partnerChannelSourceGroup && partnerChannelTargetGroup) { isMoveWithPartnerChannel = true}
                if (partnerChannelSourceGroup && !partnerChannelTargetGroup) { isMoveWithPartnerChannel = true}
                if (partnerChannelSourceGroup && partnerChannelTargetGroup && partnerChannelSourceGroup.id !== partnerChannelTargetGroup.id) { isMoveWithPartnerChannel = true}
                
                // check if both groups are different
                if (isMoveWithPartnerChannel) {
                    setDoubleConfirmMessage('You are about to move resources into a partner channel or away from a partner channel. Please be aware that the corresponding reseller will get access or is loosing acces to the customer details!')
                }
                
                // set the group
                setSelectedGroup(groupId)
            })
        })                
    }

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Move ' + props.resourceIds.length + ' resource' + (props.resourceIds.length > 1 ? 's' : '')}
            onDismiss={dissmissDialog}                                    
            type={PanelType.medium}
            progessMessage={'Moving resources...'}
            submitLabel={'Move'}
            dissmissLabel={'Cancel'}                        
            isValid={selectedGroup !== undefined}            
            onSubmit={onExecuteMoveOperation}
            doubleConfirmMessage={doubleConfirmMessage}
            >   

            <div>
                <p>Please kindly choose a group where you would like the selected resources to be relocated to:</p>            
                <SdkCustomerGroupTree tenantId={props.tenantId} virutalRootId={'root'}  onChange={onSelectedGroupChanged}/>                                   
            </div>            
        </PanelWithDefaultFooter>
    )
}
