import { useParams } from "react-router-dom"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { ReportDiscountProfileManagementComponent } from "../lib/components/Reports/ReportDiscountProfileManagementComponent"

export interface IServiceProviderReportsDiscountProfilesProps {

}

export const ServiceProviderReportsDiscountProfiles = (props: IServiceProviderReportsDiscountProfilesProps) => {

    const { tenantId } = useParams()    

    return ( 
        <div className="mv-content-container">
            <PageHeader title={'Reports - Management of Discount/Uplift Profiles'}>            
                <span>Allows to manage all discount and uplift profiles usable in reports</span>
            </PageHeader>

            <ReportDiscountProfileManagementComponent tenantId={tenantId as string} reportCollectionType={'serviceProviderCollection'} />        
        </div>
    )
}