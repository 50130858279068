import { AuthenticationContextProps } from "@mevodo/mv-react-authentication";
import { SdkProfileClient } from "../sdk/SdkProfileClient";
import { ISdkTenant } from "../sdk/models/ISdkTenant"
import { AuthenticationProfileContextProps } from "./AuthenticationProfileContext"

export interface ISdkTenantEx extends ISdkTenant {
    isAdminOnBehalfOf: boolean
}

export const loadActiveTenantDetailsFromProfileContext = async (tenantId: string, authContext: AuthenticationContextProps, profileContext: AuthenticationProfileContextProps): Promise<ISdkTenantEx | undefined> => {
    
    // set the default state of admin on behalf of
    let localIsAdminOnBehalfOf = false

    // try to find the active tenant
    let activeTenant = profileContext.profile.tenants.find(t => t.id === tenantId);

    // if not the system loads the tenant, we understand this as the admin on behalf of situation
    if (!activeTenant) { 
        
        // load the tenant with the profile client
        var client = new SdkProfileClient(authContext.currentToken as string)
        var loadedProfile = await client.getProfile(tenantId)
        if (loadedProfile) {
            activeTenant = loadedProfile.tenants.find(t => t.id === tenantId);
        }

        // set the admin on behalf of flag
        localIsAdminOnBehalfOf = true
    }

    // bail out in case we was not able to find a tenant
    if (!activeTenant) { return undefined }

    // encapsulate the tenant with the admin on behalf of flag    
    return {...activeTenant, isAdminOnBehalfOf: localIsAdminOnBehalfOf}
}