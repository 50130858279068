import { ISdkRole } from "./models/ISdkRole";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantRoleClient {
    private _sdkRoleClient: SdkClient<ISdkRole>

    constructor(
        private _tenantId: string,
        private _token: string)         
    {
        this._sdkRoleClient = SdkClientServiceDiscoveryService.Discover<ISdkRole>('/api/v1/tenants/' + this._tenantId + '/roles', this._token);;
    }

    loadRoles(): Promise<ISdkRole[]> {
        return this._sdkRoleClient.list('');
    }
}