import { ISdkTenantInvoice } from "./models/ISdkTenantInvoice";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantInvoiceClient {

    private sdkTenantInvoiceClient: SdkClient<ISdkTenantInvoice>        
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkTenantInvoiceClient = SdkClientServiceDiscoveryService.Discover<ISdkTenantInvoice>('/api/v1/tenants/' + tenantId + '/svp/invoices', token);        
    }

    listInvoices(searchText?: string): Promise<ISdkTenantInvoice[]> 
    {                
        return this.sdkTenantInvoiceClient.list(searchText ? '?search=' + searchText : '')
    }    
}