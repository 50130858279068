import './PanelWizard.scss'
import { DefaultButton, IPanelProps, Panel, PrimaryButton } from "@fluentui/react"
import { FluentProvider } from '@fluentui/react-components'
import React, { useCallback, useContext } from "react"
import { useState } from "react"
import { useAppShellContext } from '../../ds/components/AppShell'

export interface IPanelWizardProps extends IPanelProps {    
    children?: React.ReactNode[]    
}

export interface IPanelWizardStepProps {
    step: string
    children?: React.ReactNode
    hasFooterNavigation: boolean     
    isLastPage?: boolean
}

export interface IPanelWizardContextProps {
    goToStep: (step?: string) => void    
    setNextValid: (valid: boolean) => void
    onGoToNextStep: (cb:() => void) => void   
    setItemState: (item: any) => void
    getItemState: () => any
}

export const PanelWizardContext = React.createContext<IPanelWizardContextProps>({ goToStep: (step?: string) => {}, onGoToNextStep: (cb:() => void) => {}, setNextValid: (valid: boolean) => {}, setItemState: (item: any) => {}, getItemState: () => {}})

export const PanelWizard = (props: IPanelWizardProps) => { 

    const appShellContext = useAppShellContext()

    // set the state to remember the current step
    const [currentStepProps, setCurrentStepProps] = useState<IPanelWizardStepProps>()
    const [currentStepElement, setCurrentStepElement] = useState<React.ReactNode>()
    const [isNextValid, setNextValue] = useState<boolean>(false)
    const [dataItem, setDataItem] = useState<any>({})


    const onDismiss = () => {
        if (props.onDismiss)
            props.onDismiss()    
    }
        
    let currentNextStepCallback: () => void = () =>{}
    const onGoToNextStepImpl = (cb:() => void) => {        
        currentNextStepCallback = cb;
    }

    const onNext = (pageProps: IPanelWizardStepProps) => {

        if (currentNextStepCallback) {
            setNextValid(false)
            currentNextStepCallback()      
        }  
    }

    const setNextValid = (valid: boolean): void  => {
        setNextValue(valid)
    }
    const setItemState = (item: any): void  => {
        setDataItem(item)
    }
    const getItemState = (): any => {
        return dataItem;
    }

    const buttonStyles = { root: { marginRight: 8 } };

    const onRenderFooterNavigation = useCallback(() => (        
        <div>                   
          <DefaultButton onClick={() => onDismiss() } disabled={false} styles={buttonStyles}>Cancel</DefaultButton>
          <PrimaryButton onClick={() => onNext(currentStepProps as IPanelWizardStepProps)} disabled={!isNextValid}>{currentStepProps && currentStepProps.isLastPage ? 'Finish' : 'Next'}</PrimaryButton>          
        </div>
    ),
    // eslint-disable-next-line 
    [currentStepProps, isNextValid])

    // select props && the active.childr&& props.children.length > 0p 
    if (!props.children)
        return (<></>)
    
    // allow to activate the next step
    const activateStep = (step?: string) => {

        if (step && step === '$$dismiss$$')
            return onDismiss()
            
        if (!props.children)
            return;

        for(const children of props.children) {

            var childrenAny = children as any

            if (!childrenAny || !childrenAny.props.step)
                continue

            if (!step || childrenAny.props.step === step) {                    
                setCurrentStepProps(childrenAny.props)
                setCurrentStepElement(children)
                return
            }
        }

        // at this point we did not find a step
        alert('Step not found: ' + step)
    }

    // ensue the first step is active
    if (!currentStepElement)
        activateStep()    
        
    // render the current step
    return (
        <div className="mv-panelwizard">
            <Panel 
                isOpen={props.isOpen} 
                onDismiss={onDismiss} 
                type={props.type} 
                headerText={props.headerText} 
                onRenderFooterContent={currentStepProps && currentStepProps.hasFooterNavigation ? onRenderFooterNavigation : undefined}
                isFooterAtBottom={true}>
                <FluentProvider theme={appShellContext.themeV9}>
                    <PanelWizardContext.Provider value={{goToStep: activateStep, onGoToNextStep: onGoToNextStepImpl, setNextValid: setNextValid, setItemState: setItemState, getItemState: getItemState}}>
                        {currentStepElement}
                    </PanelWizardContext.Provider>
                </FluentProvider>
            </Panel>
        </div>
    )
}

export function useWizard<T>() {

    var context = useContext(PanelWizardContext);

    class PanelWizardContextAccessor<T> {

        constructor(private _context: IPanelWizardContextProps) {}

        getContext(): IPanelWizardContextProps {
            return this._context
        }

        setData(item: T) {
            this._context.setItemState(item)
        }

        getData(): T {
            return this._context.getItemState() as T
        }

        goToStep(step?: string): void {
            this._context.goToStep(step)
        }

        onGoToNextStep(cb:() => void): void {
            this._context.onGoToNextStep(cb)
        }

        setNextValid(valid: boolean): void {
            this._context.setNextValid(valid)
        }

        dissmissWizard() : void {
            this._context.goToStep('$$dismiss$$')
        }
    }

    return new PanelWizardContextAccessor<T>(context)
};