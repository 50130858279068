import { ISdkReportInstance } from "../../../sdk/models/ISdkReportInstance"

export const distinctMostRecentInstances = (reportInstances: ISdkReportInstance[]): ISdkReportInstance[] => {

    // pick the most recent instance only 
    const mostRecentInstance: ISdkReportInstance[]  = []
        
    reportInstances.forEach(periodInstance => {

        // find the instance by period in most recent
        const existingInstance = mostRecentInstance.find(i => i.periodId === periodInstance.periodId)
        if (!existingInstance) {
            mostRecentInstance.push(periodInstance)                
        } else if (periodInstance.createdAt > existingInstance.createdAt) {
            // replace the existing instance with the new one
            const index = mostRecentInstance.indexOf(existingInstance)
            mostRecentInstance.splice(index, 1, periodInstance)                
        }
    })

    return mostRecentInstance;
}