import { useParams, useSearchParams } from "react-router-dom"
import { INavigationMenuSidebarRouteEntry, NavigationMenuSidebar } from "../lib/components/DetailsListHelper/NavigationMenuSideBar";
import { useState } from "react";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { SdkTenantDashboardClient } from "../lib/sdk/SdkTenantDashboardClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";

export const ServiceProviderDashboards = () => {

    const { tenantId } = useParams();
    const [searchParams, ] = useSearchParams();

    const auth = useAuthInContext(tenantId as string);
    const [menuItems, setMenuItems] = useState<INavigationMenuSidebarRouteEntry[] | undefined>(undefined)
            
    // read all the dashboards the user created for this tenant
    useEffectAsync(async () => {
        
        setMenuItems(undefined)

        // define the menu items
        const buildedMenuItems: INavigationMenuSidebarRouteEntry[] = []

        // add the overview item
        buildedMenuItems.push({name: 'Overview', route: '/tenants/' + tenantId + '/svpdashboards/overview'})

        // get all dashboards
        var sdkDashboardClient = new SdkTenantDashboardClient(tenantId as string, auth.currentToken as string);
        const availableDashboards = await sdkDashboardClient.getDashboardDefinitions();

        if (availableDashboards && availableDashboards.length > 0) {

            availableDashboards.forEach((d) => {
                buildedMenuItems.push({name: d.name, route: '/tenants/' + tenantId + '/svpdashboards/dashboards/' + d.id})
            })            
        }

        // add the manage dashboards item
        buildedMenuItems.push({name: 'Manage Dashboards', route: '/tenants/' + tenantId + '/svpdashboards/manage'})

        setMenuItems(buildedMenuItems)
    }, [tenantId, searchParams])

    return (<NavigationMenuSidebar routes={menuItems} />)
}