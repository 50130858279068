import { ISdkAuditLogItem } from "./models/ISdkAuditLogItem";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkAuditLogClient {

    private sdkClient: SdkClient<ISdkAuditLogItem>
    
    constructor(token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkAuditLogItem>('/api/v1/tenants', token);        
    }

    getLogs(tenantId: string, logDimension: string, objectId: string): Promise<ISdkAuditLogItem[]> {
        return this.sdkClient.list('/' + tenantId + '/audit/dimensions/' + logDimension + '/references/' + objectId);
    }
}
