import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import { ISdkEnvironmentBranding } from "./models/ISdkEnvironmentBranding";

export class SdkEnvironmentClient {

    private sdkClient: SdkClient<ISdkEnvironmentBranding>    
    
    constructor()         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkEnvironmentBranding>('/api/v1/environment/branding', '');
    }

    getBranding(): Promise<ISdkEnvironmentBranding> 
    {        
        return this.sdkClient.get('');            
    }
}
