import { Link, PrimaryButton } from "@fluentui/react";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ISdkSearchResultItem } from "../lib/sdk/models/ISdkSearchResultItem";
import { SearchGeneralPanel } from "./SearchGeneralPanel";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../lib/forms/ControlledTextField";

type ManualCustomerRegistrationForm = {
    identifier: string
}

export interface IManagedLinkedCustomerRegisterDialogProps extends IAppWindowBaseProps {   
    tenantId: string    
    dataIntegrationId: string
    onCreateCustomer: (customerId: string, customerName: string) => Promise<void>
}

export const ManagedLinkedCustomerRegisterDialog = (props: IManagedLinkedCustomerRegisterDialogProps) => { 

    const [registerManuallyMode, setRegisterManuallyMode] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)
    const [externalSearchText, setExternalSearchText] = useState<string | undefined>(undefined)

    const { handleSubmit, control, reset } = useForm<ManualCustomerRegistrationForm>({ 
        defaultValues: {            
            identifier: ''            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });    

    
    const onCreateCustomer = (item: ISdkSearchResultItem) => {
        return props.onCreateCustomer(item.id, item.text)
    }

    const onLookupCustomerDirectly = (data: ManualCustomerRegistrationForm) => {
        setProcessing(true)     
        setExternalSearchText("$direct:identifier=" + data.identifier)           
    }

    const onDismissDialog = () => {
        
        setProcessing(false)
        setExternalSearchText(undefined)
        reset({identifier: ''})

        if (props.dismissDialog)
            props.dismissDialog()
    }
        
    const noResultSlotInitial = (
        <div>                    
            No matching customer was found in the current data. This could be due to the fact,
            that no consumption data is processed yet. Try registering manually by entering the  
            tenant id or domain! - <Link onClick={() => setRegisterManuallyMode(true)} style={{"textDecoration": "underline"}}>Register manually</Link>
        </div>
    )
    
    const bottomSlotInitial = (
        <div style={{textAlign: 'center', marginTop: '20px'}}>                    
            The customer you are looking for is not in the list? Try registering manually by entering the  
            tenant id or domain! - <Link onClick={() => setRegisterManuallyMode(true)} style={{"textDecoration": "underline"}}>Register manually</Link>
        </div>
    )

    const noResultSlotRegisterManually = (
        <div style={{textAlign: "left"}}>            
            <p><strong>Welcome to the manual customer registration</strong></p>
            <p>Please enter the customer-id, tenant-id or the domain-name of the customers tenant, the system will directly contact the cloud vendor to gather the required customer data if possible:</p>
            <form style={{display: "flex", width: '100%'}}>
                <ControlledTextField control={control} name="identifier" placeholder="Enter Customer-Id, Tenant-Id or Customer-Domain..." styles={{root: { flexGrow: 1, marginRight: '10px'}}} defaultValue="" disabled={processing} />    
                <PrimaryButton disabled={processing} onClick={handleSubmit((data) => { onLookupCustomerDirectly(data) })}>Lookup Customer</PrimaryButton>   
            </form>
        </div>
    )
    
    return (
        <SearchGeneralPanel 
            isVisible={props.isVisible}
            dismissDialog={onDismissDialog}
            tenantId={props.tenantId} 
            rootId={props.dataIntegrationId} 
            searchTypes={['dataintegration/datasegments']} 
            title={'Register new customer from data segment'} 
            description={'Dependent on the connected system customer related data is organized in data segements. Search for a sepcific data segement to create a new customer from it! It could take up to 24hrs until the data segments being populated here!'} 
            searchPlaceholder={'Search for data segments'} 
            searchResultHeader={'Matching Data-Segements'}
            actions={[
                { text: 'Create customer', onClick: onCreateCustomer }
            ]}
            noResultSlot={registerManuallyMode ? noResultSlotRegisterManually : noResultSlotInitial}
            bottomSlot={ registerManuallyMode ? noResultSlotRegisterManually : bottomSlotInitial }
            onSearchStarted={() => (searchText: string) => { setRegisterManuallyMode(false) }}
            onSearchFinished={() => { setProcessing(false); setExternalSearchText(undefined) }}
            disabled={processing}   
            externalSearchText={externalSearchText}
        />
    )    
}
