import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import { ISdkCustomerGroup } from "./models/ISdkCustomerGroup";

export class SdkCustomerGroupPartnerChannelClient {

    private sdkClient: SdkClient<ISdkCustomerGroup>
        
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkCustomerGroup>('/api/v1/tenants/' + tenantId + '/svp/groups-partnerchannel', token);
    }    

    issueToken(id: string): Promise<string>
    {
        return this.sdkClient.getIndirectType<string>('/' + id + '/tokens');
    }

    getPartnerChannelGroup(childGroupId: string): Promise<ISdkCustomerGroup | undefined>
    {
        return this.sdkClient.getIndirectType<ISdkCustomerGroup>('/' + childGroupId + '/partnerchannel').catch(() => {
            return undefined
        })
    }
}