import { IDropdownOption, SearchBox } from "@fluentui/react"
import { useEffect, useState } from "react"
import { IFilterItem } from "../../forms/UnControlledFilterItem"
import { FilterPillPanel } from "./FilterPillPanel"

export interface ISearchBarControlProps {    
    availableFields: IDropdownOption[]
    initialFilters: IFilterItem[]
    allowFiltering: boolean
    onSearch?: (filters: IFilterItem[], searchText: string) => void    
}

export const SearchBarControl = (props: ISearchBarControlProps) => {
    
    const [pills, setPills] = useState<IFilterItem[]>(props.initialFilters ? props.initialFilters : [])
    const [searchText, setSearchText] = useState<string>('')

    const onRemoveFilter = (filter: IFilterItem) => {
        // remove the pill from the pills array
        setPills(prevPills => prevPills.filter((p) => { return p.field !== filter.field }))        
    }
    
    const onAddFilter = (filter: IFilterItem) => {
        setPills(prevPils => [...prevPils, filter])              
    }    

    const onSearchBoxSearch = (newValue: string) => {
        setSearchText(newValue)        
    }

    useEffect(() => {     
        if (props.onSearch)
            props.onSearch(pills, searchText)           
    // eslint-disable-next-line                                     
    }, [pills, searchText])

    const styles = {
        root: {
            fontSize: '12px',
            height: '24px'
        },
        iconContainer: {
            fontSize: '12px'
        }
    }

    return (
        <div className="mv-search-bar-control" style={{ display: 'flex', padding: '10px 0 10px 24px'}}>
            <SearchBox styles={styles} placeholder="Filter" iconProps={{iconName: 'filter'}} style={{ width: '150px'}} onSearch={onSearchBoxSearch} />
            { props.allowFiltering ? (<FilterPillPanel pills={pills} onRemoveFilter={onRemoveFilter} onAddFilter={onAddFilter} availableFields={props.availableFields} />) : (<></>)}
        </div>
    )
}
