import { ISdkSearchResultItem } from "./models/ISdkSearchResultItem";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkSearchClient {

    private sdkClient: SdkClient<ISdkSearchResultItem[]>    
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkSearchResultItem[]>('/api/v1/tenants/' + tenantId + '/svp/search', token);
    }
    
    search(searchText: string, rootId?: string, includedTypes?: string[]): Promise<ISdkSearchResultItem[]> 
    {        
        return this.sdkClient.search('', {
            filter: [],
            searchText: searchText,
            data: {
                includedTypes: includedTypes ? includedTypes : [],
                rootId: rootId ? rootId : ''
            }
        })        
    }
}