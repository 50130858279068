import { ISdkDashboardItem } from "../../../../sdk/models/ISdkDashboardItem";
import { IDashboardGridControlStateStorageService } from "../Contracts/IDashboardGridControlStateStorageService";

export class DashboardGridControlLocalStorageStateStorageService implements IDashboardGridControlStateStorageService {

    constructor(private _tenantId: string)
    {}

    saveItemState(item: ISdkDashboardItem, state: any): Promise<void> {
        // log
        console.log('Writing state for widget ' + item.id + ' to server, the state contains the following information:')
        
        // dump
        const itemState = state || {}        
        console.log(itemState)

        // store
        localStorage.setItem('mv-state-' + this._tenantId + '.' + item.dashboardId + '.' + item.id, JSON.stringify(state));
        return Promise.resolve()
    }

    loadItemState(item: ISdkDashboardItem): Promise<any> {        
        let state = localStorage.getItem('mv-state-' + this._tenantId + '.' + item.dashboardId + '.' + item.id);
        if (state && state === 'undefined') { state = null }
        return Promise.resolve<any>(state ? JSON.parse(state) : {})
    }
}