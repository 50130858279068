import { Dropdown, IDropdownOption, Label, MessageBar, MessageBarType, PanelType, Spinner, SpinnerSize, Stack } from "@fluentui/react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { IFilterItem } from "../lib/forms/UnControlledFilterItem"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { SdkCustomerReportClient } from "../lib/sdk/SdkCustomerReportClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { ControlledFilterItemList } from "../lib/forms/ControlledFilterItemList"
import { ControlledDiscountItemList } from "../lib/forms/ControlledDiscountItemList"
import { IDiscountItem } from "../lib/forms/UnControlledDiscountItem"
import { SdkCustomerDiscountProfileClient } from "../lib/sdk/SdkCustomerDiscountProfileClient"
import { ISdkCustomerDiscountProfileCreateRequest } from "../lib/sdk/models/ISdkCustomerDiscountProfile"
import { ISdkReportDefinition } from "../lib/sdk/models/ISdkReportDefintion"
import { ControlledDropdown } from "../lib/forms/ControlledDropdown"
import { ControlledCheckbox } from "../lib/forms/ControlledCheckbox"

export interface IAddOrEditDiscountProfileDialog extends IAppWindowBaseProps {   
    tenantId: string
    parent: string
    parentType: string
    itemId?: string
    reportDefinitionOptions?: ISdkReportDefinition[]
}

export const AddOrEditDiscountProfileDialog = (props: IAddOrEditDiscountProfileDialog) => {
    
    const [isProcessing, setIsProcssing] = useState<boolean>(false)    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        

    const [selectedReportTemplate, setSelectedReportTemplate] = useState<ISdkReportDefinition | undefined>(undefined)        
    const [availableFields, setAvailableFields] = useState<{key: string, text: string}[]>([])
    
    const auth = useAuthInContext(props.tenantId as string);           
    
    const warningMessage =  'Changing discount/uplift profiles can affect many customers in the system. Adding ' +
                            'a new discount/uplift profile or changing an existing one will trigger a recalculation of all ' +
                            'affected customers. This process can take up to 72hrs depending on the number and size of ' +
                            'these customers. Please be patient and verify the progression in the notifications area.' 

    const availabelProfileTypes: IDropdownOption[] = [
        { key: 'Discount', text: 'Discount - Profile is reducing the WebDirect-Price for the customer (default)' },
        { key: 'Uplift', text: 'Uplift - Profile is increasing the Purchase-Price you have' }
    ]

    // load the fields
    useEffectAsync(async () => {

        if (props.isVisible && !props.reportDefinitionOptions) {

            setIsPreparing(true)

            // generate the service we need
            const sdkReportDefinitionClient = new SdkCustomerReportClient(props.tenantId, undefined, auth.currentToken as string);
            const metersReport = await sdkReportDefinitionClient.getReportDefinition('StaticDefaultCollection.D0E7511F-605F-43F8-93D5-7E2D4B7F8DE2')
            setSelectedReportTemplate(metersReport)
            
            setIsPreparing(false)
        }

    // eslint-disable-next-line
    }, [props.isVisible])

    // load the fields
    useEffectAsync(async () => {

        if (!selectedReportTemplate || !props.isVisible) { return }

        setIsPreparing(true)
        
        setAvailableFields(selectedReportTemplate.columns.map(c => {
            return {
                key: c.id,
                text: c.name
            }
        }))

        setIsPreparing(false)        

    // eslint-disable-next-line
    }, [selectedReportTemplate])

    // load the item to edit
    useEffectAsync(async () => {

        if (!props.itemId) { 
            reset({
                name: '',
                description: '',
                profileType: 'Discount',
                dataSelector: [],
                discounts: []
            })

            return
        }

        setIsPreparing(true)

        const sdkClient = new SdkCustomerDiscountProfileClient(props.tenantId, auth.currentToken as string)
        const item = await sdkClient.getDiscountProfile(props.itemId)

        reset({
            name: item.name,
            description: item.description,
            profileType: item.profileType,
            allowDiscountBelowReportedCosts: item.allowDiscountBelowReportedCosts,
            dataSelector: item.selectorRules.map((r, i) => { 
                return {
                    key: 'dataselector' + i,
                    operator: r.operator,
                    field: r.field,
                    condition: r.condition,
                    value: r.value,
                    compare: r.compare && r.compare === 'CaseInSensitive' ? true : false                        
                } 
            }),
            discounts: item.items
        })

        // select the right template
        const sdkReportDefinitionClient = new SdkCustomerReportClient(props.tenantId, undefined, auth.currentToken as string);
        const metersReport = await sdkReportDefinitionClient.getReportDefinition(item.relatedReportDefinitionId || 'StaticDefaultCollection.D0E7511F-605F-43F8-93D5-7E2D4B7F8DE2')
        setSelectedReportTemplate(metersReport)
                
        setIsPreparing(false)

    }, [props.itemId])

    type AddDiscountProfileBaseData = {
        name: string
        description: string
        profileType: 'Discount' | 'Uplift',
        allowDiscountBelowReportedCosts: boolean
        dataSelector: IFilterItem[]
        discounts: IDiscountItem[]        
    };

    const { handleSubmit, control, reset, watch, formState } = useForm<AddDiscountProfileBaseData>({
        defaultValues: {
            name: '',
            description: '',
            profileType: 'Discount',
            dataSelector: [],
            discounts: []            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    const onSubmit = (): Promise<string | void> => {

        // handle the submission of the base form 
        const submitHandler = handleSubmit((data: AddDiscountProfileBaseData) => {
                        
            let parentType = 'CustomerGroup'
            if (props.parentType === 'customer') { parentType = 'Customer' }
            else if (props.parentType === 'reports') { parentType = 'Reports' }

            const createRequest: ISdkCustomerDiscountProfileCreateRequest = {
                targetId: props.parent,
                targetType: parentType,
                name: data.name,
                description: data.description,
                relatedReportDefinitionId: selectedReportTemplate?.id as string,
                profileType: data.profileType,
                allowDiscountBelowReportedCosts: data.allowDiscountBelowReportedCosts,
                items: data.discounts,
                selectorRules: data.dataSelector.map(f => {
                    return {                        
                        operator: f.operator,
                        field: f.field,
                        value: f.value,
                        condition: f.condition,
                        compare: f.compare ? 'CaseInSensitive' : 'CaseSensitive'
                    }
                })
            }

            // at this point we can create a new item and close the dialog
            const sdkClient = new SdkCustomerDiscountProfileClient(props.tenantId, auth.currentToken as string)

            if (props.itemId) {
                return sdkClient.updateDiscountProfile(props.itemId, createRequest)            
            } else {
                return sdkClient.createDiscountProfile(createRequest)            
            }
        })
        
        return submitHandler()
        .then(() => { dissmissDialog() })
        .finally(() => { setIsProcssing(false)})        
    }

    const dissmissDialog = () => {
        setIsProcssing(false)
        reset()        
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onChangeSelectedReportDefinition = (item?: IDropdownOption ) => {
        if (!item) { return }

        const selectedReportDefinition = props.reportDefinitionOptions?.find(f => f.id === item.key)
        if (!selectedReportDefinition) { return }

        setSelectedReportTemplate(selectedReportDefinition)
    }

    // establish the field watcher 
    const profileTypeWatcher = watch('profileType')    
            
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={props.itemId ? 'Update discount/uplift profile' : 'Add new discount/uplift profile'}
            onDismiss={dissmissDialog}                                    
            type={PanelType.large}
            progessMessage={(props.itemId ? 'Updating existing' : 'Creating new') + ' discount/uplift profile'}
            submitLabel={props.itemId ? 'Update' : 'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid && formState.isDirty}
            onSubmit={onSubmit}
            errorMessagePrefix= {props.itemId? 'Error updating the discount/uplift profile!' : 'Error creating the discount/uplift profile!'}
            doubleConfirmMessage={props.reportDefinitionOptions ? undefined : warningMessage}>
                
                { isPreparing ? (
                    <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                ) : (
                    <div>
                        <div style={{marginBottom: '10px'}}>
                            <p>Discount or uplift profiles allows to control what end customer, partner or other stakeholders will see as reported costs. 
                                It's important to understand that the Pay as you go price is not impacted from it.</p> 

                            { profileTypeWatcher === 'Discount' && !props.reportDefinitionOptions && (
                                <p><strong>Important to know, the system will not reduce the reflected costs below the reported costs as long the fuse is 
                                    not deactivated in the profile settings. Multiple discount profiles are commulative but the system will never reduce 
                                    the reflected costs below the reported costs even when the cummulated discount indicates it.</strong></p>
                            )}

                            <p>All required information are request below, please enter all deatils to create a new discount/uplift profile:</p>
                            { !props.reportDefinitionOptions && (
                                <MessageBar messageBarType={MessageBarType.severeWarning}>{'Warning: ' + warningMessage}</MessageBar>                        
                            )}                            

                            { profileTypeWatcher === 'Uplift' && (
                                <MessageBar messageBarType={MessageBarType.severeWarning}>{'Important: The profile type is set the Uplift, this means the customer price is calculated by taking the reported purchase price and added the uplift value. Calculation formular is Reported Price * (100% + Uplift %)! Please verify if this is the general intention.' }</MessageBar>                        
                            )}
                        </div>

                        {props.reportDefinitionOptions && (
                            <Stack tokens={{ childrenGap: 15 }} style={{marginBottom: '10px'}}>                                                
                                <Dropdown label={'The source report this profile can be used for'} selectedKey={selectedReportTemplate ? selectedReportTemplate.id : undefined} onChange={(e, item) => onChangeSelectedReportDefinition(item)} options={props.reportDefinitionOptions.map(m => { return {key: m.id, text: m.name }})} placeholder={'Please select a reference report...'} />                            
                            </Stack>
                        )}                        

                        {selectedReportTemplate && (
                            <>
                            <form>
                                <Stack tokens={{ childrenGap: 15 }}>                
                                    <ControlledTextField label={'Profile Name'} control={control} name={'name'} rules={{ required: 'a valid valud is required'}} disabled={isProcessing} />
                                    <ControlledDropdown label={'Profile Type'} control={control} name={'profileType'} options={availabelProfileTypes} disabled={isProcessing || props.itemId !== undefined}/>                                
                                    { profileTypeWatcher === 'Discount' && (
                                        <ControlledCheckbox label={'Allow a discount which leads to costs below reported costs (be careful just one profile in the chain impacts the whole chain)'} control={control} name={'allowDiscountBelowReportedCosts'} disabled={isProcessing} />
                                    )}
                                    <ControlledTextField label={'Description'} control={control} name={'description'} disabled={isProcessing} multiline resizable />                                                        
                                </Stack>
                            </form>   

                            <div>
                                <Label style={{marginTop: '10px'}}>Data Selector (optional)</Label>
                                <ControlledFilterItemList control={control} name={'dataSelector'} availableFields={availableFields} disabled={isProcessing} />                            
                            </div>

                            <div>                            
                                <Label style={{marginTop: '10px'}}>{ profileTypeWatcher === 'Uplift' ? 'Uplifts' : 'Discounts'} (in %)</Label>
                                <ControlledDiscountItemList control={control} name={'discounts'} rules={{ required: 'at least one discount is required'}}  disabled={isProcessing} />

                            </div>
                            </>
                        )}
                    </div>
                )}
        </PanelWithDefaultFooter>
    )    
}