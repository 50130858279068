import { ISdkDashboardDefinition } from "./models/ISdkDashboardDefinition";
import { ISdkDashboardItem } from "./models/ISdkDashboardItem";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkTenantDashboardClient {

    private sdkClient: SdkClient<ISdkDashboardDefinition>    
    private sdkItemClient: SdkClient<ISdkDashboardItem>    

    constructor(
        private _tenantId: string, 
        private _token: string,
        private _adminToken?: string)        
    {   
        const adminTokenAuthentication: any = {}
        if (_adminToken) { adminTokenAuthentication['x-mv-admin-authentication'] = 'Bearer ' + _adminToken }        
        
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkDashboardDefinition>('/api/v1/tenants/' + _tenantId + '/dashboards', _token, adminTokenAuthentication);                
        this.sdkItemClient = SdkClientServiceDiscoveryService.Discover<ISdkDashboardItem>('/api/v1/tenants/' + _tenantId + '/dashboards', _token, adminTokenAuthentication);
    }

    public getDashboardDefinitions(): Promise<ISdkDashboardDefinition[]> {
        return this.sdkClient.list('');                
    }

    public getDashboardDefinition(dashboardId: string): Promise<ISdkDashboardDefinition> {
        return this.sdkClient.get('/' + dashboardId)        
    }

    public getDashboardItems(dashboardId: string): Promise<ISdkDashboardItem[]> {
        return this.sdkItemClient.list('/' + dashboardId + '/items').then((items) => {
            return items.map((item) => {
                return this.convertPayloadToItem(dashboardId, item)
            })
        })
    }

    public registerDashboardDefinition(name: string): Promise<ISdkDashboardDefinition> {
        return this.sdkClient.post('', { name: name }).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string) as Promise<ISdkDashboardDefinition>;                    
        })
    }

    public updateDashboardDefinition(dashboardId: string, name: string, defaultDashboard: boolean): Promise<ISdkDashboardDefinition> {
        return this.sdkClient.put('/' + dashboardId, { name: name, default: defaultDashboard }).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string) as Promise<ISdkDashboardDefinition>;                    
        })
    }

    public deleteDashboardDefinition(dashboardId: string): Promise<boolean> {        
        return this.sdkClient.delete('/' + dashboardId).then(() =>{
            return true
        }).catch(() => {
            return false
        })
    }    

    public addDashboardDefinitionItem(dashboardId: string, item: ISdkDashboardItem): Promise<boolean> {

        const widgetPayload = this.convertItemToPayload(item)
        return this.sdkItemClient.post('/' + dashboardId + '/items', widgetPayload).then(() => {        
            return true
        }).catch(() => {
            return false
        })
    }

    public updateDashboardDefinitionItems(dashboardId: string, items: ISdkDashboardItem[]): Promise<boolean> {

        const widgetsPayload = items.map((item) => {
            return this.convertItemToPayload(item)            
        })

        return this.sdkItemClient.put('/' + dashboardId + '/items', widgetsPayload).then(() => {        
            return true
        }).catch(() => {
            return false
        })
    }
    
    public updateDashboardDefinitionItem(dashboardId: string, item: ISdkDashboardItem): Promise<boolean> {

        const widgetPayload = this.convertItemToPayload(item)        

        return this.sdkClient.put('/' + dashboardId + '/items/' + item.id, widgetPayload).then(() => {        
            return true
        }).catch(() => {
            return false
        })
    }

    public deleteDashboardDefinitionItem(dashboardId: string, itemId: string): Promise<boolean> {        
        return this.sdkItemClient.delete('/' + dashboardId + '/items/' + itemId).then(() =>{
            return true
        }).catch(() => {
            return false
        })
    }

    private convertItemToPayload(item: ISdkDashboardItem): any {
        return {
            Id: item.id,
            Name: item.name,
            Description: item.description,
            ReportDefinitionId: item.reportDefinitionId,
            AttributesData: item.attributes ? JSON.stringify(item.attributes) : JSON.stringify({}),
            ItemType: item.itemType,
            X: item.x,
            Y: item.y,
            H: item.h,
            W: item.w,
            State: item.state
        }
    }

    private convertPayloadToItem(dashboardId: string, payload: any): ISdkDashboardItem {
        return {
            dashboardId: dashboardId,
            id: payload.id,
            name: payload.name,
            description: payload.description,
            reportDefinitionId: payload.reportDefinitionId,
            attributes: payload.attributesData ? JSON.parse(payload.attributesData) : {},
            itemType: payload.itemType,
            x: payload.x,
            y: payload.y,
            h: payload.h,
            w: payload.w,
            state: payload.state
        }
    }
}