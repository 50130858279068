import { useParams } from "react-router-dom";
import { useNavigationService } from "./AppDynamicNavigate";
import { ISdkTenantEx } from "./lib/authentication/AuthenticationProfileHelper";
import { SdkTenantDashboardClient } from "./lib/sdk/SdkTenantDashboardClient";
import { useEffectAsync } from "./lib/components/Helpers/EffectAsyncHook";
import { AuthenticationContextProps, useAuthInContext } from "@mevodo/mv-react-authentication";
import { SdkProfileClient } from "./lib/sdk/SdkProfileClient";

export interface IAppNavigationManagerProps {
    activeTenant: ISdkTenantEx | undefined,
    auth: AuthenticationContextProps,
    children?: React.ReactNode
}

export const AppNavigationManager = (props: IAppNavigationManagerProps) => {

    const { tenantId } = useParams();
    const auth = useAuthInContext(tenantId as string)
    const navigationService = useNavigationService();        

    // handle the initial load 
    useEffectAsync( async () => {
        
        // check if the system identified an active tenant, if 
        // not we do not need to do anything
        if (!props.activeTenant) { return }
 
        // set the preparing mode 
        navigationService.setPreparing(true)

        // prepare the access check service
        const accessCheckClient = new SdkProfileClient(auth.currentToken as string);

        // reset the whole navigation panel, depending on the tenant
        // the system is generating the navigation
        navigationService.removeAllItems();        

        // injcet the information for the active tenant
        if (props.activeTenant.managed) { 

            // get all dashboards
            var sdkDashboardClient = new SdkTenantDashboardClient(tenantId as string, props.auth.currentToken as string);            
            const availableDashboards = await sdkDashboardClient.getDashboardDefinitions();

            // add the create dashboard item 
            navigationService.addActionItem('dashboard-create', 'faPlus', 'Create Dashboard', 'createDashboard')

            // build the navigation panel via service
            if (availableDashboards) {

                availableDashboards.forEach((dashboard) => {                    
                    navigationService.addNavigationItem('dashboard-' + dashboard.id, 'faChartLineUp', dashboard.name, '/tenants/' + tenantId+ '/portal/' + dashboard.id)
                })
            }

            // inject the standard operation for a managed tenant
            navigationService.addNavigationItem('reports', 'faFileChartColumn', 'Reports', '/tenants/' + tenantId + '/portal/reports')
            navigationService.addNavigationItem('triggers', 'faArrowProgress', 'Triggers', '/tenants/' + tenantId + '/portal/triggers')
            navigationService.addNavigationItem('settings', 'faScrewdriverWrench', 'Settings', '/tenants/' + tenantId + '/settings/users')

        } else {
            // inject the standard operation for a service provider tenant
            navigationService.addNavigationItem('dashboards', 'faChartPie', 'Dashboards', '/tenants/' + tenantId + '/svpdashboards/overview')            
            navigationService.addNavigationItem('customers', 'faBuildings', 'Customers', '/tenants/' + tenantId + '/svp/customers')            
            navigationService.addNavigationItem('reports', 'faFileChartColumn', 'Reports', '/tenants/' + tenantId + '/svpreports/reports')            
            navigationService.addNavigationItem('triggers', 'faArrowProgress', 'Triggers', '/tenants/' + tenantId + '/svptriggers/triggers')            
            navigationService.addNavigationItem('settings', 'faScrewdriverWrench', 'Settings', '/tenants/' + tenantId + '/settings/users')       
            
            // TODO: Check permissions: must be service prvouder or serviceprovider channel tenant and permission to specfici permission            
            const hasDeveloperPermissions = await accessCheckClient.hasAccess('mevodo.cloud.service.cloudbilling/' + tenantId + '/tenant', 'mevodo.cloud.service.cloudbilling/developer/opendevcenter')
            if (hasDeveloperPermissions) {
                navigationService.addNavigationItem('devcenter', 'faWebHook', 'Development Center', '/tenants/' + tenantId + '/developmentcenter/powershell')            
            }
        }

        // done with preparation
        navigationService.setPreparing(false)

    // eslint-disable-next-line 
    }, [props.activeTenant]);
    
    return (
        <>
            {props.children}
        </>
    )
}