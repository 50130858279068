import { PanelType } from "@fluentui/react";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useState } from "react";
import uuid from "react-uuid";
import { useNotifications } from "reapop";
import { ICommandPermission } from "../../commands/ICommandPermission";
import { IAppWindowBaseProps } from "../../ds/components/AppWindow";
import { ConfirmDialog, useConfirmDialog } from "../../ds/components/ConfirmDialog";
import { ISdkReportDefinition } from "../../sdk/models/ISdkReportDefintion";
import { ISdkReportInstance } from "../../sdk/models/ISdkReportInstance";
import { SdkClientServiceDiscoveryService } from "../../sdk/SdkClientServiceDiscoveryService";
import { SdkTenantReportClient } from "../../sdk/SdkTenantReportClient";
import { IColumnsBuilder } from "../DetailsListHelper/ColumnsBuilder";
import { ICommandBarService } from "../DetailsListHelper/ComandBarService";
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations";
import { PanelWithDefaultFooter } from "../DetailsListHelper/PanelWithDefaultFooter";
import { ReportAddOrEditDialog } from "./ReportAddOrEditDialog";
import { PeriodSelectorPanel } from "../Generic/PeriodSelectorPanel";
import { distinctMostRecentInstances } from "../DashboardWidgets/SupportFunctions/ReportInstanceLogic";

export interface IReportInstancesDialogProps extends IAppWindowBaseProps {
    item: ISdkReportDefinition
    reportCollectionType: "serviceProviderCollection" | undefined
    tenantId: string
}

export const ReportInstancesDialog = (props: IReportInstancesDialogProps) => {

    const auth = useAuthInContext(props.tenantId)    
    const { notify } = useNotifications()
    const [confirmDialog, confirmDialogService] = useConfirmDialog()
    
    const [refresh, setRefresh] = useState<boolean>(false)    
    const [isReportAddOrEditDialogVisible, setIsReportAddOrEditDialogVisible] = useState(false)
    const [isReportRunforInstanceDialogVisible, setIsReportRunforInstanceDialogVisible] = useState(false)
    
    const onDismissDialog = () => {                
        if (props.dismissDialog)
            props.dismissDialog()
    }

    // icon map
    const iconMap: any = {        
        pending: 'faHourglassClock',
        running: 'faPlay',
        finished: 'faCheck',
        failed: 'faCircleExclamation'        
    }

    const buildColumns = (builder: IColumnsBuilder<ISdkReportInstance>): void  => 
    {                
        builder.IconColumn({name: "StatusIcon", headerIcon: 'faFolder', iconName: (item) => iconMap[item.state.toLowerCase()]})                                
        builder.TextColumn({name: "Id", maxWidth: 250,  value: 'id' })        
        builder.TextColumn({name: "Period", maxWidth: 100,  value: 'periodId' })        
        builder.TextColumn({name: "Type", maxWidth: 100,  value: 'type' })        
        builder.DateTimeColumn({name: "Created", maxWidth: 250,  value: 'createdAt' })        
        builder.TextColumn({name: "Status", maxWidth: 100,  value: 'state' })      

        builder.IconColumn({name: "OpenExcel", headerIcon: 'faEye', iconName: 'faArrowUpRightFromSquare', onClick: (item) => { 

            if (item.state !== 'Finished') {
                confirmDialogService.show('Opening Excel', 'This report is currently in processing and can\'t be opened. Please refresh or try again later!')
                return;                
            }

            let documentUri = SdkClientServiceDiscoveryService.DiscoverUri(item.downloadUriExcel);                   
            documentUri = documentUri.replace('http://localhost:5082', 'https://apps.staging.mevodo.com/cloudbilling')
            window.open("https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(documentUri + '&nocache=' + uuid()) + '&nc=' + uuid(), '_blank')
        }})

        builder.IconColumn({name: "DownloadExcel", headerIcon: 'faFileExcel', iconName: 'faDownload', onClick: (item) => { 
            
            if (item.state !== 'Finished') {
                confirmDialogService.show('Download Excel', 'This report is currently in processing and can\'t be downloaded. Please refresh or try again later!')
                return;                
            }

            const fullUri = SdkClientServiceDiscoveryService.DiscoverUri(item.downloadUriExcel);                        
            window.open(fullUri + '&nocache=' + uuid(), '_blank')                        
        }})                        
        
        builder.IconColumn({name: "DownloadJson", headerIcon: 'faFileCode', iconName: 'faDownload', onClick: (item) => { 

            if (item.state !== 'Finished') {
                confirmDialogService.show('Download JSON', 'This report is currently in processing and can\'t be downloaded. Please refresh or try again later!')
                return;                
            }

            const fullUri = SdkClientServiceDiscoveryService.DiscoverUri(item.downloadUriJson);                        
            window.open(fullUri + '&nocache=' + uuid(), '_blank')            
        }})                        
    }

    const loadData = (): Promise<ISdkReportInstance[]> => {        
        const reportInstanceClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)            
        return reportInstanceClient.getReportInstances(props.item.id as string)
    }

    const onRunReport = () => onRunReportFor('discover', false)    

    const onRunReportForShowdialog = () => {
        setIsReportRunforInstanceDialogVisible(true)
    }

    const onRunReportFor = (period: string, forceNotification: boolean) => {
        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)
        reportClient.triggerReportDefinitionRunFor(props.item.id, period, forceNotification).then(()=> {
            setRefresh(prevRefresh => !prevRefresh)
            notify(
                period !== 'discover' ? 
                'Building a new report instance for period ' + period + ' was started succssfully'
                : 'Building a new report instance was started succssfully', 'success', { dismissible: true, dismissAfter: 2000 })                
        }).catch((error) => {
            notify('An error occured during the operation: ' + (error.message ? error.message : error), 'error', { dismissible: true })
        })
    }

    const onReRunAllPeriods = () => {    
        confirmDialogService.ask('Re-Calculate existing periods', 'Re-Calculating all existing report periods will create a newly triggered instance for every existing period. The system triggers at max twelve periods started by the newest one. This processs could take a long, are you sure you want to execute the operation?', async () => {
            
            // get the instances
            const reportInstanceClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)            
            const instances = await reportInstanceClient.getReportInstances(props.item.id as string)

            // get the distinct instances and sort the distinct instances by periodId
            const disctinctInstances = distinctMostRecentInstances(instances).sort((a, b) => a.periodId.localeCompare(b.periodId)).reverse()

            // reduce to the last 12 periods
            if (disctinctInstances.length > 12) {
                disctinctInstances.splice(0, disctinctInstances.length - 12)
            }

            // trigger the report generation 
            disctinctInstances.forEach(async (instance) => {
                await onRunReportFor(instance.periodId, false)
            })                    
        })
    }

    const onEditReport = () => {        
        setIsReportAddOrEditDialogVisible(true)
    }

    const addCommands = (commandBarService: ICommandBarService, commandPermissions: ICommandPermission[]) => {
        if (!props.item.triggers.find(t => t.triggerType === 'Manual')) { return }
        commandBarService.Command('trigger', 'Run', 'faPlay', onRunReport, undefined, undefined, undefined, '_first')   
        commandBarService.Command('triggerfor', 'Run for ...', 'faCalendar', onRunReportForShowdialog, undefined, undefined, undefined, 'trigger')   
        commandBarService.Command('triggerexisting', 'Re-Run all periods ', 'faBolt', onReRunAllPeriods, undefined, undefined, undefined, 'triggerfor')   
        commandBarService.Command('edit', 'Edit', 'faPen', onEditReport, undefined, undefined, undefined, 'triggerexisting')   
        commandBarService.Command('deleteSmart', 'Smart Delete', 'faFire', onSmartDeleteItems, undefined, undefined, undefined, 'delete')   
    }

    const onDeleteItems = async (items: ISdkReportInstance[]) => {
        confirmDialogService.ask('Delete report instances', 'Are you sure you want to delete the selected report instances? This operation cannot be undone.', async () => {
            const reportClient = new SdkTenantReportClient(props.tenantId, undefined, auth.currentToken as string)
            for(const reportInstance of items) {
                await reportClient.deleteReportInstance(props.item.id, reportInstance.id)
            }

            // refresh the list
            setRefresh(prevRefresh => !prevRefresh)
        });        
    }

    const onSmartDeleteItems = async () => { 
        confirmDialogService.ask('Smart Delete', 'Are you sure you want to delete all redundent instances for the different periods? The system is remvoing only triggered instances, scheduled instance will stay untouched. This operation cannot be undone.', async () => {
            
            // get all triggered instances
            const reportInstanceClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)
            const instances = await reportInstanceClient.getReportInstances(props.item.id as string)
            const triggeredInstances = instances.filter(i => i.state === 'Finished' && i.type === 'triggered')

            // get the distinct instances and sort the distinct instances by periodId
            const disctinctTriggeredInstances = distinctMostRecentInstances(triggeredInstances)

            // remove the distinct instances from the list
            const redundantInstances = triggeredInstances.filter(i => !disctinctTriggeredInstances.find(d => d.id === i.id))

            // trigger delete operation for redundant instances        
            for(const reportInstance of redundantInstances) {
                await reportInstanceClient.deleteReportInstance(props.item.id, reportInstance.id)
            }

            // refresh the list
            setRefresh(prevRefresh => !prevRefresh)
        });
    }
        
    // ensure we only render when the item has a value
    if (!props.item) { return (<></>)}

    // render the content
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Report Instances'}
            onDismiss={onDismissDialog}
            onSubmit={() => Promise.resolve() }                    
            type={PanelType.large}   
            progessMessage={''}    
            submitLabel={'Close'}                  
            dissmissLabel={'Cancel'}   
            noDissmissButton={true}          
            isValid={true}>

            <div>
                <p>The report definition <strong>{props.item.name} ({props.item.id})</strong> has the following instances. It's possible 
                to download the different reports in the excel format</p>   
                
                <NavigationalDetailsListWithStandardOperations
                    uniqueIdentifier='reportInstances'   
                    embedded={true}
                    hideReferesh={false}       
                    refresh={refresh}
                    disabledItemSelect={false}                  
                    onBuildColumns={buildColumns}
                    onLoadData={loadData}
                    onAddCommands={addCommands}
                    onDeleteItems={onDeleteItems}
                    allowSorting={true}
                    defaultSortBy={'Created'}
                    defaultSortDirection={'desc'}
                     />      
                <ConfirmDialog {...confirmDialog}/>
            </div>                
            <ReportAddOrEditDialog tenantId={props.tenantId} reportCollectionType={props.reportCollectionType} isVisible={isReportAddOrEditDialogVisible} item={props.item} dismissDialog={() => { setIsReportAddOrEditDialogVisible(false) }}/>              
            <PeriodSelectorPanel 
                title={'Trigger report for specific period'} 
                description={'Please select the period the report should be executed for.'} 
                progressMessage={'Triggering new report instance ...'}
                maxPeriods={12} 
                showNotifyCheckbox={true}
                isVisible={isReportRunforInstanceDialogVisible} 
                onPeriodSelected={(period: string, forceNotification: boolean) => { onRunReportFor(period,forceNotification); setIsReportRunforInstanceDialogVisible(false) }} 
                dismissDialog={() => setIsReportRunforInstanceDialogVisible(false)}/>
        </PanelWithDefaultFooter>)
}