import { Button } from "@fluentui/react-components"
import { useEffect, useState } from "react"
import { IDropdownOption } from "@fluentui/react"
import { ControlledDropdown } from "../lib/forms/ControlledDropdown"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { UnControlledListFormsContainer } from "../lib/forms/UnControlledListFormsContainer"
import uuid from "react-uuid"

export type FieldDataFormatterModel = {    
    id: string
    field: string
    dataType: string
    format: string
}

export const FieldDataFormatterModelDefaultValues: FieldDataFormatterModel = {    
    id: '',
    field: '',
    dataType: '',
    format: ''
}

export const FieldDataFormatterDataTypes: IDropdownOption[] = [
    {key: 'string', text: 'String'},
    {key: 'number', text: 'Number'},
    {key: 'currency', text: 'Currency'}
]

export const ServiceProviderPad = () => {
    
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [items, setItems] = useState<FieldDataFormatterModel[]>([])

    const availableFields: IDropdownOption[] = [
        {key: 'm1', text: 'Field 1'},
        {key: 'm2', text: 'Field 2'},
        {key: 'm3', text: 'Field 3'}        
    ]

    useEffect(() => {
        setItems([
            {id: uuid(), field: 'm2', dataType: 'currency', format: '0.00 €'},
            {id: uuid(), field: 'm1', dataType: 'string', format: ''},            
            {id: uuid(), field: 'm3', dataType: 'number', format: '0.00'},
            {id: uuid(), field: 'm1', dataType: 'number', format: '0.00'},
            {id: uuid(), field: 'm2', dataType: 'number', format: '0.00'},
        ])
    }, [])
    
    const onAddItem = (item: FieldDataFormatterModel) => {
        setItems(items => [...items, item])
        return Promise.resolve()
    }

    const onUpdateItem = (item: FieldDataFormatterModel) => {                
        // setItems(items => [...items.map(i => i.id === item.id ? item : i)])
        return Promise.resolve()
    }

    const onDeleteItem = (item: FieldDataFormatterModel) => {
        setItems(items => [...items.filter(i => i.id !== item.id)])
        return Promise.resolve()
    }
    
    return (
        <div>
            <div style={{margin: '10px'}}>        
                <UnControlledListFormsContainer disabled={isDisabled} items={items} itemDefaultValues={FieldDataFormatterModelDefaultValues} onAddItem={onAddItem} onDeleteItem={onDeleteItem} onUpdateItem={onUpdateItem} renderEditor={(control) => (
                    <>
                        <ControlledDropdown key={'field'} control={control} name={'field'} placeholder={'Select field'} disabled={isDisabled} rules={{ required: 'a valid value is required'}} options={availableFields} style={{minWidth: '120px'}} />
                        <ControlledDropdown key={'dataType'} control={control} name={'dataType'} placeholder={'Select datatype'} disabled={isDisabled} rules={{ required: 'a valid value is required'}} options={FieldDataFormatterDataTypes} style={{minWidth: '150px'}} />
                        <div style={{marginTop: '10px'}}>
                            <ControlledTextField key={'format'} control={control} name={'format'} placeholder={'Enter the format'} disabled={isDisabled} />
                        </div>
                    </>            
                )} />            

                <div style={{marginTop: '10px'}}>
                    <Button appearance={'primary'} onClick={() => setIsDisabled(disabled => !disabled)}>toogle Disable</Button>
                </div>
            </div>

            <div style={{margin: '10px'}}>        
                    
            </div>
        </div>
    )
}
