import { IDropdownOption, Label, mergeStyleSets } from "@fluentui/react";
import { useForm } from "react-hook-form";
import uuid from "react-uuid";
import { ControlledCheckbox } from "../../../forms/ControlledCheckbox";
import { ControlledDropdown } from "../../../forms/ControlledDropdown";
import { ControlledTextField } from "../../../forms/ControlledTextField";
import { useWizard } from "../../PanelWizard/PanelWizard";
import { IDashboardGridWidgetSelectorStepProps } from "./IDashboardGridWidgetSelectorStepProps";
import { IDashboardGridWidgetSelectorWizardState } from "./IDashboardGridWidgetSelectorWizardState";
import { useEffect, useState } from "react";
import { ISdkDashboardWidgetTemplateAttribute } from "../../../sdk/models/ISdkDashboardWidgetTemplateAttribute";
import { ISdkReportDefinition } from "../../../sdk/models/ISdkReportDefintion";
import { SdkCustomerReportClient } from "../../../sdk/SdkCustomerReportClient";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { ControlledFieldList } from "../../../forms/ControlledFieldList";
import { ControlledFieldFormatMappingList } from "../../../forms/ControlledFieldFormatMappingList";

export const DashboardGridWidgetSelectorStep003ConfigureAttributes = (props: IDashboardGridWidgetSelectorStepProps) => {
          
    // inject the authentication context
    const auth = useAuthInContext(props.tenantId);           
    
    // inject the wizard
    const wizard = useWizard<IDashboardGridWidgetSelectorWizardState>();
    
    // generate the form handler 
    const { handleSubmit, control, formState: { isValid }, watch } = useForm<any>({ mode: 'onChange', reValidateMode: 'onChange' });
    
    // get the wizard data 
    const wizardData = wizard.getData()

    // set the next status to valid if form is valid
    wizard.setNextValid(isValid)

    // build the save handler 
    wizard.onGoToNextStep(() => {
        
        // build a submit hanlder in the wizard page
        const submitHandler = handleSubmit((data) => {

            const dashboardItemToAdd = {
                id: uuid(),
                dashboardId: props.dashboardId,
            
                name: data.name || data.title,
                description: data.description,
            
                reportDefinitionId: wizardData?.widgetReportDefinition?.id as string,
                attributes: data,
            
                itemType: wizardData.widgetTemplate.type,
                
                x: 0,
                y: 0,
                w: 8,
                h: 4,

                state: {}
            }

            return props.onAddDashboardItem(dashboardItemToAdd).then(() => {
                wizard.dissmissWizard()
            })            
        })

        // submit the data
        submitHandler();
    })    
    
    const classNames = mergeStyleSets({
        checkbox: {
            marginTop: '10px'
        }
    })

    let propertyOptions: IDropdownOption[] = []
    if (wizardData.widgetReportDefinition && wizardData.widgetReportDefinition.columns) {
        propertyOptions = wizardData.widgetReportDefinition.columns.map(c => { return { key: c.id, text: c.name } })        
    }

    // establish the field watcher 
    const propertyWatcher = watch(wizardData.widgetTemplate.attributes.map(f => f.id))
    const [controlDisableState, setControlDisableState] = useState<{[key: string]: any}>({})

    // establish the disable state     
    useEffect(() => {

        let newControlDisableState: any = {}

        propertyWatcher.forEach((value: any, index: number) => {
            newControlDisableState[wizardData.widgetTemplate.attributes[index].id] = value
        })

        setControlDisableState(newControlDisableState)

    // eslint-disable-next-line 
    }, propertyWatcher)

    const isControlDisabled = (controlAttribute: ISdkDashboardWidgetTemplateAttribute) => {

        if (!controlAttribute.dependentOn) { return false; }
        
        const currentValue = controlDisableState[controlAttribute.dependentOn]
        if (currentValue) {

            if (controlAttribute.dependentOnValue) {
                return currentValue !== controlAttribute.dependentOnValue
            } else {
                return false
            }
        } else {
            return true
        }        
    }

    // available report definitions
    const [availableReportDefinitions, setAvailableReportDefinitions] = useState<IDropdownOption[]>([])

    useEffect(() => {

        // generate the service we need
        const sdkReportDefinitionClient = new SdkCustomerReportClient(props.tenantId, undefined, auth.currentToken as string);
        sdkReportDefinitionClient.getReportDefinitions().then((reportDefinitions) => {
            setAvailableReportDefinitions(reportDefinitions.map((reportDefinition: ISdkReportDefinition) => { return { key: reportDefinition.id, text: reportDefinition.name } }))
        })
        
    // eslint-disable-next-line
    }, [])

    // allow to render the dynamic form
    const renderForm = () => {                
        return (
            <div>
                <p>Please configure your widget. It's possible to use variable substitution by just using the selected columns form the datasource</p>
                <form >         
                    <ControlledTextField control={control} name="title" label="Title" defaultValue="" />       
                    <ControlledTextField control={control} name="description" label="Description" defaultValue="" multiline resizable/>       
                    { wizardData.widgetTemplate.attributes.map(f => {

                        switch (f.type) {
                            case 'bool':
                                return (<ControlledCheckbox key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} className={classNames.checkbox} />)                                
                            case 'property':
                                return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} options={propertyOptions}/>)
                            case 'list<property>':
                                return (
                                    <>
                                    <Label key={f.id} style={{marginTop: '10px'}}>{f.name}</Label>
                                    <ControlledFieldList key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} availableFields={propertyOptions} />
                                    </>
                                )                                
                            case 'value':
                                return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} options={f.values ? f.values.map(c => { return { key: c, text: c } }) : []} />)                                                            
                            case 'reportdefinition':
                                return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} options={availableReportDefinitions}/>)                                
                            case 'map<property, format>':
                                return (<ControlledFieldFormatMappingList key={f.id} label={f.name} control={control} name={f.id} disabled={isControlDisabled(f)} availableFields={propertyOptions} />)                                                                                        
                            default: 
                                return (<ControlledTextField key={f.id} label={f.name} control={control} name={f.id} rules={{ required: 'a valid valud is required'}} disabled={isControlDisabled(f)} />)                                
                        }
                    })}
                </form>
            </div>
        )
    }

    
    return (
        <div>
            <p>{wizardData.widgetTemplate.description}</p>
            {renderForm()}
        </div>
        
    )
}