import { Panel, PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../../ds/components/AppWindow"
import { ISdkAuditLogItem } from "../../sdk/models/ISdkAuditLogItem"
import { MetaDataList } from "../../ds/components/MetaDataList"

export interface IActivityLogDetailsPanelProps extends IAppWindowBaseProps {
    item?: ISdkAuditLogItem 
}

export const ActivityLogDetailsPanel = (props: IActivityLogDetailsPanelProps) => {

    if (!props.isVisible || !props.item)
        return (<></>)

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }
    
    return (
        <Panel isOpen={props.isVisible} onDismiss={dissmissDialog} type={PanelType.large} headerText={'Audit-Log Item Details'}>
             <div> 
                <h3>Summary</h3>
                {props.item.activity}
            </div>

            <MetaDataList items={[
                { name: 'Event Date', value: props.item.eventDate },
                { name: 'Action', value: props.item.targetObjectAction },
                { name: 'Target', value: props.item.targetObjectType + ': ' + props.item.targetObjectName + ' (' + props.item.targetObjectId + ')' },                                
                { name: 'Initiated by', value: props.item.initiatedByName + ' (' + props.item.initiatedBy + ')' }
            ]} />            
            
            <div>
                <h3>Request Details</h3>
                <div className="mv-terminal">                    
                    <pre>
                        {JSON.stringify(JSON.parse(props.item.details), null, 2)}
                    </pre>
                </div>
            </div>                    

        </Panel>
    )
}