import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { SdkTenantClient } from "../lib/sdk/SdkTenantClient"
import { useState } from "react"
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient"
import { IDropdownOption, Label, PanelType, Spinner, SpinnerSize } from "@fluentui/react"
import { IFieldItem } from "../lib/forms/UnControlledFieldItem"
import { useForm } from "react-hook-form"
import { ControlledFieldList } from "../lib/forms/ControlledFieldList"

export interface IAddOrEditCustomerSubscriptionsProps extends IAppWindowBaseProps {   
    tenantId?: string
    customerId?: string
}

export const AddOrEditCustomerSubscriptions = (props: IAddOrEditCustomerSubscriptionsProps) => {
    
    const authInContext = useAuthInContext(props.tenantId as string)    

    const [processing, setProcessing] = useState<boolean>(true)
    const [managaebleSubscriptions, SetManagaebleSubscriptions] = useState<IDropdownOption[]>([])

    type SubscriptionData = {
        selectedSubscriptions: IFieldItem[]        
    }
    
    const { handleSubmit, control, reset, formState} = useForm<SubscriptionData>({
        defaultValues: { selectedSubscriptions: [] },
        reValidateMode: "onSubmit",
        mode: "all"        
    });

    useEffectAsync(async () => {

        if (props.tenantId === undefined || props.customerId === undefined || !props.isVisible) { return;}

        setProcessing(true)

        // get all the manageable subscriptions from the service provider tenant         
        const sdk = new SdkTenantClient(authInContext.currentToken as string);
        const availableSubscriptionsForServiceProvider = await sdk.getSubscriptions(props.tenantId as string)

        // get the managaable subscriptions for the customer
        let manageableSubscriptions: IDropdownOption[] = []
        availableSubscriptionsForServiceProvider.forEach((subscription) => {
            subscription.manageableSubscriptions.forEach((manageableSubscription) => {
                if (manageableSubscription.id && manageableSubscription.id.indexOf('hidden.') === 0) { return }
                manageableSubscriptions.push({ key: manageableSubscription.id, text: manageableSubscription.name })
            })                      
        })

        // set the manageable subscriptions
        SetManagaebleSubscriptions(manageableSubscriptions)        

        // lookup the assigned subscriptions
        const sdkCustomer = new SdkCustomerClient(props.tenantId as string, authInContext.currentToken as string);
        const assignedSubscriptions = await sdkCustomer.getSubscriptions(props.customerId as string)        
        reset({ selectedSubscriptions: assignedSubscriptions.map((s) => { return { key: s.id, field: s.id }}) })

        setProcessing(false)
    }, [props.tenantId, props.customerId])

    const dissmissDialog = () => {        
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = (): Promise<string | void> => {

        setProcessing(true)

        var submitHandler = handleSubmit((data: SubscriptionData) => {            
            const customerClient = new SdkCustomerClient(props.tenantId as string, authInContext.currentToken as string)
            return customerClient.setSubscriptions(props.customerId as string, data.selectedSubscriptions.map((s) => { return s.field }))
        })
        
        return submitHandler()
            .finally(() => {
                setProcessing(false)
            })
            .then(() => {
                dissmissDialog()
            })
            .catch((error) => {  
                let errorMessage = 'Something went wrong updating the subscriptions, please try again later'
                errorMessage += error.code ?  ' (' + error.code + ')' : ''            
                return errorMessage
            })                           
    }

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            type={PanelType.medium}            
            headerText={'Adjust Subscriptions'}
            onDismiss={dissmissDialog}                                                
            progessMessage={'Updating subscriptions...'}
            submitLabel={'Save'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid && !processing}
            onSubmit={onSubmit}>

                { processing ? 
                    (
                        <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}} />
                    ) : (
                        <>
                            <p>
                                Subscriptions defininig the capabilities customers are able to use in. Please add or remove subscriptions to extand or 
                                limit customers. Changing subscriptions has an impact how the system charges you as the service provider. Additionally 
                                customers will get access to more or less data sources in the end customer portal.
                            </p>                                 

                            <Label>Subscriptions</Label> 
                            <ControlledFieldList key={'selectedSubscriptions'} label={'selectedSubscriptions'} control={control} name={'selectedSubscriptions'}  availableFields={managaebleSubscriptions} disabled={false}/>
                        </>
                    )}            
        </PanelWithDefaultFooter>
    )
}