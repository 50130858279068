import './Stage.scss'

export interface StageProps {
    isVisible: boolean,
    brandLogo?: string,
    brandText?: string,
    headerTitle?: string,
    height?: string,
    children?: React.ReactNode
}

export function Stage(props: StageProps) {

    let stageStyle = { height: '50%' };
    if (props.height) { stageStyle.height = props.height }

    if (!props.isVisible)
        return (<></>)
    else
        return (
            <div className="mevodo-fluent-stage center shadow" style={stageStyle}>
                {props.brandText || props.brandLogo ? (
                    <div className="mevodo-fluent-stage-brand">
                        {props.brandLogo ? (<img src={props.brandLogo} alt="" width="30" height="24" className='d-inline-block align-text-top' />) : (<></>)}
                        {props.brandText ? (<span>&nbsp;{props.brandText}</span>) : (<></>)}
                    </div>
                ) : (<></>)}

                {props.headerTitle ? (
                    <div className="mevodo-fluent-stage-header">
                        <h1>{props.headerTitle}</h1>
                    </div>
                ): <></>}

                <div className="mevodo-fluent-stage-content">
                    {props.children}
                </div>            
            </div>
        )
}