import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useNavigate, useParams } from "react-router-dom"
import { IColumnsBuilder, ISubCommandDefinition } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ConfirmDialog, useConfirmDialog } from "../lib/ds/components/ConfirmDialog"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { ISdkTenant } from "../lib/sdk/models/ISdkTenant"
import { SdkProfileClient } from "../lib/sdk/SdkProfileClient"
import { SdkTenantClient } from "../lib/sdk/SdkTenantClient"
import { useProfileContext } from "../lib/authentication/AuthenticationProfileContext"
import { ActivityLogPanel } from "../lib/components/ActivityLog/ActivityLogPanel"
import { useState } from "react"

export interface ITenantManagementProps {

}
export const TenantManagement = (props: ITenantManagementProps) => {
    
    const nav = useNavigate();
    const profileContext = useProfileContext();
        
    // get the current tenant state
    const { tenantId } = useParams()         
    const currentTenant = profileContext.profile.tenants.find(t => t.id === tenantId)
    
    // inject the auth 
    const auth = useAuthInContext(tenantId as string);

    // some states
    const [subCommandTenant, setSubCommandTenant] = useState<ISdkTenant | undefined>(undefined)
    const [activityLogIsOpen, setActivityLogIsOpen] = useState(false)

    // get the confirmDialog 
    const [confirmDialog, confirmDialogService] = useConfirmDialog()    
        
    const columnClickOperation = (item: ISdkTenant) => {
        confirmDialogService.ask(
            'Switch Tenant',
            'Do you want to switch to the tenant ' + item.name + '?',
            () => {                
                nav('/tenants/' + item.id + '/settings/tenants')
                window.location.reload()
                return Promise.resolve()
            })
    }

    const columnSubCommands: ISubCommandDefinition<ISdkTenant>[] =  [
        { iconName: 'faListTimeline', description: 'Activity-Log of the tenant', onClick: (item) => { setSubCommandTenant(item); setActivityLogIsOpen(true)  }},                
    ]


    const buildColumns = (builder: IColumnsBuilder<ISdkTenant>): void  => 
    {        
        builder.IconColumn({name: "Type", headerIcon: 'faBuildings', iconName: 'faBuildings', onClick: columnClickOperation})
        builder.TextColumn({name: "Display Name", value: 'name', maxWidth: 250, onClick: columnClickOperation, subCommands: columnSubCommands})            
        builder.TextColumn({name: "Id", value: "id", maxWidth: 250, onClick: columnClickOperation})
        builder.TextColumn({name: "Information", value: item => item.id === tenantId ? 'active' : '', maxWidth: 250, onClick: columnClickOperation})        
    }
        
    const reloadData = async (): Promise<ISdkTenant[]> => {
        const sdkProfileClient = new SdkProfileClient(auth.currentToken as string);
        const profile = await sdkProfileClient.getProfile(tenantId)
        
        if (profile.contact.indexOf('@service-principals.accounts.mevodo.local') > 0) {
            return Promise.resolve(profile.tenants.filter(t => t.id === tenantId))
        } else {
            return Promise.resolve(profile.tenants)
        }        
    }

    const addTenant  = (): Promise<void> => {            
        nav('/welcome/signup')
        return Promise.resolve()
    }

    const onDeleteTenants = async (items: ISdkTenant[]): Promise<void> => {    
        
        // check if one of the selected tenants is the current tenant
        const currentTenant = items.find((t) => t.id === tenantId)
        if (currentTenant) {
            confirmDialogService.show(
                'Cannot delete current tenant', 
                'You cannot delete the tenant you are currently logged in to. Please switch to another tenant first.')
            
            return
        }

        // check if the current is owner of all the selected tenants        
        const sdkClient = new SdkProfileClient(auth.currentToken as string);

        // load the profile 
        const profile = await sdkClient.getProfile(tenantId)

        // build the access check request
        const accessCheckRequest = items.map(tenant => {
            return {
                id: profile.profileId,
                resourceIdentifier: 'mevodo.cloud.service.accounts/' + tenant.id + '/tenant',
                permissionIdentifier: 'mevodo.cloud.service.accounts/tenants/delete'
            }
        })

        // check the permissions
        const accessCheckResult = await sdkClient.checkAccess(accessCheckRequest)        

        // show the message when we are not allowed to execute the delte
        if (accessCheckResult.some(r => !r.allowed)) {
            confirmDialogService.show(
                'Cannot delete tenant', 
                'You cannot delete the selected tenant(s) because you are not the owner of all of them.')
            
            return
        }
        
        const sdkTenantClient = new SdkTenantClient(auth.currentToken as string);
        for (const tenant of items) {
            await sdkTenantClient.deleteTeanant(tenant)
        }   
        
        // referesh all 
        window.location.reload()
    }    

    return (
        <div className="mv-content-container">

            <PageHeader title={'Tenants Management'}>            
                <span>Manage all tenants your are part of. Many operations are only available for tenant owners!</span>
            </PageHeader>            
        
            <NavigationalDetailsListWithStandardOperations<ISdkTenant> 
                uniqueIdentifier='tenantsroot' 
                onBuildColumns={buildColumns} 
                onLoadData={reloadData} 
                onDeleteItems={currentTenant && !currentTenant.managed ? onDeleteTenants : undefined} 
                onNewItem={currentTenant && !currentTenant.managed ? addTenant : undefined}                                
            />        
            <ConfirmDialog {...confirmDialog}/>
            <ActivityLogPanel isVisible={activityLogIsOpen} dismissDialog={() => { setSubCommandTenant(undefined); setActivityLogIsOpen(false) }} tenantId={subCommandTenant?.id as string} objectType="tenant" objectId={subCommandTenant?.id as string} objectDisplayName={'tenant ' + subCommandTenant?.name as string}/>
        </div>
    )
}