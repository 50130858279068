import { ISdkCustomerCustomPropertyProfile, ISdkCustomerCustomPropertyProfileCreateRequest } from "./models/ISdkCustomerCustomPropertyProfile";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerCustomPropertyProfileClient {

    private sdkClient: SdkClient<ISdkCustomerCustomPropertyProfile>    
    
    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<ISdkCustomerCustomPropertyProfile>('/api/v1/tenants/' + tenantId + '/svp/custompropertyprofiles', token);
    }

    listCustomPropertyProfiles(parent?: string, parentType?: string): Promise<ISdkCustomerCustomPropertyProfile[]> 
    {        
        const parentId = parent ? parent : 'root'
        const parentTypeValue = parentType ? parentType : 'CustomerGroup'

        return this.sdkClient.list('?parentId=' + parentId + '&parentType=' + parentTypeValue);        
    }

    listEffectiveCustomPropertyProfiles(parent?: string, parentType?: string): Promise<ISdkCustomerCustomPropertyProfile[]> 
    {        
        const parentId = parent ? parent : 'root'
        const parentTypeValue = parentType ? parentType : 'CustomerGroup'

        return this.sdkClient.list('?parentId=' + parentId + '&parentType=' + parentTypeValue + '&effective=true');        
    }

    deleteCustomPropertyProfile(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }

    createCustomPropertyProfile(createRequest: ISdkCustomerCustomPropertyProfileCreateRequest): Promise<ISdkCustomerCustomPropertyProfile> 
    {                    
        return this.sdkClient.post('', createRequest).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })               
    }

    updatecustomPropertyProfile(id: string, createRequest: ISdkCustomerCustomPropertyProfileCreateRequest): Promise<ISdkCustomerCustomPropertyProfile> 
    {                    
        return this.sdkClient.put('/' + id, createRequest).then(async () => {
            return this.getCustomPropertyProfile(id)
        })               
    }

    getCustomPropertyProfile(id: string): Promise<ISdkCustomerCustomPropertyProfile>
    {
        return this.sdkClient.get('/' + id + '/details')
    }
}

