import { ISdkDashboardItem } from "../../../../sdk/models/ISdkDashboardItem";
import { IDashboardGridControlItemLayout, IDashboardGridControlLayoutService } from "../Contracts/IDashboardGridControlLayoutService";

export class DashboardGridControlLocalStorageLayoutService implements IDashboardGridControlLayoutService {

    getItemLayout(item: ISdkDashboardItem): IDashboardGridControlItemLayout {
        const layout = localStorage.getItem('mv-layout-' + item.dashboardId + '.' + item.id);
        return layout ? JSON.parse(layout) : { x: item.x, y: item.y, w: item.w, h: item.h}
    }   

    saveItemLayout(item: ISdkDashboardItem, layout: IDashboardGridControlItemLayout): Promise<void> {
        localStorage.setItem('mv-layout-' + item.dashboardId + '.' + item.id, JSON.stringify(layout));
        return Promise.resolve();        
    }
}