import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ClipboardTextField } from "../lib/forms/ClipBoardTextField"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { ISdkProfileApplicationDetails } from "../lib/sdk/models/ISdkProfileApplicationDetails"
import { SdkTenantUserProfileClient } from "../lib/sdk/SdkTenantUserProfileClient"

export interface ITenantUserManagementAddApplicationProps extends IAppWindowBaseProps {
    tenantId: string
}


export const TenantUserManagementAddApplication = (props: ITenantUserManagementAddApplicationProps) => { 
        
    // get the authenticaiton 
    const auth = useAuthInContext(props.tenantId as string)        
    
    const [error, setError] = useState<string>()
    const [isProcessing, { setTrue: startProccesing, setFalse: stopProcessing}] = useBoolean(false)
    const [applicationDetails, setApplicationDetails] = useState<ISdkProfileApplicationDetails>()

    const stackTokens = { childrenGap: 15 };
    
    const buttonStyles = { root: { marginRight: 8 } };


    type AddApplicationForm = {
        name: string
    };
    
    const { handleSubmit, control, reset } = useForm<AddApplicationForm>({
        defaultValues: {
            name: ''            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });

    const onSubmit: SubmitHandler<AddApplicationForm> = (data, e) => {        
        startProccesing();

        const userProfileClient = new SdkTenantUserProfileClient(props.tenantId, auth.currentToken as string)
        userProfileClient.registerApplication(data.name).then((applicationDetails) => {
            setApplicationDetails(applicationDetails)
            stopProcessing();            
        }).catch((error) => {  
            let errorMessage = 'Something went wrong creating the new application, please try again later'
            errorMessage += error.code ?  ' (' + error.code + ')' : ''
            setError(errorMessage)
            stopProcessing();
        })          
    }

    const dissmissDialog = () => {
        reset();
        setApplicationDetails(undefined)
        setError(undefined);
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onRenderFooterContentClose = useCallback(() => (
        <div>
            <PrimaryButton onClick={dissmissDialog} styles={buttonStyles}>Close</PrimaryButton>
        </div>
    // eslint-disable-next-line         
    ), [])

    const onRenderFooterContent = useCallback(() => (        
        <div>            
            { isProcessing ? (<ProgressIndicator label="Registering application..." />) : (<></>)}
            <PrimaryButton onClick={handleSubmit(onSubmit)} styles={buttonStyles} disabled={isProcessing}>Save</PrimaryButton>
            <DefaultButton onClick={dissmissDialog} disabled={isProcessing}>Cancel</DefaultButton>
        </div>
    // eslint-disable-next-line 
    ), [isProcessing])
    
    return (
        <Panel
            isOpen={props.isVisible}
            type={PanelType.medium}
            headerText={'Register new application'}
            onDismiss={dissmissDialog}                        
            onRenderFooterContent={applicationDetails ? onRenderFooterContentClose : onRenderFooterContent}
            isFooterAtBottom={true}>
                <p>Please enter a valid name for the application should get access to the
                    tenant. The application will be a tenant member by default, additional 
                    permissions must be configured in the user management</p>                 

                { error ? (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {'Error: ' + error}
                    </MessageBar>
                ) : (<></>)}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack tokens={stackTokens}>                
                        <ControlledTextField label="Name" control={control} name={'name'} rules={{ required: 'A valid name is required'}} disabled={isProcessing || applicationDetails !== undefined} />                                                                     
                    </Stack>                     
                </form>
                
                { applicationDetails ? (
                    <div>
                        <p><strong>The application was created successfully, please keep this information secure. This is a onetime view of the secret, you can't see the details again!</strong></p>

                        <ClipboardTextField value={applicationDetails.id} name={'applicationId'} label={'Application-Id'} readOnly={true} />
                        <ClipboardTextField value={applicationDetails.secret} name={'secret'} label={'Secret'} readOnly={true} />
                    </div>
                ) : (<></>)}
        </Panel>
    ) 
}

