import { Panel, PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { MetaDataList } from "../lib/ds/components/MetaDataList"
import { ISdkTenantInvoice } from "../lib/sdk/models/ISdkTenantInvoice"

export interface IPanelInvoiceDetailsProps extends IAppWindowBaseProps {
    tenantId: string
    invoice: ISdkTenantInvoice    
} 

export const PanelInvoiceDetails = (props: IPanelInvoiceDetailsProps) => {
    
    if (!props.isVisible)
        return (<></>)

    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    return (
        <Panel isOpen={props.isVisible} onDismiss={dissmissDialog} type={PanelType.medium} headerText={'Invoice Details: ' + props.invoice.invoiceId}>
            <ShowInvoiceDetails {...props} />                    
        </Panel>
    )
}

export const ShowInvoiceDetails = (props: IPanelInvoiceDetailsProps) => {
            
    return (
        <>            
            <div> 
                <h3>Invoice Details</h3>
                The invoice with the id <strong>{props.invoice.invoiceId}</strong> contains data 
                about <strong>{props.invoice.description}</strong>. Use the download button to 
                get access to the invoice document. 
            </div>

            <MetaDataList items={[
                { name: 'Id', value: props.invoice.invoiceId },
                { name: 'Balance', value: String(props.invoice.balance) },
                { name: 'Start', value: props.invoice.billingStartDate  },
                { name: 'End', value: props.invoice.billingEndDate }                
            ]} />                                

            <div>
                <h3>Connected Data Integrations</h3>
                {props.invoice.dataIntegrations.map(i => {
                    return (<p>{i.name}</p>)
                })} 
            </div>

            <div>
                <h3>Invoice Type</h3>
                <p>Providers: {props.invoice.billingProviders}</p>
                <p>Content: {props.invoice.description}</p>
            </div>
        </>
    )
}