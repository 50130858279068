import { IDropdownOption, PanelType, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useForm } from "react-hook-form";
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter";
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ControlledTextField } from "../lib/forms/ControlledTextField";
import { ControlledDropdown } from "../lib/forms/ControlledDropdown";
import { useEffect, useState } from "react";
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook";
import { SdkTenantIdentityProviderClient } from "../lib/sdk/SdkTenantIdentityProviderClient";

export interface IAddIdentityProviderPanelProps extends IAppWindowBaseProps {   
     tenantId: string     
}

export const AddIdentityProviderPanel = (props: IAddIdentityProviderPanelProps) => {
        
    const auth = useAuthInContext(props.tenantId);
        
    const [isSubmitProcessing, { setTrue: startSubmitProccesing, setFalse: stopSumitProcessing}] = useBoolean(false)
    const [templates, setTemplates] = useState<IDropdownOption[]>([])
    const [activeTemplate, setActiveTemplate] = useState<string | undefined>(undefined)

    type AddIdentityProviderForm = {
        name: string 
        type: string           
    };
    
    const { handleSubmit, control, reset, watch, formState } = useForm<AddIdentityProviderForm>({
        defaultValues: {
            name: '',       
            type: ''          
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });    

    const dissmissDialog = () => {
        reset()        
        if (props.dismissDialog)
            props.dismissDialog()
    }    

    const onSubmit = (): Promise<string | void> => {

        startSubmitProccesing()

        var submitHandler = handleSubmit(async (data: AddIdentityProviderForm) => {           
            const sdkClient = new SdkTenantIdentityProviderClient(props.tenantId as string, auth.currentToken as string); 
            await sdkClient.registerIdentityProvider(data.name, data.type)
            return Promise.resolve()
        })
        
        return submitHandler().finally(() => {
            stopSumitProcessing()
        }).then(() => {
            dissmissDialog()
        })            
    }
    
    // load the templates when the system becomes active
    useEffectAsync(async () => {

        const sdkClient = new SdkTenantIdentityProviderClient(props.tenantId as string, auth.currentToken as string);
        const loadedTemplates = await  sdkClient.listTemplates()        
        setTemplates(loadedTemplates.map((template) => {
            return {
                key: template.type,
                text: template.name
            }
        }))
    }, [])

    // establish the field watcher 
    const propertyWatcher = watch(['type'])    

    // establish the disable state     
    useEffect(() => {
        setActiveTemplate(propertyWatcher[0])        
    }, [propertyWatcher])
  
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            type={PanelType.medium}
            headerText={'Add Identity Provider'}
            onDismiss={dissmissDialog}                                                
            progessMessage={'Creating new identity provider...'}
            submitLabel={'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid}
            onSubmit={onSubmit}            
            errorMessagePrefix={'Something went wrong creating the new identity provider, please try again later'}>

                <p>Please select the identity provider type from the list below:</p>                 
                
                <Stack tokens={{ childrenGap: 15 }}>                
                    <ControlledDropdown label="Identity Provider Type" control={control} name={'type'} options={templates} rules={{ required: 'A valid identity provider type is required'}} disabled={isSubmitProcessing} />
                </Stack>                     

                { activeTemplate && (
                    <>
                    <p style={{paddingTop: '10px'}}>
                        The selected identity provider type requires the following information to be provided. Please fill in the required fields below:
                    </p>
                    <Stack tokens={{ childrenGap: 15 }}>                
                        <ControlledTextField label="Name" control={control} name={'name'} rules={{ required: 'A valid name of the identity provider is required'}} disabled={isSubmitProcessing} />                                                                 
                    </Stack>                     
                    </>
                )}
                
        </PanelWithDefaultFooter>
    )
}