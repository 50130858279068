import { useParams, useSearchParams } from "react-router-dom"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { IColumnsBuilder, ISubCommandDefinition } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { ISdkServiceCredential } from "../lib/sdk/models/ISdkServiceCredential"
import { useCommandPermissionEnforcer } from "../lib/commands/ComandPermissionEnforcer"
import { ICommandDescription } from "../lib/commands/ICommandDescription"
import { ICommandPermission } from "../lib/commands/ICommandPermission"
import { useBoolean } from "@fluentui/react-hooks"
import { useEffect, useState } from "react"
import { ISdkObjectFromTemplate, ISdkObjectFromTemplateCreate } from "../lib/sdk/models/ISdkObjectTemplate"
import { SdkCustomerServiceCredentialsClient } from "../lib/sdk/SdkCustomerServiceCredentialsClient"
import { useAuth } from "@mevodo/mv-react-authentication"
import { useNotifications } from "reapop"

import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import { ICommandBarService } from "../lib/components/DetailsListHelper/ComandBarService"
import { SdkAddOrEditTemplateBasedObjectPanel } from "../lib/sdkdatacomponents/SdkAddOrEditTemplateBasedObjectPanel"
dayjs.extend(relativeTime)

export interface IServiceProviderServiceCredentialsProps {

}

export const ServiceProviderServiceCredentials = (props: IServiceProviderServiceCredentialsProps) => {

    // get the authenticaiton 
    const auth = useAuth()

    // allow notifications
    const { notify } = useNotifications()
        
    // get the tenant id from the route
    const { tenantId } = useParams()    
    const [searchParams, setSearchParams] = useSearchParams();    
    
    const [isAddOrEditCredentialsOpen, { setTrue: showAddOrEditCredentials, setFalse: hideAddOrEditCredentials}] = useBoolean(false)    
    const [itemtoEdit, setItemToEdit] = useState<ISdkServiceCredential>()    
    const [refresh, { toggle: toggleRefresh} ] = useBoolean(false)
    const [selectedItems, setSelectedItems] = useState<ISdkServiceCredential[]>([])

    const onColumnCellClick = (item: ISdkServiceCredential) => {
        setItemToEdit(item)
        showAddOrEditCredentials()
    }

    const onReconsent = async (item: ISdkServiceCredential) => {

        // notify
        notify('Starting consent process, please wait', 'loading')            

        // request the reconsent location 
        const sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string);
        var nextStepLocation = await sdkClient.reconsentServiceCredetials (item.id, {finalLocation: window.location.href})
        
        // navigate to the location        
        window.location.href = nextStepLocation         
    }

    const columnSubCommands: ISubCommandDefinition<ISdkServiceCredential>[] =  [
        { iconName: 'faClipboard', description: 'Copies content to the clipboard', onClick: (item: ISdkServiceCredential) => {navigator.clipboard.writeText(item.id)}}        
    ]

    const columnNameSubCommands: ISubCommandDefinition<ISdkServiceCredential>[] =  [
        { iconName: 'faRetweet', description: 'Executes a re-consent flow for the given credentials', filter: (item: ISdkServiceCredential) => item.isConsentFlowRequired, onClick: onReconsent},
        ...columnSubCommands,        
    ]

    const onBuildColumns = (builder: IColumnsBuilder<ISdkServiceCredential>) => {
        builder.IconColumn({name: "Status", headerIcon: 'faShieldKeyhole', iconName: (item) => 'fas' + item.status, iconClass: (item) => 'status' + item.status.toLowerCase(), onClick: onColumnCellClick})                        
        builder.TextColumn({name: "Name", value: "name", maxWidth: 350, onClick: onColumnCellClick, subCommands: columnNameSubCommands})                  
        builder.TextColumn({name: "Id", value: "id", maxWidth: 250, onClick: onColumnCellClick, subCommands: columnSubCommands})                              
        builder.TextColumn({name: "Last Refresh", value: (item) => dayjs(item.lastRefresh).fromNow(), maxWidth: 150, onClick: onColumnCellClick})                              
        builder.TextColumn({name: "Status Message", value: "statusMessage", maxWidth: 350, onClick: onColumnCellClick})                              
    }

    const permissionEnforcer = useCommandPermissionEnforcer(tenantId as string, 'servicecrendentials', 'svpcustomerservicecredentials')
    const onEvaluateCommandPermissions = async (commands: ICommandDescription[]): Promise<ICommandPermission[]> => {
        return await permissionEnforcer.enforce(commands)        
    }

    const onNewItem = () => {
        showAddOrEditCredentials()
    }

    const onLoadData = (): Promise<ISdkServiceCredential[]> => {         
        const sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string);
        return sdkClient.listServiceCredetials()        
    }
   
    const onDeleteItems = (items: ISdkServiceCredential[]): Promise<void> => {
        const sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string);        
        return Promise.all(items.map((item) => sdkClient.deleteServiceCredentials(item.id))).then(() => {
            return Promise.resolve()
        })        
    }
    
    // build the commandbar    
    const handleDissmissWithReload = (cb: () => void) => {
        return () => {
            cb()  
            setItemToEdit(undefined)
            toggleRefresh()                      
        }
    }
    
    const checkConnectivity = () => {
        const sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string);
        const promisses = selectedItems.map((item) => 
            sdkClient.checkConnectivity(item.id).then((result) => {
                notify('Checking connectivity of the credential set ' + item.name + (result ? ' started successfuly...' : ' failed'), result ? 'success' : 'error', { dismissible: true, dismissAfter: 2000 })                
                return result
            }))                    
        return Promise.all(promisses)                        
    }

    const onSelectedItemsChanged = (selectedItems: ISdkServiceCredential[], allItems: ISdkServiceCredential[]): void => {
        setSelectedItems(selectedItems)
    }

    const onLoadTemplates = () => {
        const sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string);
        return sdkClient.listServiceCredetialsTemplates().then((templates) => {
            return templates.map((template) => {
                return {...template, submitTitle: template.metaData && template.metaData['ConsentFlow'] === 'true' ? 'Start consent process' : 'Create'}
            })
        })
    }

    const onCreate = async (item: ISdkObjectFromTemplateCreate) => {    
        
        var sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string);
        var nextStepLocation = await sdkClient.createServiceCredentials({...item, finalLocation: window.location.href})

        if ( nextStepLocation && nextStepLocation.indexOf('api/v1') === -1) {
            notify('Starting consent process, please wait', 'loading')            
            window.location.href = nextStepLocation 
        } 
        
        return Promise.resolve()
    }

    const onUpdate = async (item: ISdkObjectFromTemplate) => {
        var sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string)
        var nextStepLocation = await sdkClient.updateServiceCredetials(item.id, {...item, finalLocation: window.location.href})

        if ( nextStepLocation && nextStepLocation.indexOf('api/v1') === -1) {
            notify('Starting consent process, please wait', 'loading')            
            window.location.href = nextStepLocation 
        }
    }

    const onLoad = (itemId: string) => { 
        var sdkClient = new SdkCustomerServiceCredentialsClient(tenantId as string, auth.currentToken as string);
        return sdkClient.getServiceCredetials(itemId)               
    }

    const onAddCommands = (commandBarService: ICommandBarService, commandPermission: ICommandPermission[]) => {
        commandBarService.Command('verifyConnectivity', 'Verify Connectivity', 'faCloudScale', checkConnectivity, selectedItems.length === 0)        
    }
    
    useEffect(() => {        
    
        if (!searchParams.has('finish_consent')) { return }
        const consentResult = searchParams.get('finish_consent')
        searchParams.delete('finish_consent')
        setSearchParams(searchParams)

        if (consentResult === '1') {
            notify('Finished consent process succesfully, the system is checking the connectivity in the background...', 'success')            
        } else {
            notify('Finishing consent process failed, please remove the credentials and restart', 'error')            
        }
    
    // eslint-disable-next-line 
    }, [])

    return ( 
        <div className="mv-content-container">
            <PageHeader title={'Service Credentials'}>            
                <span>Service Credentials are an abstraction of different security and authentication mechanism widely used in the application. It's good style to create a dedicated service credential for every system
                    and re-use it in datasources and data integrations as well. 
                </span>
            </PageHeader>

            <NavigationalDetailsListWithStandardOperations 
                uniqueIdentifier={'servicecredentialsroot'}   
                onEvaluateCommandPermissions={onEvaluateCommandPermissions}             
                onBuildColumns={onBuildColumns}
                onNewItem={onNewItem}
                newItemLabel={'Add Credentials'}                
                onLoadData={onLoadData}
                hideReferesh={false} 
                refresh={refresh}
                onDeleteItems={onDeleteItems}                
                onAddCommands={onAddCommands}
                onSelectedItemsChanged={onSelectedItemsChanged}
                allowSorting={true} />
            
            <SdkAddOrEditTemplateBasedObjectPanel 
                isVisible={isAddOrEditCredentialsOpen} dismissDialog={handleDissmissWithReload(hideAddOrEditCredentials)} 
                tenantId={tenantId as string} itemId={itemtoEdit ? itemtoEdit.id : undefined}
                objectTitle={'Service Credentials'}
                objectDescription={'Service Credentials are an abstraction of different security and authentication mechanism widely used in the application. It\'s good style to create a dedicated service credential for every system and re-use it in datasources and data integrations as well.'}
                templateSelectorLabel="Select a template to create a new service credential"
                templateSelectorPlaceholder="Select a template"                
                onLoadTemplates={onLoadTemplates}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onLoad={onLoad}
                layerProps={{ eventBubblingEnabled: true }}
                />  
        </div>
    )
}