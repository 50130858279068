import { useParams } from "react-router-dom"
import { NavigationMenuSidebar } from "../lib/components/DetailsListHelper/NavigationMenuSideBar";

export const ServiceProviderTriggers = () => {

    const { tenantId } = useParams();

    return (    
        <NavigationMenuSidebar routes={[
            {name: 'Triggers', route: '/tenants/' + tenantId + '/svptriggers/triggers' }            
        ]} />        
    )
}