import { DropdownMenuItemType, IDropdownOption } from "@fluentui/react"
import { ISdkReportDefinition } from "../../sdk/models/ISdkReportDefintion"

export const reportGenerateGroupedDatasourceOptions = (datasources: ISdkReportDefinition[]) => {

    const groupTranslationMatrix: any = {
        'StaticDefaultCollection':          { groupId: 'systemConsumptionReports', groupText: 'Consumption Reports (System)', orderId: 100},
        'SystemDefaultCollection':          { groupId: 'systemConsumptionReports', groupText: 'Consumption Reports (System)', orderId: 100},        
        'SlaMonitoringReportDefinitions':   { groupId: 'slaMonitoringReports', groupText: 'SLA Monitoring Reports (System)', orderId: 100},
        'DataIntegrationsCollection':       { groupId: 'customerAnalysis', groupText: 'Customer Analysis (System)', orderId: 200},
        'CustomPropertiesCollection':       { groupId: 'customerAnalysis', groupText: 'Customer Analysis (System)', orderId: 200},
        'PartnerChannelCollection':         { groupId: 'customerAnalysis', groupText: 'Customer Analysis (System)', orderId: 200},
        'ServiceProviderReportCollection':  { groupId: 'customerAnalysis', groupText: 'Customer Analysis (System)', orderId: 200},
        'ServiceProviderUsageReports':      { groupId: 'usageReports', groupText: 'Usage Reports (System)', orderId: 300},        
        'IndividualReportsCollection':      { groupId: 'customerReports', groupText: 'Custom Reports', orderId: 400},
    }

    // split the datasoruces it groups by id-prefix
    const groupedDatasources: any[] = []
    datasources.forEach((item) => {

        // get the prefix 
        const prefix = item.id.split('.')[0]

        // lookup the translation item 
        const prefixTranslation = groupTranslationMatrix[prefix] || { groupId: prefix, groupText: prefix }

        // check if the group already exists
        const existingGroup = groupedDatasources.find((g) => g.id === prefixTranslation.groupId)
        if (existingGroup) {
            existingGroup.items.push(item)
        } else {
            groupedDatasources.push({ id: prefixTranslation.groupId, text: prefixTranslation.groupText, items: [item], orderId: prefixTranslation.orderId })
        }
    })

    // order items in the groups by name
    groupedDatasources.forEach((group) => {
        group.items = group.items.sort((a: ISdkReportDefinition, b: ISdkReportDefinition) => a.name.localeCompare(b.name))
    })

    // order the grouped datasources by order id
    groupedDatasources.sort((a, b) => a.orderId - b.orderId)

    // orde the data sources 

    // add all items of every group into the datasource options but create 
    // a group header element
    const datasourecOptions: IDropdownOption[] = []
    groupedDatasources.forEach((group) => {
                
        // generate teh group header
        datasourecOptions.push({ key: group.id, text: group.text, itemType: DropdownMenuItemType.Header })        

        // add all items
        group.items.forEach((item: ISdkReportDefinition) => {
            datasourecOptions.push({ key: item.id, text: item.name })
        })

        // add a divider
        datasourecOptions.push({ key: group.id + '_divider', text: '-', itemType: DropdownMenuItemType.Divider })
    })
    
    return datasourecOptions
}